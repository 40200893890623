import React, { Component } from 'react'
import GenericProfile from '../../components/ProfileLayout'
import Navbar from '../../components/Navbar'

export default class StaffProfile extends Component {
  render() {
    return (
      <>
        <Navbar
          navLinks={[
            {
              header: 'Staff |',
              path: '/staff'
            },
            {
              header: '| Profile',
              path: '#'
            }
          ]}
        />
        <GenericProfile />
      </>
    )
  }
}
