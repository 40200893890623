import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import 'chart.js';
import { ColumnChart } from 'react-chartkick';
import InfoDashlet from '../../app/components/centralDashboard/InfoDashlet';
import DashletSkeleton from './skeleton/DashletSkeleton';
import ResourceListSkeleton from './skeleton/ResourceListSkeleton';
import farmersdashlet from '../../assets/farmersdashlet.svg';
import farmermaps from '../../assets/farmermaps.svg';
import { api } from '../api/api.config';
import notify from '../helpers/notify';
import FarmsOnMap from './community/FarmsOnMap';
import BaseUrl from '../../security/base-url';
import { Button } from '@material-ui/core';
import { Loading} from "notiflix"
const { error } = notify();


const NewSummary = props => {
  const [userSeason, setuserSeason] = useState([]);
  const [userProjects, setuserProjects] = useState([]);
  const [chartData, setchartData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [farms, setFarms] = useState([]);
  const [selectedCommunity, setSelectedCommunity] = useState(0);
  const [viewMaps, setViewMaps] = useState(false);

  const getUserSeasons = async () => {
    setIsLoading(true);
    try {

       fetch(BaseUrl.url_new + 'cbn_dashboard_rest/seasons/' + props.username, {
              method: 'get',
              headers: {}
            })
              .then(response => response.json())
              .then(data => {
              setuserSeason(data);
              setIsLoading(false);
              })
              .catch(e => {
                error('Error changing Farm Status');
              }); 


      
    } catch (e) {
      //error(e);
    }
  };

  // const RenewPasswordPopup = () => {
  //   Swal({
  //     icon: 'info',
  //     title: 'Update your Password',
  //     text: 'To keep your account secure, goto Settings and update it',
  //     confirmButtonText: 'Okay',
  //     confirmButtonColor: '#96C63D'
  //   });
  // };

  useEffect(() => {
    getUserSeasons();

  }, []);

  useEffect(() => {
    if (isLoading)
    {
      Loading.dots()
    } else {
      Loading.remove();
    }
    
  },[isLoading])

  const getMapsForCommunityResource = async (crName)=> {
    try {
      
      

      if (crName !=  "") { 
           fetch(BaseUrl.url_new + 'cbn_dashboard_rest/maps/?communityResourceName=' + crName, {
              method: 'get',
              headers: {}
            })
              .then(response => response.json())
              .then(data => {
               setFarms(data);   
              })
              .catch(e => {
                error('Error changing Farm Status');
              }); 
           
       }
    } catch (e) {
      //error(e);
      console.log(e)
    }

  }
  const getProjects = async e => {
    if (e.target.value !== 0) {
      try {
        var user = localStorage.getItem('user');  
        fetch(BaseUrl.url_new + `cbn_dashboard_rest/communitiesAccessList/${e.target.value}/${user}`, {
              method: 'get',
              headers: {}
          })
                            .then(response => response.json())
                            .then(data => {
                              setuserProjects(data);
                            })
                            .catch(e => {
                              error('Error changing Farm Status');
                            });
                      
                    } catch (e) {
                      error(e);
                    }
                  } else {
                    setuserProjects(null);
                  }
                };

  const renderProgramsData = async e => {
    setIsLoading(true);
    if (e.target.value !== 0) {
      try {

          let crName = e.target.value;
          setSelectedCommunity(e.target.value)
          
        fetch(BaseUrl.url_new + `cbn_dashboard_rest/analytics/${props.username}?project=${e.target.value}`, {
              method: 'get',
              headers: {}
            })
              .then(response => response.json())
              .then(data => { 
                  setchartData(data);
                  setIsLoading(false);
                  getMapsForCommunityResource(crName)
              })
              .catch(e => {
                
                error('Error changing Farm Status');
              });
     
      } catch (e) {
        error(e);
      }
    } else {
      setuserProjects(null);
    }
  };

  return (
    <div>
      <div className='bg-white py-3 d-flex'>
        <div className='col-md-5 align-items-center d-flex'>
          <label htmlFor='community' className='m-0'>
            Choose Season
          </label>
          <select name='season' id='season' className='custom-select' onChange={getProjects}>
            <option key='choose-one' value='Choose One'>
              Choose one
            </option>
            {userSeason.map(season => (
              <option key={season}>{season}</option>
            ))}
           
          </select>
        </div>
        <div className='col-md-5 align-items-center d-flex'>
          <div htmlFor='farmingseason' className='mr-2 w-75'>
            Choose Project
          </div>
          <select
            name='projects'
            id='projects'
            className='custom-select'
            onChange={renderProgramsData}
          >
            {userProjects.map(project => (
              <option key={project}>{project}</option>
            ))}
            <option key='choose-one' value='Choose One'>
              Choose one
            </option>
          </select>
        </div>
      </div>

      <div className='container-fluid bg-light p-3'>
        <div className='container'>
          <div className='row my-4'>
            <div className='col-md-4'>
              {isLoading ? (
                <DashletSkeleton />
              ) : (
                <InfoDashlet
                  icon={farmersdashlet}
                  iconName='farmers'
                  title='Registered Farmers'
                  value={chartData.totalFarmers}
                />
              )}
            </div>
            <div className='col-md-4'>
              {isLoading ? (
                <DashletSkeleton />
              ) : (
                <InfoDashlet
                  icon={farmersdashlet}
                  iconName='processed'
                  title='Processed Farms'
                  value={chartData.totalProcessed}
                />
              )}
            </div>
            <div className='col-md-4'>
              {isLoading ? (
                <DashletSkeleton />
              ) : (
                <InfoDashlet
                  icon={farmermaps}
                  iconName='services'
                  title='Total Farm Size'
                  value={chartData.totalFarmSize}
                />
              )}
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='d-flex justify-content-between align-items-center'>
            <h5 className='text-black-50 h4'>Farmers By State</h5>
            <Button style={{ backgroundColor: "green", color: "#fff" }} onClick={() => {
              if (viewMaps) { 
              setViewMaps(false)
              } else {
                setViewMaps(true)
            }
            }}>{ viewMaps ? "View Charts":"View Maps"}</Button>
          </div>
          { !viewMaps ?  <div className='row mt-5' style={{height:"500px"}}>
            <div className='col-md-12'>
              {isLoading ? (
                <ResourceListSkeleton />
              ) : (
                <ColumnChart
                  data={chartData.charts}
                  dataset={[
                    { backgroundColor: '#96c63d' },
                    { backgroundColor: '#0085FF' },
                    { backgroundColor: '#c4c4c4' }
                  ]}
                  colors={['#96c63d', '#0085FF', '#c4c4c4']}
                  height={'90%'}
                  xtitle='States'
                  ytitle='Total Farmers'
                />
              )}
             
            </div>
          </div>
            : 
            
             <div className='row mt-5'>
            <div className='col-md-12'>
               <h6 className='bg-white ml-5 p-2 my-4 text-center'>MAPS</h6>
              {
                farms.length > 0 &&
                <FarmsOnMap farms={farms} />}
                </div>
            </div>
          }
          
         
        </div>
      </div>
      
    </div>
  );
};

const mapStateToProps = state => {
  return state;
};

export default connect(mapStateToProps)(NewSummary);
