//import accessStorage from './accessStorage';
import axios from 'axios';
import notify from '../helpers/notify';
const { error, success } = notify();

const retryRanges = [
  // https://en.wikipedia.org/wiki/List_of_HTTP_status_codes
  // 1xx - Retry (Informational, request still processing)
  // 2xx - Do not retry (Success)
  // 3xx - Do not retry (Redirect)
  // 4xx - Do not retry (Client errors)
  // 429 - Retry ("Too Many Requests")
  // 5xx - Retry (Server errors)
  [100, 199],
  [429, 429],
  [500, 599]
];

const isInErrorRange = status => {
  let isInRange = false;
  for (const [min, max] of retryRanges) {
    if (status >= min && status <= max) {
      isInRange = true;
      break;
    }
  }

  return isInRange;
};

const isNetworkError = statusText =>
  /The network connection was lost/i.test(statusText) ||
  /failed to fetch/i.test(statusText) ||
  /Network Error/i.test(statusText) ||
  /Could not connect to the server./i.test(statusText);

export default function errorHandler(error) {
  const { config, response } = error;
  const { headers } = config;
  //check error status code
  if (
    (isInErrorRange(response?.status) && !headers.retry) ||
    (isNetworkError(error.message) && !headers.retry)
  ) {
    headers.retry = true;
    return axios(config);
  }

  // Properply capture errors that lead to timeout here so the system will
  //auto logout user
  // if (
  //   response.status === "Cannot read property 'statusText' of undefined" ||
  //   response.status ===
  //     'Request header field retry is not allowed by Access-Control-Allow-Headers in preflight response.' ||
  //   response.status ===
  //     "No 'Access-Control-Allow-Origin' header is present on the requested resource"
  //   //i.test(response.statusText) ||
  // ) {
  //   localStorage.clear();
  //   window.location.href = '/';
  //   //accessStorage.clear('token', 'role', 'user');
  //   //window.location.reload();
  //   //requestLogout();
  //   return;
  // }

  // return Promise.reject(error);

  if (
    error.response &&
    error.response.data &&
    error.response.data.error &&
    (error.response.data.session === false || error.response.data.session === 'false')
  ) {
    localStorage.clear(); // <-- add your var
    window.location.href = '/'; // <-- add your path
  } else if (
    error.response &&
    error.response.data &&
    error.response.data.error &&
    error.response.data.error.message
  ) {
    error(error.response.data.error.message, 1);
  } else if (error.response && error.response.status === 401) {
    localStorage.clear(); // <-- add your var
    window.location.href = '/'; // <-- add your path
  } else return Promise.reject(error);
}
