import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Swal from 'sweetalert2'
import apiService from '../../api/apiService'
import { postedAgentDetails } from '../../components/dataTable/columnTitles'
import DataTable from '../../components/dataTable/DataTable'
import Navbar from '../../components/Navbar'
import TaskDropdown from '../../components/serviceProvider/assignedTaskList/TaskDropdown'
import Toolbar from '../../components/Toolbar'
import { getFilteredData } from '../../utils/resourceFilter'

export default class AssignedTask extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      activationInput: [],
      isLoading: false,
      searchValue: '',
      taskGroupInfo: {}
    }
  }

  componentDidMount() {
    //   setIsLoading(true)
    this.getGroupAgents()
    // setIsLoading(false)
  }

  handleDeleteAgent = async agent => {
    //  deleteAlert()
    const res = await apiService.get(`/postinggroup/deleteposting/${agent.id}`)
    if (res.code === 0) {
      this.getGroupAgents()
      Swal('success', res.message, 'success')
    } else {
      Swal('Error', '' + res.message, 'error')
    }
  }

  handleActivationStatus = async ({ id, status }) => {
    const { activationInput } = this.state
    const activationRes = await apiService.post(`/agents/updatesinglestatus/${id}/${status}`, {
      activationInput
    })
    // apiService.get(
    //   `/postinggroup/suspendposting/${id}/${status}`
    // );
    if (activationRes.code !== -1) {
      Swal('success', activationRes.message, 'success')
      this.getGroupAgents()
    } else {
      Swal('Error', ' ' + activationRes.message, 'error')
    }
  }

  getGroupAgents = async () => {
    const { assignedTaskId } = this.props.match.params
    this.setState({ isLoading: true })
    try {
      const agents = await apiService.get(`/postinggroup/findbygroupseason/${assignedTaskId}`)
      let taskGroup = {}
      const [taskGroupInfo] = agents.reduce(function(r, el) {
        if (!taskGroup[el.group_id]) {
          taskGroup[el.group_id] = {
            id: el.group_id,
            name: el.group_name,
            cost_per_farmer: el.cost_per_farmer,
            agents: []
          }
          r.push(taskGroup[el.group_id])
        }
        taskGroup[el.group_id].agents.push({
          id: el.posting_id,
          name: el.address + ' ' + el.surname,
          email: el.email,
          address: el.first_name,
          number: el.phone,
          community_name: el.community_name !== null ? el.community_name : '',
          status: el.status
        })
        return r
      }, [])
      this.setState({ taskGroupInfo })
    } catch (reason) {
      console.error(reason)
    } finally {
      this.setState({ isLoading: false })
    }
  }

  onSearch = e =>
    this.setState({
      [e.target.name]: e.target.value
    })

  handleActivationInput = e => {
    const { activationInput } = this.state
    let newInput =
      activationInput.find(input => input === e.target.value) === undefined
        ? [...activationInput, e.target.value]
        : activationInput.filter(arrItem => arrItem !== e.target.value)
    this.setState({ activationInput: newInput })
    // setActivationInput(newInput);
  }

  render() {
    const {
      match: {
        params: { assignedTaskId }
      }
    } = this.props
    const { taskGroupInfo, isLoading, searchValue } = this.state
    return (
      <>
        <Navbar
          navLinks={[
            {
              header: `Shared Agency |`,
              path: `#`
            },
            {
              header: `| Assigned Tasks |`,
              path: `#`
            },
            {
              header: taskGroupInfo.name,
              path: '#'
            }
          ]}
          small='View and manage service provider'
        />
        <div className='bg-white'>
          <div className='text-right p-1 mx-4 h6'>
            Cost Per Farmer: {taskGroupInfo & taskGroupInfo.cost_per_farmer}
          </div>
          <Toolbar onSearch={this.onSearch} />
          <DataTable
            {...this.props}
            columnTitle={postedAgentDetails}
            data={
              taskGroupInfo.agents &&
              getFilteredData(taskGroupInfo.agents, searchValue, 'assignedTaskFilterTag')
            }
            dropdown={TaskDropdown}
            isLoading={isLoading}
            rowUrl={`${assignedTaskId}/agent-profile`}
          />
        </div>
      </>
    )
  }
}
