import React, { Component } from 'react'
import Navbar from '../../components/Navbar'
import NewDataModal from '../../components/modals/NewDataModal'
import Spinner from '../../components/Spinner'
import ResourceItem from '../../components/resources/resourcesList/ResourceItem'
import BaseUrl from '../../../security/base-url'

export default class WarehouseResourceList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      warehouseResourceList: [],
      isUpdate: false
    }
  }

  /*
   ** TODO: Retrieve all Warehouse facilities
   */
  componentDidMount() {
    // fetch(BaseUrl.url_dashbord + '', {
    //   method: 'GET',
    //   headers: {
    //     Accept: 'application/json'
    //   },
    //   mode: 'cors'
    // })
    //   .then(res => res.json())
    //   .then(warehouseResourceList =>
    //     this.setState({ warehouseResourceList: warehouseResourceList.reverse() })
    //   )
    //   .catch(err => console.log(err));
  }

  // TODO: Save New Resource
  handleSaveResource = () => {
    console.log()
    fetch(BaseUrl.url_dashbord + '', {
      method: 'POST',
      headers: {
        Accept: 'application/json'
      },
      mode: 'cors'
    }).then(res => res.json())
  }

  openEditForm = resource => {
    this.setState({ isUpdate: true })
    document.getElementById('editdrpdwn').setAttribute('data-target', '#warehouseResourceModal')
    document.getElementById('editdrpdwn').click()
  }

  resetUpdateState = () => this.setState({ isUpdate: false })

  renderWarehouseList = () => {
    const { warehouseResourceList } = this.state
    return warehouseResourceList.map(resource => (
      <ResourceItem
        listItem={resource}
        modalName='Warehouse'
        modalUpdate={this.openEditForm}
        key={resource.id}
      />
    ))
  }

  render() {
    return (
      <div className='main-content container-fluid'>
        <Navbar
          navLinks={[
            {
              path: '/resources/warehouse',
              header: 'Warehouses'
            }
          ]}
          small='Add or Remove Warehouse Resources on the Platform'
        />
        <div className='bg-white py-1 px-2'>
          <>
            <div className='d-flex w-100 my-2 py-2 align-items-center'>
              <input type='checkbox' className='mx-2' />
              <select className='mr-2 custom-select w-20 form-control'>
                <option value='Select actions...'>Select actions...</option>
                <option value='Nil'>Nil</option>
              </select>
              <i className='fas fa-filter text-black-50 input-prepend' />
              <input className='form-control w-20 mr-auto' type='search' placeholder='Search' />
              <span className='badge badge-pill badge-warning'>
                {this.state.warehouseResourceList.length} Warehouses
              </span>
              <button
                className='btn btn-revenue text-white btn-sm px-3 ml-2'
                data-toggle='modal'
                data-target='#warehouseResourceModal'
              >
                <i className='fas fa-plus fa-sm mr-2' />
                <span>Add Warehouse Resources</span>
              </button>
            </div>

            <div className='fix-height revenue-scrollbar p-1'>
              <ul className='list-group'>
                <Spinner data={this.state.warehouseResourceList}>
                  {this.renderWarehouseList()}
                </Spinner>
              </ul>
            </div>
          </>
        </div>
        <NewDataModal
          modalId='warehouse'
          modalName='Warehouse'
          isUpdate={this.state.isUpdate}
          resetUpdateState={this.resetUpdateState}
          saveResource={this.handleSaveResource}
        />
      </div>
    )
  }
}
