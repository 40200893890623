//import apiService from './api/apiService';
import {
  RENEW_TOKEN,
  REQUEST_LOGOUT,
  REQUEST_LOGOUT_SUCCESS,
  REQUEST_USER,
  REQUEST_USER_FAILED,
  REQUEST_USER_SUCCESS,
  RESET_ISLOGGING_IN
} from './app.constant';

export const requestLogin = ({ creds, history }) => {
  return {
    type: REQUEST_USER,
    payload: {
      creds,
      history
    }
  };
};

export const loginSuccessful = (user, validity) => {
  localStorage.setItem("userAccountId",user.userAccountId);
  return {
    type: REQUEST_USER_SUCCESS,
    payload: {
      user,
      validity
    }
  };
};

export function loginFailed(message) {
  return {
    type: REQUEST_USER_FAILED,
    payload: { message }
  };
}

export const resetIsLoggingIn = () => ({
  type: RESET_ISLOGGING_IN
});

export function requestLogout(payload) {
  return {
    type: REQUEST_LOGOUT,
    payload
  };
}

export function logoutSuccessful() {
  return {
    type: REQUEST_LOGOUT_SUCCESS
  };
}

export const renewToken = () => ({
  type: RENEW_TOKEN
});

//FIXME: is this necessary?
// export function siteAutoLogin(rand, username) {
//   return dispatch => {
//     dispatch(requestLogin({ username }))
//     return apiService
//       .get(
//         `/auth/siteAuto/?siteAuto=true&username=${username}&rand=
//         ${rand}`
//       )
//       .then(response => response.json().then(user => ({ user, response })))
//       .then(({ user, response }) => {
//         if (!response.ok) {
//           // If there was a problem, we want to
//           // dispatch the error condition
//           dispatch(loginFailed(user.message))
//           return Promise.reject(user)
//         } else {
//           // If login was successful, set the token in local storage
//           // localStorage.setItem('id_token', user.id_token)
//           // localStorage.setItem('id_token', user.access_token)
//           localStorage.setItem('token', response.headers.get('Authorization'))

//           // Dispatch the success action
//           var data = response.headers.get('Validity')
//           dispatch(loginSuccessful(user, data))
//         }
//       })
//       .catch(err => {
//         dispatch(loginFailed('Login Failed, please try again'))
//       })
//   }
// }
