import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import MaterialTable from 'material-table';
import InfoDashlet from '../centralDashboard/InfoDashlet';
import DashletSkeleton from '../skeleton/DashletSkeleton';
import { recoveryColumns } from '../../utils/tablecolumns';

const Recovery = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [recovery, setRecovery] = useState([]);
  const [totalFarmers, setTotalFarmers] = useState(0);
  const [totalAmountDistbursed, setTotalAmountDistbursed] = useState(0);
  const [totalAmountRecovered, setTotalAmountRecovered] = useState(0);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    fetch(
      process.env.REACT_APP_BASEURL + '/recoverysummary/' + props.communityResourceId,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json'
        }
      },
      { signal: signal }
    )
      .then(response => response.json())
      .then(response => {
        setRecovery(response.data);
        setTotalFarmers(response.totalFarmers);
        setTotalAmountRecovered(response.totalAmountRecovered);
        setTotalAmountDistbursed(response.totalAmountDistbursed);


        console.log(response.data);
      })
      .catch(error => {
        console.log(error);
      });
    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <>
      <div>
        {' '}
        <h3 className=' my-1'>Summary of Recovery</h3>
      </div>
      <div className='row my-4'>
        <div className='col-md-4'>
          {isLoading ? <DashletSkeleton /> : <InfoDashlet title='Total Farmers' value={totalFarmers} />}
        </div>
        <div className='col-md-4'>
          {isLoading ? (
            <DashletSkeleton />
          ) : (
            <InfoDashlet title='Total Disbursed' value={`₦` + totalAmountDistbursed} />
          )}
        </div>
        <div className='col-md-4'>
          {isLoading ? (
            <DashletSkeleton />
          ) : (
            <InfoDashlet title='Total Recovered' value={`₦` + totalAmountRecovered} />
          )}
        </div>
      </div>
      <div className='row my-4'>
        <div className='col-md-12'>
          <div className='card'>
            <div className='bg-secondary p-2 d-flex flex-column justify-content-between'>
              <div>
                <MaterialTable
                  onRowClick={(_, rowData) =>
                    props.history.push({
                      pathname: `/community-resource/recoverydetails/${rowData.id}`,
                      state: { recovery }
                    })
                  }
                  columns={recoveryColumns}
                  data={recovery}
                  style={{
                    fontFamily: 'Nunito',
                    width: '100%',
                    boxShadow: 'none'
                  }}
                  options={{
                    actionsColumnIndex: 4,
                    sorting: true,
                    showTitle: false,
                    exportButton: true,
                    searchFieldAlignment: 'left',
                    loadingType: 'linear',
                    headerStyle: {
                      backgroundColor: '#4f4f4f',
                      color: '#FFF'
                    }
                  }}
                  components={{
                    CircularProgress: props => (
                      <div style={{ backgroundColor: '#4f4f4f' }}>
                        <CircularProgress {...props} />
                      </div>
                    )
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

//export default Reports;

// const mapStateToProps = state => {
//   return {
//     isLoading: state.comReducer.isLoading,
//     communityResourceList: state.comReducer.communityResourceList
//   };
// };

export default withRouter(Recovery);

// function mapStateToProps(state) {
//   return;
//   getCommunityResource: getAllCommunityResource(state);
// }

// export default connect(mapStateToProps)(Reports);
