import { useReducer } from 'react';

const useServiceProvider = () => {
  const initialState = {
    serviceProviderData: '',
    searchValue: '',
    providerName: '',
    description: '',
    hasUpdated: false
  };

  const reducer = (state = initialState, { type = 'DEFAULT', payload }) => {
    const userActions = {
      RESET: {
        ...state,
        providerName: '',
        description: '',
        hasUpdated: false
      },
      EDIT: payload && {
        ...state,
        description: payload.description,
        hasUpdated: true,
        providerName: payload.name,
        serviceProviderData: payload
      },
      ON_CHANGE: { ...state, ...payload }
    };
    return userActions[type] || state;
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  return [state, dispatch];
};
export default useServiceProvider;
