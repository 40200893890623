import React, { memo } from 'react'

const ImportResourceModal = ({ importInfo }) => {
  return (
    <div className='modal fade' id='importModal' tabIndex={-1} role='dialog' aria-hidden='true'>
      <div className='modal-dialog' role='document'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h4>Upload CSV file</h4>
            <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
          <div className='modal-body p-4'>
            <form>
              <input
                type='file'
                className='p-2 w-100'
                name='resource-list'
                accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
              />
              <div className='text-right'>
                <button
                  type='button'
                  className='btn btn-expend text-white mx-3'
                  data-dismiss='modal'
                >
                  Close
                </button>
                <button onClick={importInfo} type='button' className='btn btn-revenue text-white'>
                  Save changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
export default memo(ImportResourceModal, (prevProps, nextProps) => prevProps === nextProps)
