// This is used in the Add/Edit EOP table
export const addediteop = [
  {
    header: 'Name',
    accessor: 'name'
  },
  {
    header: 'Unit of Measurement',
    accessor: 'unitOfMeasurement'
  },
  {
    header: 'Quantity Per Hectare',
    accessor: 'quantityPerHectare'
  },
  {
    header: 'Cost Per Hectare ₦',
    accessor: 'unitCost'
  },
  {
    header: 'Action',
    accessor: 'action'
  }
];

// This is used in the Farmers Profile EOP table
export const farmerseop = [
  {
    Header: 'Input',
    accessor: 'inputDistribusionInputAllocatedId.inputId.name'
  },
  {
    Header: 'Expected Qty',
    accessor: 'inputDistribusionInputAllocatedId.inputId.quantityPerHectare',
    width: 100
  },
  {
    Header: 'Collected Qty',
    accessor: 'inputDistribusionInputAllocatedId.quantityCollected',
    width: 100
  },
  {
    Header: 'Amount (₦)',
    accessor: 'inputDistribusionInputAllocatedId.inputId.unitCost'
  }
];

// This is used in Extensions Resource page
export const issues = [
  {
    header: 'Issuer',
    accessor: 'issuer'
  },
  {
    header: 'Requests',
    accessor: 'requests'
  },
  {
    header: 'Issue Summary',
    accessor: 'summary'
  },
  {
    header: 'Location(s)',
    accessor: 'location'
  }
];

// This is used in Extensions Resource page
export const txHistory = [
  {
    header: 'Person',
    accessor: 'person'
  },
  {
    header: 'Account Number',
    accessor: 'account'
  },
  {
    header: 'Amount',
    accessor: 'amount'
  },
  {
    header: 'Task/Posting',
    accessor: 'task'
  },
  {
    header: 'Date',
    accessor: 'date'
  }
];

// This is used in Extensions Resource - Farmer Issues page
export const farmerissues = [
  {
    header: 'Issue',
    accessor: 'issue'
  },
  {
    header: 'Requested Visiting Day',
    accessor: 'visiting'
  },
  {
    header: 'Contact Information',
    accessor: 'contact'
  },
  {
    header: 'Location(s)',
    accessor: 'location'
  }
];

export const farmers = [
  {
    header: 'Surname',
    accessor: 'surname'
  },
  {
    header: 'FirstName',
    accessor: 'firstName'
  },

  {
    header: 'Occupation',
    accessor: 'type'
  },
  {
    header: 'PhoneNumber',
    accessor: 'phone'
  }
];

export const agent = [
  {
    title: 'Name',
    field: 'surname'
  },
  {
    title: 'State',
    field: 'state'
  },
  {
    title: 'Status',
    field: 'status'
  },

  {
    title: 'Phone Number',
    field: 'phone'
  }
];

export const postedAgentDetails = [
  {
    header: 'Name',
    accessor: 'name'
  },
  {
    header: 'Community',
    accessor: 'community_name'
  },
  {
    header: 'Number',
    accessor: 'number'
  },
  {
    header: 'Posting status',
    accessor: 'status'
  }
];
export const sharedAgency = [
  {
    header: 'Name',
    accessor: 'name'
  },
  {
    header: 'Status',
    accessor: 'status'
  },
  {
    header: 'Created On',
    accessor: 'createdOn'
  }
];
export const inputSupply = [
  {
    header: 'Supplier',
    accessor: 'supplier'
  },
  {
    header: 'Commodity Supplied',
    accessor: 'commoditySupplied'
  },
  {
    header: 'Email',
    accessor: 'email'
  },
  {
    header: 'Location',
    accessor: 'location'
  }
];
export const labour = [
  {
    header: 'Name',
    accessor: 'name'
  },
  {
    header: 'Role',
    accessor: 'role'
  },
  {
    header: 'Email',
    accessor: 'email'
  },
  {
    header: 'Location',
    accessor: 'Location'
  }
];
export const jobPosting = [
  {
    header: 'Job',
    accessor: 'job'
  },
  {
    header: 'Posting Area',
    accessor: 'validity'
  },
  {
    header: 'Employee Experience',
    accessor: 'experience'
  },
  {
    header: 'Duties & Responsibilites',
    accessor: 'duties'
  }
];
export const capturedData = [
  {
    header: 'Task Group',
    accessor: 'id'
  },
  {
    header: 'Posting Cycle',
    accessor: 'resourceName'
  },
  {
    header: 'Total Captured',
    accessor: 'total'
  }
];
export const capturedDataDetails = [
  {
    header: 'Name',
    accessor: 'name'
  },
  {
    header: 'Posting Area/Location',
    accessor: 'ward'
  },
  {
    header: 'BVN',
    accessor: 'bvn'
  }
];
export const transactionHistory = [
  {
    header: 'Posting Group',
    accessor: 'postingGroup'
  },
  {
    header: 'Total Farmers Captured',
    accessor: 'farmersCaptured'
  },
  {
    header: 'Total Cost',
    accessor: 'totalCost'
  }
];
export const staffDetails = [
  {
    header: 'Name',
    accessor: 'surname'
  },
  {
    header: 'State',
    accessor: 'state'
  },

  {
    header: 'Status',
    accessor: 'status'
  },
  {
    header: 'Phone Number',
    accessor: 'phoneNumber'
  }
];
