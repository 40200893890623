import React from 'react'
import PropTypes from 'prop-types'

const ServiceProviderTools = ({ totalServiceProviders }) => (
  <>
    <span className='badge badge-pill badge-warning mx-2'>
      {totalServiceProviders} Service Providers
    </span>
    <button
      className='btn btn-revenue text-white btn-sm px-3 mr-3'
      id='serviceProviderBtn'
      data-toggle='modal'
      data-target='#serviceProviderForm'
    >
      <i className='fas fa-plus fa-sm mr-2' />
      <span> Add Service Provider </span>
    </button>
  </>
)

ServiceProviderTools.propTypes = {
  totalServiceProviders: PropTypes.number.isRequired
}

export default ServiceProviderTools
