import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Form, InputGroup } from 'react-html5-form'

export default class SharedAgencyForm extends Component {
  static propTypes = {
    handleSaveSharedAgency: PropTypes.func.isRequired,
    rowData: PropTypes.object.isRequired,
    isEditing: PropTypes.bool.isRequired,
    resetForm: PropTypes.func.isRequired
  }

  saveSharedAgency = () => {
    const { rowData, isEditing, handleSaveSharedAgency } = this.props
    handleSaveSharedAgency(document.querySelector('#agencyName').value, rowData.id, isEditing)
  }

  render() {
    const { isEditing, resetForm } = this.props
    return (
      <div
        className='modal fade'
        id='sharedAgencyModal'
        data-backdrop='static'
        data-keyboard='false'
        tabIndex={-1}
        role='dialog'
        aria-hidden='true'
      >
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h4> {!isEditing ? `Add New Shared Agency` : `Edit Shared Agency`} </h4>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
                id='close'
                onClick={resetForm}
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              <Form id='resourceForm' name='resourceForm' onSubmit={this.saveSharedAgency}>
                {() => (
                  <>
                    <InputGroup tag='div' validate={['name']}>
                      {({ error, valid }) => (
                        <div className='col-md-12 my-2'>
                          <label htmlFor='agencyName'> Shared Agency's Name </label>
                          <input
                            type='text'
                            required
                            className={`form-control form-control-sm ${!valid && 'is-invalid'}`}
                            id='agencyName'
                            name='name'
                          />
                          {error && <div className='invalid-feedback'>{error}</div>}
                        </div>
                      )}
                    </InputGroup>

                    <div className='modal-footer border-top-0'>
                      <button
                        type='button'
                        className='btn btn-expend text-white'
                        data-dismiss='modal'
                        onClick={resetForm}
                      >
                        Close
                      </button>
                      <button
                        type='submit'
                        id='submitButton'
                        name='submitButton'
                        className={!isEditing ? 'btn btn-revenue text-white float-right' : 'hidden'}
                      >
                        Add
                      </button>
                      <button
                        type='submit'
                        id='updateButton'
                        name='updateButton'
                        className={isEditing ? 'btn btn-revenue text-white float-right' : 'hidden'}
                      >
                        Save changes
                      </button>
                    </div>
                  </>
                )}
              </Form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
