import { servicePRoviderActions } from '../pages/serviceProvider/serviceProvider.constants';

export const ALERT_FAILED = 'ALERT_FAILED';
export const ALERT_SUCCESS = 'ALERT_SUCCESS';
export const CLEAR_ALERT = 'CLEAR_ALERT';

export const actions = {
  REQUEST_DASHBOARD_DATA: 'REQUEST_DASHBOARD_DATA',
  REQUEST_DASHBOARD_DATA_SUCCESS: 'REQUEST_DASHBOARD_DATA_SUCCESS',
  REQUEST_DASHBOARD_DATA_FAILED: ' REQUEST_DASHBOARD_DATA_FAILED',
  REQUEST_TRASH: 'REQUEST_TRASH',
  REQUEST_TRASH_SUCCESS: 'REQUEST_TRASH_SUCCESS',
  REQUEST_TRASH_FAILED: 'REQUEST_TRASH_FAILED',
  ...servicePRoviderActions
};
