import React, { Component } from 'react'
import { Form, InputGroup } from 'react-html5-form'
import Loader from '../../../assets/loader.gif'
import { issues } from '../../components/dataTable/columnTitles'
import DataTable from '../../components/dataTable/DataTable'

export default class ExtensionResourceIssues extends Component {
  constructor(props) {
    super(props)
    this.state = {
      issue: [
        {
          id: 1,
          issuer: 'Adewale Tijani',

          requests: 4,
          summary: 'Rice Decoloration Help!!',
          location: 'Yauri, Sokoto North'
        },
        {
          id: 2,
          issuer: 'Maitama Tambuwal',
          requests: 14,
          summary: 'Rice Decoloration Help!!',
          location: 'Isoko Central, Bendel'
        }
      ],
      isUpdate: false
    }
  }
  render() {
    const issueId = props => this.props.match.params === this.state.issue.id
    return (
      <div className='container-fluid main-content'>
        {/* <Navbar
            navLinks={[
              {
                path: "/resources/extension",
                header: "Extension"
              }
            ]}
            small="Manage Reports from Extension Workers"
          /> */}

        <div className='row mx-0 bg-white'>
          <div className='col-sm-12 text-right d-flex w-100 py-3 align-items-center'>
            <input type='checkbox' name='' className='mx-2' />
            <select className='mr-3 custom-select form-control w-20'>
              <option value='Select actions...'>Select actions...</option>
              <option value='Nil'>Nil</option>
            </select>
            <button className='btn btn-secondary'>Apply</button>
            <i className='fas fa-filter text-black-50 input-prepend' />

            <input
              className='mr-auto form-control w-20'
              type='search'
              placeholder='Search'
              // onSubmit={filterResource(e)}
            />

            {/* <NavLink to="#" className="btn btn-sm btn-revenue text-white p-2">
                <i className="fas fa-plus fa-sm mx-1" />
                <span>Add Schedule</span>
              </NavLink> */}
          </div>
          <div className='col-md-12'>
            {/* <ReactTable
                       className="-striped -highlight"
                data={this.props.allCommunities}
                defaultPageSize={10}
                noDataText={"Loading... Please Wait"}
              /> */}
            {this.state.issue.length === 0 ? (
              <div className='d-flex justify-content-center w-25 mx-auto'>
                <img src={Loader} alt='loader' className='my-auto' />
              </div>
            ) : (
              <DataTable
                columnTitle={issues}
                className='-striped -highlight w-100'
                getRowData={this.getcapturedDataInfo}
                rowData={this.state.data}
                rowUrl={`farmer-issues/${issueId}`}
                defaultPageSize={10}
                data={this.state.issue}
              />
            )}
          </div>
        </div>

        {/* Add Outbreak Modal */}
        <div
          className='modal fade'
          id='outbreakModal'
          data-backdrop='static'
          data-keyboard='false'
          tabIndex={-1}
          role='dialog'
          aria-hidden='true'
        >
          <div className='modal-dialog' role='document'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h4> Submit Outbreak</h4>
                <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-body'>
                <Form id='resourceForm' name='resourceForm'>
                  {({ error, valid, submitting, pristine, form }) => (
                    <>
                      <InputGroup tag='div' validate={['type']}>
                        {({ error, valid }) => (
                          <div className='col-md-12 my-2'>
                            <div className='form-group'>
                              <label htmlFor='type'>Type</label>
                              <select className='form-control' id='type'>
                                <option>Pest</option>
                                <option>Disease</option>
                                <option>Weed</option>
                              </select>
                            </div>
                            {error && <div className='invalid-feedback'>{error}</div>}
                          </div>
                        )}
                      </InputGroup>
                      <InputGroup tag='div' validate={['type']}>
                        {({ error, valid }) => (
                          <div className='col-md-12 my-2'>
                            <div className='form-group'>
                              <label for='type'>Severity</label>
                              <select className='form-control' id='type'>
                                <option>High</option>
                                <option>Low</option>
                                <option>Medium</option>
                              </select>
                            </div>
                            {error && <div className='invalid-feedback'>{error}</div>}
                          </div>
                        )}
                      </InputGroup>
                      <InputGroup tag='div' validate={['description']}>
                        {({ error, valid }) => (
                          <div className='col-md-12 my-3'>
                            <textarea
                              className={`form-control form-control-sm ${!valid && 'is-invalid'}`}
                              required
                              id='description'
                              placeholder='Enter description'
                              name='description'
                              rows='3'
                            />

                            {error && <div className='invalid-feedback'>{error}</div>}
                          </div>
                        )}
                      </InputGroup>
                      <div className='modal-footer'>
                        <button
                          type='button'
                          className='btn btn-expend text-white'
                          data-dismiss='modal'
                        >
                          Close
                        </button>

                        <button
                          type='submit'
                          id='updateButton'
                          name='updateButton'
                          className='btn btn-revenue text-white float-right'
                          disabled={pristine || submitting}
                        >
                          Submit
                        </button>
                      </div>
                    </>
                  )}
                </Form>
              </div>
            </div>
          </div>
        </div>
        {/* Outbreak Modal Ends */}
      </div>
    )
  }
}
