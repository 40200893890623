/* eslint-disable jsx-a11y/no-onchange */
import React, { PureComponent } from 'react';
import { NavLink, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import {
  getCommunityResource,
  handleAnalyticsName,
  getAnalytics
} from '../community/communities.actions';
import WithSubRoutes from '../../HOC/WithSubRoutes';

class Analytics extends PureComponent {
  componentDidMount() {
    this.props.getAllCommunityResource();
  }

  initAnalytics = e => {
    const {
      target: { value }
    } = e;
    const [selectedResource] = this.props.communityResourceList.filter(
      communityResource => communityResource.name === value
    );

    if (Object.prototype.hasOwnProperty.call(selectedResource, 'name')) {
      this.props.handleAnalyticsName(selectedResource.name);
      this.props.getAnalytics(selectedResource.id);
    }
  };

  renderAnalyticsRoutes = () => {
    const analyticsRoutes = this.props?.routes.map(route => (
      <WithSubRoutes key={route.path} {...route} analytics={this.props?.analytics} />
    ));
    return analyticsRoutes;
  };

  renderSelectProgramOptions = () => {
    const isResource = Object.entries(this.props.communityResourceList).length > 0;
    return isResource ? (
      this.props.communityResourceList.map(communityResource => {
        return (
          <option
            key={communityResource.name + communityResource.id}
            value={communityResource.name}
          >
            {communityResource.name}
          </option>
        );
      })
    ) : (
      <option key='no community' value='No community resource'>
        No community resource
      </option>
    );
  };

  render() {
    const {
      match: { url },
      location
    } = this.props;
    return (
      <div className='container-fluid'>
        <Navbar
          navLinks={[
            {
              path: '/analytics',
              header: 'Analytics'
            }
          ]}
          small='Breakdown of Data from Farmers and Farmers Inputs'
        />
        <div className='bg-white py-3 d-flex'>
          <div className='col-md-5 align-items-baseline d-flex'>
            <label htmlFor='community' className='mr-3'>
              Community
            </label>
            <select
              name='community'
              id='community'
              className='custom-select form-control'
              onChange={this.initAnalytics}
              ref={this.communityResourceRef}
            >
              <option value='select-direction'>Choose a program from the list</option>
              {this.renderSelectProgramOptions()}
            </select>
          </div>
          <div className='col-md-4 align-items-center d-flex'>
            <div htmlFor='farmingseason' className='mr-2 w-75'>
              Farming season
            </div>
            <select name='' id='' className='custom-select'>
              {['Dry Season', 'Rainy season'].map(season => (
                <option key={season}>{season}</option>
              ))}
            </select>
          </div>
        </div>
        <div className='py-3 border border-top-0 border-light'>
          <ul className='navbar-nav mr-auto d-flex flex-row'>
            <li className='nav-item'>
              <NavLink to={url} className={location.pathname === url ? 'active-nav p-3' : 'p-3'}>
                Farm
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink
                to={`${url}/fertilizer`}
                className={location.pathname === url + '/fertilizer' ? 'active-nav p-3' : 'p-3'}
              >
                Fertilizer
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink
                to={`${url}/input-supply`}
                className={location.pathname === url + '/input-supply' ? 'active-nav p-3' : 'p-3'}
              >
                Input supply
              </NavLink>
            </li>
          </ul>
        </div>

        <div className='px-md-3 px-sm-2 px-2 py-2 bg-white'>
          <h4 className='greener font-18 py-2'>{this.props.analyticsName}</h4>

          <Switch>{this.renderAnalyticsRoutes()}</Switch>
        </div>
        <Footer />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    communityResourceList: state.comReducer.communityResourceList,
    analyticsName: state.comReducer.analyticsName,
    analytics: state.comReducer.analytics
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getAllCommunityResource: getCommunityResource,
    handleAnalyticsName,
    getAnalytics
  })(Analytics)
);
