import { api } from '../api/api.config';

/**
 *
 * @param {} headers The axios http header
 * @param {string} key
 */
function getHeaderVal(headers, key) {
  if (typeof headers === 'object' || headers !== undefined) {
    const res = key.map(index => headers[index.toLowerCase()]);
    return res;
  }
}

/**
 * An object with username and password
 * @typedef Credentials
 * @property {string} username - The user's unique name
 * @property {string} password - The password
 */

/**
 * Makes `request` to server end and posts data with the data's unique credentials attached to url
 * @param {Credentials} `creds` the user's credentials to be verified
 * & authenticated
 * @returns {Promise<any[]>} a resolved response
 #### Note:  start url with `/`
 */
const login = async creds => {
  let authResponse, authError;
  try {
    const { data, headers, status } = await api.get('/login', {
      params: {
        username: creds.username,
        password: creds.password
      }
    });

    if (status === 200) {
      authResponse = {
        user: data,
        headers: getHeaderVal(headers, ['Authorization', 'Validity'])
      };
    } else {
      throw Error('You are not authorised to access this portal.');
    }
  } catch (reason) {
    authError = reason;
  }

  return Promise.resolve([authResponse, authError]);
};

/**
 * Logs user out of the platform and clears keys available in local storage
 * @param  {string[]} keys
 */
const logout = (...keys) => {
  return keys !== undefined
    ? [...keys].forEach(key => localStorage.removeItem(key))
    : localStorage.clear();
};

export default function authService() {
  return { login, logout };
}
