import React from 'react'
import { Form, InputGroup } from 'react-html5-form'

const NewDataModal = ({ isUpdate, modalId, modalName, resetUpdateState, saveResource }) => {
  return (
    <div
      className='modal fade'
      id={`${modalId}ResourceModal`}
      data-backdrop='static'
      data-keyboard='false'
      tabIndex={-1}
      role='dialog'
      aria-hidden='true'
    >
      <div className='modal-dialog' role='document'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h4> {!isUpdate ? `Add New ${modalName}` : `Edit ${modalName}`} </h4>
            <button
              type='button'
              className='close'
              data-dismiss='modal'
              aria-label='Close'
              onClick={resetUpdateState}
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
          <div className='modal-body'>
            <Form id='resourceForm' name='resourceForm' onSubmit={event => saveResource(event)}>
              {({ error, valid, submitting, pristine, form }) => (
                <>
                  <InputGroup tag='div' validate={['name']}>
                    {({ error, valid }) => (
                      <div className='col-md-12 my-2'>
                        <input
                          type='text'
                          required
                          className={`form-control form-control-sm ${!valid && 'is-invalid'}`}
                          id='name'
                          placeholder='Enter name'
                          name='name'
                        />
                        {error && <div className='invalid-feedback'>{error}</div>}
                      </div>
                    )}
                  </InputGroup>
                  <InputGroup tag='div' validate={['description']}>
                    {({ error, valid }) => (
                      <div className='col-md-12'>
                        <textarea
                          className={`form-control form-control-sm ${!valid && 'is-invalid'}`}
                          required
                          id='description'
                          placeholder='Enter description'
                          name='description'
                          rows='3'
                        />

                        {error && <div className='invalid-feedback'>{error}</div>}
                      </div>
                    )}
                  </InputGroup>
                  <div className='modal-footer'>
                    <button
                      type='button'
                      className='btn btn-expend text-white'
                      data-dismiss='modal'
                      onClick={resetUpdateState}
                    >
                      Close
                    </button>
                    <button
                      type='submit'
                      id='submitButton'
                      name='submitButton'
                      className={
                        isUpdate === false ? 'btn btn-revenue text-white float-right' : 'hidden'
                      }
                      disabled={pristine || submitting}
                    >
                      Add
                    </button>
                    <button
                      type='submit'
                      id='updateButton'
                      name='updateButton'
                      className={
                        isUpdate === true ? 'btn btn-revenue text-white float-right' : 'hidden'
                      }
                      disabled={pristine || submitting}
                    >
                      Save changes
                    </button>
                  </div>
                </>
              )}
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewDataModal
