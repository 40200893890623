// The auth reducer. The initial state sets authentication
// based on if authtoken is in local storage. In a real app,
// we would also want a util to check if the token is expired.
import {
  REQUEST_LOGOUT_SUCCESS,
  REQUEST_USER,
  REQUEST_USER_FAILED,
  REQUEST_USER_SUCCESS,
  RESET_ISLOGGING_IN
} from './app.constant';
import accessStorage from './utils/accessStorage';
const extractLinks = res => res.filter(link => !/^self$/i.test(link.rel));
// const extractServerLinks = res => extractLinks(res).

const extractUserResources = array => {
  return extractLinks(array).map(link => link.rel.split(/Resource/i)[0].toLowerCase());
};

export const initialState = {
  authError: '',
  isLoggingIn: false,
  isAuthenticated: accessStorage.getFromStore('token') ? true : false,
  userRole: '',
  userId: '',
  accessId: '',
  links: [],
  userActiveResources: [],
  validity: 0
};

export default function auth(state = initialState, { type, payload }) {
  switch (type) {
    case REQUEST_USER:
      return {
        ...state,
        isLoggingIn: true
      };
    case REQUEST_USER_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        authError: '',
        isSessionActive: true,
        isPasswordReset: payload.user.isPasswordReset,
        validity: payload.validity,
        userId: payload.user.userId,
        accessId: payload.user.accessId,
        userRole: payload.user.roles[0],
        userName: payload.user.userAccountId,
        userAccountId: payload.user.userAccountId,
        links: extractLinks(payload.user.links),
        userActiveResources: extractUserResources(payload.user.links)
      };
    case REQUEST_USER_FAILED:
      return {
        ...state,
        isLoggingIn: false,
        isAuthenticated: false,
        authError: payload.message
      };
    case REQUEST_LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false
      };
    case RESET_ISLOGGING_IN:
      return { ...state, isLoggingIn: false };
    default:
      return state;
  }
}
