/**
 * FIXING ERRORS FROM DATABASE of Not Properly formating coordinates
 * @param {*} path
 */
export function parseRemoteMapJsonData(path) {
  try {
    var data = path.replace(/lon/g, '"lng"').replace(/lat/g, '"lat"')
    return JSON.parse(data.replace(/}{/g, '},{'))
  } catch (e) {
    return undefined
  }
}

/**
 * Find the coordinate in the Middle
 * @param {*} path
 */
export function findCenterCoordinateIndex(path) {
  // var bounds = new google.maps.LatLngBounds();
  // var polyCoord = [];
  // for(index = 0; index < path.length; index ++)
  // {
  //   bounds.extend(new google.maps.LatLng(path[index].lat,path[index].lng));
  // }

  return Math.round(path.length / 2)
}
