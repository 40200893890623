import React from 'react';
import FarmAnalysis from './components/analytics/FarmAnalysis';
import FertilizerAnalysis from './components/analytics/fertilzer-analysis';
import InputSupplyAnalysis from './components/analytics/InputSupplyAnalysis';
import ErrorPage from './components/error/ErrorPage';
import Unauthorised from './components/error/Unauthorised';
import Overview from './components/Overview';
import ServiceProviderExtension from './components/serviceProvider/ServiceproviderExtension';
import SharedAgency from './components/serviceProvider/SharedAgency';
import AgentPostingForm from './pages/serviceProvider/AgentPostingForm';
import MainWallet from './components/wallet/mainwallet';
import WalletTransactionHistory from './components/wallet/walletTransactionHistory';
import Payments from './components/wallet/payments';
import PrivateRoute from './HOC/PrivateRoute';
import WarehouseResourceList from './pages/agroInventory/WarehouseResourceList';
import Analytics from './pages/analytics/analytics';
import CentralDashboard from './pages/centralDashboard/CentralDashboard';
import NewCommunitiesMap from './pages/community/CommunitiesMaps';
import AddNewCommunityMember from './pages/community/CommunityAddMember';
import NewCommunityFeatures from './pages/community/CommunityFeatures';
import CommunitiesMembers from './pages/community/CommunityMembers';
import CommunityResource from './pages/community/CommunityResource';
import CommunityResourceFeatures from './pages/community/CommunityResourceFeatures';
import CommunityFarmersProfile from './pages/community/FarmersCommunityProfile';
import FarmersDetailsQR from './pages/community/FarmersDetailsQR';
import ProcessedFarmers from './pages/community/ProcessedFarmers';
import Exoverview from './pages/exoverview/Exoverview';
import ExtensionList from './pages/extension-resource/ExtensionList';
import ExtensionResourceFeatures from './pages/extension-resource/ExtensionResourceFeatures';
import FarmerIssues from './pages/extension-resource/FarmerIssues';
import IssuesInfo from './pages/extension-resource/IssuesInfo';
import LoggedIssues from './pages/extension-resource/LoggedIssue';
import Outbreaks from './pages/extension-resource/Outbreaks';
import AddNewAsset from './pages/farm/AddNewAsset';
import FarmDetails from './pages/farm/FarmDetails';
import FarmerLoggedIssues from './pages/farm/FarmerLoggedIssues';
import Farmers from './pages/farm/Farmers';
import FarmersLoggedResponse from './pages/farm/FarmersLoggedResponse';
import FarmersProfile from './pages/farm/FarmersProfile';
import FarmItemList from './pages/farm/FarmItemList';
import FarmResource from './pages/farm/FarmResource';
import FarmsMap from './pages/farm/FarmsMapView';
import LogAnIssue from './pages/farm/LogAnIssue';
import NewAssetsMap from './pages/farm/NewAssetsMapView';
import ViewAssets from './pages/farm/ViewAssets';
import ViewFarmersProfile from './pages/farm/ViewFarmersProfile';
import ViewFarms from './pages/farm/ViewFarms';
import Login from './pages/login/Login';
import Reports from './pages/reports/Reports';
import Resources from './pages/resources/Resources';
import ActiveServices from './pages/serviceProvider/ActiveServices';
import AddJobPosting from './pages/serviceProvider/AddJobPosting';
import AgentData from './pages/serviceProvider/AgentData';
import CapturedData from './components/serviceProvider/agentData/CapturedData';
import TransactionHistory from './components/serviceProvider/agentData/TransactionHistory';
import AgentInfoForm from './pages/serviceProvider/AgentInfoForm';
import Agents from './pages/serviceProvider/Agents';
import AssignedTask from './pages/serviceProvider/AssignedTask';
import AssignedTaskSummary from './pages/serviceProvider/AssignedTaskSummary';
import CapturedDataDetails from './pages/serviceProvider/CapturedDataDetails';
import JobPostings from './pages/serviceProvider/JobPostings';
import ServiceProviders from './pages/serviceProvider/ServiceProviders';
import AddNewStaff from './pages/staff/AddNewStaff';
import Staff from './pages/staff/Staff';
import StaffProfile from './pages/staff/StaffProfile';
import Trash from './pages/trash/Trash';
import Settings from './components/settings/Settings';
import AgentPostingDetails from './pages/serviceProvider/AgentPostingDetails';
import ManageAccessList from './pages/staff/ManageAccessList';
import MapConverter from './pages/maps/MapConverter';

const getGeneralRoutes = () => {
  return [
    {
      exact: true,
      path: '/overview',
      component: Overview
    }
  ];
};

const getExternalOverviewRoutes = () => {
  return [
    {
      exact: true,
      path: '/overview',
      component: Overview
    },
    {
      exact: true,
      path: '/ex-overview',
      component: Exoverview
    },
    {
      exact: true,
      path: '/farmers',
      component: Farmers
    },
    {
      path: '/analytics',
      component: Analytics,
      routes: [
        {
          exact: true,
          path: '/analytics/fertilizer',
          component: FertilizerAnalysis
        },
        {
          exact: true,
          path: '/analytics/input-supply',
          component: InputSupplyAnalysis
        },
        {
          exact: true,
          path: '/analytics',
          component: FarmAnalysis
        }
      ]
    },
    {
      exact: true,
      path: '/view-farms/:farmResourceId',
      component: ViewFarms
    },
    {
      exact: true,
      path: '/farm-details/:farmId',
      component: FarmDetails
    },
    {
      exact: true,
      path: '/view-profile/:farmerId',
      component: ViewFarmersProfile
    },
    {
      exact: true,
      path: '/add-new-asset/',
      component: AddNewAsset
    },
    {
      exact: true,
      path: '/farms/map-view/',
      component: FarmsMap
    }
  ];
};

const getAgentRoutes = () => {
  return [
    getGeneralRoutes()[0],
    {
      exact: true,
      path: '/log-an-issue/',
      component: LogAnIssue
    },
    {
      exact: true,
      path: '/logged-issues/',
      component: LoggedIssues
    },
    {
      exact: true,
      path: '/farmer-issues/',
      component: FarmerIssues
    },
    {
      exact: true,
      path: '/issues-info/',
      component: IssuesInfo
    },
    {
      exact: true,
      path: '/farmers-logged-response/',
      component: FarmerLoggedIssues
    },
    {
      exact: true,
      path: '/profile',
      component: FarmersProfile
    },
    {
      exact: true,
      path: '/:agentId/captured-data/:communityId',
      component: AgentPostingDetails
    },
    {
      exact: true,
      path: '/settings',
      component: Settings
    },
    {
      exact: true,
      path: '/extension/:extensionId',
      component: Outbreaks
    },
    {
      path: '/agent-profile/:agentId',
      component: AgentData,
      routes: [
        {
          exact: true,
          path: '/agent-profile/:agentId/captured-data',
          component: CapturedData
        },
        {
          exact: true,
          path: '/agent-profile/:agentId/transaction-history',
          component: TransactionHistory
        }
      ]
    }
  ];
};

const getFarmerRoutes = () => {
  return [
    getGeneralRoutes()[0],
    {
      exact: true,
      path: '/log-an-issue/',
      component: LogAnIssue
    },
    {
      exact: true,
      path: '/logged-issues/',
      component: LoggedIssues
    },
    {
      exact: true,
      path: '/farmer-issues/',
      component: FarmerIssues
    },
    {
      exact: true,
      path: '/issues-info/',
      component: IssuesInfo
    },
    {
      exact: true,
      path: '/farmers-logged-response/',
      component: FarmerLoggedIssues
    },
    {
      exact: true,
      path: '/profile',
      component: FarmersProfile
    },
    {
      path: '/wallet',
      component: MainWallet,
      routes: [
        {
          exact: true,
          path: '/wallet/payments',
          component: Payments
        },
        {
          exact: true,
          path: '/wallet/tx-history',
          component: WalletTransactionHistory
        }
      ]
    },
    {
      exact: true,
      path: '/farmer/bvn',
      component: ViewFarmersProfile
    },
    {
      exact: true,
      path: '/view-farms/:farmResourceId',
      component: ViewFarms
    },
    {
      exact: true,
      path: '/view-assets/:farmId',
      component: ViewAssets
    },
    {
      exact: true,
      path: '/farm-details/:farmId',
      component: FarmDetails
    },
    {
      exact: true,
      path: '/new-farm-resource',
      component: FarmDetails
    },
    {
      exact: true,
      path: '/view-profile/:farmerId',
      component: ViewFarmersProfile
    },
    {
      exact: true,
      path: '/new-assets/map-view/',
      component: NewAssetsMap
    },
    {
      exact: true,
      path: '/add-new-asset/',
      component: AddNewAsset
    },
    {
      exact: true,
      path: '/farms/map-view/',
      component: FarmsMap
    }
  ];
};
/**
 *
 * @param {string} role
 * @returns {Array}
 */
const getAdminRoutes = role => [
  {
    exact: true,
    path: '/overview',
    component: role === 'SUPERAMIN' ? CentralDashboard : Overview
  },
  {
    exact: true,
    path: '/accesslist',
    component: ManageAccessList
  },
  {
    exact: true,
    path: '/kmlConverter',
    component: MapConverter
  },
  {
    exact: true,
    path: ['/ex-overview', '/ex-overview-extension'],
    component: Exoverview
  },
  {
    path: '/resources',
    component: Resources,
    routes: [
      {
        exact: true,
        path: '/resources/farm',
        component: FarmResource
      },
      {
        exact: true,
        path: '/resources/serviceprovider',
        component: ServiceProviders
      },
      {
        exact: true,
        path: '/resources/community',
        component: CommunityResource
      },
      {
        exact: true,
        path: '/resources/agro-inventory',
        component: WarehouseResourceList
      },
      {
        exact: true,
        path: '/resources/extension',
        component: ExtensionList
      }
    ]
  },
  {
    exact: true,
    path: '/farmers',
    component: Farmers
  },
  {
    exact: true,
    path: '/qr',
    component: FarmersDetailsQR
  },
  {
    exact: true,
    path: '/farmer/bvn',
    component: ViewFarmersProfile
  },
  {
    exact: true,
    path: '/farm',
    component: FarmResource
  },
  {
    exact: true,
    path: '/view-farms',
    component: FarmItemList
  },
  {
    path: '/view-farms/:farmResourceId',
    component: ViewFarms
  },
  {
    path: '/view-assets/:farmId',
    component: ViewAssets
  },
  {
    path: '/farm-details/:farmId',
    component: FarmDetails
  },

  {
    path: '/new-farm-resource',
    component: FarmDetails
  },
  {
    path: '/view-profile/:farmerId',
    component: ViewFarmersProfile
  },
  {
    path: '/new-assets/map-view/',
    component: NewAssetsMap
  },
  {
    path: '/add-new-asset/',
    component: AddNewAsset
  },
  {
    path: '/farms/map-view/',
    component: FarmsMap
  },
  {
    path: '/processed-farmers/',
    component: ProcessedFarmers
  },
  {
    path: '/serviceprovider',
    component: ServiceProviders
  },
  {
    path: '/service-provider-resource/:serviceProviderId',
    component: ActiveServices,
    routes: [
      {
        exact: true,
        path: '/service-provider-resource/:serviceProviderId/shared-agency',
        component: SharedAgency
      },
      {
        path: '/service-provider-resource/:serviceProviderId/labour',
        component: () => <h5 className='my-5'>Labour page</h5>
      },
      {
        path: '/service-provider-resource/:serviceProviderId/input-supply',
        component: () => <h5 className='my-5'>Input Supply page</h5>
      },
      {
        path: '/service-provider-resource/:serviceProviderId/extension-services',
        component: ServiceProviderExtension
      },
      {
        exact: true,
        path: ['/service-provider-resource/:serviceProviderId'],
        component: SharedAgency
      }
    ]
  },
  {
    exact: true,
    path: '/:serviceProviderId/shared-agency/:sharedAgencyId',
    component: Agents
  },
  {
    path: ['/:sharedAgencyId/add-agent', '/:sharedAgencyId/edit-agent'],
    component: AgentInfoForm
  },
  {
    path: '/:sharedAgencyId/post-agent/:agentId',
    exact: true,
    component: AgentPostingForm
  },
  {
    path: '/:sharedAgencyId/job-postings/create',
    component: AddJobPosting
  },
  {
    path: '/job-postings',
    component: JobPostings
  },
  {
    path: '/:sharedAgencyId/assigned-tasks/:assignedTaskId',
    component: AssignedTask
  },
  {
    path: '/:sharedAgencyId/assigned-tasks',
    component: AssignedTaskSummary
  },
  {
    path: ['/:assignedTaskId/agent-profile/:agentId', '/:agentId/captured-data/:communityId'],
    component: CapturedDataDetails
  },
  {
    path: '/agent-profile/:agentId',
    component: AgentData
  },
  {
    exact: true,
    path: '/community',
    component: CommunityResource
  },
  {
    path:
      '/community-resource/(community-resource-overview|map-view|inputs|recovery|communities|evaluate)?',
    component: CommunityResourceFeatures
  },
  {
    path: '/community-features',
    component: NewCommunityFeatures
  },

  {
    path: '/communities/map-view/',
    component: NewCommunitiesMap
  },
  {
    path: '/community/communitiesMembers/',
    component: CommunitiesMembers
  },
  {
    path: '/community-add-new-member/',
    component: AddNewCommunityMember
  },
  {
    path: '/community-farmers-profile/',
    component: CommunityFarmersProfile
  },
  {
    path: '/extension',
    component: ExtensionList
  },
  {
    path: '/extension-resource/:extensionResourceId',
    component: ExtensionResourceFeatures
  },
  {
    path: 'resources/extension-resource/:extensionResourceId',
    component: ExtensionResourceFeatures
  },
  {
    exact: true,
    path: '/log-an-issue',
    component: LogAnIssue
  },
  {
    path: '/logged-issues',
    component: LoggedIssues
  },
  {
    path: '/farmer-issues',
    component: FarmerIssues
  },
  {
    path: '/issues-info',
    component: IssuesInfo
  },
  {
    path: '/farmers-logged-response',
    component: FarmersLoggedResponse
  },
  {
    path: '/agro-inventory',
    component: WarehouseResourceList
  },
  {
    exact: true,
    path: '/staff',
    component: Staff
  },
  {
    exact: true,
    path: '/staff/:staffId',
    component: StaffProfile
  },
  {
    exact: true,
    path: '/add-new-staff',
    component: AddNewStaff
  },
  {
    path: '/analytics',
    component: Analytics,
    routes: [
      {
        exact: true,
        path: '/analytics/fertilizer',
        component: FertilizerAnalysis
      },
      {
        exact: true,
        path: '/analytics/input-supply',
        component: InputSupplyAnalysis
      },
      {
        exact: true,
        path: '/analytics',
        component: FarmAnalysis
      }
    ]
  },
  {
    exact: true,
    path: '/reports',
    component: Reports
  },
  {
    exact: true,
    path: '/trash',
    component: Trash
  },
  {
    exact: true,
    path: '/settings',
    component: Settings
  },
  {
    exact: true,
    path: '/profile',
    component: FarmersProfile
  },
  {
    path: '/wallet',
    component: MainWallet,
    routes: [
      {
        exact: true,
        path: '/wallet/payments',
        component: Payments
      },
      {
        exact: true,
        path: '/wallet/tx-history',
        component: WalletTransactionHistory
      }
    ]
  },
  {
    component: ErrorPage
  }
];

const renderRoutes = (role = 'general') => {
  const routes = {
    general: getGeneralRoutes(),
    CBN: getExternalOverviewRoutes(),
    AGENT: getAgentRoutes(),
    FARMER: getFarmerRoutes(),
    SUPERADMIN: getAdminRoutes(role),
    ADMIN: getAdminRoutes()
  };
  return routes[role.toUpperCase()];
};

export const appRoutes = [
  { exact: true, path: ['/:accessId(\\d{8})', '/login'], component: Login },
  { path: '/error/unauthorised', component: Unauthorised },
  {
    path: '/',
    component: PrivateRoute,
    routes: renderRoutes
  },
  {
    component: Unauthorised
  }
];
