import { all } from 'redux-saga/effects';
import { watchClearUser, watchFetchUser } from './app/app.saga';
import { watchDashboardRequest } from './app/pages/centralDashboard/centralDashboard.saga';
import { watchRequestAsset } from './app/pages/login/login.saga';
import { serviceProvidersSaga } from './app/pages/serviceProvider/serviceProvider.watchers';
import { watchFetchTrash, watchRestoreTrash } from './app/pages/trash/trash.saga';

function* rootSaga() {
  yield all([
    serviceProvidersSaga(),
    watchFetchTrash(),
    watchRestoreTrash(),
    watchDashboardRequest(),
    watchRequestAsset(),
    watchFetchUser(),
    watchClearUser()
  ]);
}

export default rootSaga;
