import React from 'react';
import { Link } from 'react-router-dom';

// This is used in the Add/Edit EOP table
export const addediteopColumns = [
  {
    title: 'Name',
    field: 'name'
  },
  {
    title: 'Unit of Measurement',
    field: 'unitOfMeasurement'
  },
  {
    title: 'Quantity Per Hectare',
    field: 'quantityPerHectare'
  },
  {
    title: 'Cost Per Hectare ₦',
    field: 'unitCost'
  },
  {
    title: 'Action',
    field: 'action'
  }
];

// This is used in the Farmers Profile EOP table
export const farmerseopColumns = [
  {
    Header: 'Input',
    field: 'inputDistribusionInputAllocatedId.inputId.name'
  },
  {
    Header: 'Expected Qty',
    field: 'inputDistribusionInputAllocatedId.inputId.quantityPerHectare',
    width: 100
  },
  {
    Header: 'Collected Qty',
    field: 'inputDistribusionInputAllocatedId.quantityCollected',
    width: 100
  },
  {
    Header: 'Amount (₦)',
    field: 'inputDistribusionInputAllocatedId.inputId.unitCost'
  }
];

// This is used in Extensions Resource page
export const issuesColumns = [
  {
    title: 'Issuer',
    field: 'issuer'
  },
  {
    title: 'Requests',
    field: 'requests'
  },
  {
    title: 'Issue Summary',
    field: 'summary'
  },
  {
    title: 'Location(s)',
    field: 'location'
  }
];

// This is used in Extensions Resource page
export const txHistoryColumns = [
  {
    title: 'Person',
    field: 'person'
  },
  {
    title: 'Account Number',
    field: 'account'
  },
  {
    title: 'Amount',
    field: 'amount'
  },
  {
    title: 'Task/Posting',
    field: 'task'
  },
  {
    title: 'Date',
    field: 'date'
  }
];

// This is used in Extensions Resource - Farmer Issues page
export const farmerissuesColumns = [
  {
    title: 'Issue',
    field: 'issue'
  },
  {
    title: 'Requested Visiting Day',
    field: 'visiting'
  },
  {
    title: 'Contact Information',
    field: 'contact'
  },
  {
    title: 'Location(s)',
    field: 'location'
  }
];

export const farmersColumns = [
  {
    title: 'Name',
    field: 'surname'
  },
  {
    title: 'Community',
    field: 'state'
  },

  {
    title: 'BVN',
    field: 'bvn'
  },
  {
    title: 'PhoneNumber',
    field: 'number'
  }
];

// Found on Community Resource 0verview
export const recoveryColumns = [
  {
    title: 'Farmers ID',
    field: 'farmerId'
  },
  {
    title: 'Farmer Name',
    field: 'farmerName'
  },
  {
    title: 'Total Amount(₦)',
    field: 'totalAmountPaid'
  },
  {
    title: 'Amount Recovered(₦)',
    field: 'totalAmountRecieved'
  }
];

export const recoveryDetailsColumn = [
  {
    title: 'Transaction Date',
    field: 'transactionDate'
  },
  {
    title: 'Current Market Price (₦)',
    field: 'currentMarketValue'
  },
  {
    title: 'Amount Due (₦)',
    field: 'totalAmountDue'
  },
  {
    title: 'Bags Collected',
    field: 'totalNoBagsCollected'
  },
  {
    title: 'Size (kg)',
    field: 'sizeInKg'
  }
];

// Found on Agents.jsx
export const agentColumns = [
  {
    title: 'Name',
    field: 'surname',
    render: data => <Link to={`/agent-profile/${data.id}`}>{data.surname}</Link>
  },
  {
    title: 'State',
    field: 'state'
  },
  {
    title: 'Status',
    field: 'status',
    render: data => (
      <span
        className={`${
          /inactive/gi.test(data.status) ? 'bg-danger' : 'bg-revenue'
        } w-75 text-center font-weight-bold text-white`}
      >
        {data.status}
      </span>
    )
  },

  {
    title: 'Phone Number',
    field: 'phone'
  }
];

export const postedAgentDetailsColumns = [
  {
    title: 'Name',
    field: 'name'
  },
  {
    title: 'Community',
    field: 'community_name'
  },
  {
    title: 'Number',
    field: 'number'
  },
  {
    title: 'Posting status',
    field: 'status'
  }
];
export const sharedAgencyColumns = [
  {
    title: 'Name',
    field: 'name'
  },
  {
    title: 'Status',
    field: 'status'
  },
  {
    title: 'Created On',
    field: 'createdOn'
  }
];
export const inputSupplyColumns = [
  {
    title: 'Supplier',
    field: 'supplier'
  },
  {
    title: 'Commodity Supplied',
    field: 'commoditySupplied'
  },
  {
    title: 'Email',
    field: 'email'
  },
  {
    title: 'Location',
    field: 'location'
  }
];
export const labour = [
  {
    title: 'Name',
    field: 'name'
  },
  {
    title: 'Role',
    field: 'role'
  },
  {
    title: 'Email',
    field: 'email'
  },
  {
    title: 'Location',
    field: 'Location'
  }
];
export const jobPostingColumns = [
  {
    title: 'Job',
    field: 'job'
  },
  {
    title: 'Posting Area',
    field: 'validity'
  },
  {
    title: 'Employee Experience',
    field: 'experience'
  },
  {
    title: 'Duties & Responsibilites',
    field: 'duties'
  }
];
export const capturedDataColumns = [
  {
    title: 'Task Group',
    field: 'id'
  },
  {
    title: 'Posting Area/Location',
    field: 'resourceName',
    render: data => <Link to={`/agent-profile/captured-data/${data.id}`}>{data.resourceName}</Link>
  }
];
export const agentPostingDetailsColumns = [
  {
    title: 'Name',
    field: 'name'
  },
  {
    title: 'Posting Area/Location',
    field: 'ward'
  },
  {
    title: 'BVN',
    field: 'bvn'
  }
];
export const transactionHistoryColumns = [
  {
    title: 'Posting Group',
    field: 'postingGroup'
  },
  {
    title: 'Total Farmers Captured',
    field: 'farmersCaptured'
  },
  {
    title: 'Total Cost',
    field: 'totalCost'
  }
];
export const staffDetailsColumns = [
  {
    title: 'Name',
    field: 'surname'
  },
  {
    title: 'State',
    field: 'state'
  },

  {
    title: 'Status',
    field: 'status'
  },
  {
    title: 'Phone Number',
    field: 'phoneNumber'
  }
];
