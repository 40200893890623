import React from 'react'

const TaskSummaryTools = () => {
  return (
    <button
      className='btn btn-revenue text-white btn-sm px-2 mx-2'
      data-toggle='modal'
      data-target='#assignedTaskModal'
    >
      <i className='fas fa-plus fa-sm mr-2' />
      <span> Add Task </span>
    </button>
  )
}

export default TaskSummaryTools
