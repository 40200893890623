import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { farmers } from '../../components/dataTable/columnTitles';
import DataTable from '../../components/dataTable/DataTable';
import '../../pages/community/communityStyle.css';
import * as actions from './communities.actions';

class NewCommunityMembers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
          id: 1,
          surname: 'Yangida',
          state: 'Jigawa',
          bvn: 250666419,
          number: 23470322250
        }
      ]
      //  this.props.getFarmersMembers(this.props.communityId)
    };
  }
  componentDidMount() {
    this.props.getFarmersMembers(this.props.communityId);
  }

  render() {
    return (
      <React.Fragment>
        <div className='container-fluid bg-white '>
          <div className='row bg-white'>
            <h5 className='my-2 mx-3'>{this.props.communityName} Community Members</h5>
            <div className='col-md-12 text-right d-flex w-100 py-3 align-items-center'>
              <input type='checkbox' name='' className='mx-2' />
              <select className='mr-3 custom-select form-control w-20'>
                <option value='Select actions...'>Select actions...</option>
                <option value='Nil'>Nil</option>
              </select>
              <input
                className='mr-auto form-control w-20'
                type='search'
                placeholder='Search'
                // onSubmit={filterResource(e)}
              />
              <button
                className='btn btn-outline-revenue btn-sm px-3 mr-2'
                data-toggle='modal'
                data-target='#importFarmersModal'
              >
                <i className='fas fa-upload fa-sm mr-3' />
                Import Farmers
              </button>
              {/* <Link to='/community-add-new-member/'>
                <button className='btn btn-revenue text-white btn-sm px-3 mr-2'>
                  <i className='fas fa-plus fa-sm mr-3' />
                  Add Member
                </button>
              </Link> */}
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-12'>
            {/* <ReactTable
                columns={
                  localStorage.getItem("role") === "ADMIN"
                    ? adminColumn
                    : cbnColumn
                }
                className="-striped -highlight"
                data={this.props.allCommunities}
                defaultPageSize={10}
                noDataText={"Loading... Please Wait"}
              /> */}
            <DataTable
              className='-striped -highlight w-100'
              columnTitle={farmers}
              data={this.props.farmersMembers}
              defaultPageSize={10}
              deleteData={this.deleteSubResource}
            />
          </div>
        </div>
        {/* Import Farmers Modal */}
        <div
          className='modal fade'
          id='importFarmersModal'
          tabIndex={-1}
          role='dialog'
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
        >
          <div className='modal-dialog' role='document'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h4>Upload CSV file</h4>
                <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                  <span aria-hidden='true'>×</span>
                </button>
              </div>
              <div className='modal-body'>
                <form>
                  <input
                    type='file'
                    className='form-control'
                    name='farmers-list'
                    accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                  />

                  <button
                    type='button'
                    className='btn btn-expend text-white mx-4'
                    data-dismiss='modal'
                  >
                    Close
                  </button>
                  <button type='button' className='btn btn-revenue text-white'>
                    Save changes
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* Import Farmers here */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    communityName: state.comReducer.communityName,
    communityId: state.comReducer.communityId,
    farmersMembers: state.comReducer.farmersMembers,
    isReset: state.comReducer.isReset
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getFarmersMembers: communityId => dispatch(actions.getFarmersMembers(communityId)),
    updateFarmersMembers: newFarmersMembers =>
      dispatch(actions.handleUpdateFarmersMembers(newFarmersMembers)),
    resetSelectedMembers: reset => dispatch(actions.resetSelectedMembers(reset))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewCommunityMembers));
