import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import MaterialTable from 'material-table';
import InfoDashlet from '../centralDashboard/InfoDashlet';
import DashletSkeleton from '../skeleton/DashletSkeleton';
import { recoveryDetailsColumn } from '../../utils/tablecolumns';

const RecoveryDetails = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [details, setDetails] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    fetch(
      process.env.REACT_APP_BASEURL +
        '/recoverysummary/details/' +
        Number(props.match.params.farmerId),
      {
        method: 'GET',
        headers: {
          Accept: 'application/json'
        }
      },
      { signal: signal }
    )
      .then(response => response.json())
      .then(response => {
        setDetails(response);
        console.log(777777, response);
      })
      .catch(error => {
        console.log(error);
      });
    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <>
      <div>
        <h3 className='font-18 font-weight-bold my-1'>
          {details.farmerName}
          Recovery Details
        </h3>
      </div>
      <div className='row my-4'>
        <div className='col-md-4'>
          {isLoading ? (
            <DashletSkeleton />
          ) : (
            <InfoDashlet title='Total Disbursed' value={`₦` + details.totalAmountPaid} />
          )}
        </div>
        <div className='col-md-4'>
          {isLoading ? (
            <DashletSkeleton />
          ) : (
            <InfoDashlet title='Total Recovered' value={`₦` + details.totalAmountRecieved} />
          )}
        </div>
      </div>
      <div className='row my-4'>
        <div className='col-md-12'>
          <div className='card'>
            <div className='bg-secondary p-2 d-flex flex-column justify-content-between'>
              <div>
                <MaterialTable
                  columns={recoveryDetailsColumn}
                  data={details.recoveryDetailsList}
                  style={{
                    fontFamily: 'Nunito',
                    width: '100%',
                    boxShadow: 'none'
                  }}
                  options={{
                    actionsColumnIndex: 4,
                    sorting: true,
                    showTitle: false,
                    exportButton: true,
                    searchFieldAlignment: 'left',
                    loadingType: 'linear',
                    headerStyle: {
                      backgroundColor: '#4f4f4f',
                      color: '#FFF'
                    }
                  }}
                  components={{
                    CircularProgress: props => (
                      <div style={{ backgroundColor: '#4f4f4f' }}>
                        <CircularProgress {...props} />
                      </div>
                    )
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

//export default Reports;

// const mapStateToProps = state => {
//   return {
//     isLoading: state.comReducer.isLoading,
//     communityResourceList: state.comReducer.communityResourceList
//   };
// };

export default withRouter(RecoveryDetails);

// function mapStateToProps(state) {
//   return;
//   getCommunityResource: getAllCommunityResource(state);
// }

// export default connect(mapStateToProps)(Reports);
