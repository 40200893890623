import { useEffect } from 'react'
import Swal from 'sweetalert2'
import { clearAlertMessage } from '../api/api.actions'

/**
 * custom hook for creating a fetchData action
 * @param {*} dataType {string} - Data to be requested for.
 * @param {*} dependencies {array} - An array of dependencies require to cause an invocation of useFetch hook
 * @param {*} uniqueInfo {string | number} - An array of dependencies require to cause an invocation of useFetch hook
 */
export const useDisplayResponse = (successMsg, errorMsg) => {
  const displayAlert = (title, text, type) =>
    Swal.fire({
      title: title,
      text: text,
      type: type,
      animation: true,
      showCancelButton: false,
      confirmButtonColor: '#ff9326',
      confirmButtonText: 'Close',
      onClose: clearAlertMessage(),
      onAfterClose: clearAlertMessage()
    })
  useEffect(() => {
    successMsg && displayAlert('Success!', successMsg, 'success')
    errorMsg && displayAlert('Error', errorMsg, 'error')
    return
  }, [successMsg, errorMsg])
}
