import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import assetmapview from '../../../../../assets/asset-map-view.svg';
import moreIcon from '../../../../../assets/3Dots.svg';
import BaseUrl from '../../../../../security/base-url';

export default class FarmsToolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <button
          className='btn btn-outline-revenue btn-sm'
          data-toggle='modal'
          data-target='#uploadFarms'
        >
          Import Farms
        </button>
        <button
          className='btn btn-outline-revenue btn-sm mx-2'
          data-toggle='modal'
          data-target='#uploadFarmMaps'
        >
          Import Maps
        </button>
        {/* <Link to='/farms/map-view/' className='mx-2 text-revenue'>
          <img src={assetmapview} alt='Map View' className='mx-2' />
          View on Map
        </Link> */}
        {/* <span className="badge badge-pill badge-warning mx-2">
          {Object.keys(this.props.farmItems).length}&nbsp;Farms
        </span> */}
        {/* <button
          className='btn btn-revenue text-white btn-sm px-3 mr-2'
          data-toggle='modal'
          data-target='#addResourceModal'
        >
          <i className='fas fa-plus fa-sm mr-3' />
          Add Farm
        </button> */}
        {/* <Link
          className="btn btn-revenue text-white btn-sm px-2 mx-2"
          to={`/add-agent`}>
          <i className="fas fa-plus fa-sm mr-2" />
          <span>Add Agent</span>
        </Link> */}
        <span className='dropdown dropleft mr-2'>
          <span
            className='d-flex justify-content-center mousePointer'
            id='dropdownMenuButton'
            data-toggle='dropdown'
            aria-haspopup='true'
            aria-expanded='false'
          >
            <img src={moreIcon} className='cursor p-2' alt='More Icon' />
          </span>

          <span className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
            <button
              className='dropdown-item pointer'
              data-toggle='modal'
              data-target='#uploadInputs'
            >
              Import Inputs
            </button>
            {/* <button
              className='dropdown-item pointer'
              data-toggle='modal'
              data-target='#uploadFarms'
            >
              Import Farms
            </button> */}
            {/* <button
              className='dropdown-item pointer'
              data-toggle='modal'
              data-target='#uploadFarmMaps'
            >
              Import Maps
            </button> */}{' '}
            <a
              target='_blank'
              href={
                BaseUrl.url_dashbord +
                '/farmresources/export_processed_farms/' +
                this.props.selectedFarmResource.id
              }
              className=' btn dropdown-item pointer'
            >
              Export Mapped{' '}
            </a>
            {' '}
            <a
              target='_blank'
              href={
                BaseUrl.url_dashbord +
                '/farmresources/export_extension/' +
                this.props.selectedFarmResource.id
              }
              className=' btn dropdown-item pointer'
            >
              Export Extension{' '}
            </a>
            {' '}
            <a
              target='_blank'
              href={
                BaseUrl.url_dashbord +
                '/farmresources/export_data_quality_assurance/' +
                this.props.selectedFarmResource.id
              }
              className=' btn dropdown-item pointer'
            >
              Export DQA{' '}
            </a>
            {/* <button
              href="#"
              className="dropdown-item"
              data-toggle="modal"
              data-target="#importModal">
              Import Farm
            </button> */}
            {' '}
            <a
              target='_blank'
              href={
                BaseUrl.url_dashbord +
                '/farmresources/export_indirect_jobs/' +
                this.props.selectedFarmResource.id
              }
              className=' btn dropdown-item pointer'
            >
              Export Indirect Jobs{' '}
            </a>
            {' '}
            <a
              target='_blank'
              href={
                BaseUrl.url_dashbord +
                '/farmresources/export_annual_survey/' +
                this.props.selectedFarmResource.id
              }
              className=' btn dropdown-item pointer'
            >
              Export Annual Survey{' '}
            </a>
          </span>
        </span>
      </>
    );
  }
}
