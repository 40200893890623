//Login Action Types
export const REQUEST_USER = 'REQUEST_USER'
export const REQUEST_USER_SUCCESS = 'REQUEST_USER_SUCCESS'
export const REQUEST_USER_FAILED = 'REQUEST_USER_FAILED'

//Logout Action Types
export const REQUEST_LOGOUT = 'REQUEST_LOGOUT'
export const REQUEST_LOGOUT_SUCCESS = 'REQUEST_LOGOUT_SUCCESS'
export const REQUEST_LOGOUT_FAILED = 'REQUEST_LOGOUT_FAILED'

//RENEW SESSION
export const RENEW_TOKEN = 'RENEW_TOKEN'
export const UPDATE_SESSION_STATE = 'UPDATE_SESSION_STATE'

//SITE LOGIN
export const REQUEST_SITE_LOGIN_ASSETS = 'REQUEST_SITE_LOGIN_ASSETS'

export const RESET_ISLOGGING_IN = 'RESET_ISLOGGING_IN'
