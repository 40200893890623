import React, { Component } from 'react'
import 'chart.js'
import { ColumnChart } from 'react-chartkick'
import { Link } from 'react-router-dom'

import bag from '../../../assets/bag.svg'

export default class EmptyBagAnalysis extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data2: [
        ['Bagudu', 16],
        ['K/Besse', 12],
        ['Sakaba', 10],
        ['D/Kamba', 9],
        ['D/Wasagu', 8]
      ]
    }
  }
  render() {
    return (
      <div className='container-fluid bg-light p-3 mt-3'>
        <div className='d-flex justify-content-between align-items-center'>
          <h3 className='text-black-50 h4'>Empty Bag Analysis</h3>
          <div className='border rounded text-center py-2 px-3 d-flex flex-sm-column flex-md-row ml-md-auto mr-2'>
            <img src={bag} alt='farmer' className='mr-2' />

            <div>
              <h4 className='text-dark font-weight-light'>Total Empty Bags (m)</h4>
              <p className='font-weight-bold font-fix mb-0'>
                {this.props.analytics.emptyBag ? this.props.analytics.emptyBag : 0}
              </p>
            </div>
          </div>
          <div className='border rounded text-center py-2 px-3 d-flex mr-2 bg-revenue text-white'>
            <img src={bag} alt='farmer' className='mr-2' />

            <div>
              <h4 className='text-white font-weight-light'>Less Than 40</h4>
              <p className='font-weight-bold font-fix mb-0'>
                {this.props.analytics.emptyBag ? this.props.analytics.emptyBag : 0}
              </p>
            </div>
          </div>
          <div className='border rounded text-center py-2 px-3 d-flex mr-2 bg-expend text-white'>
            <img src={bag} alt='farmer' className='mr-2' />

            <div>
              <h4 className='text-white font-weight-light'>Greater Than 40</h4>
              <p className='font-weight-bold font-fix mb-0'>
                {this.props.analytics.emptyBag ? this.props.analytics.emptyBag : 0}
              </p>
            </div>
          </div>
        </div>
        <div className='row mt-5'>
          <div className='col-md-6'>
            <ColumnChart
              data={this.state.data2}
              dataset={{ backgroundColor: '#96c63d' }}
              colors={['#96c63d', '#00a650']}
              xtitle='LGA'
              ytitle='Numbers'
            />
            <h6 className='bg-white ml-5 p-2 my-4 text-center'>
              Number Of Empty Bags Per LGA(Top 5)
            </h6>
          </div>
          <div className='col-md-6'>
            <ColumnChart
              data={this.state.data2}
              dataset={{ backgroundColor: '#c66868' }}
              colors={['#c66868']}
              xtitle='LGA'
              ytitle='Numbers'
            />
            <h6 className='bg-white ml-5 p-2 my-4 text-center'>
              Number Of Empty Bags Per LGA(Bottom 5)
            </h6>
          </div>
        </div>
        <div className='greener text-right font-18 underline'>
          <Link to='#'>...View more</Link>
        </div>
      </div>
    )
  }
}
