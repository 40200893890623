import PropTypes from 'prop-types'
import React from 'react'
import { jobPosting } from '../../components/dataTable/columnTitles'
import DataTable from '../../components/dataTable/DataTable'
import Navbar from '../../components/Navbar'

const JobPostings = props => {
  const {
    match: {
      params: { sharedAgencyId, serviceProviderId }
    },
    location: { pathname }
  } = props
  return (
    <>
      <Navbar
        navLinks={[
          {
            path: `/new-service-provider-resource/${serviceProviderId}`,
            header: ' Service Provider |'
          },
          {
            header: `| Shared Agency |`,
            path: `/${serviceProviderId}/new-shared-agency/${sharedAgencyId}`
          },
          {
            header: `| Job Postings`,
            path: `${pathname}`
          }
        ]}
        small='View and manage service providers'
      />

      {/* this is a dummy UI */}
      <div className='bg-white'>
        <DataTable
          {...props}
          columnTitle={jobPosting}
          data={[
            {
              job: 'Soil scientist',
              validity: 'true',
              experience: '2 years',
              duties: 'Testing and monitoring of soil pH level'
            }
          ]}
          buttonName='Create Job Advert'
          buttonLink={`${pathname}/create`}
          dropdownName={['Duplicate post', 'Suspend this post']}
          dropdownLink={['job-postings/create']}
        />
      </div>
    </>
  )
}

JobPostings.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default JobPostings
