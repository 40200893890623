import { call, put, takeEvery } from 'redux-saga/effects'
import { api } from '../../api/api.config'
import accessStorage from '../../utils/accessStorage'
import { requestAssetFailed, requestAssetSuccess } from './login.actions'
import { REQUEST_ASSET } from './login.constant'

export function* watchRequestAsset() {
  yield takeEvery(REQUEST_ASSET, fetchAssets)
}

// workers
export function* fetchAssets({ payload: accessId }) {
  try {
    yield call([accessStorage, 'store'], 'access_id', accessId)
    const assets = yield call([api, 'get'], `/useraccount-resource/assets/${accessId}`)
    if (assets.code === undefined) {
      yield put(requestAssetSuccess(assets))
    } else {
      throw Error(assets.message)
    }
  } catch (reason) {
    yield put(requestAssetFailed(reason))
  }
}
