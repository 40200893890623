import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { NavLink, Route, Switch } from 'react-router-dom';
import BaseUrl from '../../../security/base-url';
import Navbar from '../../components/Navbar';
import AgentProfile from '../../components/serviceProvider/agentData/AgentProfile';
import CapturedData from '../../components/serviceProvider/agentData/CapturedData';
import TransactionHistory from '../../components/serviceProvider/agentData/TransactionHistory';

class AgentData extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      agentBio: {},
      agentId: this.props.match.params,
      agentDataCaptureList: [],
      capturedDataInfo: [],
      isLoading: false
    };
  }

  getAgentById = id => {
    fetch(BaseUrl.url_dashbord + '/agents/byagentid/' + id, {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    })
      .then(response => response.json())
      .then(agentBio => {
        this.setState({ agentBio });
      })
      .catch(err => console.log(err));
  };

  // retrieves the agent's data from database
  componentDidMount() {
    const { agentId } = this.props.match.params;
    this.getAgentById(agentId);
  }

  renderStyles = (tab = '') => {
    return this.props.location.pathname === `${this.props.match.url}${tab}`
      ? 'active-nav p-3'
      : 'p-3';
  };

  updateLoginDetails = () => {
    const {
      //eslint-disable-next-line no-unused-vars
      params: { agentId }
    } = this.props.match;
  };

  render() {
    const {
      match: { url, path },
      location: { pathname }
    } = this.props;
    return (
      <>
        <Navbar
          navLinks={
            path === '/:assignedTaskId/agent-profile/:agentId'
              ? [
                  {
                    path: `#`,
                    header: ' Assigned Tasks |'
                  },
                  {
                    path: `#`,
                    header: `| Task |`
                  },
                  {
                    path: `#`,
                    header: `| Agent's Profile `
                  }
                ]
              : [
                  {
                    path: `/service-provider-resource/3`,
                    header: ' Services |'
                  },
                  {
                    path: `/3/shared-agency/2`,
                    header: `| Shared Agency |`
                  },
                  {
                    path: `/${pathname}`,
                    header: `| Agent's Profile `
                  }
                ]
          }
          small='Agents information and data captured'
        />
        <div className='mt-3'>
          <ul className='navbar-nav mr-auto d-flex flex-row'>
            <li className='nav-item'>
              <NavLink to={url} className={this.renderStyles()}>
                Profile
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink to={`${url}/captured-data`} className={this.renderStyles(`/captured-data`)}>
                Captured Data
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink
                to={`${url}/transaction-history`}
                className={this.renderStyles(`/transaction-history`)}
              >
                Transaction History
              </NavLink>
            </li>
          </ul>
        </div>

        <Switch>
          <Route
            path={`${path}/transaction-history`}
            render={() => <TransactionHistory {...this.props} />}
          />

          <Route path={`${path}/captured-data`} render={() => <CapturedData {...this.props} />} />

          <Route
            exact
            path={`${path}`}
            render={() => <AgentProfile agentInfo={this.state.agentBio} />}
          />
        </Switch>
      </>
    );
  }
}

export default AgentData;
