import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import DataTable from '../dataTable/DataTable'
import { addediteop } from '../dataTable/columnTitles'
import * as actions from '../../pages/community/communities.actions'

class AddEditInput extends Component {
  state = {
    isValidate: false,
    eop: {
      name: '',
      quantity: '',
      unit: '',
      cost: ''
    }
  }

  componentDidMount() {
    this.props.getEOPS(this.props.communityResourceId)
  }

  handleEOPInput = e => {
    e.preventDefault()
    const eopData = {
      name: this.state.eop.name,
      quantityPerHectare: this.state.eop.quantity,
      unitCost: this.state.eop.cost,
      unitOfMeasurement: this.state.eop.unit
    }
    this.props.addEOP(eopData, this.props.communityResourceId)
    document.getElementById('eopForm').reset()
  }

  handleInputValidation = e => {
    const oldEop = this.state.eop
    oldEop[e.target.name] = e.target.value
    this.setState({ eop: oldEop }, () => {
      if (this.state.eop.cost !== undefined) {
        this.setState({ isValidate: true })
      }
    })
  }

  // Delete function for a subResource
  deleteSubResource = () => {
    this.sweetAlertDeleteMethod()
  }
  render() {
    return (
      <>
        <div className='p-5 my-3 bg-light mx-auto'>
          <form className='my-3' id='eopForm' onSubmit={e => this.handleEOPInput(e)}>
            <div className='form-row'>
              <div className='form-group col-md-8'>
                <input
                  type='text'
                  name='name'
                  className='form-control border-0'
                  id='name'
                  placeholder='Enter EOP Name'
                  onChange={this.handleInputValidation}
                />
              </div>
              <div className='form-group col-md-4'>
                <input
                  type='number'
                  name='quantity'
                  id='quantity'
                  pattern='[0-9.]+'
                  className='form-control border-0'
                  placeholder='Quantity per hectare e.g 2000'
                  onChange={this.handleInputValidation}
                />
              </div>
            </div>
            <div className='form-row'>
              <div className='form-group col-md-4'>
                <input
                  type='text'
                  name='unit'
                  id='unit'
                  className='form-control border-0'
                  placeholder='Unit of Measurement'
                  onChange={this.handleInputValidation}
                />
              </div>
              <div className='form-group col-md-4'>
                <input
                  type='number'
                  name='cost'
                  id='cost'
                  pattern='[0-9.]+'
                  className='form-control border-0'
                  placeholder='Cost per Hectare e.g 1000'
                  onChange={this.handleInputValidation}
                />
              </div>
              <div className='col-md-4'>
                <button
                  type='submit'
                  className={`btn btn-block btn-outline-revenue ${
                    this.state.isValidate ? 'text-dark' : 'text-muted'
                  }`}
                  id='submit'
                  disabled={this.state.isValidate === false}
                >
                  <i className='fas fa-plus-circle text-revenue mx-2' />
                  Add Input
                </button>
              </div>
            </div>
          </form>

          {/* Farmers List Toolbar here */}
          <div className='row bg-white'>
            <div className='col-md-12 text-right d-flex w-100 py-3 align-items-center'>
              <input type='checkbox' name='' className='mx-2' />
              <select className='mr-3 custom-select form-control w-20'>
                <option value='Select actions...'>Select actions...</option>
                <option value='Nil'>Nil</option>
              </select>
              <button className='btn btn-secondary'>Apply</button>
            </div>
            <div className='w-100'>
              <DataTable
                className='-striped -highlight'
                columnTitle={addediteop}
                data={this.props.eops}
                defaultPageSize={10}
                deleteData={this.deleteSubResource}
              />
            </div>
          </div>
        </div>
      </>
    )
  }
}
const mapStateToProps = state => {
  return {
    communityResourceId: state.comReducer.communityResourceId,
    eops: state.comReducer.eops
  }
}
const mapDispatchToProps = dispatch => {
  return {
    getEOPS: communityResourceId => dispatch(actions.getEOP(communityResourceId)),
    addEOP: (eop, communityResourceId) => dispatch(actions.addEOP(eop, communityResourceId))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddEditInput))
