import fetchIntercept from 'fetch-intercept';
//import accessStorage from '../app/utils/accessStorage';
import BaseUrl from './base-url';

const checkServerResponse = error =>
  error.includes('allowed by Access-Control-Allow-Origin'.toUpperCase()) ||
  error.includes('The network connection was lost'.toUpperCase()) ||
  error.includes('failed to fetch'.toUpperCase()) ||
  error.includes('unauthorized'.toUpperCase()) ||
  error.includes('NetworkError'.toUpperCase()) ||
  error.includes("No 'Access-Control-Allow-Origin' header is present on the requested resource") ||
  error.includes(
    'Request header field retry is not allowed by Access-Control-Allow-Headers in preflight response'
  ) ||
  error.includes('Could not connect to the server.'.toUpperCase());

/**
 * Runs a check to validate the window path
 */
// const isLoginPathValid = () => {
//   const { pathname } = window.location;
//   if (typeof pathname === 'string') {
//     const pattern = /([0-9]{8}$)|(login)$/;
//     return pattern.test(pathname);
//   }
//   return;
// };

export const unregister = fetchIntercept.register({
  // Modify the url or config here
  request: function(url, config) {
    if (
      url === BaseUrl.url_dashbord + '/farmers/qr' ||
      url.includes(BaseUrl.url_dashbord + '/useraccount-resource/assets')
    ) {
      return [url, config];
    } else if (config.headers) {
      const token = localStorage.getItem('token');
      config.headers.Authorization = token;
      return [url, config];
    }
  },

  // Called when an error occured during another 'request' interceptor call
  requestError: function(error) {
    if (error) {
      // eslint-disable-next-line no-console
      console.log("I'm a requestError:", error);
    }
    return Promise.reject(error);
  },

  response: function(response) {
    // Modify the reponse object'
    return response;
  },

  responseError: function(reason) {
    //handles fetch error
    const error = (reason + '').toUpperCase();

    if (reason) {
      // eslint-disable-next-line no-console
      console.log("I'm a responseError:", reason);
    }
    if (checkServerResponse(error)) {
      //accessStorage.clear('token', 'role', 'user');
      localStorage.clear();
      window.location.href = '/';
      //window.location.reload(true)
      //   const replacementUrl = `${window.location.protocol}//${
      //     window.location.hostname
      //   }:${window.location.port}
      //       /${accessStorage.getFromStore('access_id')}`;
      //   window.location.replace(replacementUrl);
      // }
    }
    return Promise.reject(error);
  }
});
