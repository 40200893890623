import React, { useEffect } from 'react';
import { Form, InputGroup } from 'react-html5-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { requestLogin } from '../../../app/app.actions';
import EveryfarmerLogo from '../../../assets/eflogo.png';
import Loader from '../../../assets/loader.gif';
import Footer from '../../components/Footer';
import Image from '../../components/Image';
import accessStorage from '../../utils/accessStorage';
import { requestAsset } from './login.actions';
import { accountNameSelector, getUserLogoSelector } from './login.selectors';

const Login = ({
  history,
  requestAsset,
  shortDescription,
  requestLogin,
  authError,
  logo,
  match,
  accountName,
  isLoggingIn,
  assetLoading
}) => {
  const accessIdExists = () =>
    match !== undefined && match.params !== undefined && match.params.accessId !== undefined;
  const accessId = accessIdExists() ? match.params.accessId : '23441900';

  useEffect(() => {
    if (accessId && accessId.length === 8) {
      requestAsset(accessId);
    } else {
      history.push('/error/unauthorised');
    }
    return;
  }, [accessId, history, requestAsset]);

  const handleLoginSubmit = form => {
    if (form.valid) {
      const formData = new FormData(form.getRef().current);
      accessStorage.store('user', formData.get('username'));
      const data = {
        creds: {
          username: formData.get('username'),
          password: formData.get('password')
        },
        history: history
      };
      return requestLogin(data);
    }
    return form.setError('Please enter the correct credentials.');
  };

  return (
    <div className='d-flex flex-wrap min-vh-100 bg-field'>
      {isLoggingIn || assetLoading ? (
        <div className='d-flex justify-content-center w-20 mx-auto'>
          <img data-testid='loader' src={Loader} alt='loader' className='my-auto' />
        </div>
      ) : (
        <>
          <div className='col-md-8 d-flex flex-column-reverse flex-md-column col-sm-12'>
            <div className='h-100 d-flex flex-column justify-content-center align-items-center'>
              {/* <Image src={logo && logo} fallbackSrc={EveryfarmerLogo} /> */}
              <Image src={EveryfarmerLogo} />
              <h4 className='green my-4'>
                <em>{shortDescription}</em>
              </h4>
              <p className='text-danger'>
                {authError !== undefined && typeof authError === 'string' ? authError : ''}
              </p>
            </div>

            <div className='mt-md-auto d-none d-md-flex align-self-md-end align-self-sm-center'>
              <Footer />
            </div>
          </div>

          <div className='bg-revenue-dark text-white col-sm-12 col-md-4 p-5'>
            <h3 className='font-weight-bold text-white mt-5'>
              Welcome Back{accountName ? `, ${accountName}` : ''}
            </h3>
            <p className='my-3'>Sign in to continue</p>
            <Form onSubmit={handleLoginSubmit} className='py-4'>
              {({ pristine, submitting }) => (
                <>
                  <InputGroup validate={['username']} tag='div' className='form-row'>
                    {({ error, valid }) => {
                      return (
                        <div className='form-group  input-group-md col-md-12'>
                          <label htmlFor='loginUsername' className='text-black'>
                            Username
                          </label>
                          <input
                            data-testid='username'
                            id='loginUsername'
                            required
                            type='text'
                            // pattern='^([0-9]{9})|([A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3})$'
                            name='username'
                            placeholder='Enter Username'
                            className={`form-control ${!valid && 'is-invalid'}`}
                          />

                          {error && <p className='text-danger'>{error}</p>}
                        </div>
                      );
                    }}
                  </InputGroup>

                  <InputGroup validate={['password']} tag='div' className='form-row'>
                    {({ error }) => (
                      <div className='form-group  input-group-md col-md-12'>
                        <label htmlFor='loginPasword' className='text-black'>
                          Password
                        </label>
                        <input
                          data-testid='password'
                          id='loginPasword'
                          required
                          name='password'
                          type='password'
                          placeholder='Password'
                          className='form-control'
                        />
                        {error && <p className='text-danger'>{error}</p>}
                      </div>
                    )}
                  </InputGroup>
                  <div className='d-flex justify-content-center align-items-center'>
                    <button
                      id='load'
                      type='submit'
                      disabled={pristine || submitting}
                      className='btn btn-block btn-light btn-sm px-3'
                      data-loading-text="<i className='fa fa-spinner fa-spin '></i> Processing user credential"
                    >
                      Login
                    </button>
                  </div>
                </>
              )}
            </Form>
          </div>
        </>
      )}
      <div className='d-md-none mx-auto'>
        <Footer />
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    isLoggingIn: state.auth.isLoggingIn,
    authError: state.auth.authError,
    assetLoading: state.assets.isFetching,
    accountName: accountNameSelector(state),
    logo: getUserLogoSelector(state),
    shortDescription: state.assets.shortDescription
  };
};

export default connect(mapStateToProps, { requestAsset, requestLogin })(withRouter(Login));
