import React, { Component } from 'react'

export default class Footer extends Component {
  getYear() {
    return new Date().getFullYear()
  }

  render() {
    return (
      // className={
      //   this.props.match == "/resources" ? "d-none" : "d-block"
      // }
      <div className='container-fluid'>
        {/* <div className="container footer fixed-bottom mt-5 py-1"> */}
        <div className='row d-flex justify-content-center my-3'>
          <p>
            <span>Copyright</span>
            <span className='green ml-2'>
              <strong>Every</strong>
            </span>
            <span className='greener mr-2'>
              <strong>Farmer </strong>
            </span>
            <span>{this.getYear()}. All right reserved</span>
          </p>
        </div>
      </div>
    )
  }
}
