import React from 'react'
import 'chart.js'
import { ColumnChart } from 'react-chartkick'
import { Link } from 'react-router-dom'

import farmer from '../../../assets/Farmer.svg'

const FarmersAnalysis = props => {
  return (
    <div className='bg-light p-3'>
      <div className='container-fluid'>
        <div className='d-flex flex-sm-column flex-md-row justify-content-between justify-content-center-sm align-items-center'>
          <h3 className='text-black-50 h4'>Farmer's Analysis</h3>
          <div className='border rounded text-center py-2 px-3 d-flex justify-content-between'>
            <img src={farmer} alt='farmer' className='mr-2' />
            <div>
              <h4 className='text-dark font-weight-light'>Total Farmers</h4>
              <p className='font-weight-bold font-fix mb-0'>
                {props.analytics ? props.analytics.communitiesTotal : 0}
              </p>
            </div>
          </div>
        </div>

        {/* /*Farmer's anaysis bar chart */}
        <div className='row mt-5'>
          <div className='col-md-6 col-sm-12 mb-sm-2 mb-0'>
            <ColumnChart
              // data={props.analytics.firstFive}
              data={[
                ['Sokoto', '500'],
                ['Kebbi', '700'],
                ['Birin', '890'],
                ['Kano', '600'],
                ['Benin', '550']
              ]}
              dataset={{ backgroundColor: '#96c63d' }}
              colors={['#96c63d']}
              xtitle='LGA'
              ytitle='Number of farmers'
              height={'80%'}
            />
            <h6 className='bg-white ml-5 p-2 my-4 text-center'>
              {' '}
              Numbers Of Farmers By LGA (Top 5)
            </h6>
          </div>
          <div className='col-md-6 col-sm-12'>
            <ColumnChart
              // data={props.analytics.lastFive}
              data={[
                ['Sokoto', '200'],
                ['Kebbi', '145'],
                ['Birin', '690'],
                ['Kano', '300'],
                ['Benin', '550']
              ]}
              dataset={{ backgroundColor: '#c66868' }}
              colors={['#c66868']}
              height={'80%'}
              xtitle='LGA'
              ytitle='Number of farmers'
            />
            <h6 className='bg-white ml-5 p-2 my-4 text-center'>
              Numbers Of Farmers By LGA (Bottom 5)
            </h6>
          </div>
        </div>

        <div className='greener text-right font-18 underline'>
          <Link to='#'>...View more</Link>
        </div>
      </div>
    </div>
  )
}

export default FarmersAnalysis
