import React from 'react'
import GrantAccessModal from './GrantAccessModal'

const WorkInfo = props => {
  const {
    formRef,
    formState: { accessGrants, pageNum, staffData },
    prevPage,
    nextPage
  } = props
  return (
    <form onSubmit={nextPage} ref={formRef}>
      <>
        <div className='py-2'>
          <label htmlFor='designation' className='d-flex'>
            Designation <span className='red'>*</span>
          </label>
          <select
            name='designation'
            id='designation'
            className={`custom-select border-0`}
            defaultValue={staffData && staffData.designation}
          >
            <option value=''>Select...</option>
            <option value='collabotor'>Collaborator</option>
            <option value='externalUser'>External User</option>
          </select>
        </div>
        <div className='py-2'>
          <label htmlFor='resources' className='d-flex'>
            Resources <span className='red'>*</span>
          </label>
          {staffData.designation === 'collaborator' && (
            <button
              className='btn btn-secondary text-white cursor'
              data-toggle='modal'
              data-target='#grantAccess'
            >
              Grant Resource Access
            </button>
          )}
          {accessGrants &&
            accessGrants.map(grant => (
              <div
                className='d-flex justify-content-between w-sm-75 w-lg-50 py-2'
                key={grant.resource}
              >
                <h5 className='font-weight-bold'>{grant.resource}</h5>
                <h6>
                  {grant.Edit && grant.viewOnly && 'Mixed'}
                  {grant.Edit && !grant.viewOnly && 'View & Edit'}
                  {!grant.Edit && grant.viewOnly && 'View only'}
                </h6>
              </div>
            ))}
        </div>
        <div className='d-flex justify-content-center align-items-baseline py-2 mt-3'>
          <button onClick={prevPage} className='btn btn-secondary text-white mx-3'>
            Prev
          </button>
          <span className='px-md-5 px-sm-3'>{`${pageNum}/3`}</span>
          <button type='submit' className='btn btn-revenue text-white ml-3'>
            Next
          </button>
        </div>
        <GrantAccessModal formState={props.formState} grantAccess={props.grantAccess} />
      </>
    </form>
  )
}

export default WorkInfo
