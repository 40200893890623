import React, { Component } from 'react'
import { Form, InputGroup } from 'react-html5-form'
import locust from '../../../assets/locust-swarm.jpg'
export default class Outbreaks extends Component {
  constructor(props) {
    super(props)
    this.state = {
      issue: [
        {
          id: 1,
          issuer: 'Adewale Tijani',

          requests: 4,
          summary: 'Rice Decoloration Help!!',
          location: 'Yauri, Sokoto North'
        },
        {
          id: 2,
          issuer: 'Maitama Tambuwal',
          requests: 14,
          summary: 'Rice Decoloration Help!!',
          location: 'Isoko Central, Bendel'
        }
      ],
      isUpdate: false
    }
  }
  render() {
    return (
      <div className='container-fluid main-content'>
        {/* <Navbar
            navLinks={[
              {
                path: "/resources/extension",
                header: "Extension"
              }
            ]}
            small="Manage Reports from Extension Workers"
          /> */}
        <div className='row justify-content-end'>
          <button
            className='btn btn-expend btn-sm float-right text-white px-3 m-2'
            data-toggle='modal'
            data-target='#outbreakModal'
          >
            <i className='fas fa-plus fa-sm mr-2' />
            <span>Submit Outbreak</span>
          </button>
        </div>

        <div className='row bg-white'>
          <div className='col-sm-7'>
            <div className='card-block'>
              <h2 className='card-title'>Pest</h2>
              <h4>High</h4>
              <p>
                There has been a locust infestation in the maize farms at Bosso Local Government.
                Assistance is needed as soon as possible to save the crops
              </p>

              {/* <a href="#" className="btn btn-primary btn-sm">
                  Read More
                </a> */}
            </div>
          </div>

          <div className='col-sm-5'>
            <img className='d-block w-100' src={locust} width='150' alt='locust' />
          </div>
        </div>

        {/* Add Outbreak Modal */}
        <div
          className='modal fade'
          id='outbreakModal'
          data-backdrop='static'
          data-keyboard='false'
          tabIndex={-1}
          role='dialog'
          aria-hidden='true'
        >
          <div className='modal-dialog' role='document'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h4> Submit Outbreak</h4>
                <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-body'>
                <Form id='resourceForm' name='resourceForm'>
                  {({ error, valid, submitting, pristine, form }) => (
                    <>
                      <InputGroup tag='div' validate={['type']}>
                        {({ error, valid }) => (
                          <div className='col-md-12 my-2'>
                            <div className='form-group'>
                              <label htmlFor='type'>Type</label>
                              <select className='form-control' id='type'>
                                <option>Pest</option>
                                <option>Disease</option>
                                <option>Weed</option>
                              </select>
                            </div>
                            {error && <div className='invalid-feedback'>{error}</div>}
                          </div>
                        )}
                      </InputGroup>
                      <InputGroup tag='div' validate={['type']}>
                        {({ error, valid }) => (
                          <div className='col-md-12 my-2'>
                            <div className='form-group'>
                              <label htmlFor='type'>Severity</label>
                              <select className='form-control' id='type'>
                                <option>High</option>
                                <option>Low</option>
                                <option>Medium</option>
                              </select>
                            </div>
                            {error && <div className='invalid-feedback'>{error}</div>}
                          </div>
                        )}
                      </InputGroup>
                      <InputGroup tag='div' validate={['description']}>
                        {({ error, valid }) => (
                          <div className='col-md-12 my-3'>
                            <textarea
                              className={`form-control form-control-sm ${!valid && 'is-invalid'}`}
                              required
                              id='description'
                              placeholder='Enter description'
                              name='description'
                              rows='3'
                            />

                            {error && <div className='invalid-feedback'>{error}</div>}
                          </div>
                        )}
                      </InputGroup>
                      <div className='modal-footer'>
                        <button
                          type='button'
                          className='btn btn-expend text-white'
                          data-dismiss='modal'
                        >
                          Close
                        </button>

                        <button
                          type='submit'
                          id='updateButton'
                          name='updateButton'
                          className='btn btn-revenue text-white float-right'
                          disabled={pristine || submitting}
                        >
                          Submit
                        </button>
                      </div>
                    </>
                  )}
                </Form>
              </div>
            </div>
          </div>
        </div>
        {/* Outbreak Modal Ends */}
      </div>
    )
  }
}
