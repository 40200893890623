import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import '../../../../pages/farm/FarmResourceItem.css';
import minus from '../../../../../assets/Minus-Remove.svg';
import downIcon from '../../../../../assets/Down.svg';
import moreIcon from '../../../../../assets/3Dots.svg';
import RightChevron from '../../../../../assets/Chevron.svg';
import Up from '../../../../../assets/Up.svg';
import AreaMapping from '../area-mapping/AreaMapping';
import SoilMapping from '../soil-mapping/SoilMapping';
import Assets from '../assets/AssetItem';
import { selectFarmItem, manageFarmFeature } from '../../../../pages/farm/farm.actions';

class FarmItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subResourcesVisible: false
    };
  }

  toggleResources = () => {
    this.setState(prevState => ({
      subResourcesVisible: !prevState.subResourcesVisible
    }));
  };

  changeStatus = (status, feature) => {
    this.props.dispatch(
      manageFarmFeature(this.props.farmItems, feature, this.props.data.id, status)
    );
  };

  manageAreaMapping = status => {
    this.changeStatus(status, 'AREA_MAPPING');
    return !status;
  };

  manageSoilMapping = status => {
    this.changeStatus(status, 'SOIL_MAPPING');
    return !status;
  };

  manageAsset = status => {
    this.changeStatus(status, 'ASSET');
    return !status;
  };

  // componentDidMount() {
  //   console.log('FARM ITEM ' + JSON.stringify(this.props.data))
  // }

  render() {
    const { data, farmItems } = this.props;
    return (
      <div className='p-1'>
        {/* <MaterialTable
          columns={this.farmItemColumn}
          data={data}
          style={{
            fontFamily: 'Nunito',
            width: '100%',
            boxShadow: 'none'
          }}
          options={{
            actionsColumnIndex: 4,
            sorting: true,
            showTitle: false,
            loadingType: 'linear',
            headerStyle: {
              backgroundColor: '#4f4f4f',
              color: '#FFF'
            }
          }}
          components={{
            CircularProgress: props => (
              <div style={{ backgroundColor: '#4f4f4f' }}>
                <CircularProgress {...props} />
              </div>
            )
          }}
        /> */}
        {/* <div className='container item'>
          <div className='row w-100 border-bottom resource-head align-items-center'>
            <div className='col-4 py-2 d-flex'>
              <p className='d-inline text-secondary font-weight-light mb-0'>
                {this.props.data.name}
              </p>
            </div>
            <div className='col-1 d-flex justify-content-around'>
              <img
                src={minus}
                className='ml-1 mr-2'
                alt='Remove Icon'
                onClick={() => {
                  this.props.onDelete(this.props.data.id)
                }}
              />
              <div className='dropdown ml-auto'>
                <div
                  className='menuIconWrapper d-flex justify-content-center align-content-center mousePointer'
                  id='dropdownMenuButton'
                  data-toggle='dropdown'
                  aria-haspopup='true'
                  aria-expanded='false'
                >
                  <img src={moreIcon} className='' alt='More Icon' />
                </div>
                <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                  <button
                    className='dropdown-item'
                    onClick={() => this.props.onEdit(this.props.data)}
                    data-toggle='modal'
                    data-target='#addResourceModal'
                  >
                    Edit
                  </button>
                  <button className='dropdown-item' href='#'>
                    Add a Program
                  </button>
                  <button
                    className='dropdown-item border-top'
                    onClick={() => {
                      this.props.onDelete(this.props.data.id)
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
            <div
              className='col-1 text-center py-2 ml-auto border-left resource-head__btn cursor'
              onClick={this.toggleResources}
            >
              {this.state.subResourcesVisible === false ? (
                <img src={downIcon} alt='Hide Icon' />
              ) : (
                <img src={Up} alt='Hide Icon' />
              )}
            </div>
          </div>
        </div>

        {this.state.subResourcesVisible === false ? (
          ' '
        ) : (
          <div className='container py-2 open-resource item'>
            <AreaMapping
              detailsOfArea={this.props.FarmResourceDetail}
              isActivated={this.props.data.isAreaMappingEnable}
              onToggle={this.manageAreaMapping}
            />

            <SoilMapping
              detailsOfSoil={this.props.FarmResourceDetail}
              isActivated={this.props.data.isSoilMappingEnabled}
              onToggle={this.manageSoilMapping}
            />
            <Assets
              detailsOfAssets={this.props.FarmResourceDetail}
              isActivated={this.props.data.isAssetEnabled}
              onToggle={this.manageAsset}
            />

            <div className='col d-flex justify-content-end'>
              <NavLink
                onClick={() => {
                  this.props.dispatch(selectFarmItem(this.props.data.id))
                }}
                to='/new-farm-resource/new-area-mapping/'
                type='button'
                className='btn text-secondary font-weight-light bg-white drop-shadow btn-sm mousePointer py-1 px-3 mb-1'
              >
                View/Manage
                <img src={RightChevron} className='ml-3' alt='Right Arrow Icon' />
              </NavLink>
            </div>
          </div>
        )} */}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedResource: state.farmResource.selectedFarmResource,
    farmItems: state.farmResource.farmItems
  };
};

export default withRouter(connect(mapStateToProps)(FarmItem));
