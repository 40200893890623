export const SELECT_FARM_RESOURCE = 'SELECT_FARM_RESOURCE'
export const SELECT_FARM_ITEM = 'SELECT_FARM_ITEM'
export const FETCH_FARMS = 'FETCH_FARM'
export const FETCH_FARMS_SUCCESS = 'FETCH_FARM_SUCCESS'
export const FETCH_FARMS_ERROR = 'FETCH_FARMS_ERROR'
export const LOAD_FARMS = 'LOAD_FARMS'
export const UPDATE_FARM_FEATURE_ERROR = 'UPDATE_FARM_FEATURE_ERROR'
export const UPDATE_FARM_FEATURE = 'UPDATE_FARM_FEATURE'
export const DELETE_FARM_ITEM = 'DELETE_FARM_ITEM'
export const DELETE_FARM_ITEM_ERROR = 'DELETE_FARM_ITEM_ERROR'
export const IMPORT_FARMS = 'IMPORT_FARMS'
export const CREATE_FARM = 'CREAT_FARM'
export const CREATE_FARM_ERROR = 'CREATE_FARM_ERROR'
export const IMPORT_FARMS_ERROR = 'IMPORT_FARMS_ERROR'
export const FETCH_FARMS_BY_COMMUNITY_RESOURCE = 'FETCH_FARMS_BY_COMMUNITY_RESOURCE'
export const FETCH_FARMS_BY_COMMUNITY_RESOURCE_ERROR = 'FETCH_FARMS_BY_COMMUNITY_RESOURCE_ERROR'
export const LOAD_FARMS_BY_COMMUNITY_RESOURCE = 'LOAD_FARMS_BY_COMMUNITY_RESOURCE'
