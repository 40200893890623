import React, { Component } from 'react'
import Loader from '../../../assets/loader.gif'
import BaseUrl from '../../../security/base-url'
import NewDataModal from '../../components/modals/NewDataModal'
import Navbar from '../../components/Navbar'
import ResourceItem from '../../components/resources/resourcesList/ResourceItem'

export default class ExtensionList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      allResources: [
        {
          id: 0,
          name: '2018/2019 Wet Season Cotton'
        },
        {
          id: 1,
          name: '2018/2019 Wet Season Maize'
        }
      ],
      isUpdate: false,

      serviceProviders: []
    }
  }

  componentDidMount() {
    //this.getExtensions();
  }

  getServiceProviders = () => {
    fetch(BaseUrl.url_dashbord + '/serviceproviderresource/byuseraccount', {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      },
      mode: 'cors'
    })
      .then(response => response.json())
      .then(res => {
        this.setState({ serviceproviders: res })
      })
      .catch(reason => console.info(reason))
  }

  getExtensions = () => {
    fetch(`${BaseUrl.url_dashbord}/serviceprovider/${this.state.serviceProviderId}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      },
      mode: 'cors'
    })
      .then(res => res.json())
      .then(allResources => this.setState({ allResources: allResources.reverse() }))
      .catch(err => console.log(err))
  }

  handleSaveResource = () => {
    fetch(`${BaseUrl.url_dashbord}/extension_service/${this.state.serviceProviderId}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      },
      mode: 'cors',
      body: JSON.stringify({
        name: this.state.name,
        description: this.state.description
      })
    }).then(res => res.json())
  }

  handleModalUpdate = resource => {
    this.setState({ isUpdate: true })
    document.getElementById('editdrpdwn').setAttribute('data-target', '#extensionResourceModal')
    document.getElementById('editdrpdwn').click()
  }

  resetUpdateState = () => document.getElementsByClassName('close').click()

  renderExtensionList = () => {
    const { allResources } = this.state
    return allResources.map(resource => (
      <ResourceItem
        listItem={resource}
        modalName='Extension-resource'
        modalUpdate={this.handleModalUpdate}
        key={resource.id}
        {...this.props}
      />
    ))
  }

  render() {
    return (
      <div className='main-content container-fluid'>
        <Navbar
          navLinks={[
            {
              path: '/extension',
              header: 'Extension'
            }
          ]}
          small='Create and Manage Extension Services on the Platform'
        />

        <div className='bg-white py-1 px-2'>
          {this.state.allResources.length === 0 ? (
            <div className='d-flex justify-content-center'>
              <img src={Loader} alt='loader' className='text-center' />
            </div>
          ) : (
            <>
              <div className='d-flex w-100 my-2 py-2 align-items-center'>
                <input type='checkbox' className='mx-2' />
                <select className='mr-2 custom-select w-20 form-control'>
                  <option value='Select actions...'>Select actions...</option>
                  <option value='Nil'>Nil</option>
                </select>
                <i className='fas fa-filter text-black-50 input-prepend' />
                <input className='form-control w-20 mr-auto' type='search' placeholder='Search' />
                <span className='badge badge-pill badge-warning'>
                  {this.state.allResources.length} Services
                </span>
                {/* <button
                  className="btn btn-revenue text-white btn-sm px-3 ml-2"
                  data-toggle="modal"
                  data-target="#extensionResourceModal">
                  <i className="fas fa-plus fa-sm mr-2" />
                  <span>Add Extension Service</span>
                </button> */}
              </div>
              <div className='fix-height revenue-scrollbar p-1'>
                <ul className='list-group'>{this.renderExtensionList()}</ul>
              </div>
            </>
          )}
        </div>

        <NewDataModal
          modalId='extension'
          modalName='Extension'
          isUpdate={this.state.isUpdate}
          resetUpdateState={this.resetUpdateState}
          saveResource={this.handleSaveResource}
        />
      </div>
    )
  }
}
