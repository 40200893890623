import Proptypes from 'prop-types';
import React, { useMemo, useCallback } from 'react';
import { Redirect, Route } from 'react-router-dom';
import accessStorage from '../utils/accessStorage';

/**
 * Protects the `route` against unauthorized usage/view
 * @param `View` The component to be protected from unauthorised visit
 * @param  `...rest`
 *
 */
const PrivateRoute = ({
  view: View,
  isSiteAuto,
  accessId,
  isAuthenticated,
  routes,
  path,
  ...rest
}) => {
  const token = accessStorage.getFromStore('token');

  const isLoggedIn = useMemo(
    () => isSiteAuto === true || (isAuthenticated === true && token !== null),
    [isAuthenticated, isSiteAuto, token]
  );

  const hasAccessIdAndNoToken = useCallback(
    () =>
      (token === null &&
        accessId !== null &&
        accessId !== process.env.REACT_APP_ADMIN_ID &&
        accessId === undefined) || [(accessId, token)]
  );
  return (
    <Route
      path={path}
      render={props =>
        isLoggedIn ? (
          <View {...props} routes={routes} {...rest} />
        ) : hasAccessIdAndNoToken() ? (
          <Redirect push to='/login' />
        ) : (
          <Redirect push to={`/${accessId}`} />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  view: Proptypes.elementType.isRequired,
  isSiteAuto: Proptypes.bool,
  accessId: Proptypes.string.isRequired,
  isAuthenticated: Proptypes.bool,
  routes: Proptypes.func.isRequired,
  path: Proptypes.string.isRequired
};

export default PrivateRoute;
