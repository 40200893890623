import React, { lazy, Suspense, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { fetchData } from '../../api/api.actions'
import Navbar from '../../components/Navbar'
import Spinner from '../../components/Spinner'
import './centralDashboard.css'

const Overview = lazy(() => import('../../components/centralDashboard/CentralOverview'))
const ResourcesActivities = lazy(() =>
  import('../../components/centralDashboard/ResourcesActivities')
)

/**
 * Central Dashboard for EveryFarmer's administrative user
 * @param {*} props
 */
const CentralDashboard = props => {
  const { centralDashboard, fetchData } = props
  const [viewPeriod, setviewPeriod] = useState(30)
  const [isLoading, setisLoading] = useState(false)

  useEffect(() => {
    setisLoading(true)
    fetchData('DASHBOARD_DATA')
    return setisLoading(false)
  }, [fetchData])

  const {
    progressionRate,
    totalIncome,
    farmersProgressionRate,
    communities,
    lastYearRevenue,
    inputSupply,
    serviceProviders,
    soilCategories
  } = centralDashboard

  const platformStats = {
    activeServices: centralDashboard.activeServices,
    inactiveServices: centralDashboard.inactiveServices,
    activeUsers: centralDashboard.activeUsers,
    inactiveUsers: centralDashboard.inaobjectctiveUsers,
    activeSubscriptions: centralDashboard.activeSubscriptions,
    inactiveSubscriptions: centralDashboard.inactiveSubscriptions,
    registeredFarmers: centralDashboard.registeredFarmers,
    activeFarmers: centralDashboard.activeFarmers,
    inactiveFarmers: centralDashboard.inactiveFarmers,
    activeServiceProviders: centralDashboard.activeServiceProviders,
    inactiveServiceProviders: centralDashboard.inactiveServiceProviders,
    activeAgents: centralDashboard.activeAgents,
    inactiveAgents: centralDashboard.inactiveAgents
  }

  const formatAsMoney = amount =>
    amount.toLocaleString('en-NG', {
      style: 'currency',
      currency: 'NGN'
    })
  const totalRevenue = formatAsMoney(totalIncome)
  const monthlyRevenue = formatAsMoney(Math.round(totalIncome / 12))
  const biAnnualRevenue = formatAsMoney(Math.round(totalIncome / 2))

  /**
   * Updates the `view period` value as per user's preference selected view
   * @param {*} paramm `target` element sending to event signal
   */
  const handleOptionChange = ({ target }) => {
    setviewPeriod(target.value)
  }

  return (
    <div className='overflow-hidden'>
      <Navbar navLinks={[{ path: '/', header: 'Overview' }]} small='Summary Of Activities' />
      <div role='main' className='mx-3 fix-height fix-height-75 revenue-scrollbar'>
        <Suspense fallback={<Spinner isLoading={isLoading} data={[]} />}>
          <Overview
            platformStats={platformStats}
            biAnnualRevenue={biAnnualRevenue}
            lastYearRevenue={lastYearRevenue}
            monthlyRevenue={monthlyRevenue}
            progressionRate={progressionRate}
            totalRevenue={totalRevenue}
            viewPeriod={viewPeriod}
            handleOptionChange={handleOptionChange}
          />
          <ResourcesActivities
            inputSupply={inputSupply}
            serviceProviders={serviceProviders}
            communities={communities}
            platformStats={platformStats}
            farmersProgressionRate={farmersProgressionRate}
            soilCategories={soilCategories}
          />
        </Suspense>
      </div>
    </div>
  )
}

CentralDashboard.propTypes = {}

const mapStateToProps = ({ centralDashboard }) => ({ centralDashboard })

export default connect(mapStateToProps, { fetchData })(CentralDashboard)
