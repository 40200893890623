import Swal from 'sweetalert2'
import BaseUrl from '../../security/base-url'
import apiService from '../api/apiService'

const sweetAlertDeleteMethod = (path, headersConfig, cb) => {
  const swalWithBootstrapButtons = Swal.mixin({
    confirmButtonClass: 'btn btn-revenue text-white mx-2',
    cancelButtonClass: 'btn btn-expend text-white',
    buttonsStyling: false
  })

  swalWithBootstrapButtons({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, cancel!',
    reverseButtons: true
  }).then(result => {
    if (result.value) {
      fetch(BaseUrl.url_dashbord + path, headersConfig)
        .then(res => res.json())
        .then(data => {
          if (data !== null) {
            data.message === 'error'
              ? swalWithBootstrapButtons(
                  'Error!',
                  'Error while trying to delete resource.',
                  'error'
                )
              : swalWithBootstrapButtons('Deleted!', 'Your resource has been deleted.', 'success')
            cb()
          }
        })
        .catch(err => err)
    }
  })
}
export default sweetAlertDeleteMethod

const deleteAlert = async (path, cb) => {
  const swalWithBootstrapButtons = Swal.mixin({
    confirmButtonClass: 'btn btn-revenue text-white mx-2',
    cancelButtonClass: 'btn btn-expend text-white',
    buttonsStyling: false
  })

  const userChoice = await swalWithBootstrapButtons({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, cancel!',
    reverseButtons: true
  })
  if (userChoice.value) {
    try {
      const deleteRes = await apiService.delete(path)
      deleteRes.message !== null
        ? swalWithBootstrapButtons('Error!', 'Error while trying to delete resource.', 'error')
        : swalWithBootstrapButtons('Deleted!', 'Your resource has been deleted.', 'success')
      cb()
    } catch (error) {
      console.error(error, '-deleteAlert')
    }
  }
}

export { deleteAlert }
