import { createSelector } from 'reselect'

export const getTrashItems = createSelector(
  ({ trash }) => trash.trashItems,
  trashItems => trashItems
)
export const getIsLoading = createSelector(
  ({ trash }) => trash.isLoading,
  isLoading => isLoading
)
