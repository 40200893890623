import React, { PureComponent } from 'react'
import ReactTable from 'react-table-v6'
import 'react-table-v6/react-table.css'
import { Link } from 'react-router-dom'

import moreIcon from '../../../assets/3Dots.svg'
import Spinner from '../Spinner'
import DataTableDropdown from './DataTableDropdown'

class DataTable extends PureComponent {
  render() {
    const {
      accessRight,
      data = [],
      columnTitle,
      dropdown: Dropdown = DataTableDropdown,
      isLoading,
      rowUrl
    } = this.props
    const renderColumn = tableProps => [
      {
        Cell: props => (
          <input
            type='checkbox'
            id={props.original.id}
            value={props.original.id}
            className='table-checkbox'
          />
        ),
        headerClassName: 'table-header py-2',
        width: 30
      },
      {
        Header: columnTitle[0].header,
        accessor: columnTitle[0].accessor,
        headerClassName: 'table-header py-2',
        Cell: props => {
          return rowUrl ? (
            <span className='pointer'>
              <Link to={`/${rowUrl}/${props.original.id}`}>{props.value}</Link>
            </span>
          ) : (
            <span>{props.value}</span>
          )
        }
      },
      {
        Header: columnTitle[1].header,
        accessor: columnTitle[1].accessor,
        headerClassName: 'table-header py-2',
        Cell: props => {
          if (props.value === 'ACTIVE') {
            return <span className='badge bg-revenue text-white'>{props.value}</span>
          }
          if (props.value === 'INACTIVE') {
            return <span className='badge bg-expend text-white'>{props.value}</span>
          } else {
            return (
              <span className='pointer'>
                <Link to={`/${rowUrl}/${props.original.id}`}>{props.value}</Link>
              </span>
            )
          }
        }
      },
      {
        Header: columnTitle[2].header,
        accessor: columnTitle[2].accessor,
        headerClassName: 'table-header py-2',
        Cell: props => {
          if (props.value === 'ACTIVE') {
            return <span className='badge bg-revenue text-white'>{props.value}</span>
          }
          if (props.value === 'INACTIVE') {
            return <span className='badge bg-expend text-white'>{props.value}</span>
          } else {
            return <span>{props.value}</span>
          }
        }
      },
      {
        Header: columnTitle[3] ? columnTitle[3].header : null,
        accessor: columnTitle[3] ? columnTitle[3].accessor : null,
        headerClassName: columnTitle[3] ? 'table-header py-2' : 'd-none',
        show: columnTitle[3] && true
      },
      {
        Header: (
          <span className='text-center'>
            <img src={moreIcon} className='px-2' alt='More Icon' />
          </span>
        ),
        headerClassName: accessRight === 'viewOnly' ? 'd-none' : 'table-header',
        Cell: ({ original }) => <Dropdown {...tableProps} row={original} />,
        filterable: false,
        sortable: false,
        width: 60,
        show: accessRight === 'viewOnly' ? false : true
      }
    ]

    let column = renderColumn(this.props)

    return (
      <div className='container-fluid main-content pt-2'>
        <Spinner isLoading={isLoading} data={data}>
          <ReactTable
            columns={column}
            className='fix-height -striped -highlight pointer mx-md-2'
            data={data}
            defaultPageSize={10}
            getTbodyProps={() => ({
              className: 'revenue-scrollbar'
            })}
          />
        </Spinner>
      </div>
    )
  }
}

export default DataTable
