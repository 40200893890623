export const EDIT_AGENT_DATA = 'EDIT_AGENT_DATA';
export const UPDATE_SERVICE_PROVIDERS = 'UPDATE_SERVICE_PROVIDERS';
export const TOGGLE_SERVICE_ACTIVATION = 'TOGGLE_SERVICE_ACTIVATION';
export const TOGGLE_SERVICE = 'TOGGLE_SERVICE';
export const RESET_ISLOADING = 'RESET_ISLOADING';

export const servicePRoviderActions = {
  REQUEST_SERVICE_PROVIDERS: 'REQUEST_SERVICE_PROVIDERS',
  REQUEST_SERVICE_PROVIDERS_SUCCESS: 'REQUEST_SERVICE_PROVIDERS_SUCCESS',
  REQUEST_SERVICE_PROVIDERS_FAILED: 'REQUEST_SERVICE_PROVIDERS_FAILED',
  REQUEST_SHARED_AGENCIES: 'REQUEST_SHARED_AGENCIES',
  REQUEST_SHARED_AGENCIES_SUCCESS: 'REQUEST_SHARED_AGENCIES_SUCCESS',
  REQUEST_SHARED_AGENCIES_FAILED: 'REQUEST_SHARED_AGENCIES_FAILED',
  REQUEST_CAPTURED_DATA: 'REQUEST_CAPTURED_DATA',
  REQUEST_CAPTURED_DATA_SUCCESS: 'REQUEST_CAPTURED_DATA_SUCCESS',
  REQUEST_CAPTURED_DATA_FAILED: 'REQUEST_CAPTURED_DATA_FAILED',
  REQUEST_AGENTS: 'REQUEST_AGENTS',
  REQUEST_AGENTS_SUCCESS: 'REQUEST_AGENTS_SUCCESS',
  REQUEST_AGENTS_FAILED: 'REQUEST_AGENTS_FAILED'
};
