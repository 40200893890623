import React, { useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { useSortBy, useTable } from 'react-table'
import Navbar from '../../../app/components/Navbar'
import Loader from '../../../assets/loader.gif'
import {
  getFarmersMembers,
  handleFilterFarmersMembers,
  handleSwitchProcessedFarmer2
} from '../../pages/community/communities.actions'
import { getFilteredData } from '../../utils/resourceFilter'

//New Table Method
function Table({ columns, data }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data
    },
    useSortBy
  )

  // We don't want to render all 2000 rows for this example, so cap
  // it at 20 for this use case
  //const firstPageRows = rows.slice(0, 20);

  return (
    <>
      <table className='table table-hover' {...getTableProps()}>
        <thead className='thead-dark'>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th scope='col' {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  {/* Add a sort direction indicator */}
                  <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td className='px-4' {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      {/* <br />
      <div>Showing the first 20 results of {rows.length} rows</div> */}
    </>
  )
}

const ProcessedFarmers = props => {
  const [search, setSearch] = useState('')
  const { switchProcessedFarmers, communityResourceName } = props
  // const goToEop = id => {
  //   props.handleSwitchProcessedFarmer2(true);
  //   props.history.push(`/view-profile/" + id`);
  // };

  const onSearch = event => {
    setSearch(event.target.value)
  }

  //New Table data

  const columns = useMemo(
    () => [
      {
        Header: 'S/N',
        accessor: (row, i) => i + 1
      },

      {
        Header: 'First Name',
        accessor: 'firstName',
        headerClassName: 'table-header py-3'
      },
      {
        Header: 'Surname',
        accessor: 'surname',
        headerClassName: 'table-header py-3'
      },
      {
        Header: 'Other Name',
        accessor: 'othername',
        headerClassName: 'table-header py-3'
      },
      {
        Header: 'BVN',
        accessor: 'bvn',
        headerClassName: 'table-header py-3'
      },
      {
        Header: 'Phone',
        accessor: 'phone',
        headerClassName: 'table-header py-3'
      },

      {
        Header: 'Created On',
        accessor: 'createdOn',
        headerClassName: 'table-header py-3'
      }
    ],
    []
  )

  const data = useMemo(
    () => getFilteredData(switchProcessedFarmers, search, 'processesFarmerTag'),
    [search, switchProcessedFarmers]
  )

  return (
    <div className='container-fluid'>
      <Navbar
        navLinks={[
          {
            path: '/resources/community/',
            header: 'Communty Resource |'
          },
          {
            path: '#',
            header: '|Processed Farmers'
          }
        ]}
        small={`Processed Farmers in ${communityResourceName}`}
      />

      <div className='container-fluid bg-white '>
        <div className='row bg-white'>
          <div className='col-md-12 text-right d-flex w-100 py-3 align-items-center'>
            {/* <input type="checkbox" name="" className="mx-2" />
              <select className="mr-3 custom-select form-control w-20">
                <option value="Select actions...">Select actions...</option>
                <option value="Nil">Nil</option>
              </select> */}
            <input
              className='mr-auto form-control w-20'
              type='search'
              name={search}
              placeholder='Search'
              onChange={onSearch}
            />
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-12'>
          {switchProcessedFarmers ? (
            // <ReactTable
            //    getToProps={(state, rowInfo, column, instance) => {
            //   //   return {
            //   //     onClick: (e, handleOriginal) => {
            //   //       goToEop(rowInfo.original.id);
            //   //     }
            //   //   };
            //   // }}
            //   className="-striped -highlight pointer"
            //   defaultPageSize={10}
            //   columns={columns}
            //   data={getFilteredData(
            //     props.switchProcessedFarmers,
            //     search,
            //     "processesFarmerTag"
            //   )}
            //   noDataText={"No record(s) found"}
            // />
            <Table columns={columns} data={data} />
          ) : (
            <div className='d-flex w-25 mx-auto'>
              <img src={Loader} alt='loader' className='' />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    communityResourceName: state.comReducer.communityResourceName,
    isProcessed: state.comReducer.isProcessed,
    switchProcessedFarmers: state.comReducer.switchProcessedFarmers
  }
}

export default withRouter(
  connect(mapStateToProps, {
    handleSwitchProcessedFarmer2,
    getFarmersMembers,
    handleFilterFarmersMembers
  })(ProcessedFarmers)
)
