import React from "react"
import { useState } from "react"
import { useEffect } from "react"
import BaseUrl from "../../../security/base-url"
import { Notify } from "notiflix"
import { Row,Col} from "react-bootstrap"


export default function ManageAccessList()
{


    const [communityResource, setCommunityResource] = useState([]);
    const [accessList, setAccessList] = useState([]);
    const [selectedUser, setSelectedUser] = useState("");
    const [user, setUser] = useState("");
    const [addNew, setAddNew] = useState(false);
    const [selectedItem, setSelectedItem] = useState("");

    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [isUserActive, setIsUserActive] = useState(false);


    useEffect(() => {
        findAllResource();
        console.log(communityResource)
    },[])


    const  findAllResource = () => 
    {
         const headersConfig = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
        };
    fetch(BaseUrl.url_dashbord + '/community-resource/', headersConfig)
      .then(respons => respons.json())
      .then(data => {
        const reverseData = data.reverse();
        setCommunityResource(reverseData)
      })
    }


    const hanldeFetchResourceAccessList = () => {
         const headersConfig = {
            method: 'GET',
            headers: {
                 
            }
        };
        fetch(BaseUrl.url_new + 'cbn_dashboard_rest/communitiesAccessListForUser/' + user,headersConfig)
        .then(respons => respons.json())
        .then(data => {
            const reverseData = data.reverse();
            setAccessList(reverseData)
            if (reverseData.length == 0)
            {
                Notify.info("No record found")
               }
        })
    }
    

    const handleCreateUser = (userObj) => {
         const headersConfig = {
            method: 'POST',
            headers: {
                 "content-type":"application/json"
             },
            body:JSON.stringify(userObj)

         };
        
        fetch(BaseUrl.url_new + 'cbn_dashboard_rest/createuser/' + localStorage.getItem("user"), headersConfig)
        .then(respons => respons.json())
        .then(data => {
           
            if (data.code == 200)
            {
                Notify.success("user successfully created");
                setSelectedUser(data.user)
                setIsUserActive(true);

            } else {
                Notify.failure("Error creating user");

            }
        })
        
    }

    


    return <>
        <h1>Access List</h1>
      
        
        <div style={{width:"600px",margin:"50px"}}>
            <h5>Manage User</h5>
             {!addNew ?
                <button onClick={() => {
                    setAddNew(true)
                }}>Add New</button>
                :
                <button onClick={() => {
                    setAddNew(false)
                }}>Find User</button>}
            <hr width="400px" style={{marginTop:"10px",marginBottom:"10px"}}/>
           
        
            {addNew && 
                <div>
                <form onSubmit={(evt) => {
                    evt.preventDefault();
                    
                   

                    const data = {
                            "username": username,
                            "password":password
                    }

                    handleCreateUser(data);
                    setUsername("");
                    setPassword("");
                }}>
                        <label>username</label>
                    <input  name="username" value={username} onChange={(evt) => { setUsername(evt.target.value)}}/>
                        <label>Password</label>
                        <input type="password" name="password" value={password} onChange={(evt) => { setPassword(evt.target.value)}}/>
                        <button>Create User</button>
                </form>
                
                </div>
            }
            {

                !addNew &&
                <form onSubmit={(evt) => {
                    evt.preventDefault();
                    hanldeFetchResourceAccessList();
                }}>
                     <input type="text" name="username" value={user} onChange={(evt) => {
                        setUser(evt.target.value)
                    }} />
                    <button>find user data</button>
                </form>
                
            }
                 
            <h5>Current User AccessList</h5>
            {accessList.map(item => {
                return <>
                    <Row>
                        <Col>
                            { item.id}
                        </Col>
                        <Col xs="8">
                            { item.name} 
                        </Col>
                        <Col>
                            {item.status}
                        </Col>
                    </Row>
                    
                </>
            })}
        </div>

        
        <div style={{width:"600px",margin:"50px"}}>
            <h5>Manage AccessList</h5>
            <select value={selectedItem} onChange={(evt) => {
                setSelectedItem(evt.target.value);
            }} width="300px">

                <option>choose...</option>
                {communityResource.map(c => {
                    return <option value={ c.id}>{c.name}</option>
                })}
            </select>
              
            <button type="button" onClick={() => {

                if (user == "")
                {
                    Notify.failure("You must enter a valid username");
                    return;
                }
                

                 const headersConfig = {
                    method: 'POST',
                    headers: {
                        
                    }
                };
                fetch(BaseUrl.url_new + 'accesslist/'+localStorage.getItem("user")+"/"+selectedItem+ "/"+ user,headersConfig)
                .then(respons => respons.json())
                .then(data => {
                    
                    if (data.code == -1)
                    {
                        Notify.info("Resouce Already Part of access List")
                    } else if (data.id > 0)
                    {
                        Notify.success("Resource Successfully Added To List")
                        }


                })


                // hanldeFetchResourceAccessList();
                setSelectedItem("");
                
                

            }}>

                Add List
            </button>
        </div>
    
    </>
}