const BaseUrl = {
  // url: 'http://everyfarmer.farm:8080/everyfarmer-dashboard-rest-test/resources/dashboard/login',
  // url_dashbord: 'http://everyfarmer.farm:8080/everyfarmer-dashboard-rest-test/resources/dashboard'
  url: 'https://api.everyfarmer.farm/everyfarmer-dashboard-rest/resources/dashboard/login',
  url_dashbord:
    'https://api.everyfarmer.farm/everyfarmer-dashboard-rest/resources/dashboard',
  url_new:'https://api.everyfarmer.farm/new-rest/'
  // url:
  //   "http://everyfarmer.farm:8080/everyfarmer-dashboard-rest-test/resources/dashboard/login",
  // url_dashbord:
  //   "http://everyfarmer.farm:8080/everyfarmer-dashboard-rest-test/resources/dashboard"
};

export default BaseUrl;
