import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink, withRouter } from 'react-router-dom'
import listview from '../../../assets/list-view.svg'
import Loader from '../../../assets/loader.gif'
import FarmersOnClusters from '../../components/maps/FarmersOnClusters'

class NewCommunitiesMap extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <div className='container-fluid'>
        <div className=' bg-white d-flex justify-content-between m-2 p-2'>
          <div>
            <h4>Resource Coverage area: 100 Km²</h4>
            <small>Coordinates: 53.2734, -7.77832031</small>
          </div>
          <div>
          <NavLink to="/community-resource/communities/" className="mx-2">
              <img src={listview} alt='List View' className='mx-2' />
              List View
            </NavLink>
          </div>
        </div>
        {this.props.allCommunities ? (
          <div>
            <FarmersOnClusters farms={this.props.farmItemsByCommunityResource} />
          </div>
        ) : (
          <div className='d-flex w-25 mx-auto'>
            <img src={Loader} alt='loader' className='' />
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    allCommunities: state.comReducer.allCommunities,
    farmItemsByCommunityResource: state.farmResource.farmItemsByCommunityResource
  }
}

export default withRouter(connect(mapStateToProps)(NewCommunitiesMap))
