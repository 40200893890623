function getFromStorage(key) {
  let value
  try {
    value = localStorage.getItem(key)
  } catch (reason) {
    console.info('local storage get item reason  :', reason)
  }
  return value
}

function setToStorage(key, value) {
  localStorage.setItem(key, value)
}

export default {
  getFromStore: getFromStorage,
  store: setToStorage
}
