import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { appRoutes } from './app.routes';
import './App.scss';
import {
  getActiveResources,
  getUserName,
  getAuthStatus,
  getUserId,
  getUserRole,
  getPasswordReset
} from './app.selectors';
import AppLayout from './components/AppLayout';
import WithSubRoutes from './HOC/WithSubRoutes';
import { getUserLogoSelector } from './pages/login/login.selectors';
import accessStorage from './utils/accessStorage';
// eslint-disable-next-line no-unused-vars
import { unregister } from '../security/fetch-interceptor';

const App = ({
  isAuthenticated,
  logo,
  userId,
  userName,
  userActiveResources,
  userRole,
  isPasswordReset
}) => {
  const urlParams = new URLSearchParams(window.location.search);
  const isSiteAuto = urlParams.get('rand') && urlParams.get('username');
  const accessId = accessStorage.getFromStore('access_id');

  const renderAppRoutes = useMemo(
    () =>
      appRoutes.map((route, idx) =>
        route.path === '/' ? (
          <WithSubRoutes
            {...route}
            key={route.path + idx}
            isAuthenticated={isAuthenticated}
            isSiteAuto={isSiteAuto}
            isPasswordReset={isPasswordReset}
            view={AppLayout}
            accessId={accessId}
            logo={logo}
            userId={userId}
            userActiveResources={userActiveResources}
            userRole={userRole}
            userName={userName}
          />
        ) : (
          <WithSubRoutes key={route.path + idx} {...route} />
        )
      ),
    [accessId, isAuthenticated, isSiteAuto, logo, userActiveResources, userId, userName, userRole]
  );

  return (
    <>
      <Router>
        <div>
          <Switch>{renderAppRoutes}</Switch>
        </div>
      </Router>
    </>
  );
};

export default connect(state => ({
  isAuthenticated: getAuthStatus(state),
  logo: getUserLogoSelector(state),
  userId: getUserId(state),
  userRole: getUserRole(state),
  userName: getUserName(state),
  isPasswordReset: getPasswordReset(state),
  userActiveResources: getActiveResources(state)
}))(App);
