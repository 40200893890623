import React, { Fragment } from 'react';
import { compose, withProps } from 'recompose';

import { withScriptjs, withGoogleMap, GoogleMap, Polygon } from 'react-google-maps';
import { split } from 'lodash';
import { Loading } from 'notiflix';

const deriveDefaultCoords = farms => {
 
  const firstExistingCoords = farms.find(farms => farms.coords.length > 1);
  return firstExistingCoords != undefined? firstExistingCoords.coords[0]:{"lat":"7.408785","lng":"9.081781"};
};

// const deriveDefaultCoords = farms => {
 
// //   const firstExistingCoords = farms.find(farms => farms.coords.length > 1);
    
//     const data = JSON.parse(farms[0].coords);
    
//   return data[0];
// };

const convertStringJSONValuesToNumbers = data => {
  var obj = [];
  data.map(datum => {
    var item = {};
    item['lat'] = parseFloat(datum.lat);
    item['lng'] = parseFloat(datum.lng);
    obj.push(item);
  });
  return obj;
};

const mapClicked = (evt) => {
  
  // var google = window.google;
  // console.log("Map Clicked");
  // var demoPoly = new google.maps.Polygon({
  //       paths: farmer['coords'],
  //       strokeColor: "#0000FF",
  //       strokeOpacity: 0.8,
  //       strokeWeight: 2,
  //       fillColor: "#0000FF",
  //       fillOpacity: 0.25,
  //       zIndex: 0
  //    });
    // console.log( split(demoPoly));
};

const mouseDown = evt => {
  // console.log(evt.latLng.lat());x
  // console.log(evt.latLng.lng())
};
const mouseUp = evt => {
  if (evt.vertex != undefined) {
    // console.log(evt.latLng.lat());
  }
  // console.log(evt);
  // console.log(evt.latLng.lng())
};

// 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDuSYpAv3M_Z8rimKO7rV8-Go6-FKmWhJ4&v=3.exp&libraries=geometry,drawing,places',
// 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAzq9cefHYPN3XW3mlfzQdHHv-4SPpfYXM&v=3.exp&libraries=geometry,drawing,places'

// AIzaSyDpJeMa5YnAyFeUu4rPwPajnRU_0LZbmKs

const FarmersInCommunity = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyCpxNJ4OZjOzEBggaJf_ntGCBO9CO800ko&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <h2 className='h2'>Loading map...</h2>,
    containerElement: <div style={{ height: `500px`, margin: '10px' }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(
  ({
    farms,
    onFarmSelected,
    updateCoords,
    onPolygonSelected,
    onMouseUp,
    indexStart,
    indexEnd,
    mapIdType
  }) => (
    <GoogleMap
      
      onClick={mapClicked}
      defaultZoom={5}
      mapTypeId='hybrid'
      defaultCenter={{
        lat: parseFloat(deriveDefaultCoords(farms).lat),
        lng: parseFloat(deriveDefaultCoords(farms).lng)
      }}
    >
      {farms.map &&
        farms.map((farm, i) => {
          return (
            <Fragment key={farm.farmId}>
              <Polygon
              
                // onClick={evt => onPolygonSelected(evt, farm)}
                onMouseUp={evt => onMouseUp(evt)}
                onMouseDown={evt => mouseDown(evt)}
                //onRightClick={dragEnd}
                //onDblClick={dragEnd}
                editable={true}
                onClick={onFarmSelected(farm, i,split)}
                onRightClick={evt => updateCoords(evt, farm, i)}
                path={convertStringJSONValuesToNumbers(farm.coords)}
                options={{
                  fillColor: farm.status === 'COMPLETED' ? '#00a650' : '#FA8072',
                  fillOpacity: 0.4,
                  strokeColor: farm.status === 'COMPLETED' ? '#0099ff' : '#420D09',
                  strokeOpacity: 1,
                  strokeWeight: 4
                }}
                getPaths={() => {
                  alert('poly');
                }}
              />
            </Fragment>
          );
        })}
    </GoogleMap>
  )
);

export default FarmersInCommunity;
