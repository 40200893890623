import React, { useEffect } from 'react';
import AddEditInput from './AddEditInput';

const CommunitySettings = ({ isMappingEnabled, toggleCommunityResourceMapping }) => {
  // useEffect(() => {
  //   const mappingStatus = document.querySelector('#mappingSetting');
  //   mappingStatus.checked = isMappingEnabled;
  //   return () => (mappingStatus.checked = false);
  // }, [isMappingEnabled]);

  const toggleMapping = () => {
    const mappingStatus = document.querySelector('#mappingSetting');
    mappingStatus.checked = isMappingEnabled;
    return () => (mappingStatus.checked = false);
  };

  const handleToggleMapping = _ => toggleCommunityResourceMapping(!isMappingEnabled);

  return (
    <div>
      {/*<div className='my-3'>
         <h5 className='font-weight-bold'>Profile</h5>
        <hr />
        <div className='mt-3'>
          <div className='d-flex'>
            <div className='mr-auto w-100'>
              <label htmlFor='communityName'>
                Name of community <span className='text-danger'>*</span>
              </label>
              <input
                className='form-control border-0 w-60'
                type='text'
                name='communityName'
                id='communityName'
              />
            </div>
            <button className='btn w-20 btn-revenue text-white align-self-end'>Save changes</button>
          </div>
        </div>
        <div className='mt-3'>
          <span className='switch'>
            <label htmlFor='mappingSetting'>
              Enable Mapping
              <input
                onClick={handleToggleMapping}
                id='mappingSetting'
                name='mapping'
                type='checkbox'
              />
              <span></span>
            </label>
          </span>
        </div>
        {isMappingEnabled && (
          <div className='form-group my-2'>
            <label htmlFor='communityLocation' className='d-block'>
              Location
              <span className='text-danger'>*</span>
            </label>
            <select
              className='form-control mb-2 mr-2 custom-select col-md-4 border-0'
              name='communityLocation'
              id='communityLocation'
            >
              <option value='Country'>Country</option>
            </select>

            <select
              className='form-control mb-2 custom-select col-md-4 border-0'
              name='communityLocation'
              id='communityLocation'
            >
              <option value='State'>State</option>
            </select>
          </div>
        )}
      </div>
      <div className='mt-3'>
        <h5 className='font-weight-bold'>Add-ons</h5>
        <hr />
        <div className='my-2'>
          <p>Communicate with members of your community via</p>
          <div className='d-flex w-40 justify-content-between'>
            <span>
              <input
                className='mr-2 border-0'
                type='radio'
                name='communicationMethod'
                id='SMS Only'
                value='SMS Only'
              />
              <label htmlFor='SMS Only'>SMS Only</label>
            </span>
            <span>
              <input
                className='mr-2 border-0'
                type='radio'
                name='communicationMethod'
                id='Email Only'
                value='Email Only'
              />
              <label htmlFor='Email Only'>Email Only</label>
            </span>
            <span>
              <input
                className='mr-2 border-0'
                type='radio'
                name='communicationMethod'
                id='Both SMS & Email'
                value='Both SMS & Email'
              />
              <label htmlFor='Both SMS & Email'>Both SMS & Email</label>
            </span>
          </div>
        </div>
      </div> */}
      <h5 className='font-weight-bold'>Economics Of Production</h5>
      <hr />
      <AddEditInput />
    </div>
  );
};

export default CommunitySettings;
