import React, { Component } from 'react'
import 'chart.js'
import { ColumnChart } from 'react-chartkick'
import { Link } from 'react-router-dom'
import droplet from '../../../assets/water-droplet.svg'

export default class HoseIntakeAnalysis extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data2: [
        { name: '0', data: { Bagudu: 16, 'K/Besse': 12, Sakaba: 10 } },
        {
          name: '5',
          data: { Bagudu: 5, 'K/Besse': 3, Sakaba: 7 }
        },
        {
          name: '10',
          data: { Bagudu: 3, 'K/Besse': 2, Sakaba: 4 }
        }
      ]
    }
  }
  render() {
    return (
      <div className='container-fluid bg-light p-3'>
        <div className='d-flex justify-content-between align-items-center'>
          <h3 className='text-black-50 h4'>Hose Intake Analysis</h3>
          <div className='border rounded text-center py-2 px-3 d-flex flex-sm-column flex-md-row ml-md-auto mr-2'>
            <img src={droplet} alt='farmer' className='mr-2' />

            <div>
              <h4 className='text-dark font-weight-light'>Total Hose Discharge (m)</h4>
              <p className='font-weight-bold font-fix mb-0'>
                {this.props.analytics.hoseIntake ? this.props.analytics.hoseIntake : 0}
              </p>
            </div>
          </div>
          <div className='border rounded text-center py-2 px-3 d-flex mr-2 bg-revenue text-white'>
            <img src={droplet} alt='farmer' className='mr-2' />

            <div>
              <h4 className='text-white font-weight-light'>Sum Of Hose Intake</h4>
              <p className='font-weight-bold font-fix mb-0'>
                {this.props.analytics.hoseIntake ? this.props.analytics.hoseIntake : 0}
              </p>
            </div>
          </div>
        </div>
        <div className='row mt-5'>
          <div className='col-md-6'>
            <ColumnChart
              data={this.state.data2}
              dataset={[
                { backgroundColor: '#96c63d' },
                { backgroundColor: '#0085FF' },
                { backgroundColor: '#c4c4c4' }
              ]}
              colors={['#96c63d', '#0085FF', '#c4c4c4']}
              height={'80%'}
              xtitle='LGA'
              ytitle='Amount Of Fertilizer Used'
            />
            <h6 className='bg-white ml-5 p-2 my-4 text-center'>Fertilizer Consumption Per LGA</h6>
          </div>
        </div>
        <div className='greener text-right font-18 underline'>
          <Link to='#'>...View more</Link>
        </div>
      </div>
    )
  }
}
