import React, { Component } from 'react'
import { Form, InputGroup } from 'react-html5-form'
import swal from 'sweetalert2'
import BaseUrl from '../../../security/base-url'

export default class ViewAssets extends Component {
  state = {
    id: '',
    name: '',
    quantity: '',
    dateAdded: '',
    assets: []
  }

  /**
   * Fetch data from the api
   */
  componentDidMount() {
    this.getAssets()
  }

  displayError = error => {
    return (
      error && (
        <div className='col-md-12'>
          <p style={this.errorstyles}>{error}</p>
        </div>
      )
    )
  }

  /***
   * Get the asset id
   */
  openDeleteAssetModal(id) {
    console.log(id)
    this.setState({
      id: id
    })
  }

  /**
   * Get the asset info
   */
  openEditAssetModal(id, name, quantity, dateAdded) {
    console.log(id)
    this.setState({
      id: id,
      name: name,
      quantity: quantity,
      dateAdded: dateAdded
    })
  }

  /**
   * Update an asset
   */
  updateAsset = data => {
    let asset = {
      id: this.state.id,
      name: this.state.name,
      quantity: this.state.quantity
    }

    fetch(BaseUrl.url_dashbord + '/assets', {
      method: 'PUT',
      body: JSON.stringify(asset),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then(res => res.json())
      .then(res => {
        console.log(res)
        if (res.code === 0) {
          swal('Asset updated successfully')
          this.getAssets()
          document.getElementById('editAssetModal').click()
        } else {
          swal(res.message)
        }

        this.setState({
          id: '',
          name: '',
          quantity: ''
        })
      })
      .catch(err => console.log(err))
  }

  // Update the value of the corresponding state field
  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  closePopUp() {
    document.getElementById('addAssets').reset()
    document.getElementById('addAssetModal').click()
  }

  /**
   * Add an asset to a farm
   */
  saveAsset = data => {
    let asset = {
      name: this.state.name,
      quantity: this.state.quantity,

      farmId: {
        id: this.props.farmId
      }
    }

    fetch(BaseUrl.url_dashbord + '/assets', {
      method: 'POST',
      body: JSON.stringify(asset),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then(res => res.json())
      .then(res => {
        console.log(res)
        if (res.code === 0) {
          swal('Asset added successfully')
          this.getAssets()
          this.closePopUp()
        } else {
          swal('Failed to add Asset ' + res.message)
        }

        this.setState({
          id: '',
          name: '',
          quantity: ''
        })
      })
      .catch(err => console.log(err))
  }

  /**
   *
   *  Delete an asset from the api
   */
  deleteAsset = data => {
    data.preventDefault()
    fetch(BaseUrl.url_dashbord + '/assets/' + this.state.id, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then(res => res.json())
      .then(res => {
        console.log(res)
        if (res.code === 0) {
          swal('Asset deleted successfully')
          this.getAssets()
        } else {
          swal('Failed to delete Asset ' + res.message)
        }
      })
      .catch(err => console.log(err))
  }

  /**
   *
   * Fetch all the farm types from the api
   */
  getAssets() {
    fetch(BaseUrl.url_dashbord + '/assets/farm/' + this.props.farmId, {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    })
      .then(response => response.json())
      .then(assets => {
        this.setState({
          assets: assets
        })
        console.log(assets)
      })
  }

  render() {
    return (
      <div className='container-fluid'>
        <h3 className=''>Farm Assets</h3>
        <div className='col'>
          <input
            type='button'
            className='btn btn-success btn-sm my-3'
            data-toggle='modal'
            data-target='#addAssetModal'
            value='Add New Farm Asset'
          />
        </div>

        <div className='row text-center p-2'>
          <div className='col-lg-12 col-md-12'>
            <table className='table table-sm'>
              <thead className='thead-dark bg-dark text-white'>
                <tr>
                  <th>Name</th>
                  <th>Edit</th>
                  <th>Delete</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody>
                {this.state.assets ? (
                  this.state.assets.map(asset => {
                    return (
                      <tr>
                        <td hidden>{asset.id}</td>
                        <td>{asset.name}</td>
                        {/* <td>{asset.quantity}</td>
                        <td>{asset.dateAdded}</td> */}
                        {/* Open Edit Asset Modal  */}
                        <td>
                          <i
                            class='fas fa fa-edit'
                            styleClass='clickable-row'
                            onClick={() =>
                              this.openEditAssetModal(asset.id, asset.name, asset.quantity)
                            }
                            data-toggle='modal'
                            data-target='#editAssetModal'
                          />
                        </td>

                        {/* Delete Asset Modal  */}
                        <td>
                          <i
                            class='fas fa fa-trash'
                            styleClass='clickable-row'
                            onClick={() => this.openDeleteAssetModal(asset.id)}
                            data-toggle='modal'
                            data-target='#deleteAssetModal'
                          />
                        </td>

                        {/* View Asset Details Modal  */}
                        <td>
                          <i
                            class='fas fa fa-eye'
                            styleClass='clickable-row'
                            onClick={() =>
                              this.openEditAssetModal(
                                asset.id,
                                asset.name,
                                asset.quantity,
                                asset.dateAdded
                              )
                            }
                            data-toggle='modal'
                            data-target='#assetDetailsModal'
                          />
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td>Loading data...</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* View Assets Modal */}
          <div
            className='modal'
            id='assetDetailsModal'
            tabIndex={-1}
            role='dialog'
            aria-hidden='true'
          >
            <div className='modal-dialog' role='document'>
              <div className='modal-content bg-dark text-white'>
                <div className='modal-header bg-dark text-white'>
                  <h5>Asset Details</h5>
                  <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                    <span aria-hidden='true'>×</span>
                  </button>
                </div>
                <div className='modal-body'>
                  <div className='col-lg-12 col-md-12'>
                    <table className='table table-sm'>
                      <thead className='thead-dark bg-dark text-white'>
                        <tr>
                          <th>Name</th>
                          <th>Quantity</th>
                          <th>Date Added</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{this.state.name}</td>
                          <td>{this.state.quantity}</td>
                          <td>{this.state.dateAdded}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className='modal-footer'>
                  <button type='submit' className='btn btn-sm btn-revenue text-white'>
                    Save
                  </button>

                  <button
                    type='button'
                    className='btn btn-sm btn-expend text-white'
                    data-dismiss='modal'
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Add Assets Modal */}
          <div className='col-md-12'>
            <Form id='addAssets' onSubmit={e => this.saveAsset(e)}>
              {({ error }) => (
                <>
                  <div
                    className='modal'
                    id='addAssetModal'
                    tabIndex={-1}
                    role='dialog'
                    aria-hidden='true'
                  >
                    <div className='modal-dialog' role='document'>
                      <div className='modal-content bg-dark text-white'>
                        <div className='modal-header bg-dark text-white'>
                          <h5>Add Farm Asset</h5>
                          <button
                            type='button'
                            className='close'
                            data-dismiss='modal'
                            aria-label='Close'
                          >
                            <span aria-hidden='true'>×</span>
                          </button>
                        </div>
                        <div className='modal-body'>
                          <div className='form-row'>
                            <div className='form-group col-6'>
                              <InputGroup validate={['name']}>
                                {({ error }) => (
                                  <div>
                                    <input
                                      required
                                      className='form-control'
                                      placeholder='Name'
                                      name='name'
                                      value={this.state.name}
                                      onChange={e => this.onChange(e)}
                                    />
                                    {this.displayError(error)}
                                  </div>
                                )}
                              </InputGroup>
                            </div>
                            <div className='form-group col-6'>
                              <InputGroup validate={['quantity']}>
                                {({ error }) => (
                                  <div>
                                    <input
                                      required
                                      type='number'
                                      className='form-control'
                                      placeholder='Quantity'
                                      name='quantity'
                                      value={this.state.quantity}
                                      onChange={e => this.onChange(e)}
                                    />
                                    {this.displayError(error)}
                                  </div>
                                )}
                              </InputGroup>
                            </div>
                          </div>
                        </div>

                        <div className='modal-footer'>
                          <button type='submit' className='btn btn-sm btn-revenue text-white'>
                            Save
                          </button>

                          <button
                            type='button'
                            className='btn btn-sm btn-expend text-white'
                            data-dismiss='modal'
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </Form>
          </div>
          {/* Add Asset modal ends here */}

          {/* Delete Asset Modal */}
          <div
            className='modal'
            id='deleteAssetModal'
            tabIndex={-1}
            role='dialog'
            aria-hidden='true'
          >
            <div className='modal-dialog modal-dialog-centered modal-sm' role='document'>
              <div className='modal-content bg-dark text-white'>
                <div className='modal-header'>
                  <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                    <span aria-hidden='true'>×</span>
                  </button>
                </div>
                <div className='modal-body'>
                  <p>Are You Sure?</p>
                </div>

                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-sm btn-revenue text-white'
                    data-dismiss='modal'
                    onClick={e => this.deleteAsset(e)}
                  >
                    Yes
                  </button>

                  <button
                    type='button'
                    className='btn btn-sm btn-expend text-white'
                    data-dismiss='modal'
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* Delete asset modal ends here */}

          {/* Edit Asset Modal */}
          <div className='col-md-12'>
            <Form id='editAsset' onSubmit={e => this.updateAsset(e)}>
              {({ error }) => (
                <>
                  <div
                    className='modal'
                    id='editAssetModal'
                    tabIndex={-1}
                    role='dialog'
                    aria-hidden='true'
                  >
                    <div className='modal-dialog' role='document'>
                      <div className='modal-content bg-dark text-white'>
                        <div className='modal-header'>
                          <h5>Edit Asset</h5>
                          <button
                            type='button'
                            className='close'
                            data-dismiss='modal'
                            aria-label='Close'
                          >
                            <span aria-hidden='true'>×</span>
                          </button>
                        </div>
                        <div className='modal-body'>
                          <div className='form-row'>
                            <div className='form-group col-6'>
                              <InputGroup validate={['name']}>
                                {({ error }) => (
                                  <div>
                                    <input
                                      required
                                      className='form-control'
                                      placeholder='Name'
                                      name='name'
                                      value={this.state.name}
                                      onChange={e => this.onChange(e)}
                                    />
                                    {this.displayError(error)}
                                  </div>
                                )}
                              </InputGroup>
                            </div>

                            <div className='form-group col-6'>
                              <InputGroup validate={['quantity']}>
                                {({ error }) => (
                                  <div>
                                    <input
                                      required
                                      className='form-control'
                                      placeholder='Quantity'
                                      name='quantity'
                                      value={this.state.quantity}
                                      onChange={e => this.onChange(e)}
                                    />
                                    {this.displayError(error)}
                                  </div>
                                )}
                              </InputGroup>
                            </div>
                          </div>

                          <div className='modal-footer'>
                            <button
                              type='submit'
                              className='btn btn-sm btn-revenue text-white'
                              // data-dismiss="modal"
                              // onClick={e => this.updateAsset(e)}
                            >
                              Update
                            </button>

                            <button
                              type='button'
                              className='btn btn-sm btn-expend text-white'
                              data-dismiss='modal'
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Update Asset modal ends here */}
                  </div>
                </>
              )}
            </Form>
          </div>
        </div>
      </div>
    )
  }
}
