import React, { Component } from 'react'
import moreIcon from '../../../assets/3Dots-white.svg'
import complainimage from '../../../assets/complain-image.svg'
import Navbar from '../../components/Navbar'

export default class IssuesInfo extends Component {
  render() {
    return (
      <div className='container-fluid'>
        <Navbar
          navLinks={[
            {
              path: '/resources/extension',
              header: 'Extension |'
            },
            {
              path: '#',
              header: '| Issue Subject Matter'
            }
          ]}
          small='Review logged issues from the armer and response'
        />
        {/* Left Side Section*/}
        <div className='row flex-column my-3 bg-white p-3'>
          <h4>Detailed Information of Issue posted by client</h4>
          <h6>Client summary on the left. Responses from the agent can be on the right</h6>

          <div className='row justify-content-around'>
            <div className='col-lg-6 col-md-6 bg-light p-5 text-left'>
              {/* Profile Section */}
              <div className='row justify-content-between'>
                <div className='border-bottom'>
                  <h4 className='font-weight-bold my-3 text-dark h5'>Issuer</h4>
                  <p className='mb-2'>Rice Decoloration to something else</p>
                </div>
                <div className='dropdown form-align'>
                  <div className='dropdown dropleft'>
                    <div
                      className='menuIconWrapper d-flex justify-content-center align-content-center mousePointer'
                      id='dropdownMenuButton'
                      data-toggle='dropdown'
                      aria-haspopup='true'
                      aria-expanded='false'
                    >
                      <img src={moreIcon} className='cursor' alt='More Icon' />
                    </div>
                    <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                      <button className='dropdown-item'>Mark As Important</button>
                      <button className='dropdown-item border-bottom'>Archive</button>
                      <button className='dropdown-item'>Delete</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row flex-column'>
                <div className='border-bottom'>
                  <h4 className='font-weight-bold my-3 text-dark h5'>Submitted By</h4>
                  <p className='mb-2'>*****</p>
                </div>
                <div className='border-bottom'>
                  <h4 className='font-weight-bold my-3 text-dark h5'>Contact Information</h4>
                  <p className='mb-2'>extension@everyfarmer.farm</p>
                </div>
              </div>

              {/* Details Section with Recent Activity */}
              <div className='row flex-column border-bottom'>
                <h4 className='font-weight-bold my-3 text-dark h5'>
                  {/* Job Duties and Responsibilities */}
                  First Notice Period
                </h4>
                <p className='mb-2'>13/3/2019</p>
              </div>
              <div className='row flex-column border-bottom'>
                <h4 className='font-weight-bold my-3 text-dark h5'>
                  {/* Job Duties and Responsibilities */}
                  Succession
                </h4>
                <p className='mb-2'>### ###</p>
              </div>
              <div className='row flex-column py-2 border-bottom'>
                <h4 className='font-weight-bold my-3 text-dark h5'>Average Numbers Affected</h4>
                <h6 className='mb-2'>####</h6>
              </div>
              <div className='row flex-column py-2 border-bottom'>
                <h4 className='font-weight-bold my-3 text-dark h5'>Period of Incident</h4>
                <h6 className='mb-2 mr-1'>2 weeks</h6>
              </div>
              <div className='row flex-column py-2 border-bottom'>
                <h4 className='font-weight-bold my-3 text-dark h5'>Proposed day of Visit</h4>
                <h6 className='mb-2'>14/4/2019</h6>
              </div>
              <div className='row flex-column py-2 border-bottom'>
                <h4 className='font-weight-bold my-3 text-dark h5'>Description</h4>
                <p className='mb-2'>******</p>
              </div>
              <div className='row flex-column py-2 border-bottom'>
                <h4 className='font-weight-bold my-3 text-dark h5'>Uploaded Media</h4>
                <div className='row bg-white p-2'>
                  <img src={complainimage} alt='complaint' />
                  <img src={complainimage} alt='complaint' className='mx-2' />
                </div>
              </div>
              <div className='row flex-column py-2 border-bottom'>
                <h4 className='font-weight-bold my-3 text-dark h5'>Further Information</h4>
                <p className='mb-2'>******</p>
              </div>
            </div>
            {/* Left Side Section Ends */}

            {/* Right Side */}
            <div className='col-lg-5 col-md-5 bg-light p-4'>
              <h4 className='font-weight-bold my-3 text-dark h5'>Set Visit Schedule</h4>
              <form>
                <div className='row'>
                  <div className='col'>
                    <div className='form-group'>
                      <label for='exampleFormControlSelect1'>Set Visiting Date for</label>
                      <input
                        type='date'
                        className='form-control'
                        id='validationTooltip01'
                        placeholder='Enter Amount'
                        defaultValue='Mark'
                        required
                      />
                    </div>
                  </div>
                  <div className='col'>
                    <div className='form-group'>
                      <label for='exampleFormControlSelect1'>Period of Visits</label>
                      <select className='form-control' id='exampleFormControlSelect1'>
                        <option>1 week</option>
                        <option>2 weeks</option>
                        <option>1 month</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className='form-row'>
                  <div className='col-md-8 mb-3'>
                    <label htmlFor='validationTooltip01'>Cost (&#8358;)</label>
                    <input
                      type='number'
                      className='form-control'
                      id='validationTooltip01'
                      placeholder='Enter Amount'
                      defaultValue='Mark'
                      required
                    />
                  </div>
                </div>
                <div className='form-row'>
                  <div className='col-md-12 mb-3'>
                    <label for='exampleFormControlTextarea1'>Further information</label>
                    <textarea className='form-control' id='exampleFormControlTextarea1' rows='3' />
                  </div>
                </div>
                <div className='form-row'>
                  <div className='col-md-12 mb-3'>
                    <label htmlFor='validationTooltip01'>Contact Info</label>
                    <input
                      type='text'
                      className='form-control'
                      id='validationTooltip01'
                      placeholder='Enter Email Address'
                      defaultValue='Enter Email Address'
                      required
                    />
                  </div>
                </div>
                <div className='form-row'>
                  <div className='col-md-12 mb-3'>
                    <label htmlFor='validationTooltip01'> Set Reminder</label>
                    <input
                      type='date'
                      className='form-control'
                      id='validationTooltip01'
                      placeholder='Enter Amount'
                      defaultValue='Mark'
                      required
                    />
                  </div>
                </div>
                <div className='form-row justify-content-center'>
                  <button className='btn btn-secondary'>Cancel</button>
                  <button type='submit' className='btn btn-revenue mx-4'>
                    Submit
                  </button>
                </div>
              </form>
            </div>

            {/* Right Side Ends*/}
          </div>
        </div>
        {/* Modal */}

        {/* preview  modal ends here */}
      </div>
    )
  }
}
