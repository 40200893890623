import { actions, ALERT_FAILED, ALERT_SUCCESS, CLEAR_ALERT } from './api.constant'

/**
 * Creates an action that sends requesBe(requestTrash));t. It's prefixed `REQUEST`
 * @param {*} DATATYPE - The resources to fetch
 * @param {*} payload
 *
 *  #### Usage
 *  fetchData('TRASH')
 * @returns:  {type: REQUEST_TRASH, payload: any}
 */

export const fetchData = (DATATYPE, payload) => {
  return({
  type: actions[`REQUEST_${DATATYPE}`],
  payload
})
}

/**
 * Creates an action that tells if request fails
 * @param {*} DATATYPE - The prefixed dataype for data fetching
 * @param {*} payload - The server response contains the error message and status code
 * */
export const fetchFailed = (DATATYPE, error) => ({
  type: actions[`REQUEST_${DATATYPE}_FAILED`],
  payload: { error }
})

/**
 * Creates an action that tells if request succeeded
 * @param {*} DATATYPE - The prefixed dataype for data fetching
 * @param {*} payload - The server response contains the information
 * */
export const fetchSuccess = (DATATYPE, payload) => ({
  type: actions[`REQUEST_${DATATYPE}_SUCCESS`],
  payload
})

/**
 * Creates an action that sends request failed alert
 * @param {*} payload - The server response contains the information
 * */
export const alertFailed = payload => ({ type: ALERT_FAILED, payload })

/**
 * Creates an action that sends request succeeded alert
 * @param {*} payload - The server response contains the information
 * */
export const alertSuccess = payload => ({ type: ALERT_SUCCESS, payload })
/**
 * Creates an action that sends clear failed alert message
 * */
export const clearAlertMessage = () => ({ type: CLEAR_ALERT })
