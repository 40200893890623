import React from 'react'
import ResourceItem from './ResourceItem'

const ResourceList = props => {
  return (
    <ul className='list-group'>
      {props.resourceList.map(resource => {
        return (
          <li key={resource.id} className='list-group-item d-flex flex-row p-2 bg-list-outer'>
            <ResourceItem data={resource} {...this.props} />
          </li>
        )
      })}
    </ul>
  )
}

export default ResourceList
