import React from 'react'
import FarmersAnalysis from './farmers-analysis'
import FarmSizeAnalysis from './farmsize-analysis'

const FarmAnalysis = ({ analytics }) => (
  <>
    <FarmersAnalysis analytics={analytics} />
    <FarmSizeAnalysis analytics={analytics} />
  </>
)

export default FarmAnalysis
