import { createSelector } from 'reselect'

export const getSuccessMsg = createSelector(
  ({ alerts }) => {
    return alerts.successMsg
  },
  successMsg => successMsg
)

export const getErrorMsg = createSelector(
  ({ alerts }) => alerts.errorMsg,
  errorMsg => errorMsg
)
