import React, { Component } from 'react'
export default class AddUpdateFarmItem extends Component {
  state = {
    name: '',
    description: ''
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  render() {
    return (
      <>
        {/* Add farm Modal */}
        <div
          className='modal fade'
          id='addResourceModal'
          data-backdrop='static'
          data-keyboard='false'
          tabIndex={-1}
          role='dialog'
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
        >
          <div className='modal-dialog modal-dialog-centered' role='document'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h4>{this.props.data ? 'Edit' : 'Add'} Farm</h4>
                <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-body'>
                <form
                  id='serviceProviderForm'
                  onSubmit={() => {
                    this.props.isEdit
                      ? this.props.onUpdate()
                      : this.props.onCreate({
                          name: this.state.name,
                          descripiton: this.props.descripiton
                        })
                  }}
                >
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter Farm Name'
                    name='name'
                    onChange={this.onChange}
                    required
                    value={this.state.name}
                  />
                  <textarea
                    className='form-control'
                    rows='2'
                    required
                    onChange={this.onChange}
                    placeholder='Enter Description'
                    name='description'
                    value={this.state.description}
                  />
                </form>
              </div>
              <div className='modal-footer'>
                <button type='button' className='btn btn-expend text-white' data-dismiss='modal'>
                  Close
                </button>
                <button type='button' className='btn btn-revenue text-white'>
                  Save Changes
                </button>
              </div>
            </div>
          </div>
          {/* Add farm modal ends here */}
        </div>
      </>
    )
  }
}
