import React, { Component } from 'react'
import Navbar from '../../components/Navbar'

class AddNewAsset extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <div>
        <Navbar
          navLinks={[
            {
              path: '/resources/farm/',
              header: 'Farm Resources |'
            },
            {
              path: '/new-farm-resource/new-assets/',
              header: '| Assets'
            }
          ]}
          small='Add New Asset to your Farm'
        />

        <div className='d-flex flex-column align-items-center my-3'>
          <h4>
            Boxes with ‘ <span className='red'>*</span> ’ must be filled for form to submit
          </h4>
          <p>Progress is automatically saved</p>
        </div>
        <div className='d-flex justify-content-center bg-light'>
          <form className='w-40 my-4'>
            <div className='form-group'>
              <label htmlFor='exampleInputEmail1'>
                Asset Name <span className='red'>*</span>
              </label>
              <input
                type='text'
                className='form-control'
                id='assetname'
                aria-describedby='emailHelp'
                placeholder='Enter name of asset'
                required
              />
            </div>
            <div className='form-group'>
              <label htmlFor='exampleInputEmail1'>
                Asset Type <span className='red'>*</span>
              </label>
              <input
                type='text'
                className='form-control'
                id='assettype'
                aria-describedby='emailHelp'
                placeholder='Enter Asset Type'
              />
            </div>
            <div className='form-group'>
              <label htmlFor='exampleInputPassword1'>Size (ft)</label>
              <input type='text' id='numbersOnly' pattern='[0-9.]+' className='form-control' />
            </div>
            <div className='form-group'>
              <label htmlFor='exampleInputPassword1'>Brief Description of Asset</label>
              <textarea id='assetdescription' className='form-control' rows='2' />
            </div>
            <div className='form-group text-center'>
              <button type='submit' className='btn btn-dark text-white'>
                Save and Set Position
              </button>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default AddNewAsset
