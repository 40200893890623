import React, { Component } from 'react'
import BaseUrl from '../../../security/base-url'

class Farmers extends Component {
  constructor() {
    super()
    this.state = {
      data: null
    }
    this.getData()
  }

  handleSelectFarmer = id => {
    // this.props.history.push("/resources");
    this.props.history.push('/view-profile/' + id)
  }

  getData() {
    fetch(BaseUrl.url_dashbord + '/farmers', {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    })
      .then(resp => resp.json())
      .then(res => {
        this.setState({ data: res })
      })
  }

  render() {
    return (
      <div className='my-3'>
        <div className='d-flex justify-content-end my-1'>
          <button className=' btn btn-sm btn-revenue px-4 text-white'>Upload Details</button>
        </div>
        {/* Table Start Here           */}
        <div className='container'>
          <div className='d-flex justify-content-end my-1'>
            <button className='btn btn-sm btn-revenue text-white px-4'>Upload Details</button>
          </div>
          {/* Table Start Here   */}

          <div className='col-md-12 order-md-1'>
            <table className='table table-sm my-3'>
              <thead className='thead-dark bg-dark text-white'>
                <tr>
                  <th />
                  <th>Name</th>
                  <th>Gender</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th>Contact Info.</th>
                  <th>Resources</th>
                </tr>
              </thead>
              <tbody>
                {this.state.data ? (
                  this.state.data.map(farmer => {
                    return (
                      <tr
                        styleClass='clickable-row'
                        onClick={() => this.handleSelectFarmer(farmer.id)}
                      >
                        <td>
                          <img
                            src={farmer.displayImage}
                            width='40px'
                            height='40px'
                            style={{ margin: 0 }}
                            alt={farmer.displayImage}
                            className='rounded-circle'
                          />
                        </td>

                        <td>{farmer.surname + ' ' + farmer.firstName}</td>
                        <td>{farmer.gender}</td>
                        <td>{farmer.phone}</td>
                        <td>{farmer.email}</td>
                        <td>{farmer.address}</td>
                        {/* view assets */}
                        <td>
                          <i
                            class='fas fa-2x fa-eye'
                            styleClass='clickable-row'
                            onClick={() => this.handleSelectFarmer(farmer.id)}
                          />
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td>
                      <div className='spinner-grow text-success' role='status'>
                        <span className='sr-only'>Loading...</span>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {/* Table Ends Here*/}
        </div>
      </div>
    )
  }
}
export default Farmers
