import { createSelector } from 'reselect';

export const getIsAuthenticated = state => state.auth.isAuthenticated;
export const getValidityPeriod = state => state.auth.validity;

export const getAuthStatus = createSelector([getIsAuthenticated], authStatus => authStatus);

export const getUserId = createSelector(
  state => state.auth.userId,
  userId => userId
);
export const getUserRole = createSelector(
  state => state.auth.userRole,
  userRole => userRole
);
export const getUserName = createSelector(
  state => state.auth.userName,
  userName => userName
);
export const getLinks = createSelector(
  state => state.auth.links,
  serverLinks => serverLinks
);
export const getPasswordReset = createSelector(
  state => state.auth.isPasswordReset,
  isPasswordReset => isPasswordReset
);

export const getActiveResources = createSelector(
  state => state.auth.userActiveResources,
  activeResources => activeResources
);
