import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

import Navbar from '../../components/Navbar'
import listview from '../../../assets/list-view.svg'
// import Map from "../../maps/map.js";

export default class FarmsMap extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <div className='container-fluid'>
        <Navbar
          navLinks={[
            {
              path: '/new-view-farms',
              header: ' View Farms |'
            },
            {
              path: '#',
              header: '| Farms Map View'
            }
          ]}
          small='Ariel map view of the farms in this farm resource'
        />
        <div className='d-flex justify-content-between m-2'>
          <div>
            <h4>Resource Coverage area: 100 Km²</h4>
            <small>Coordinates: 53.2734, -7.77832031</small>
          </div>
          <div>
            <NavLink to='/community-resource/communities' className='mx-2'>
              <img src={listview} alt='List View' className='mx-2' />
              List View
            </NavLink>
          </div>
        </div>
        {/* <Map /> Map Renderes here */}
      </div>
    )
  }
}
