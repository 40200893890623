import PropTypes from 'prop-types';
import React from 'react';
import loader from '../../assets/loader.gif';

/**
 * Displays the loader when data is loading
 * @param {*} props (object) - {data, isLoading, icon?, message?}
 *
 */
const Spinner = props => {
  const { data = [], isLoading, message = 'No data available.', size = 'xLarge' } = props;

  const sizes = {
    small: '25%',
    medium: '50%',
    large: '75%',
    xLarge: '100%'
  };

  if (Object.entries(data).length === 0 && isLoading) {
    return (
      <div className='d-flex justify-content-center w-20 mx-auto' style={{ height: '73vh' }}>
        <span className='my-auto'>
          <img src={loader} alt='loader' width={sizes[size]} />
          <h2>Loading...</h2>
        </span>
      </div>
    );
  } else if (Object.entries(data).length === 0 && !isLoading) {
    return (
      <div
        className='d-flex flex-column mx-auto justify-content-center align-items-center'
        style={{ height: '73vh' }}
      >
        <i className='fas fa-folder-open fa-7x text-muted'></i>
        <h2 className='h2 font-weight-normal w-75 text-center'>{message}</h2>
      </div>
    );
  } else {
    return <>{props.children} </>;
  }
};

Spinner.propTypes = {
  data: PropTypes.array.isRequired || PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  message: PropTypes.string,
  size: PropTypes.string
};

export default Spinner;
