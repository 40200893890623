import React, { Component } from 'react'
import AddPlus from '../../../../../assets/Add-Plus.svg'
import Minus from '../../../../../assets/Minus-Remove.svg'
import '../farmresource/FarmResourceSubItems.css'

class AssetItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      toggleState: this.props.isActivated
    }
  }

  componentDidMount() {
    this.handleState()
  }

  handleState = () => {
    // console.log("Hello World")
    if (this.state.toggleState === true) {
      document
        .getElementById(`AssetItemAddButton${this.props.detailsOfAssets}`)
        .classList.add('d-none')
      document
        .getElementById(`AssetItemRemoveButton${this.props.detailsOfAssets}`)
        .classList.remove('d-none')
      document
        .getElementById(`AssetItemWrapperButton${this.props.detailsOfAssets}`)
        .classList.remove('inActiveResource')
      this.setState({ toggleState: !this.state.toggleState })
    } else {
      document
        .getElementById(`AssetItemRemoveButton${this.props.detailsOfAssets}`)
        .classList.add('d-none')
      document
        .getElementById(`AssetItemAddButton${this.props.detailsOfAssets}`)
        .classList.remove('d-none')
      document
        .getElementById(`AssetItemWrapperButton${this.props.detailsOfAssets}`)
        .classList.add('inActiveResource')
      this.setState({ toggleState: !this.state.toggleState })
    }
  }

  handleClick = () => {
    this.setState({ toggleState: this.props.onToggle(this.state.toggleState) })
    this.handleState()
  }

  render() {
    return (
      <React.Fragment>
        <button
          type='button'
          className='btn text-secondary font-weight-light bg-white border-radius btn-sm mousePointer d-inline py-2 pl-4 mx-2'
          id={'AssetItemWrapperButton' + this.props.detailsOfAssets}
          onClick={this.handleClick}
        >
          Assets
          <img
            src={AddPlus}
            className='ml-2'
            id={'AssetItemAddButton' + this.props.detailsOfAssets}
            alt='Add Icon'
          />
          <img
            src={Minus}
            className='ml-2'
            id={'AssetItemRemoveButton' + this.props.detailsOfAssets}
            alt='Remove Icon'
          />
        </button>
      </React.Fragment>
    )
  }
}

export default AssetItem
