import { call, put, takeEvery } from 'redux-saga/effects'
import { fetchFailed, fetchSuccess } from '../../api/api.actions'
import { actions } from '../../api/api.constant'
import apiService from '../../api/apiService'

// watchers
export function* watchDashboardRequest() {
  yield takeEvery(actions.REQUEST_DASHBOARD_DATA, fetchDashboardData)
}

// workers
function* fetchDashboardData() {
  try {
    const payload = yield call(apiService.get, '/trash')
    if (payload.code === undefined) {
      yield put(fetchSuccess('DASHBOARD_DATA', payload))
    } else {
      throw new Error(payload.message)
    }
  } catch (error) {
    yield put(fetchFailed('TRASH', error))
  }
}

export default {
  watchDashboardRequest
}
