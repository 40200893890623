import PropTypes from 'prop-types'
import React from 'react'
import { NavLink, Route, Switch } from 'react-router-dom'
import community from '../../../assets/community.svg'
//import extension from '../../../assets/extension.svg'
import farms from '../../../assets/farmers.svg'
import providers from '../../../assets/providers.svg'
import resource from '../../../assets/resources.svg'
//import warehouse from '../../../assets/warehouse.svg'
import Footer from '../../components/Footer'
import WithSubRoutes from '../../HOC/WithSubRoutes'

const Resources = ({ match, routes }) => {
  return (
    <React.Fragment>
      <div className='container-fluid'>
        <div className='row justify-content-between'>
          <div className='inner-sidebar'>
            <div className='h6 text-white m-3 inner-sidebar__header'>Resources</div>
            <div className='border-bottom my-3' />
            <ul className='list-unstyled inner-sidebar__nav'>
              <li className='nav-item'>
                <NavLink
                  to={`${match.url}/farm`}
                  className='nav-link text-white'
                  activeClassName='resource'
                >
                  <img src={farms} alt='farms' className='d-inline mr-2' />
                  Farm
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink
                  to={`${match.url}/serviceprovider`}
                  className='nav-link text-white'
                  activeClassName='resource'
                >
                  <img src={providers} width='17%' alt='providers' className='d-inline mr-2' />
                  Service Provider
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink
                  to={`${match.url}/community`}
                  className='nav-link text-white'
                  activeClassName='resource'
                >
                  <img src={community} width='17%' alt='community' className='d-inline mr-2' />
                  Community
                </NavLink>
              </li>
              {/* <li className='nav-item'>
                <NavLink
                  to={`${match.url}/agro-inventory`}
                  className='nav-link text-white'
                  activeClassName='resource'
                >
                  <img
                    src={warehouse}
                    alt='warehouse'
                    height='20px'
                    className='d-inline mx-1 mr-2'
                  />
                  Agro-Inventory
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink
                  to={`${match.url}/extension`}
                  className='nav-link text-white'
                  activeClassName='resource'
                >
                  <img src={extension} alt='extension' className='d-inline mt-2 mr-2' />
                  Extension Services
                </NavLink>
              </li> */}
            </ul>
            <div className='border-bottom' />
          </div>

          <div className='content p-2'>
            <Switch>
              <Route
                exact
                path={match.path}
                render={() => (
                  <div
                    className='d-flex flex-column justify-content-center align-items-center'
                    style={{ minHeight: '50vh' }}
                  >
                    <img src={resource} width='20%' alt='resource' />
                    <h2 data-trash-text>Please select a Resource.</h2>
                    <h6 className='w-80'>
                      From this section you choose the resources you want, and add them to your
                      account. From there you can manage each of them.
                    </h6>
                  </div>
                )}
              />
              {routes.map((route, i) => (
                <WithSubRoutes key={`${route.path}${i}`} {...route} />
              ))}
            </Switch>
            <Footer />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

Resources.propTypes = {
  match: PropTypes.object.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default Resources
