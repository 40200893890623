import { createSelector } from 'reselect';
import { getLinks } from '../../app.selectors';
import { extractResourceLink } from '../../utils/extractResourceLink';

export const getServiceProviderStore = state => state.serviceProviderResource;

export const getServiceProviders = createSelector(getServiceProviderStore, serviceProviderStore => {
  return serviceProviderStore.serviceProviders;
});
export const getCurrentServiceProvider = createSelector(
  getServiceProviderStore,
  serviceProviderStore => {
    return serviceProviderStore.currentServiceProvider;
  }
);

export const selectServiceProviders = createSelector(getServiceProviders, serviceProviders =>
  Object.values(serviceProviders.data)
);

const getSelectedProviderId = (_, props) => props.match.params.serviceProviderId;

export const selectCurrentServiceProvider = createSelector(
  [getServiceProviders, getSelectedProviderId],
  ({ data: serviceProviders }, providerId) => serviceProviders[providerId]
);

export const selectSharedAgencies = createSelector(
  [getCurrentServiceProvider],
  currentServiceProvider => Object.values(currentServiceProvider.agencies)
);

export const selectCurrentServiceProviderLoading = createSelector(
  [getCurrentServiceProvider],
  currentServiceProvider => currentServiceProvider.isLoading
);

export const getResourcesLink = createSelector([getLinks], resourcesLinks => {
  const serviceProviderLink = extractResourceLink(resourcesLinks, 'ServiceProviderResource');
  return serviceProviderLink;
});

const getServiceProviderFeatures = createSelector(
  [getServiceProviders],
  serviceProviders => serviceProviders.links
);

export const getCreateResourceLink = createSelector(
  [getServiceProviderFeatures],
  serviceProviderFeatures =>
    serviceProviderFeatures.filter(features => features.rel === 'create')[0]
);

// export const getServiceProviderAgents = createSelector(
//   [getServiceProviderStore],
//   serviceProviderStore => serviceProviderStore.currentServiceProvider.agents != undefined ? serviceProviderStore.currentServiceProvider.agents:[]
// );
export const getServiceProviderAgents = createSelector(
    [getServiceProviderStore],
    serviceProviderStore => serviceProviderStore.currentSharedAgency.agents
  );
export const getAgentPostings = createSelector([getServiceProviderStore], serviceProviderStore => {
  return serviceProviderStore.agentCapturedData.postings;
});
export const selectAgentCapturedData = createSelector([getAgentPostings], postings =>
  Object.values(postings)
);

export const selectCapturedDataRequesting = createSelector(
  [getServiceProviderStore],
  serviceProviderStore => Object.values(serviceProviderStore.agentCapturedData.isLoading)
);

export const getIsLoadingAgents = createSelector(
  ({ serviceProvider }) => serviceProvider != undefined && serviceProvider.isLoadingAgents != undefined ? serviceProvider.isLoadingAgents:false,
  isLoading => isLoading!= undefined ? isLoading :false
);
