import axios from 'axios';
import accessStorage from '../utils/accessStorage';
import errorHandler from '../utils/errorHandler';

axios.defaults.baseURL = process.env.REACT_APP_BASEURL;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

axios.interceptors.request.use(
  request => requestHandler(request),
  error => requestErrorHandler(error)
);
axios.interceptors.response.use(
  response => responseHandler(response),
  error => responseErrorHandler(error)
);

/**
 * Runs a check to validate if the path is /login or /:accessID
 * @returns {boolean} true if URL path is /login or /:accessID  orhterwise false
 */
const isLoginPage = url => {
  if (typeof url === 'string') {
    return /(\/useraccount-resource\/assets)|(login)/.test(url);
  }
  return false;
};

/**
 * Handles and configures requests made using axios
 * @param request - the request configuration sent
 */
const requestHandler = request => {
  const { headers, url } = request;
  if (!isLoginPage(url)) {
    const authToken = accessStorage.getFromStore('token');
    if (headers && authToken) {
      headers['Authorization'] = authToken;
    }
  }
  return request;
};

/**
 * Handles and configures requests error made during requests
 * @param  request - the request error configuration sent
 */
const requestErrorHandler = reason => {
  return errorHandler(reason);
};

/**
 * Handles and configures response made during requests
 * @param request - the response error configuration sent
 */
const responseHandler = response => {
  return response;
};

/**
 * Handles and configures response error made during requests
 * @param request - the response error configuration sent
 */
const responseErrorHandler = reason => {
  return errorHandler(reason);
};

export { axios as api };
