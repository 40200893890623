import React from 'react'
// import Map from "../../maps/FarmHeatMap";

const NewSoilMapping = () => {
  return (
    <div>
      <h3>Assets Soil Mapping Content Here</h3>
    </div>
  )
}

export default NewSoilMapping
