import React, { Component } from 'react'
import Loader from '../../../assets/loader.gif'
import { txHistory } from '../dataTable/columnTitles'
import DataTable from '../dataTable/DataTable'

export default class walletTransactionHistory extends Component {
  constructor(props) {
    super(props)
    this.state = {
      txhistory: [
        {
          id: 1,
          person: 'Adewale Tijani',

          account: 11223344,
          amount: 'Rice Decoloration Help!!',
          task: 'Yauri, Sokoto North',
          date: '13/5/2019'
        },
        {
          id: 2,
          person: 'Adewale Tijani',

          account: 44556677,
          amount: 'Rice Decoloration Help!!',
          task: 'Yauri, Sokoto North',
          date: '13/5/2019'
        }
      ]
    }
  }
  render() {
    const { issueId } = this.props.match.params === this.state.txhistory.id

    return (
      <div className='container-fluid main-content'>
        <div className='row mx-0 bg-white'>
          <div className='col-sm-12 text-right d-flex w-100 py-3 align-items-center'>
            <input type='checkbox' className='mx-2' />
            <select className='mr-3 custom-select form-control w-20'>
              <option value='Select actions...'>Select actions...</option>
              <option value='Nil'>Nil</option>
            </select>
            <button className='btn btn-secondary'>Apply</button>
            <i className='fas fa-filter text-black-50 input-prepend' />

            <input
              className='mr-auto form-control w-20'
              type='search'
              placeholder='Search'
              // onSubmit={filterResource(e)}
            />
            {/* <Link
                to="/farmer-issues/"
                className="btn btnexpend mx-2 text-revenue">
                Next View
              </Link> */}
            {/* <NavLink to="#" className="btn btn-sm btn-revenue text-white p-2">
                  <i className="fas fa-plus fa-sm mx-1" />
                  <span>Add Schedule</span>
                </NavLink> */}
          </div>
          <div className='col-md-12'>
            {/* <ReactTable
                         className="-striped -highlight"
                  data={this.props.allCommunities}
                  defaultPageSize={10}
                  noDataText={"Loading... Please Wait"}
                /> */}
            {this.state.txhistory.length === 0 ? (
              <div className='d-flex justify-content-center w-25 mx-auto'>
                <img src={Loader} alt='loader' className='my-auto' />
              </div>
            ) : (
              <DataTable
                columnTitle={txHistory}
                className='-striped -highlight w-100'
                getRowData={this.getcapturedDataInfo}
                rowData={this.state.data}
                rowUrl={`farmer-issues/${issueId}`}
                defaultPageSize={10}
                data={this.state.txhistory}
              />
            )}
          </div>
        </div>
      </div>
    )
  }
}
