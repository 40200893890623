import React, { Component } from 'react'
import AddPlus from '../../../../../assets/Add-Plus.svg'
import Minus from '../../../../../assets/Minus-Remove.svg'
import '../../../farm/farmresource/farmresource/FarmResourceSubItems.css'

class AreaMapping extends Component {
  constructor(props) {
    super(props)
    this.state = {
      toggleState: this.props.isActivated
    }
  }

  componentDidMount() {
    this.handleState()
  }

  handleState = () => {
    if (this.state.toggleState === true) {
      document
        .getElementById(`AreaMappingAddButton${this.props.detailsOfArea}`)
        .classList.add('d-none')
      document
        .getElementById(`AreaMappingRemoveButton${this.props.detailsOfArea}`)
        .classList.remove('d-none')
      document
        .getElementById(`AreaMappingWrapperButton${this.props.detailsOfArea}`)
        .classList.remove('inActiveResource')
      this.setState({ toggleState: !this.state.toggleState })
    } else {
      document
        .getElementById(`AreaMappingRemoveButton${this.props.detailsOfArea}`)
        .classList.add('d-none')
      document
        .getElementById(`AreaMappingAddButton${this.props.detailsOfArea}`)
        .classList.remove('d-none')
      document
        .getElementById(`AreaMappingWrapperButton${this.props.detailsOfArea}`)
        .classList.add('inActiveResource')
      this.setState({ toggleState: !this.state.toggleState })
    }
  }

  handleClick = () => {
    this.setState({ toggleState: this.props.onToggle(this.state.toggleState) })
    this.handleState()
  }

  render() {
    return (
      <React.Fragment>
        <button
          type='button'
          className='btn text-secondary font-weight-light bg-white border-radius btn-sm mousePointer d-inline py-2 pl-4 mx-2'
          id={'AreaMappingWrapperButton' + this.props.detailsOfArea}
          onClick={this.handleClick}
        >
          Area Mapping
          <img
            src={AddPlus}
            className='ml-2'
            id={'AreaMappingAddButton' + this.props.detailsOfArea}
            alt='Add Icon'
          />
          <img
            src={Minus}
            className='ml-2'
            id={'AreaMappingRemoveButton' + this.props.detailsOfArea}
            alt='Remove Icon'
          />
        </button>
      </React.Fragment>
    )
  }
}

export default AreaMapping
