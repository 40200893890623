import React, { Component } from 'react'
import Navbar from '../../components/Navbar'

class AddNewCommunityMember extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <div>
        <Navbar
          path='/community-features/community-members/'
          subpath='#'
          header='&larr; Community Members ||&nbsp;'
          header1='Add New Member'
          small='Add New Member to this Community'
        />
        <div className='d-flex flex-column align-items-center my-3'>
          <h4>
            Boxes with ‘ <span className='red'>*</span> ’ must be filled for form to submit
          </h4>
          <p>Progress is automatically saved</p>
        </div>
        <div className='d-flex justify-content-center bg-light'>
          <form className='w-40 my-4'>
            <div className='form-group'>
              <label htmlFor='exampleInputEmail1'>
                Farmer First Name <span className='red'>*</span>
              </label>
              <input
                type='text'
                className='form-control'
                id='farmername'
                aria-describedby='emailHelp'
                placeholder='Enter first name'
                required
              />
            </div>
            <div className='form-group'>
              <label htmlFor='exampleInputEmail1'>Last Name </label>
              <input
                type='text'
                className='form-control'
                id='lastname'
                aria-describedby='emailHelp'
                placeholder='Enter last name'
              />
            </div>
            <div className='form-group'>
              <label htmlFor='exampleInputEmail1'>
                Email Address <span className='red'>*</span>
              </label>
              <input
                type='email'
                className='form-control'
                id='assettype'
                aria-describedby='emailHelp'
                placeholder='Enter Asset Type'
              />
            </div>
            <div className='form-group'>
              <label htmlFor='exampleInputPassword1'>Phone Number</label>
              <input type='text' id='numbersOnly' pattern='[0-9.]+' className='form-control' />
            </div>
            <div className='form-group'>
              <label htmlFor='exampleInputPassword1'>Duties & Responsibilities</label>
              <textarea id='assetdescription' className='form-control' rows='2' />
            </div>

            <button type='submit' className='btn btn-revenue text-white'>
              Save
            </button>
          </form>
        </div>
      </div>
    )
  }
}

export default AddNewCommunityMember
