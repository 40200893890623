import { createSelector } from 'reselect';
import { extractResourceLink } from '../../utils/extractResourceLink';
import { getLinks } from '../../app.selectors';

const getFarms = state => state.farmResource.farmItems;

export const getSelectedFarmResource = createSelector(
  state => state.farmResource.selectedFarmResource,
  selectedFarm => selectedFarm
);

export const getFarmResourceList = createSelector(getFarms, farmResourceList => {
  Object.values(farmResourceList);
});

// export const getServiceProviderAgents = createSelector(
//   ({ serviceProvider }) => serviceProvider.agents,
//   agents => Object.values(agents)
// )

export const getResourceLink = createSelector([getLinks], resourcesLinks => {
  const farmResourceLink = extractResourceLink(resourcesLinks, 'FarmResource');
  return farmResourceLink;
});

const getFarmResourceLinks = createSelector(state => state.farmResource.links);

export const getCreateFarmResourceLink = createSelector(
  [getFarmResourceLinks],
  createfarmResourceLink => createfarmResourceLink.filter(features => features.rel === 'create')[0]
);

// export const getCreateWithCommunityResourceLink = createSelector(
//   [getResourceLink],
//   createWithCommunityResource =>
//     createWithCommunityResource.filter(
//       features => features.rel === 'createFarmResourceWithCommunityResource'
//     )
// );
