import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import moreIcon from '../../../assets/3Dots.svg'

// create custom dropdown for each table instance

/* Receives drop down Menu Items */
const DataTableDropdown = props => {
  const {
    deleteData,
    dropdownName,
    dropdownLink,
    editPageLink,
    handleModalUpdate,
    match,
    row
  } = props

  const updateModal = () => handleModalUpdate(row)
  const handleDropdownFn = (row, index) => () => dropdownLink[index](row)

  const handleDelete = () => deleteData(row)

  return (
    <div className='dropleft actions my-n1 position-static'>
      <div
        className='menuIconWrapper d-flex justify-content-center'
        id='dropdownMenuButton'
        data-toggle='dropdown'
        aria-haspopup='true'
        aria-expanded='false'
      >
        <img src={moreIcon} className='cursor px-2' alt='More Icon' />
      </div>
      <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
        {editPageLink ? (
          <Link className='dropdown-item' to={`${editPageLink}`}>
            {' '}
            Edit{' '}
          </Link>
        ) : (
          <button
            id={`${row.name}`}
            className='dropdown-item'
            data-toggle='modal'
            onClick={updateModal}
          >
            Edit
          </button>
        )}
        {dropdownName && (
          <>
            {row.status === 'ACTIVE' ? (
              <button
                id={`${row.name}postAgent`}
                className='dropdown-item'
                data-toggle='modal'
                onClick={handleDropdownFn(row, 0)}
              >
                {dropdownName[0]}
              </button>
            ) : null}

            {dropdownName[1] !== undefined && dropdownName[1].toLowerCase() === 'captured data' ? (
              <Link
                className='dropdown-item'
                to={
                  match.path === '/:sharedAgencyId/assigned-tasks/:assignedTaskId'
                    ? `/${match.params.assignedTaskId}/agent-profile/${row.id}/captured-data`
                    : `/agent-profile/${row.id}/captured-data`
                }
              >
                {' '}
                {dropdownName[1]}
              </Link>
            ) : null}

            {dropdownName[1] !== undefined && dropdownLink[1] !== undefined ? (
              <Link className='dropdown-item w-100' to={dropdownLink[1]}>
                {' '}
                {dropdownName[1]}
              </Link>
            ) : null}

            {dropdownName[2] !== undefined && (
              <button
                id={`${row.name}dropdownTwo`}
                className='dropdown-item'
                data-toggle='modal'
                onClick={handleDropdownFn(row, 2)}
              >
                {row.status === 'ACTIVE'
                  ? 'Deactivate agent'
                  : row.status === 'INACTIVE'
                  ? 'Activate agent'
                  : dropdownName[2]}
              </button>
            )}
            {/* For the Recover Password Modal */}
            {row.status === 'ACTIVE' ? (
              <button
                id={`${row.name}dropdownThree`}
                className='dropdown-item'
                data-toggle='modal'
                onClick={handleDropdownFn(row, 3)}
                // data-target={`#${dropdownLink[3]}`}
              >
                {dropdownName[3]}
              </button>
            ) : null}
          </>
        )}
        <button className='dropdown-item' onClick={handleDelete}>
          <span> Delete</span>
        </button>
      </div>
    </div>
  )
}

DataTableDropdown.prototype = {
  deleteData: PropTypes.func,
  dropdownName: PropTypes.array,
  dropdownLink: PropTypes.array,
  editPageLink: PropTypes.string,
  handleModalUpdate: PropTypes.func,
  row: PropTypes.object.isRequired
}

export default DataTableDropdown
