import React, { Component } from 'react'
import { Form, InputGroup } from 'react-html5-form'
import goright from '../../../assets/goright.svg'
import Navbar from '../../components/Navbar'

class FarmersProfile extends Component {
  constructor() {
    super()
    this.state = {
      farmer: '',
      firstName: '',
      surname: '',
      email: '',
      address: '',
      phone: '',
      gender: 'M',
      profile: '',
      resources: '',
      communities: '',
      farms: ''
    }
  }

  componentDidMount() {
    this.getData()
  }

  displayError = error => {
    return (
      error && (
        <div className='col-md-12'>
          <p style={this.errorstyles}>{error}</p>
        </div>
      )
    )
  }

  // Update the value of the corresponding state field
  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  updateProfile() {
    const data = {
      firstName: this.state.firstName,
      surname: this.state.surname,
      email: this.state.email,
      address: this.state.address,
      phone: this.state.phone,
      gender: this.state.gender
    }

    let requestData = {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }
    fetch(
      'https://everyfarmer.farm:3579/everyfarmer-dashboard-rest/resources/dashboard/farmers',
      requestData
    )
      .then(response => response.json())
      .then(data => {
        if (data.code === 0) {
          alert('Profile updated successfully.')
          // Swal({
          //   title: "Success!",
          //   text: "Customer updated successfully.",
          //   type: "success",
          //   animation: true
          // });
          document.getElementById('editFarmerModal').click()
          document.getElementById('editFarmerForm').reset()
        } else {
          alert(data.message)
          // Swal({
          //   title: "Error!",
          //   text: data.message,
          //   type: "success",
          //   animation: true
          // });
        }
      })
      .catch(error => {
        alert(error)
        // Swal({
        //   title: "Error!",
        //   text: error,
        //   type: "success",
        //   animation: true
        // });
      })
  }

  getData() {
    fetch(
      'https://everyfarmer.farm:3579/everyfarmer-dashboard-rest/resources/dashboard/farmers/farmer',
      {
        method: 'GET',
        headers: {
          Accept: 'application/json'
        }
      }
    ).then(resp => {
      resp.json().then(res => {
        this.setState({
          farmer: res,
          firstName: res.firstName,
          surname: res.surname,
          phone: res.phone,
          address: res.address,
          email: res.email,
          gender: res.gender,
          profile: res.displayImage,
          resources: res.resources,
          farms: res.farms,
          communities: res.communities
        })
        console.log(this.state.farmer)
      })
    })
  }

  render() {
    return (
      <div className='m-2'>
        <Navbar
          navLinks={[
            {
              path: '/overview',
              header: ' Overview |'
            },
            {
              path: '#',
              header: '| Profile'
            }
          ]}
          small='Manage your profile here'
        />
        <div className='row bg-light justify-content-between my-2 p-2'>
          {/* Top Section */}

          {/* Profile Section */}
          <div className='row ml-3' style={{ width: '23rem' }}>
            <div className='col-lg-3'>
              <img src={this.state.displayImage} className='d-inline' alt='profile' width='100%' />
            </div>
            <div className='col-lg-9'>
              <h5 className='d-inline'>
                {this.state.firstName} {this.state.surname}
              </h5>
              <button
                className='btn-sm d-inline btn-edit px-2 ml-3 text-white'
                data-toggle='modal'
                data-target='#editFarmerModal'
              >
                Edit Profile
              </button>
              <h6 className='text-secondary'>{this.state.email}</h6>
              <h6>{this.state.phone}</h6>
              <span class='badge badge-dark p-1'>{this.state.farms} Farms</span>
              <span class='badge badge-dark mx-2 p-1'>{this.state.resources} Resources</span>
              <span class='badge badge-dark  p-1'>{this.state.communities} Communities</span>
            </div>
          </div>
          <div>
            <div>
              <div className='d-flex justify-content-between'>
                <small className='font-fix mr-2 mt-2'>Balance:</small>
                <p className='font-weight-bold font-24'>
                  250,000 <span className='font-18 text-secondary'>CREDITS</span>
                </p>
              </div>
              <div className='d-flex justify-content-between'>
                <small className='font-fix'>Wallet Number:</small>
                <p className='card-text'>5458 8124 FH</p>
              </div>
            </div>
            <button className='btn btn-success btn-block text-white small-font-fix'>
              Fund Wallet <img src={goright} alt='supplies' className='mx-2' />
            </button>
          </div>
        </div>
        {/* Details Section with Recent Activity */}
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-4 col-md-6'>
              <div className='card p-4'>
                <h4 className='thick'>Address</h4>
                <p>{this.state.address}</p>
                <hr className='my-2' />
                <h4 className='thick'>Gender</h4>
                <p>{this.state.gender}</p>
                <hr className='my-2' />
                {/* <h4 className="thick">Bank Information</h4>
                <p>Fidelity Bank</p>
                <p>Segun Musa Oke</p>
                <p>233 4560 477</p>
                <hr className="my-2" />
                <h4 className="thick">Income Status</h4>
                <p>Active</p> */}
              </div>
            </div>
            <div className='col-lg-8 col-md-6'>
              <h4>Recent Activity</h4>
              <ul className='list-group'>
                <li className='list-group-item d-flex justify-content-between align-items-center'>
                  <div>
                    {this.state.firstName} added a{' '}
                    <span className='font-weight-bold'>new Farm </span> for{' '}
                    <span className='b'>500 Credits.</span>
                  </div>
                  <div>
                    <p className='thick d-inline mx-3'>9:50pm</p>
                    <span className='badge badge-secondary p-1'>...</span>
                  </div>
                </li>
                <li className='list-group-item d-flex justify-content-between align-items-center'>
                  <div>
                    {this.state.firstName} added a{' '}
                    <span className='font-weight-bold'>Service Provider</span> for{' '}
                    <span className='b'>1500 Credits.</span>
                  </div>
                  <div>
                    <p className='thick d-inline mx-3'>1 day ago</p>
                    <span className='badge badge-secondary p-1'>...</span>
                  </div>
                </li>
                <li className='list-group-item d-flex justify-content-between align-items-center'>
                  <div>
                    {this.state.firstName} added a{' '}
                    <span className='font-weight-bold'>new Farm Resource</span> for{' '}
                    <span className='b'>1000 Credits.</span>
                  </div>
                  <div>
                    <p className='thick d-inline mx-3'>2 weeks ago</p>
                    <span className='badge badge-secondary p-1'>...</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Edit Farmer Modal */}
        <div className='col-md-12'>
          <Form id='editFarmerForm' onSubmit={e => this.updateProfile(e)}>
            {({ error }) => (
              <>
                <div
                  className='modal fade'
                  id='editFarmerModal'
                  role='dialog'
                  aria-labelledby='exampleModalCenterTitle'
                  aria-hidden='true'
                >
                  <div className='modal-dialog modal-dialog-centered' role='document'>
                    <div className='modal-content bg-grey text-dark'>
                      <div className='modal-header'>
                        <h5 className='modal-title' id='exampleModalLongTitle'>
                          Update Profile
                        </h5>
                        <button
                          type='button'
                          className='close'
                          data-dismiss='modal'
                          aria-label='Close'
                        >
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <div className='modal-body'>
                        <div className='form-row'>
                          <div className='form-group col-md-6'>
                            <label>First Name</label>
                            <InputGroup validate={['firstName']}>
                              {({ error }) => (
                                <div>
                                  <input
                                    required
                                    className='form-control form-control-sm'
                                    value={this.state.firstName}
                                    onChange={e => this.onChange(e)}
                                    name='firstName'
                                    placeholder='First Name'
                                  />
                                  {this.displayError(error)}
                                </div>
                              )}
                            </InputGroup>
                          </div>

                          <div className='form-group col-md-6'>
                            <label>Surname</label>
                            <InputGroup validate={['surname']}>
                              {({ error }) => (
                                <div>
                                  <input
                                    required
                                    className='form-control form-control-sm'
                                    value={this.state.surname}
                                    onChange={e => this.onChange(e)}
                                    name='surname'
                                    placeholder='Surname'
                                  />
                                  {this.displayError(error)}
                                </div>
                              )}
                            </InputGroup>
                          </div>
                        </div>

                        <div className='form-row'>
                          <div className='form-group col-md-6'>
                            <label>Email</label>
                            <InputGroup validate={['email']}>
                              {({ error }) => (
                                <div>
                                  <input
                                    required
                                    value={this.state.email}
                                    className='form-control form-control-sm'
                                    onChange={e => this.onChange(e)}
                                    name='email'
                                    type='email'
                                    placeholder='Email'
                                  />
                                  {this.displayError(error)}
                                </div>
                              )}
                            </InputGroup>
                          </div>

                          <div className='form-group col-md-6'>
                            <label>Phone Number</label>
                            <InputGroup validate={['phone']}>
                              {({ error }) => (
                                <div>
                                  <input
                                    required
                                    value={this.state.phone}
                                    className='form-control form-control-sm'
                                    onChange={e => this.onChange(e)}
                                    name='phone'
                                    placeholder='Phone Number'
                                  />
                                  {this.displayError(error)}
                                </div>
                              )}
                            </InputGroup>
                          </div>
                        </div>

                        <div className='form-row'>
                          <div className='form-group col-md-12'>
                            <label>Address</label>
                            <InputGroup validate={['address']}>
                              {({ error }) => (
                                <div>
                                  <textarea
                                    required
                                    value={this.state.address}
                                    className='form-control form-control-sm'
                                    onChange={e => this.onChange(e)}
                                    name='address'
                                    placeholder='Address'
                                  />
                                  {this.displayError(error)}
                                </div>
                              )}
                            </InputGroup>
                          </div>
                        </div>

                        <div className='form-group'>
                          <div className='row'>
                            <legend className='col-form-label col-sm-2 pt-0'>Gender</legend>
                            <div className='col-sm-6'>
                              <div className='form-check'>
                                <input
                                  type='radio'
                                  className='form-check-input'
                                  id='male'
                                  name='gender'
                                  value={'M'}
                                  onChange={e => this.onChange(e)}
                                  checked={this.state.gender === 'M'}
                                />
                                <label htmlFor='male' className='form-check-label'>
                                  Male
                                </label>
                              </div>
                              <div className='form-check'>
                                <input
                                  id='female'
                                  className='form-check-input'
                                  name='gender'
                                  type='radio'
                                  value={'F'}
                                  onChange={e => this.onChange(e)}
                                  checked={this.state.gender === 'F'}
                                />
                                <label htmlFor='female' className='form-check-label'>
                                  Female
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='modal-footer'>
                        <button type='button' className='btn btn-warning' data-dismiss='modal'>
                          Close
                        </button>
                        <button type='submit' className='btn btn-success'>
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* End Edit Customer Modal */}
                </div>
              </>
            )}
          </Form>
        </div>
      </div>
    )
  }
}
export default FarmersProfile
