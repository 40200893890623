import React, { memo } from 'react'
import minus from '../../../assets/Minus-Remove.svg'
import moreIcon from '../../../assets/3Dots.svg'
import { Link } from 'react-router-dom'

const CommunitiyResourceItem = ({
  communityResource,
  deleteCommunityResource,
  editCommunityResource,
  handleSuspend,
  viewProcessedFarmers,
  viewCommunityResourceDetails,
  previewCommunityResource
}) => {
  const handleDeleteCommResource = () => deleteCommunityResource(communityResource.id)

  const handleEditCommResource = () => {
    editCommunityResource(communityResource)
  }

  const suspendCommResource = () => handleSuspend(communityResource.id)

  const handleViewProcessedFarmers = () =>
    viewProcessedFarmers(communityResource.id, communityResource.name, true)

  const handleViewCommResourceDetails = () => viewCommunityResourceDetails(communityResource)

  const handlePreviewCommResource = () => previewCommunityResource(communityResource)

  return (
    <>
      <li className='list-group-item d-flex p-2 bg-list-outer'>
        <div className='col-11 d-flex justify-content-between bg-list-inner rounded-pill'>
          <div className='d-flex flex-row align-items-center justify-content-center'>
            <input type='checkbox' name='' className='align-middle mx-2' />
            <Link
              to='/community-resource/community-resource-overview'
              onClick={handleViewCommResourceDetails}
            >
              {communityResource.name}
            </Link>
          </div>
          <div />
          <div>
            {communityResource.status === 'ACTIVE' ? (
              <span className='badge bg-revenue text-white m-2 p-1'>Active</span>
            ) : (
              <span className='badge bg-expend text-white m-2 p-1'>Inactive</span>
            )}
            <img
              className='cursor'
              src={minus}
              alt='Remove Icon'
              data-toggle='tooltip'
              data-placement='top'
              title='Delete this resource'
              onClick={handleDeleteCommResource}
            />
          </div>
        </div>
        {/* New Component Ends */}
        <div className='dropdown ml-auto'>
          <div className='dropdown dropleft'>
            <div
              className='menuIconWrapper d-flex justify-content-center align-content-center mousePointer'
              id='dropdownMenuButton'
              data-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded='false'
            >
              <img src={moreIcon} className='cursor' alt='More Icon' />
            </div>
            <div className='dropdown-menu pointer' aria-labelledby='dropdownMenuButton'>
              {/* <button className='btn dropdown-item'>Grant Resource Access</button>
          <button className='btn dropdown-item pointer'>Add a Program</button> */}
              <button
                id={`${communityResource.name}EditFormBtn`}
                className='d-none'
                data-toggle='modal'
                data-target='#communityResourceModal'
              ></button>
              <button className='btn dropdown-item pointer' onClick={handleEditCommResource}>
                Edit
              </button>
              <button
                id={`${communityResource.name}DetailsFormBtn`}
                className='d-none'
                data-toggle='modal'
                data-target='#communityDetailsModal'
              ></button>
              <button className='btn dropdown-item pointer' onClick={handlePreviewCommResource}>
                View Details
              </button>
              <button className='btn dropdown-item pointer' onClick={handleViewProcessedFarmers}>
                View Processed Farmers
              </button>
              <button className='btn dropdown-item pointer' onClick={suspendCommResource}>
                {communityResource.status === 'ACTIVE' ? 'Suspend' : 'Activate'}
              </button>
            </div>
          </div>
        </div>
      </li>
    </>
  )
}

export default memo(CommunitiyResourceItem)
