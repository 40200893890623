import React, { PureComponent } from 'react'
import { staffDetails } from '../../components/dataTable/columnTitles'
import DataTable from '../../components/dataTable/DataTable'
import Toolbar from '../../components/Toolbar'
import ImportResourceModal from '../../components/modals/ImportResourceModal'

export default class StaffList extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      selectedStaff: []
    }
  }

  setSelectedRows = rowIds =>
    this.setState(prevState => ({
      selectedStaff: [...prevState.selectedStaff, ...rowIds]
    }))

  render() {
    return (
      <div className='bg-white'>
        <Toolbar
          buttonName='Staff'
          buttonLink='/add-new-staff'
          handleSelectedRows={this.setSelectedRows}
          viewJobBtn={true}
        />

        <DataTable
          // accessRight='viewOnly'
          columnTitle={staffDetails}
          data={this.props.staffList}
          isLoading={this.props.isLoading}
          rowUrl='staff'
        />
        <ImportResourceModal />
      </div>
    )
  }
}
