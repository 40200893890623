import { useState } from 'react';
import { Form, InputGroup, Row, Col, Button } from 'react-bootstrap';
import BaseUrl from '../../../security/base-url';
import React from 'react';
import { Loading } from 'notiflix';

export default function MapConverter() {
  const [isUploadingFile, setIsUploadingFile] = useState(false);

  const handleFormSubmit = form => {
    Loading.dots();
    form.preventDefault();
    const fileField = document.querySelector('input[type="file"]');
    console.log(fileField);
    const formData = new FormData();
    formData.append('file', fileField.files[0]);

    const requestData = {
      method: 'post',
      body: formData,
      headers: {
        Accept: 'text/plain'
      }
    };

    fetch(BaseUrl.url_dashbord + '/farmresources/convert_kml', requestData)
      .then(response => response.blob())
      .then(blob => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = 'maps.csv';
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();

        Loading.remove();
      })
      .catch(error => {
        console.log(error);
        this.displayError('Upload Failed and Error occured');

        Loading.remove();
      });
  };

  return (
    <div className='mt-5'>
      <Row>
        <Col sm={{ span: 5, offset: 1 }}>
          <h3>KML File converter</h3>{' '}
          <Form onSubmit={handleFormSubmit} id='uploads'>
            <small>Maximum file size is 25MB</small>
            <Form.Group>
              <Form.Label>Choose a File</Form.Label>
              <Form.Control type='file' accept='.kml' name='files' id='files' />
            </Form.Group>
            <Button type='submit'>Upload</Button>
          </Form>
        </Col>
      </Row>
    </div>
  );
}
