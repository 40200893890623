/* eslint-disable jsx-a11y/no-onchange */
import MaterialTable, { CircularProgress } from 'material-table';
import React, { useEffect, useMemo } from 'react';
import AsyncSelect from 'react-select/async';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Navbar from '../../../app/components/Navbar';
import farmer from '../../../assets/farmer-white.svg';
import FarmersOnClusters from '../../components/maps/FarmersOnClusters';
import mappedarea from '../../../assets/mapped-area.svg';
import {
  getAnalytics,
  getCommunityResource,
  getExternalFarmerList,
  handleAnalyticsName
} from '../../pages/community/communities.actions';
import { fetchFarmItemsByCommunityResource } from '../../pages/farm/farm.actions';

const Exoverview = props => {
  const {
    getAllCommunityResource,
    getExternalFarmerList,
    handleAnalyticsName,
    getAnalytics,
    communityResourceList,
    fetchFarmItemsByCommunityResource,
    history,
    externalFarmerList,
    analyticsName,
    analytics,
    isLoading
  } = props;

  //const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getAllCommunityResource();
  }, [getAllCommunityResource]);

  const initAnalytics = (resourceName, resourceId) => {
    getExternalFarmerList(resourceId);
    handleAnalyticsName(resourceName);
    getAnalytics(resourceId);
  };

  const handleSelectCommunityResource = e => {
    const [selectedCommunityResource] = communityResourceList.filter(
      communityResource => communityResource.name === e.target.value
    );

    initAnalytics(selectedCommunityResource.name, selectedCommunityResource.id);
    fetchFarmItemsByCommunityResource(selectedCommunityResource.id);
    fetchFarmItemsByCommunityResource(selectedCommunityResource.id);
  };

  //New Table data

  const farmers = useMemo(
    () => [
      {
        title: 'Name',
        field: 'name'
      },
      // {
      //   title: 'Gender',
      //   field: 'gender'
      // },
      {
        title: 'BVN',
        field: 'bvn'
      },
      {
        title: 'Phone Number',
        field: 'phone'
      },
      {
        title: 'CBN ID',
        field: 'cbnApplicantId'
      },
      {
        title: 'Input Collected',
        field: 'inputCollected',
        render: data => (
          <span
            className={`${
              /inactive/i.test(data.inputCollected) ? 'bg-danger' : 'bg-revenue'
            } w-75 text-center font-weight-bold text-white`}
          >
            {data.inputCollected}
          </span>
        )
      },
      {
        title: 'Farm Size',
        field: 'size'
      }
    ],
    []
  );

  return (
    <div className='container-fluid'>
      <Navbar
        navLinks={[
          {
            path: '#',
            header: ' External Overview'
          }
        ]}
        small='View Information on the several community activities'
      />
      <div className='card border-light p-3'>
        <h3 className='font-18 my-3 text-capitalize'>
          {`${analyticsName ? analyticsName : 'No'} Community Resource`}
        </h3>
        <div className='d-flex justify-content-between my-4'>
          <div className='align-items-baseline d-flex'>
            <input
              type='text'
              list='data'
              size='25'
              placeholder='Search program from the list'
              className='form-control form-control-sm'
              onChange={handleSelectCommunityResource}
            />
            <datalist id='data'>
              {Object.entries(communityResourceList).length > 0 ? (
                communityResourceList.map(communityResource => {
                  return (
                    <option
                      key={communityResource.name + communityResource.id}
                      value={communityResource.name}
                    >
                      {communityResource.name}
                    </option>
                  );
                })
              ) : (
                <option key='no community' value='No community resource'>
                  No community resource
                </option>
              )}
            </datalist>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-3 col-md-3 '>
            <div className='card bg-revenue text-white'>
              <div className='card-body d-flex justify-content-around p-2'>
                <div>
                  <img src={farmer} alt='captured' width='50' className='d-inline mx-3' />
                </div>
                <div>
                  <h5 className='text-white font-weight-bold'>Total Farmer(s)</h5>

                  <h2 className='card-text my-2 text-white'>
                    {analytics.communitiesTotal ? analytics.communitiesTotal : 0}
                  </h2>
                </div>
              </div>
            </div>
          </div>

          <div className='col-lg-3 col-md-3'>
            <div className='card bg-revenue text-white'>
              <div className='card-body d-flex justify-content-around p-2'>
                <div>
                  <img src={farmer} alt='captured' width='50' className='d-inline mx-3' />
                </div>
                <div>
                  <h5 className='text-white font-weight-bold'>Total Mapped</h5>

                  <h2 className='card-text my-2 text-white'>
                    {analytics.processedFarmersCount ? analytics.processedFarmersCount : 0}
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-3 col-md-3'>
            <div className='card bg-revenue text-white'>
              <div className='card-body d-flex justify-content-around p-2'>
                <div>
                  <img src={mappedarea} alt='captured' width='50' className='d-inline m-3' />
                </div>
                <div>
                  <h5 className='text-white font-weight-bold'>Total Hectares</h5>
                  <h2 className='card-text my-2 text-white'>
                    {analytics.hectaresTotalInCommunity
                      ? analytics.hectaresTotalInCommunity.toFixed(2)
                      : 0}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Farmers Map renders here 
        {/* <div className='row flex-column my-3'>
          <FarmersOnClusters farms={props.farmItemsByCommunityResource} />
        </div> 
         Farmers List in the selected Community renders here */}

        <div className='row flex-column mx-3'>
          <div className='row'>
            <MaterialTable
              columns={farmers}
              data={externalFarmerList}
              style={{
                fontFamily: 'Nunito',
                width: '100%',
                boxShadow: 'none'
              }}
              actions={[
                {
                  icon: 'pageview',
                  tooltip: 'View Farmer Profile',
                  onClick: (event, rowData) => {
                    history.push('/view-profile/' + rowData.id);
                  }
                }
              ]}
              isLoading={isLoading}
              options={{
                actionsColumnIndex: 7,
                sorting: true,
                showTitle: false,
                exportButton: true,
                exportAllData: true,
                exportFileName: `${analyticsName}`,
                loadingType: 'overlay',
                searchFieldAlignment: 'left',
                headerStyle: {
                  backgroundColor: '#4f4f4f',
                  color: '#FFF'
                  //borderRight: "0.5px solid #fff"
                }
              }}
              components={{
                CircularProgress: props => (
                  <div style={{ backgroundColor: '#96c63d' }}>
                    <CircularProgress {...props} />
                  </div>
                )
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    isLoading: state.comReducer.isLoading,
    farmItemsByCommunityResource: state.farmResource.farmItemsByCommunityResource,
    communityResourceList: state.comReducer.communityResourceList,
    analyticsName: state.comReducer.analyticsName,
    analytics: state.comReducer.analytics,
    externalFarmerList: state.comReducer.externalFarmerList
  };
};

export default withRouter(
  connect(mapStateToProps, {
    fetchFarmItemsByCommunityResource,
    getAllCommunityResource: getCommunityResource,
    handleAnalyticsName,
    getAnalytics,
    getExternalFarmerList
  })(Exoverview)
);
