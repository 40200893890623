import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import moreIcon from '../../../../assets/3Dots.svg'
import minus from '../../../../assets/Minus-Remove.svg'

/**
 * @param {*} listItem(Object)
 * @param {*} modalName(String)
 * @param {*} modalUpdate(function)
 */
const ResourceItem = ({ listItem, modalName, modalUpdate }) => {
  const resourceName = modalName.toLowerCase()
  const editResource = () => modalUpdate(listItem)
  const deleteItem = () => console.log('resItem', listItem.name)
  const suspendItem = () => console.log('resItem', listItem.name)

  return (
    <li className='list-group-item d-flex flex-row p-2 bg-list-outer'>
      <div className='col-11 d-flex justify-content-between bg-list-inner rounded-pill'>
        <div className='d-flex flex-row align-items-center justify-content-center'>
          <input type='checkbox' name={listItem.name} className='align-middle' />
          <Link to={`${resourceName}/${listItem.id}`} className='mx-2'>
            {listItem.name}
          </Link>
        </div>
        <div>
          {listItem.status === 'ACTIVE' ? (
            <span className='badge bg-revenue text-white m-2 p-1'>Active</span>
          ) : (
            <span className='badge bg-expend text-white m-2 p-1'>Inactive</span>
          )}
          <img
            className='cursor'
            src={minus}
            alt='Remove Icon'
            data-toggle='tooltip'
            data-placement='top'
            title='Delete this resource'
            onClick={deleteItem}
          />
        </div>
      </div>

      {/* New Component Ends */}
      <div className='col-1 dropdown'>
        {/* View resource's Details Modal */}
        <div className='dropdown dropleft'>
          <div
            className='menuIconWrapper d-flex justify-content-center align-content-center mousePointer'
            id='dropdownMenuButton'
            data-toggle='dropdown'
            aria-haspopup='true'
            aria-expanded='false'
          >
            <img src={moreIcon} className='cursor' alt='More Icon' />
          </div>
          <div className='dropdown-menu pointer' aria-labelledby='dropdownMenuButton'>
            <button
              className='dropdown-item pointer'
              onClick={editResource}
              id='editdrpdwn'
              data-toggle='modal'
            >
              Edit
            </button>
            <button
              className='dropdown-item pointer'
              data-toggle='modal'
              data-target='#resourceDetailsModal'
            >
              View Details
            </button>
            <button className='dropdown-item pointer' onClick={suspendItem}>
              Suspend
            </button>
          </div>
        </div>
      </div>
      {/* View resource's Details modal starts here */}
      <div
        className='modal fade'
        id='resourceDetailsModal'
        data-backdrop='static'
        data-keyboard='false'
        tabIndex={-1}
        role='dialog'
        aria-hidden='true'
      >
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h3>
                <span className='badge'>{`${modalName}`} Details</span>
              </h3>
              <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              <p>Name:</p>
              <p>{listItem.name}</p>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-expend text-white' data-dismiss='modal'>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* View resource's Details modal ends here */}
    </li>
  )
}

ResourceItem.propTypes = {
  listItem: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string
  }),
  modalName: PropTypes.string.isRequired,
  modalUpdate: PropTypes.func
}

export default memo(ResourceItem)
