import { ALERT_FAILED, ALERT_SUCCESS, CLEAR_ALERT } from './api.constant'

const intitalState = {
  errorMsg: '',
  successMsg: ''
}

const alerts = (state = intitalState, { type, payload }) => {
  const alertState = {
    [ALERT_SUCCESS]: {
      ...state,
      successMsg: payload,
      errorMsg: ''
    },
    [ALERT_FAILED]: {
      ...state,
      errorMsg: payload,
      successMsg: ''
    },
    [CLEAR_ALERT]: { ...intitalState },
    default: state
  }
  return alertState[type] || state
}

export default alerts
