import { CircularProgress } from '@material-ui/core';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { fetchData } from '../../api/api.actions';
import apiService from '../../api/apiService';
import { api } from '../../api/api.config';
import ImportResourceModal from '../../components/modals/ImportResourceModal';
import Navbar from '../../components/Navbar';
import AgentsTools from '../../components/serviceProvider/sharedAgents/Agents-Tools';
import AgentTableActions from '../../components/serviceProvider/sharedAgents/AgentTableActions';
import RecoverPasswordModal from '../../components/serviceProvider/sharedAgents/RecoverPasswordModal';
import Spinner from '../../components/Spinner';
import Toolbar from '../../components/Toolbar';
import notify from '../../helpers/notify';
import { agentColumns } from '../../utils/tablecolumns';
import { getFilteredData } from '../../utils/resourceFilter';
import { handleEditAgentData } from './serviceProvider.actions';
import { getIsLoadingAgents, getServiceProviderAgents } from './serviceProvider.selectors';
import { agent } from '../../components/dataTable/columnTitles';
import BaseUrl from '../../../security/base-url';

const { error, success } = notify();

class Agents extends PureComponent {
  static propTypes = {
    // agents: PropTypes.objectOf({
    //   id: PropTypes.number.isRequired
    // }).isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    fetchData: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    handleEditAgentData: PropTypes.func.isRequired
  };

  state = {
    agentData: [],
    newPassword: '',
    searchValue: ''
  };

  componentDidMount() {
    const { match, fetchData } = this.props;
    const { sharedAgencyId } = match.params;
    fetchData('AGENTS', { sharedAgencyId });
    this.getFilteredAgents();
  }

  getFilteredAgents = () => {
    const { searchValue } = this.state;
    const { agents } = this.props;
    return getFilteredData(agents, searchValue, 'sharedAgentsTag');
  };

  handleInputChange = e => {
    e.persist();
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleChangeStatus = ({ id, status }) => {
    const { match, fetchData } = this.props;
    const { sharedAgencyId } = match.params;
    try {
      fetch(BaseUrl.url_dashbord + `/agents/updatesinglestatus/${id}/${status}`, {
        method: 'get',

        headers: {}
      })
        .then(response => response.json())
        .then(data => {
          success('Agent Activated Successfully');
          fetchData('AGENTS', { sharedAgencyId });
        })
        .catch(error => {
          console.log(error);
        });
      // const activationRes =  apiService.post();
      // console.log("activationRes");
      // console.log(activationRes);
    } catch (err) {
      error('Server is unable to process your request. Try again later.');
    }
  };

  clearRecoverModal = () => this.setState({ newPassword: '' });

  render() {
    const {
      match: {
        params: { serviceProviderId, sharedAgencyId }
      },
      location: { pathname },
      history,
      isLoading,
      handleEditAgentData
    } = this.props;
    const { newPassword } = this.state;

    return (
      <>
        <Navbar
          navLinks={[
            {
              path: '/resources/serviceprovider',
              header: ' ServiceProviders |'
            },
            {
              path: `/new-service-provider-resource/${serviceProviderId}`,
              header: '| Services |'
            },
            {
              path: `${pathname}`,
              header: `| Shared Agency`
            }
          ]}
          small='View and manage service provider'
        />
        <div className='bg-white'>
          {/* <Toolbar
            {...this.props}
            tools={AgentsTools}
            handleSelectedRows={this.handleSelectedRows}
            onSearch={this.handleInputChange}
          /> */}
          <AgentsTools {...this.props} />
          <Spinner isLoading={isLoading} data={this.getFilteredAgents()}>
            <MaterialTable
              columns={[
                ...agentColumns,

                {
                  title: '...',
                  field: 'actions',
                  render: data => (
                    <>
                      <AgentTableActions
                        data={data}
                        history={history}
                        handleEditAgentData={handleEditAgentData}
                        changeStatus={this.handleChangeStatus}
                        sharedAgencyId={sharedAgencyId}
                        // deleteData={this.deleteData}
                      />
                      <RecoverPasswordModal
                        onChange={this.handleInputChange}
                        clearRecoverModal={this.clearRecoverModal}
                        agentsId={data.id}
                      />
                    </>
                  )
                }
              ]}
              data={this.getFilteredAgents()}
              style={{
                fontFamily: 'Nunito',
                width: '100%',
                boxShadow: 'none'
              }}
              options={{
                actionsColumnIndex: 4,
                sorting: true,
                showTitle: false,
                exportButton: true,
                searchFieldAlignment: 'left',
                loadingType: 'linear',
                headerStyle: {
                  backgroundColor: '#4f4f4f',
                  color: '#FFF'
                }
              }}
              isLoading={isLoading}
              actions={[
                {
                  icon: 'account_circle',
                  tooltip: 'View User Profile',
                  onClick: (_, data) => history.push(`/agent-profile/${data.id}`)
                }
              ]}
              components={{
                CircularProgress: props => (
                  <div style={{ backgroundColor: '#4f4f4f' }}>
                    <CircularProgress {...props} />
                  </div>
                )
              }}
            />
          </Spinner>

          <ImportResourceModal importInfo={this.importInfo} />
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  let agents = getServiceProviderAgents(state);
  return {
    agents: agents,
    isLoading: getIsLoadingAgents(state)
  };
}
export default connect(mapStateToProps, { fetchData, handleEditAgentData })(Agents);
