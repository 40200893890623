import { REQUEST_ASSET, REQUEST_ASSET_SUCCESS, REQUEST_ASSET_FAILED } from './login.constant'

export const requestAsset = accessId => {
  return {
    type: REQUEST_ASSET,
    payload: accessId
  }
}

export function requestAssetSuccess(assets) {
  return {
    type: REQUEST_ASSET_SUCCESS,
    payload: assets
  }
}

export const requestAssetFailed = message => ({
  type: REQUEST_ASSET_FAILED,
  payload: message
})
