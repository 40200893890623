import { actions } from '../../api/api.constant'

const {
  REQUEST_DASHBOARD_DATA,
  REQUEST_DASHBOARD_DATA_SUCCESS,
  REQUEST_DASHBOARD_DATA_FAILED
} = actions
const communitiesD = {
  totalFarmers: 300000,
  totalCommunities: 5000
}
const serviceProvidersD = {
  sharedAgents: 120000,
  labour: 20000,
  farmers: 300000
}
const inputSupplyD = [
  {
    itemName: 'Organic Fertilizer',
    undistributed: 19,
    distributed: 80,
    inStore: 10,
    allocated: 0
  },
  {
    itemName: 'Synthetic Fertilizer',
    undistributed: 19,
    distributed: 80,
    inStore: 50,
    allocated: 0
  },
  {
    itemName: 'Organic Fertilizer',
    undistributed: 29,
    distributed: 80,
    inStore: 100,
    allocated: 0
  },
  {
    itemName: 'Synthetic Fertilizer',
    undistributed: 19,
    distributed: 80,
    inStore: 9,
    allocated: 0
  },
  {
    itemName: 'Organic Fertilizer',
    undistributed: 1,
    distributed: 80,
    inStore: 100,
    allocated: 0
  },
  {
    itemName: 'Synthetic Fertilizer',
    undistributed: 19,
    distributed: 80,
    inStore: 100,
    allocated: 0
  },
  {
    itemName: 'Organic Fertilizer',
    undistributed: 19,
    distributed: 80,
    inStore: 100,
    allocated: 0
  },
  {
    itemName: 'Synthetic Fertilizer',
    undistributed: 19,
    distributed: 80,
    inStore: 100,
    allocated: 0
  }
]

const initialcentralDashboard = {
  progressionRate: 0,
  farmersProgressionRate: 25,
  totalIncome: 0,
  activeServices: 0,
  inactiveServices: 0,
  activeUsers: 0,
  inactiveUsers: 0,
  activeSubscriptions: 0,
  inactiveSubscriptions: 0,
  registeredFarmers: 0,
  activeFarmers: 0,
  inactiveFarmers: 0,
  activeServiceProviders: 0,
  inactiveServiceProviders: 0,
  activeAgents: 0,
  inactiveAgents: 0,
  communities: communitiesD,
  lastYearRevenue: 0,
  inputSupply: inputSupplyD,
  serviceProviders: serviceProvidersD,
  soilCategories: [
    {
      type: 'Loamy',
      totalMapped: '260000000'
    },
    {
      type: 'Clay',
      totalMapped: '260000000'
    },
    {
      type: 'Humus',
      totalMapped: '260000000'
    },
    {
      type: 'Loamy',
      totalMapped: '260000000'
    },
    {
      type: 'Clay',
      totalMapped: '260000000'
    },
    {
      type: 'Humus',
      totalMapped: '260000000'
    },
    {
      type: 'Loamy',
      totalMapped: '260000000'
    },
    {
      type: 'Clay',
      totalMapped: '260000000'
    },
    {
      type: 'Humus',
      totalMapped: '260000000'
    }
  ]
}

const centralDashboard = (state = initialcentralDashboard, { type, payload }) => {
  switch (type) {
    case REQUEST_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        payload
      }

    case REQUEST_DASHBOARD_DATA:
    case REQUEST_DASHBOARD_DATA_FAILED:
    default:
      return state
  }
}

export default centralDashboard
