import { REQUEST_ASSET, REQUEST_ASSET_FAILED, REQUEST_ASSET_SUCCESS } from './login.constant'

export const initialAssetState = {
  logo: '',
  accountName: '',
  errorMessage: '',
  shortDescription: '',
  longDescription: '',
  isFetching: false,
  assetsError: false
}

export default function assets(state = initialAssetState, { type, payload }) {
  switch (type) {
    case REQUEST_ASSET:
      return {
        ...state,
        isFetching: true
      }
    case REQUEST_ASSET_SUCCESS:
      return {
        ...state,
        logo: payload.logo,
        accountName: payload.name,
        shortDescription: payload.shortDescription,
        longDescription: payload.longDescription,
        isFetching: false
      }
    case REQUEST_ASSET_FAILED:
      return {
        ...state,
        errorMessage: payload,
        isFetching: false,
        assetsError: true
      }

    default:
      return state
  }
}
