import { call, put, takeEvery } from 'redux-saga/effects'
import {
  alertFailed,
  alertSuccess,
  clearAlertMessage,
  fetchData,
  fetchFailed,
  fetchSuccess
} from '../../api/api.actions'
import { actions } from '../../api/api.constant'
import apiService from '../../api/apiService'
import { RESTORE_TRASH } from './trash.actions'

// watchers
export function* watchFetchTrash() {
  yield takeEvery(actions.REQUEST_TRASH, fetchTrashItems)
}

export function* watchRestoreTrash() {
  yield takeEvery(RESTORE_TRASH, restoreTrashItem)
}

// workers
export function* fetchTrashItems(action) {
  try {
    const payload = yield call([apiService, 'get'], '/trash', action.payload)
    if (payload.code === undefined) {
      yield put(fetchSuccess('TRASH', payload))
    } else {
      throw new Error(payload.message)
    }
  } catch (error) {
    yield put(fetchFailed('TRASH'))
  }
}

export function* restoreTrashItem({ payload: trashId }) {
  try {
    const payload = yield call([apiService, 'update'], `/trash/recover/${trashId}`)
    if (payload.code === undefined) {
      yield put(alertSuccess('Item successfully restored.'))
      yield put(fetchData('TRASH'))
    } else {
      throw new Error(payload.message)
    }
  } catch (error) {
    yield put(alertFailed('Error restoring item, please try again.'))
    yield put(fetchFailed('TRASH', error))
  } finally {
    yield put(clearAlertMessage())
  }
}

export default {
  watchFetchTrash,
  watchRestoreTrash,
  fetchTrashItems,
  restoreTrashItem
}
