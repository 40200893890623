import React, { memo } from 'react';
import './skeleton.css';

const ResourceListSkeleton = memo(() => (
  <div className='bg-white'>
    <div className=' skeleton bg-light skeleton__resource-item my-2' />
    <div className=' skeleton bg-light skeleton__resource-item my-2' />
    <div className=' skeleton bg-light skeleton__resource-item my-2' />
    <div className=' skeleton bg-light skeleton__resource-item my-2' />
    <div className=' skeleton bg-light skeleton__resource-item my-2' />
  </div>
));

export default ResourceListSkeleton;
