import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import analytics from '../../assets/analytics.svg';
import community from '../../assets/community-black.svg';
import extension from '../../assets/extension-black.svg';
import farmers from '../../assets/farms.svg';
import overview from '../../assets/overview.svg';
import providers from '../../assets/providers-black.svg';
import resources from '../../assets/resources.svg';
import staff from '../../assets/staff.svg';
import reports from '../../assets/reports.svg';
import togglerclosed from '../../assets/toggler-closed.svg';
import toggleropen from '../../assets/toggler-open.svg';
import trash from '../../assets/trash.svg';
import warehouse from '../../assets/warehouse-black.svg';

class Sidebar extends PureComponent {
  static propTypes = {
    userActiveResources: PropTypes.arrayOf(PropTypes.string),
    userName: PropTypes.string.isRequired,
    userId: PropTypes.number.isRequired,
    logo: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
    console.log("-----------PROPS------------")
    console.log(props)
  }

  toggleClass = () => {
    let sidebar = document.querySelector('#sidebar');
    let content = document.querySelector('#content');
    sidebar.classList.toggle('active');
    content.classList.toggle('active');
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  };

  logoTypes = {
    wacot: 'https://everyfarmer-app-uploads.s3.eu-central-1.amazonaws.com/everyfarmer_resources/logo/wacot-logo.png',
    nswitch: 'https://everyfarmer-app-uploads.s3.eu-central-1.amazonaws.com/everyfarmer_resources/logo/nswitch-logo.png',
    default: 'https://everyfarmer-app-uploads.s3.eu-central-1.amazonaws.com/everyfarmer_resources/logo/everyfarmer-logo.png',
    erg: 'https://api.everyfarmer.farm/uploads/logo/erg.PNG',
    gconsult: 'https://everyfarmer-app-uploads.s3.eu-central-1.amazonaws.com/everyfarmer_resources/logo/gconsult-logo.png',
    agropark:'https://everyfarmer-app-uploads.s3.eu-central-1.amazonaws.com/everyfarmer_resources/logo/ias_logo.png'
  };

  renderLogo = () => {
    switch (this.props.userName) {
      case 'wacot-rice':
        return this.logoTypes.wacot;
      case 'nswitch':
        return this.logoTypes.nswitch;

      case 'erg':
        return this.logoTypes.erg;
        case 'g-consult':
          return this.logoTypes.gconsult;
      case 'agro-pack':
          return this.logoTypes.agropark;
      default:
        return this.logoTypes.default;
    }
  };

  renderNavItems = navItems => {
    const icons = {
      farm: farmers,
      serviceprovider: providers,
      'agro-Inventory': warehouse,
      community,
      extension,
      staff,
      analytics,
      reports,
      trash
    };

    return navItems.map(navItem => (
      <li className='nav-item' key={navItem}>
        <NavLink to={navItem ? `/${navItem}` : 'resource'} className='nav-link text-capitalize'>
          <img src={icons[navItem]} alt={navItem} className='d-inline mx-2' />
          {navItem.includes('service') ? 'Service Provider ' : navItem}
        </NavLink>
      </li>
    ));
  };

  renderActiveResources = () => {
    const { userActiveResources } = this.props;
    // const peripherals = ['reports', 'analytics', 'staff', 'trash'];
    const peripherals = ['reports', 'trash'];

    if (userActiveResources) {
      return (
        <>
          <li className='bg-revenue-light my-3'>
            <span className='text-center p-1'>Active Resources</span>
          </li>
          {this.renderNavItems(userActiveResources)}
          <li className='bg-revenue-light mt-1'>
            <span className='text-center p-1'>Peripherals</span>
          </li>
          {this.renderNavItems(peripherals)}
        </>
      );
    }
  };

  renderMainAdminNav = () => {
    return (
      <>
        <li className='nav-item'>
          <NavLink to='/overview' className='nav-link' activeClassName='active'>
            <img src={overview} alt='overview' className='d-inline mx-2' />
            Overview
          </NavLink>
        </li>
        <li className='nav-item'>
          <NavLink to='/resources' className='nav-link' activeClassName='active'>
            <img src={resources} alt='resources' className='d-inline mx-2' />
            Resources
          </NavLink>
        </li>
        <li className='nav-item'>
          <NavLink to='/accesslist' className='nav-link' activeClassName='active'>
            <img src={staff} alt='resources' className='d-inline mx-2' />
            AccessList
          </NavLink>
        </li>
         <li className='nav-item'>
          <NavLink to='/kmlConverter' className='nav-link' activeClassName='active'>
            <img src={staff} alt='resources' className='d-inline mx-2' />
            Map Converter
          </NavLink>
        </li>
        {this.renderActiveResources()}
        {this.state.isOpen ? (
          <li className='d-flex justify-content-end'>
            <button className='btn' onClick={this.toggleClass}>
              <img src={toggleropen} alt='toggler' className=' ml-4 cursor sidebar-toggle' />
            </button>
          </li>
        ) : (
          <li className='d-flex justify-content-end'>
            <button className='btn' onClick={this.toggleClass}>
              <img src={togglerclosed} alt='toggler' className='cursor sidebar-toggle' />
            </button>
          </li>
        )}
      </>
    );
  };

  renderAgentNav = () => {
    return (
      <div>
        <li className='nav-item'>
          <NavLink to='/overview' className='nav-link' activeClassName='active'>
            <img src={overview} alt='overview' className='d-inline mx-2' />
            Overview
          </NavLink>
        </li>
        <li className='nav-item'>
          <NavLink
            to={`/agent-profile/${this.props.userId}`}
            className='nav-link'
            activeClassName='active'
          >
            <img src={resources} alt='data-captured' className='d-inline mx-2' />
            Data Captured
          </NavLink>
        </li>
      </div>
    );
  };

  renderExternalOverviewNav = () => {
    return (
      <div>
        <li className='nav-item'>
          <NavLink to='/overview' className='nav-link' activeClassName='active'>
            <img src={overview} alt='overview' className='d-inline mx-2' />
            Home
          </NavLink>
        </li>
        <li className='nav-item'>
          {/* <NavLink to='/ex-overview' className='nav-link' activeClassName='active'>
            <img src={resources} alt='external-overview' className='d-inline mx-2' />
            Farmers
          </NavLink> */}
        </li>
      </div>
    );
  };

  renderFarmerNav = () => {
    return (
      <div>
        <li className='nav-item'>
          <NavLink to='/' className='nav-link' activeClassName='active'>
            <img src={resources} alt='data-captured' className='d-inline mx-2' />
            Data Captured
          </NavLink>
        </li>
        <li className='nav-item'>
          <NavLink to='/' className='nav-link' activeClassName='active'>
            <img src={extension} alt='extension' className='d-inline mx-2' />
            Extension
          </NavLink>
        </li>
      </div>
    );
  };

  renderNavigation = role => {
    switch (role.toUpperCase()) {
      case 'CBN':
        return this.renderExternalOverviewNav();

      case 'ADMIN':
        return this.renderMainAdminNav();

      case 'AGENT':
        return this.renderAgentNav();

      case 'FARMER':
        return this.renderFarmerNav();

      default:
        return <></>;
    }
  };

  render() {
    return (
      <nav id='sidebar' className='fix-height scrollbar'>
        <div className='sidebar-header mt-2'>
          <NavLink to='/overview'>
            <img
              src={this.renderLogo()}
              width='157px'
              height='auto'
              alt={`${this.props.userName}-logo`}
              className='toplogo'
            />
            <img
              src={this.props.logo}
              width='30px'
              alt={`${this.props.userName}-logo`}
              className='downlogo mx-auto'
            />
          </NavLink>
        </div>
        <ul className='list-unstyled components'>
          <div>
            <li className='bg-revenue-light'>
              <span className='p-1'>Main Pages</span>
            </li>
            {this.renderNavigation(this.props.role)}
          </div>

          <li className='bg-revenue-light'>
            <span className='ml-2 p-1'>More...</span>
          </li>
          {!(this.props.role.toUpperCase() === 'CBN') ||
          !Array.isArray(this.props.userActiveResources) ||
          !this.props.userActiveResources.length ? (
            <div>
              {/* <li className='nav-item'>
                <NavLink to='#' className='nav-link'>
                  Blog
                </NavLink>
              </li> */}
              <li className='nav-item'>
                <NavLink to='/settings' className='nav-link'>
                  Settings
                </NavLink>
              </li>
              {/* <div className='form-group m-2 push-down'>
                <select className='form-control p-1 w-80 font-fix' id='exampleFormControlSelect1'>
                  <option>English</option>
                  <option>French</option>
                </select>
              </div> */}
            </div>
          ) : (
            ''
          )}
        </ul>
      </nav>
    );
  }
}

export default Sidebar;
