import React, { memo } from 'react'
import './skeleton.css'

const DashletSkeleton = memo(() => {
  return (
    <div className='card drop-shadow border skeleton skeleton__info-dashlet'>
      <div className='card-body text-center px-5 py-2' />
    </div>
  )
})

export default DashletSkeleton
