import Swal from 'sweetalert2';
import BaseUrl from '../../../security/base-url';
import * as actionType from './community.constant';
import { METHOD_GET } from '../../utils/rest-config';

const BASEURL = BaseUrl.url_dashbord + '/';

export const toggleCommunityResourceMapping = mappingStatus => ({
  type: actionType.TOGGLE_COMMUNITY_RESOURCE_MAPPING,
  payload: { mappingStatus }
});

//Method creators for community
/**
 * this method is action creator for TOGGLELIST action type.
 * @param {is use as a payload on toggleList action creator } id
 */
export const toggleList = id => {
  return {
    type: actionType.TOGGLELIST,
    id: id
  };
};

/**
 * this method is action creator for COMMUNITYID action type.
 * @param {is use as a payload on handleCommunityId action creator } communityId
 */
export const handleCommunityId = communityId => {
  return {
    type: actionType.COMMUNITYID,
    communityId: communityId
  };
};
/**
 * this method is action creator for updating farmers members
 *
 */
export const handleUpdateFarmersMembers = newFarmersMembers => {
  return {
    type: actionType.UPDATEFARMERSMEMBERS,
    newFarmersMembers: newFarmersMembers
  };
};
/**
 * this method is action creator for COMMUNITYID action type.
 * @param {is use as a payload on handleCommunityId action creator } communityId
 */
export const handleIsLoggin = isloggin => {
  return {
    type: actionType.ISLOGGIN,
    isloggin: isloggin
  };
};

/**
 * this method is action creator for COMMUNITYID action type.
 * @param {is use as a payload on handleCommunityId action creator } communityResourceId
 */
export const handleCommunityResourceId = (communityResource, isProcessed) => {
  return {
    type: actionType.COMMUNITYRESOURCEID,
    payload: {
      communityResourceId: communityResource.id,
      communityResourceName: communityResource.name,
      isProcessed,
      communityResourceMappingEnabled: communityResource.mappingEnabled
    }
  };
};

/**
 * this method is action creator for COMMUNITYID action type.
 * @param {is use as a payload on handleCommunityId action creator } communityResourceId
 */
export const handleSelectedFarmersFile = event => {
  return {
    type: actionType.SELECTEDFARMERSFILE,
    event: event
  };
};
/**
 * this method is action creator for COMMUNITYID action type.
 * @param {is use as a payload on handleCommunityId action creator } communityResourceId
 */
export const handleCommunityIdAndName = (communityId, communityName, isProcessed) => {
  return {
    type: actionType.COMMUNITYNAME,
    communityId: communityId,
    communityName: communityName,
    isProcessed: isProcessed
  };
};

const fetchCommuntiyResources = _ => ({
  type: actionType.FETCH_COMMUNITY_RESOURCE
});

const fileUploadError = () => {
  return {
    type: actionType.FILESELECTEDERROR
  };
};

/**
 * This action creator set farmers members
 * @param {list of farmers members as a payload that will be set to farmersMembers state variable} farmersMembers
 */
export const handleSwitchProcessedFarmer = isProcessed => {
  return {
    type: actionType.ISPROCESSED,
    isProcessed: isProcessed
  };
};

/**
 * This action creator set farmers members
 * @param {list of farmers members as a payload that will be set to farmersMembers state variable} farmersMembers
 */
export const handleSwitchProcessedFarmer2 = isProcessed2 => {
  return {
    type: actionType.ISPROCESSED2,
    isProcessed2: isProcessed2
  };
};

/**
 * This action creator set farmers members
 * @param {list of farmers members as a payload that will be set to farmersMembers state variable} farmersMembers
 */
const setFarmersMembers = farmersMembers => {
  return {
    type: actionType.GETFARMERSMEMBERS,
    farmersMembers: farmersMembers
  };
};
/**
 * this action creator method set is dispatch when error occur
 * during api asyn call.
 */
export const handleSetError = () => {
  return {
    type: actionType.SETERROR
  };
};

export const handleFilterFarmersMembers = (event, elementId) => {
  return {
    type: actionType.FILTERFARSMERMEMBERS,
    event: event,
    elementId: elementId
  };
};
export const handleSuspend = communityResourceId => {
  return dispatch => {
    Swal.fire({
      title: 'Are you sure?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#96c63d',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then(result => {
      if (result.value) {
        const requestConfig = {
          method: 'PUT',
          headers: {
            'content-type': 'application/json',
            Accept: 'application/json'
          }
        };

        fetch(BASEURL + 'community-resource/suspend/' + communityResourceId, requestConfig)
          .then(response => response.json())
          .then(data => {
            if (data.message === 'success') {
              dispatch(getCommunityResource());
              Swal({
                title: 'Success!',
                text: 'Community Resource Suspended successfully!',
                type: 'success',
                animation: true
              });
            } else {
              Swal({
                title: 'Error!',
                text: 'Error while suspending community resource!',
                type: 'error',
                animation: true
              });
            }
          })
          .catch(e => {
            Swal({
              title: 'Error!',
              text: 'Error while suspending community resource!',
              type: 'error',
              animation: true
            });
          });
      }
    });
  };
};
/**
 * This method get all the farmers members
 * @param {is use to get all the farmers members based on community Id} communityId
 */
export const getFarmersMembers = communityId => {
  return dispatch => {
    const requestConfig = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };
    fetch(BASEURL + 'community-resource/communitymembers/' + communityId, requestConfig)
      .then(response => response.json())
      .then(data => {
        dispatch(setFarmersMembers(data.reverse()));
      })
      .catch(error => {
        dispatch(handleSetError());
      });
  };
};

/**
 * This action creator set farmers members
 * @param {list of farmers members as a payload that will be set to farmersMembers state variable} farmersMembers
 */
const setCommunityOverview = communityoverview => {
  return {
    type: actionType.COMMUNITYOVERVIEW,
    communityoverview: communityoverview
  };
};

/**
 * This method get all the farmers members
 * @param {is use to get all the farmers members based on community Id} communityId
 */
export const getCommunityOverview = () => {
  return dispatch => {
    const requestConfig = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };
    fetch(BASEURL + 'community-resource/overview', requestConfig)
      .then(response => response.json())
      .then(data => {
        dispatch(setCommunityOverview(data));
      })
      .catch(error => {
        dispatch(handleSetError());
      });
  };
};

/**
 * this method is action creator for COMMUNITYID action type.
 * @param {is use as a payload on handleCommunityId action creator } communityId
 */
const setSwitchFarmers = (communityResourceName, processedFarmer, isProcessed) => {
  return {
    type: actionType.SWITCHFARMERS,
    communityResourceName: communityResourceName,
    processedFarmer: processedFarmer,
    isProcessed: isProcessed
  };
};

/**
 * This method get all the farmers members
 * @param {is use to get all the farmers members based on community Id} communityId
 */
export const getSwitchFarmers = (communityResourceId, communityResourceName, isProcessed) => {
  return dispatch => {
    const requestConfig = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };
    fetch(BASEURL + 'community-resource/processesFarmers/' + communityResourceId, requestConfig)
      .then(response => response.json())
      .then(data => {
        dispatch(setSwitchFarmers(communityResourceName, data.reverse(), isProcessed));
      })
      .catch(error => {
        dispatch(handleSetError());
      });
  };
};

/**
 * This action creator set farmers members
 * @param {list of farmers members as a payload that will be set to farmersMembers state variable} farmersMembers
 */
const setCommunityMessages = communityMessages => {
  return {
    type: actionType.GETALLCOMMUNITYMESSAGES,
    communityMessages: communityMessages
  };
};

/**
 * This method get all the farmers members
 * @param {is use to get all the farmers members based on community Id} communityId
 */
export const getCommunityMessages = communityId => {
  return dispatch => {
    const requestConfig = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };
    fetch(BASEURL + 'communitychat/' + communityId, requestConfig)
      .then(response => response.json())
      .then(data => {
        dispatch(setCommunityMessages(data.reverse()));
      })
      .catch(error => {
        dispatch(handleSetError());
      });
  };
};

/**
 * This action creator set farmers members
 * @param {list of farmers members as a payload that will be set to farmersMembers state variable} farmersMembers
 */
const setAllCommunities = allCommunities => {
  return {
    type: actionType.GETALLCOMMUNITIES,
    allCommunities: allCommunities
  };
};

/**
 * This method get all the farmers members
 * @param {is use to get all the farmers members based on community Id} communityId
 */
export const getAllCommunities = communityResourceId => {
  return dispatch => {
    const requestConfig = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };
    fetch(BASEURL + 'community-resource/communities/' + communityResourceId, requestConfig)
      .then(response => response.json())
      .then(data => {
        dispatch(setAllCommunities(data.reverse()));
      })
      .catch(error => {
        dispatch(handleSetError());
      });
  };
};

export const handleUploadFarmers = (selectedFile, communityId) => {
  return dispatch => {
    const data = new FormData();
    data.append('file', selectedFile, selectedFile.name);
    const requestConfig = {
      method: 'POST',
      body: data,
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json'
      }
    };
    fetch(BASEURL + 'farmers/file/' + communityId, requestConfig)
      .then(response => response.json())
      .then(data => {
        if (data.message === 'success') {
          dispatch(getFarmersMembers(communityId));
          dispatch(fileUploadError());
          Swal({
            title: 'Success!',
            text: 'New Farmers uploaded successfully!',
            type: 'success',
            animation: true
            // confirmButtonText: 'Cool'
          });
        } else {
          dispatch(fileUploadError());
          Swal({
            title: 'Error!',
            text: 'Error while uploading farmers!',
            type: 'error',
            animation: true
            // confirmButtonText: 'Cool'
          });
          // dispatch(handleResetError(oldError))
        }
      })
      .catch(error => {
        dispatch(fileUploadError());
        Swal({
          title: 'Error!',
          text: 'Error while uploading farmers!',
          type: 'error',
          animation: true
          // confirmButtonText: 'Cool'
        });
      });
  };
};

/**
 * This action creator set farmers members
 * @param {list of farmers members as a payload that will be set to farmersMembers state variable} farmersMembers
 */
const setCommunityResource = allcommunityResource => {
  return {
    type: actionType.GETCOMMUNITYRESOURCE,
    allcommunityResource: allcommunityResource
  };
};

//this method switch community resource name on analytics pages
export const handleAnalyticsName = resourceName => {
  return {
    type: actionType.ANALYTICSNAME,
    resourceName: resourceName
  };
};

/**
 * This action creator set farmers members
 * @param {list of farmers members as a payload that will be set to farmersMembers state variable} farmersMembers
 */
export const getCommunityResource = () => {
  return dispatch => {
    dispatch(fetchCommuntiyResources());
    const headersConfig = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };
    fetch(BaseUrl.url_dashbord + '/community-resource/', headersConfig)
      .then(respons => respons.json())
      .then(data => {
        const reverseData = data.reverse();
        // FIXME: why is getAnalytics, getExternalFsrmerList and handleAnalyticsName being called
        dispatch(getAnalytics(reverseData[0].id));
        dispatch(getExternalFarmerList(reverseData[0].id));
        dispatch(handleAnalyticsName(reverseData[0].name));
        dispatch(setCommunityResource(reverseData));
      })
      .catch(err => {
        dispatch(handleSetError());
      });
  };
};

/**
 * This action creator set farmers members
 * @param {list of farmers members as a payload that will be set to farmersMembers state variable} farmersMembers
 */
const setAnalytics = analytics => {
  return {
    type: actionType.ANALYTICS,
    analytics: analytics
  };
};

/**
 * This method get all the farmers members
 * @param {is use to get all the farmers members based on community Id} communityId
 */
export const getAnalytics = communityResourceId => {
  return dispatch => {
    const requestConfig = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };
    fetch(BASEURL + 'community-resource/analytics/' + communityResourceId, requestConfig)
      .then(response => response.json())
      .then(data => {
        dispatch(setAnalytics(data));
      })
      .catch(error => {
        dispatch(handleSetError());
      });
  };
};

const setExternalFarmerList = externalFarmerList => {
  return {
    type: actionType.EXTERNALFARMERLISTSUCCESS,
    externalFarmerList: externalFarmerList
  };
};

export const getExternalFarmerList = communityResourceId => {
  return dispatch => {
    const requestConfig = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };
    fetch(
      BASEURL + 'community-resource/findfarmers-by-communityid/' + communityResourceId,
      requestConfig
    )
      .then(response => response.json())
      .then(data => {
        dispatch(setExternalFarmerList(data.reverse()));
      })
      .catch(error => {
        dispatch(handleSetError());
      });
  };
};
//this action creator set membersmsmessages
const setMemberSmsMessages = smsMessages => {
  return {
    type: actionType.MEMBERSMSMESSAGES,
    smsMessages: smsMessages
  };
};

export const getMemberSmsMessages = communityId => {
  return dispatch => {
    const requestConfig = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    };
    fetch(BASEURL + 'community-resource/membersmsmessage/' + communityId, requestConfig)
      .then(response => response.json())
      .then(data => {
        dispatch(setMemberSmsMessages(data));
      })
      .catch(error => {
        dispatch(handleSetError());
      });
  };
};

export const addEOP = (eop, communityResourceId) => {
  return dispatch => {
    const requestConfig = {
      method: 'POST',
      body: JSON.stringify(eop),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    };
    fetch(BASEURL + 'community-resource/eop/' + communityResourceId, requestConfig)
      .then(response => response.json())
      .then(data => {
        if (data.message === 'success') {
          dispatch(getEOP(communityResourceId));
          Swal({
            title: 'Success!',
            text: 'New EOP added successfully!',
            type: 'success',
            animation: true
            // confirmButtonText: 'Cool'
          });
        } else {
          Swal({
            title: 'Error!',
            text: 'Error while adding EOP!',
            type: 'error',
            animation: true
            // confirmButtonText: 'Cool'
          });
        }
      })
      .catch(error => {
        dispatch(handleSetError());
        Swal({
          title: 'Error!',
          text: 'Error while adding EOP!',
          type: 'error',
          animation: true
          // confirmButtonText: 'Cool'
        });
      });
  };
};
const setEOP = eops => {
  return {
    type: actionType.EOPS,
    eops: eops
  };
};

export const getEOP = communityResourceId => {
  return dispatch => {
    fetch(BASEURL + 'community-resource/eop/' + communityResourceId, METHOD_GET)
      .then(response => response.json())
      .then(data => {
        dispatch(setEOP(data.reverse()));
      })
      .catch(error => {
        dispatch(handleSetError());
      });
  };
};

export const deleteEOP = (eopId, communityResourceId) => {
  return dispatch => {
    Swal.fire({
      title: 'Are you sure?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#96c63d',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then(result => {
      if (result.value) {
        const requestConfig = {
          method: 'PUT',
          headers: {
            'content-type': 'application/json',
            Accept: 'application/json'
          }
        };

        fetch(BASEURL + 'community-resource/eop/' + eopId, requestConfig)
          .then(response => response.json())
          .then(data => {
            if (data.message === 'success') {
              dispatch(getEOP(communityResourceId));
              Swal({
                title: 'Success!',
                text: 'EOP deleted successfully!',
                type: 'success',
                animation: true
              });
            } else {
              Swal({
                title: 'Error!',
                text: 'Error while deleting EOP!',
                type: 'error',
                animation: true
              });
            }
          })
          .catch(e => {
            Swal({
              title: 'Error!',
              text: 'Error while deleting EOP!',
              type: 'error',
              animation: true
            });
          });
      }
    });
  };
};

export const resetSelectedMembers = isReset => {
  return {
    type: actionType.RESETSELECTEDMEMBERS,
    isReset: isReset
  };
};
