import React, { Component } from 'react'
import { NavLink, Route, Switch, withRouter } from 'react-router-dom'
import Footer from '../../components/Footer'
import Navbar from '../../components/Navbar'
import ExtensionResourceIssues from './ExtensionResourceIssues'
import Outbreaks from './Outbreaks'

//This is the COmponent that loads when you go into a resource

class ExtensionResourceFeatures extends Component {
  constructor(props) {
    super(props)
    this.state = {
      issue: [
        {
          id: 1,
          issuer: 'Adewale Tijani',

          requests: 4,
          summary: 'Rice Decoloration Help!!',
          location: 'Yauri, Sokoto North'
        },
        {
          id: 2,
          issuer: 'Maitama Tambuwal',
          requests: 14,
          summary: 'Rice Decoloration Help!!',
          location: 'Isoko Central, Bendel'
        }
      ],
      isUpdate: false
    }
  }
  //   componentDidMount() {
  //     this.props.getEOPS(this.props.communityResourceId);
  //   }
  handleDeleteEOP = eopId => {
    this.props.deleteEOP(eopId, this.props.communityResourceId)
  }
  render() {
    const { match } = this.props
    return (
      <React.Fragment>
        <Navbar
          navLinks={[
            {
              path: '/extension',
              header: 'Extension Resource |'
            },
            {
              path: '#',
              header: '| Extension'
            }
          ]}
          small='Extension resources with issues and outbreaks'
        />

        <div className='container-fluid'>
          {/* <div className='row my-3'>
            <h3 className='font-18 my-3'>{this.props.communityResourceName}</h3>
          </div> */}
          <div className='row my-2 px-2'>
            <ul className='navbar-nav mr-auto d-flex flex-row'>
              <li className='nav-item'>
                <NavLink to={match.url} className='p-3' exact={true} activeClassName='active-nav'>
                  Issues
                </NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink
                  to={this.props.match.url + "/community-members/"}
                  className="p-3"
                  exact={true}
                  activeClassresourcesName="active-nav">
                  Members
                </NavLink>
              </li> */}
              <li className='nav-item'>
                <NavLink
                  to={match.url + '/outbreaks'}
                  className='p-3'
                  exact={true}
                  activeClassName='active-nav'
                >
                  Outbreaks
                </NavLink>
              </li>
            </ul>
            {/* <div className='filter-list'>
              <Link
                to='/log-an-issue/'
                className='btn btn-revenue text-white ml-4'
              >
                Log Issue
              </Link>
            </div> */}
          </div>

          <div className='mt-4'>
            <Switch>
              <Route
                exact
                path={`${match.path}/(issues)?`}
                render={() => <ExtensionResourceIssues />}
              />
              <Route exact path={`${match.path}/outbreaks`} render={() => <Outbreaks />} />
              {/* <Route exact path="/community/evaluate/" component={Evaluation} /> */}
            </Switch>
            <Footer />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(ExtensionResourceFeatures)
