import React from 'react';
import { Form, InputGroup } from 'react-html5-form';

const NewFarmResourceModal = props => {
  const {
    inputs,
    createFarmResource,
    onChange,
    states,
    selectedResource,
    handleEnableCommunityResource,
    enableCommunityResource
  } = props;

  const validateCall = input => {
    if (input.current.value === 'Choose...') {
      input.setCustomValidity(`Please select ${input.current.title}`);
      return false;
    }
    return true;
  };
  return (
    <div
      className='modal fade'
      id='addFarmResourceModal'
      tabIndex={-1}
      aria-labelledby='exampleModalLabel'
      aria-hidden='true'
    >
      <Form id='addFarmResource' onSubmit={createFarmResource}>
        {({ error, valid, pristine, submitting }) => (
          <div className='modal-dialog' role='document'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5>Add Farm Resource</h5>
                <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                  <span aria-hidden='true'>×</span>
                </button>
              </div>
              <div className='modal-body'>
                <div className='form-row'>
                  <div className='form-group col-md-12'>
                    <InputGroup
                      tag='div'
                      className='form-group col-md-12'
                      validate={{
                        season: validateCall
                      }}
                    >
                      {({ errors, valid }) => (
                        <div className='d-flex justify-content-around my-3'>
                          <label className='my-2' htmlFor='selectDay'>
                            Choose Season
                          </label>
                          <select
                            name='season'
                            id='selectSeason'
                            title='Season'
                            className={`form-control ${valid ? '' : 'is-invalid'}`}
                            value={inputs.season}
                            onChange={onChange}
                          >
                            <option value='Choose...'>Choose...</option>
                            <option value='Rainy&nbsp;Season'>Rainy Season</option>
                            <option value='Dry&nbsp;Season'>Dry Season</option>
                          </select>
                          {error && <div className='invalid-feedback'>{error}</div>}
                        </div>
                      )}
                    </InputGroup>
                  </div>
                  <div className='form-group col-md-12'>
                    <InputGroup validate={['name']}>
                      {({ error }) => (
                        <div>
                          <input
                            required
                            className='form-control'
                            placeholder='Resource Name'
                            name='name'
                            defaultValue={inputs.name}
                            onChange={onChange}
                          />
                          {error && (
                            <div className='alert alert-danger' role='alert'>
                              {error}
                            </div>
                          )}
                        </div>
                      )}
                    </InputGroup>
                  </div>
                  <div className='form-group col-md-12'>
                    <InputGroup validate={['description']}>
                      {({ error }) => (
                        <div>
                          <textarea
                            required
                            className='form-control'
                            placeholder='Resource Description'
                            name='description'
                            defaultValue={inputs.description}
                            onChange={onChange}
                          />
                          {error && (
                            <div className='alert alert-danger' role='alert'>
                              {error}
                            </div>
                          )}
                        </div>
                      )}
                    </InputGroup>
                  </div>
                  <div className='form-row'>
                    <InputGroup validate={['handleCommunity']} className='form-group col-md-12'>
                      {({ error, valid }) => (
                        <div className='form-check'>
                          <input
                            name='handleCommunity'
                            type='checkbox'
                            className={`form-check-input ${valid ? '' : 'is-invalid'}`}
                            id='handleCommunity'
                            onChange={handleEnableCommunityResource}
                          />
                          <label className='form-check-label' htmlFor='exampleCheck1'>
                            Enable Community Resource
                          </label>
                          {error && <div className='invalid-feedback'>{error}</div>}
                        </div>
                      )}
                    </InputGroup>
                  </div>
                  {enableCommunityResource && (
                    <div className='form-row justify-content-between w-100'>
                      <InputGroup
                        tag='div'
                        className='form-group col-md-6'
                        validate={{
                          state: validateCall
                        }}
                      >
                        {({ errors, valid }) => (
                          <div className='d-flex justify-content-around my-3'>
                            <label className='my-2' htmlFor='selectDay'>
                              Choose State
                            </label>
                            <select
                              name='state'
                              id='selectState'
                              title='State'
                              className={`form-control ${valid ? '' : 'is-invalid'}`}
                              value={inputs.state}
                              onChange={onChange}
                            >
                              <option value='Choose...'>Choose...</option>
                              {states ? (
                                states.map((item, index) => (
                                  <option key={index} value={item.name}>
                                    {item.name}
                                  </option>
                                ))
                              ) : (
                                <option value='nodata'>No State</option>
                              )}
                            </select>
                            {error && <div className='invalid-feedback'>{error}</div>}
                          </div>
                        )}
                      </InputGroup>

                      {/* <InputGroup
                            tag='div'
                            className='form-group col-md-6'
                            validate={{
                              call: validateCall
                            }}
                          >
                            {({ errors, valid }) => (
                              <div className='d-flex justify-content-around my-3'>
                                <label className='my-2' htmlFor='selectDay'>
                                  Choose LGA
                                </label>
                                <select
                                  name='lg'
                                  id='selectLga'
                                  title='LGA'
                                  className={`form-control ${valid ? '' : 'is-invalid'}`}
                                >
                                  <option value='Choose...'>Choose...</option>
                                  <option>Call</option>
                                  <option>No Call</option>
                                </select>

                                {error && <div className='invalid-feedback'>{error}</div>}
                              </div>
                            )}
                          </InputGroup> */}
                    </div>
                  )}
                </div>
                <div className='modal-footer'>
                  <button type='button' className='btn btn-expend text-white' data-dismiss='modal'>
                    Close
                  </button>
                  <button
                    type='submit'
                    className='btn btn-revenue text-white'
                    disabled={pristine || submitting}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Form>
    </div>
    /* Add Farm resource modal ends here */
  );
};

export default NewFarmResourceModal;

//   state = {
//     name: '',
//     description: '',
//     modified: false
//   };

//   onChange = e => {
//     this.setState({
//       [e.target.name]: e.target.value
//     });
//   };

// const handleEdit = data => {
//   setSelectedResource(data);
// };

// Update a farm resource

// const updateFarmResource = (event, data) => {
//   fetch(BaseUrl.url_dashbord + '/farmresources', {
//     method: 'PUT',
//     body: JSON.stringify(data),
//     headers: {
//       'Content-Type': 'application/json',
//       Accept: 'application/json'
//     }
//   })
//     .then(res => {
//       if (res.status === 200) {
//         document.getElementById('addFarmResource').reset();
//         document.getElementById('addFarmResourceModal').click();

//         success('Farm resource updated successfully');
//         this.getFarmResources();
//       } else {
//         error('An Error Occured');
//       }
//     })
//     .catch(err => console.log(err));
// };

// componentDidMount() {
//   if (this.props.selectedResource) {
//     this.setState({
//       name: this.props.selectedResource.name,
//       description: this.props.selectedResource.description
//     });
//   }
// }

// onChange = e => {
//   this.setState({
//     [e.target.name]: e.target.value
//   });
//   switch (e.target.name) {
//     case 'name':
//       this.setState({ nameModified: true });
//       break;
//     case 'description':
//       this.setState({ descriptionModified: true });
//       break;
//     default:
//       break;
//   }
// };

// loadText = () => {
//   if (!this.state.nameModified) {
//     this.setState({
//       name: this.props.selectedResource.name,
//       nameModified: true
//     });
//     return this.props.selectedResource.name;
//   } else if (!this.state.descriptionModified) {
//     this.setState({
//       description: this.props.selectedResource.description,
//       descriptionModified: true
//     });
//     return this.props.selectedResource.description;
//   }
// };

// // Handle error in the modals

// const displayError = error => {
//   return (
//     error && (
//       <div className='col-md-12'>
//         <p className='text-danger'>{error}</p>
//       </div>
//     )
//   );
// };
