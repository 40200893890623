import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import moreIcon from '../../../../assets/3Dots.svg';
import PropTypes from 'prop-types';

const AgentTableActions = ({
  data,
  sharedAgencyId,
  history,
  handleEditAgentData,
  changeStatus,
  deleteData
}) => {
  const handleEdit = () => {
    handleEditAgentData(data);
    history.push(`/${sharedAgencyId}/edit-agent`);
  };
  return (
    <div className='dropleft actions my-n1 position-static'>
      <div
        className='menuIconWrapper d-flex justify-content-center'
        id='dropdownMenuButton'
        data-toggle='dropdown'
        aria-haspopup='true'
        aria-expanded='false'
      >
        <img src={moreIcon} className='cursor px-2' alt='More Icon' />
      </div>
      <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
        {/* <button className='dropdown-item' onClick={handleEdit} type='button'>
          Edit
        </button> */}
        <NavLink className='dropdown-item' to={`/${sharedAgencyId}/post-agent/${data.id}`}>
          Post agent
        </NavLink>
        <Link className='dropdown-item' to={`/agent-profile/${data.id}/captured-data`}>
          Captured Data
        </Link>
        <button className='dropdown-item' data-toggle='modal' onClick={() => changeStatus(data)}>
          {data.status === 'ACTIVE' ? 'Deactivate agent' : 'Activate agent'}
        </button>

        <button
          id={`${data.name}dropdownThree`}
          className='dropdown-item'
          data-toggle='modal'
          data-target='#recoverModal'
          onClick={()=>{
            localStorage.setItem("selected_Agent",JSON.stringify(data));
          }}
        >
          Recover password
        </button>
        {/* <button className='dropdown-item' onClick={deleteData}>
          <span> Delete</span>
        </button> */}
      </div>
    </div>
  );
};

AgentTableActions.propTypes = {
  data: PropTypes.objectOf({
    id: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }),
  sharedAgencyId: PropTypes.number.isRequired,
  changeStatus: PropTypes.func.isRequired,
  // deleteData: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  handleEditAgentData: PropTypes.func.isRequired
};

export default AgentTableActions;
