import React, { useState, useEffect } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import MaterialTable from 'material-table';
import ResourceListSkeleton from '../skeleton/ResourceListSkeleton';
import { recoveryColumns } from '../../utils/tablecolumns';
import BaseUrl from '../../../security/base-url';

const Inputs = props => {
  const [inputs, setInputs] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    fetch(
      BaseUrl.url_dashbord + '/input/summary/' + props.communityResourceId,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json'
        }
      },
      { signal: signal }
    )
      .then(response => response.json())
      .then(response => {
        setInputs(response.data);
      })
      .catch(error => {
        console.log(error);
      });
    return () => {
      abortController.abort();
    };
  }, []);

  // Found on Community
  let inputsColumns = [
    {
      title: 'Input Name',
      field: 'inputName'
    },
    {
      title: 'Total Farmers',
      field: 'totalFarmers'
    }
    // {
    //   title: 'Actions',
    //   field: 'actions',
    //   render: () => <button className='btn btn-outline-secondary text-center'>View Details</button>
    // }
  ];

  return (
    <>
      <div className='row my-3'>
        <div className='col-md-12'>
          <div className='card'>
            <div className='bg-secondary p-2 d-flex flex-column justify-content-between'>
              <p className='font-18 font-weight-bold my-1'>
                Summary of Inputs Distributed in Community
              </p>
              <div>
                {inputs ? (
                  <MaterialTable
                    // onRowClick={(_, inputs) =>
                    //   props.history.push({
                    //     pathname: '/community-resource/inputs/inputsdetails/',
                    //     search: '?query=abc',
                    //     state: { detail: response.data }
                    //   })
                    // }
                    columns={inputsColumns}
                    data={inputs}
                    style={{
                      fontFamily: 'Nunito',
                      width: '100%',
                      boxShadow: 'none'
                    }}
                    options={{
                      actionsColumnIndex: -1,
                      sorting: true,
                      showTitle: false,
                      exportButton: true,
                      searchFieldAlignment: 'left',
                      loadingType: 'linear',
                      headerStyle: {
                        backgroundColor: '#4f4f4f',
                        color: '#FFF'
                      }
                    }}
                    actions={[
                      {
                        icon: 'pageview',
                        tooltip: 'View Input Details',
                        onClick: (event, rowData) =>
                          props.history.push({
                            pathname: `/community-resource/inputdetails/${rowData.inputId}`,
                            state: { inputs: rowData }
                          })
                      }
                    ]}
                    components={{
                      CircularProgress: props => (
                        <div style={{ backgroundColor: '#4f4f4f' }}>
                          <CircularProgress {...props} />
                        </div>
                      )
                    }}
                  />
                ) : (
                  <ResourceListSkeleton />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(Inputs);
