import React, { Component } from 'react'
import { Form, InputGroup } from 'react-html5-form'
import Swal from 'sweetalert2'
import loading from '../../../../../assets/loader.gif'
import BaseUrl from '../../../../../security/base-url'

class UploadFarmMaps extends Component {
  state = {
    isUploaded: false,
    message: '',
    imageId: -1,
    isDeleteButton: false,
    isUploadingFile: false
  }

  displayError = error => {
    Swal({
      title: 'Error',
      type: 'error',
      html: error,
      showCloseButton: true
    })
  }

  handleFormSubmit = form => {
    this.setState({ isUploadingFile: true })

    const formData = new FormData(form.getRef().current)
    const requestData = {
      method: 'POST',
      body: formData,
      headers: {
        accept: 'application/json'
      }
    }

    fetch(
      BaseUrl.url_dashbord + '/farmresources/import_map_data/' + this.props.resourceId,
      requestData
    )
      .then(response => response.json())
      .then(data => {
        if (data.totalCount > 0) {
          this.setState({
            isUploaded: true,
            message: (
              <span>
                <strong>Total Farm Maps Uploaded: </strong>
                {data.totalCount}
                <br />
                <strong>Total Farms Updated Successfully: </strong>
                {data.totalSuccessCount}
              </span>
            ),
            imageId: data.code
          })
        } else {
          console.log('ELSE')
          this.setState({
            isUploaded: false,
            message: 'Error uploading',
            isUploadingFile: false
          })
          throw Error()
        }
      })
      .catch(error => {
        console.log(error)
        this.setState({ isUploadingFile: false })
        this.setState({
          isUploaded: false,
          message: 'Upload Failed and Error occured'
        })

        this.displayError('Upload Failed and Error occured')
      })
  }

  render() {
    return (
      <div>
        <div className='col-md-10'>
          <Form id='uploadFarmsMaps' onSubmit={this.handleFormSubmit}>
            {({ error, valid, sumitted, prestine, form, submitting }) => (
              <>
                <div
                  className='modal fade'
                  id='uploadFarmMaps'
                  tabIndex='-1'
                  role='dialog'
                  aria-labelledby='exampleModalCenterTitle'
                  aria-hidden='true'
                >
                  <div className='modal-dialog modal-dialog-centered' role='document'>
                    <div className='modal-content'>
                      <div className='modal-header'>
                        <h4>Import Map Data</h4>
                        <button
                          type='button'
                          className='close'
                          data-dismiss='modal'
                          aria-label='Close'
                        >
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      {/* BEGIN MODAL BODY */}

                      {this.state.isUploaded ? (
                        <div className='modal-body'>
                          <p className='row flex-row justify-content-around my-3'>
                            {this.state.message}
                          </p>
                        </div>
                      ) : (
                          <div className='modal-body'>
                             <InputGroup
                            tag='div'
                            className='row justify-content-center my-3'
                            validate={['importType']}
                          >
                            {({ error, valid }) => (
                              <>
                                <div className='col-md-8'>
                                  <select
                                    required
                                    name='importType'
                                    id='importType'
                                    className={`form-control ${!valid && 'is-invalid'}`}
                                    onChange={this.onSelectUniqueType}
                                  >
                                    <option>Select Import Type</option>
                                    <option value='KML'>KML</option>
                                    <option value='Others'>Others</option>
                                    
                                  </select>
                                </div>
                                <div className='row'>
                                  <div className='col-md-6'>{error}</div>
                                </div>
                              </>
                            )}
                          </InputGroup>
                          <InputGroup tag='div' validate={['file']}>
                            {({ error }) => (
                              <>
                                <small>Maximum file size is 25MB</small>
                                <div className='row flex-row justify-content-around my-3'>
                                  <input
                                    size='25000000'
                                    accept='.csv,.kml, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                                    required
                                    className='file-form-control col-md-6'
                                    type='file'
                                    name='file'
                                  />
                                  <button
                                    disabled={this.state.isUploadingFile}
                                    onClick={() => {}}
                                    className='btn btn-success btn-sm'
                                  >
                                    Upload
                                  </button>
                                  {this.state.isUploadingFile && (
                                    <img
                                      src={loading}
                                      width='20%'
                                      height='25%'
                                      alt='logo'
                                      className='col-md-2 d-inline'
                                    />
                                  )}
                                </div>
                                <div className='row'>
                                  <div className='col-md-6'>{error}</div>
                                </div>
                              </>
                            )}
                          </InputGroup>
                        </div>
                      )}

                      {/* END MODAL BODY */}
                      <div className='modal-footer'>
                        {this.state.isUploaded ? (
                          <button
                            type='button'
                            className='btn btn-sm btn-success'
                            data-dismiss='modal'
                            onClick={() => {
                              this.setState({
                                isUploaded: false,
                                message: 'Error uploading',
                                isUploadingFile: false
                              })
                            }}
                          >
                            Continue
                          </button>
                        ) : (
                          <button
                            disabled={this.state.isUploadingFile}
                            type='button'
                            className='btn btn-sm btn-danger'
                            data-dismiss='modal'
                            onClick={() => {}}
                          >
                            Cancel
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Form>
        </div>
      </div>
    )
  }
}

export default UploadFarmMaps
