import _ from 'lodash';
import { combineReducers } from 'redux';
import { actions } from '../../api/api.constant';
import { EDIT_AGENT_DATA, RESET_ISLOADING } from './serviceProvider.constants';

const intialCurrentServiceProvider = {
  agencies: [],
  isLoading: false,
  links: []
};
const intialCurrentSharedAgency = {
  isLoading: false,
  agentData: {},
  agents: {},
  links: []
};
const intialServiceProvider = {
  data: {},
  links: [],
  isLoading: false
};

const serviceProviders = (state = intialServiceProvider, { type, payload }) => {
  switch (type) {
    case actions.REQUEST_SERVICE_PROVIDERS:
      return { ...state, isLoading: true };
    case actions.REQUEST_SERVICE_PROVIDERS_SUCCESS:
      return {
        isLoading: false,
        data: _.mapKeys(payload.list, 'id'),
        links: payload.links
      };
    case actions.REQUEST_SERVICE_PROVIDERS_FAILED:
    case RESET_ISLOADING:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

const currentServiceProvider = (state = intialCurrentServiceProvider, { type, payload }) => {
  switch (type) {
    case actions.REQUEST_SHARED_AGENCIES:
    case actions.REQUEST_AGENT:
      return { ...state, isLoading: true };
    case actions.REQUEST_SHARED_AGENCIES_SUCCESS:
      return {
        isLoading: false,
        agencies: _.mapKeys(payload.list, 'id'),
        links: _.mapKeys(payload.links, 'rel')
      };

    case actions.REQUEST_SHARED_AGENCIES_FAILED:
    case RESET_ISLOADING:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

const currentSharedAgency = (state = intialCurrentSharedAgency, { type, payload }) => {
  switch (type) {
    case actions.REQUEST_AGENT:
      return { ...state, isLoading: true };
    case actions.REQUEST_AGENTS_SUCCESS: 
      return {
        ...state,
        agents: _.mapKeys(payload.data, 'id')
      };
    case EDIT_AGENT_DATA:
      return { ...state, agentData: payload };
    default:
      return state;
  }
};

const agentCapturedData = (state = { postings: {}, isLoading: false }, { type, payload }) => {
  switch (type) {
    case actions.REQUEST_CAPTURED_DATA:
      return { ...state, isLoading: true };
    case actions.REQUEST_CAPTURED_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        postings: _.mapKeys(payload.list, 'id')
      };
    case EDIT_AGENT_DATA:
      return { ...state, agentData: payload };
    default:
      return state;
  }
};
const currentCapturedDataPosting = (state = { data: {}, isLoading: false }, { type, payload }) => {
  switch (type) {
    case actions.REQUEST_CURRENT_CAPTURED_DATA:
      return { ...state, isLoading: true };
    case actions.REQUEST_CURRENT_CAPTURED_DATA_SUCCESS:
      debugger;
      return {
        isLoading: false,
        data: payload
      };
    case actions.REQUEST_CURRENT_CAPTURED_DATA_FAILURE:
    default:
      return state;
  }
};

const serviceProvider = combineReducers({
  serviceProviders,
  currentServiceProvider,
  currentSharedAgency,
  agentCapturedData,
  currentCapturedDataPosting
});

export default serviceProvider;
