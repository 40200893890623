import React, { memo } from 'react';
import PropTypes from 'prop-types';

const TrashItem = memo(({ data, index, style }) => {
  const { restoreTrash, trash } = data;
  const trashItem = trash[index];
  return (
    <li style={style} className='list-group-item' data-testid='trashItem' key={trashItem.id}>
      <div className='row align-items-center px-3 py-2 mx-0 d-flex bg-list-inner rounded-pill'>
        <p className='col-sm-6 col-md-7'>{trashItem.description}</p>
        <p className='col-2'>{trashItem.itemType}</p>
        <p className='col-2'>{trashItem.createdOn}</p>
        <button
          className='col-sm-2 col-md-1 text-white btn btn-sm btn-revenue mx-xl-n3'
          onClick={restoreTrash}
          name={trashItem.id}
          data-testid='restoreTrash'
        >
          Restore
        </button>
      </div>
    </li>
  );
});

TrashItem.propTypes = {
  index: PropTypes.number.isRequired,
  style: PropTypes.object,
  data: PropTypes.shape({
    restoreTrash: PropTypes.func.isRequired,
    trash: PropTypes.object.isRequired
  })
};

export default TrashItem;
