import React from 'react'

const Unauthorised = () => {
  return (
    <div>
      <h1 className='display-1 my-5 p-5'>You Are Not Authorised To Use Portal</h1>
      <a className='btn btn-revenue' href='http://everyfarmer.farm/'>
        Sign Up to use this portal
      </a>
    </div>
  )
}

export default Unauthorised
