import React, { useState } from 'react'

const Toolbar = ({ children, onSearch, tools: Tools, ...otherProps }) => {
  const [allRowsSelected, setAllRowsSelected] = useState(false)
  const { handleSelectedRows } = otherProps

  const saveMultipleSelection = () => {
    let selectedRows = document.querySelectorAll('input.table-checkbox[type=checkbox]:checked')

    const selectedData = []
    for (var row of selectedRows) {
      selectedData.push(row.id)
    }
    return selectedData
  }

  const multiplePosting = () => {
    const postAgent = document.getElementById('postAgent')
    const multipleSelection = saveMultipleSelection()
    handleSelectedRows && handleSelectedRows(multipleSelection)

    postAgent.setAttribute('data-target', '#postAgentModal')
    postAgent.click()
    postAgent.removeAttribute('data-target', '#postAgentModal')
  }

  const selectAll = () => {
    const allCheckboxes = document.querySelectorAll('input.table-checkbox[type=checkbox]')
    if (!allRowsSelected) {
      for (let checkbox of allCheckboxes) {
        checkbox.checked = true
      }
      setAllRowsSelected(true)
    } else {
      setAllRowsSelected(false)
      for (let checkbox of allCheckboxes) {
        checkbox.checked = false
      }
    }
  }

  return (
    <div className='px-lg-3 px-md-2 text-right d-flex w-100 py-3 align-items-center'>
      <input type='checkbox' className='mx-2' id='select-all' onChange={selectAll} />
      <div className='mx-2 dropdown'>
        <button
          data-toggle='dropdown'
          id='dropdownMenuButton'
          className='btn btn-sm border dropleft custom-select'
        >
          Select action
        </button>
        <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
          <button
            onClick={multiplePosting}
            id='postAgent'
            className='dropdown-item'
            data-toggle='modal'
          >
            Post
          </button>
        </div>
      </div>
      <button className='btn btn-secondary'>Apply</button>
      <i className='fas fa-filter text-black-50 input-prepend' />
      <input
        className='form-control w-20 mr-auto'
        type='search'
        placeholder='Search'
        name='searchValue'
        onChange={onSearch}
      />
      {Tools && <Tools {...otherProps} />}
      {children}
    </div>
  )
}

export default Toolbar
