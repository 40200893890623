import React from 'react'

const BioInfo = ({ nextPage, formRef, formState: { staffData, pageNum }, handleInputChange }) => {
  return (
    <form ref={formRef} onSubmit={nextPage}>
      <>
        <div className='py-2'>
          <label htmlFor='firstName' className='d-flex'>
            First Name <span className='red'>*</span>
          </label>
          <input
            type='text'
            name='firstName'
            className={`form-control border-0`}
            onChange={handleInputChange}
            value={staffData.firstName}
          />
        </div>

        <div className='py-2'>
          <label htmlFor='lastName' className='d-flex'>
            Last Name <span className='red'>*</span>
          </label>
          <input
            type='text'
            className={`form-control border-0`}
            name='lastName'
            onChange={handleInputChange}
            value={staffData.lastName}
          />
        </div>

        <div className='row'>
          <div className='col-md-6 py-2'>
            <label htmlFor='gender' className='d-flex'>
              Gender <span className='red'>*</span>
            </label>
            <select
              name='gender'
              id='gender'
              className='custom-select border-0'
              onChange={handleInputChange}
              value={staffData.gender}
            >
              ><option value='No gender'>Select...</option>
              <option value='female'>Female</option>
              <option value='male'>Male</option>
            </select>
          </div>

          <div className='col-md-6 py-2'>
            <label htmlFor='phoneNum' className='d-flex'>
              Phone Number <span className='red'>*</span>
            </label>
            <input
              type='text'
              pattern='[0-9]{11}'
              className={`form-control border-0`}
              name='phoneNumber'
              onChange={handleInputChange}
              value={staffData.phoneNumber}
            />
          </div>
        </div>

        <div className='py-2'>
          <label htmlFor='email' className='d-flex'>
            E-mail <span className='red'>*</span>
          </label>
          <input
            type='email'
            name='email'
            className={`form-control border-0`}
            onChange={handleInputChange}
            value={staffData.email}
          />
        </div>

        <div className='py-2'>
          <label htmlFor='address' className='d-flex'>
            Contact Address <span className='red'>*</span>
          </label>
          <textarea
            name='address'
            id='address'
            rows='3'
            cols='30'
            className={`form-control border-0`}
            onChange={handleInputChange}
            value={staffData.address}
          />
        </div>

        <div className='d-flex justify-content-center align-items-baseline py-2'>
          <button disabled className='btn btn-secondary text-white'>
            Prev
          </button>
          <span className='px-md-5 px-sm-3'>{`${pageNum}/3`}</span>
          <button type='submit' className='btn btn-revenue text-white'>
            Next
          </button>
        </div>
      </>
    </form>
  )
}

export default BioInfo
