/* eslint-disable jsx-a11y/no-onchange */
import { CircularProgress } from '@material-ui/core';
import React, { useMemo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table-v6';
import MaterialTable from 'material-table';
import Spinner from '../../components/Spinner';
import Navbar from '../../../app/components/Navbar';
import apiService from '../../api/apiService';
import { getCommunityResource } from '../../pages/community/communities.actions';
import { Item } from 'semantic-ui-react';

const Reports = props => {
  const { getAllCommunityResource, communityResourceList, isLoading } = props;

  const [searchKeyword, setSearchKeyword] = useState('');
  const [communityResource, setCommunityResource] = useState('');
  const [report, setReport] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  // const [communityResourceList,setCommunityResourceList] = useState([]);

  useEffect(() => {
    getAllCommunityResource();
  }, [getAllCommunityResource]);

  const handleReportDownload = async community => {
    const reportRequest = await fetch('');

    //eslint-disable-next-line no-unused-vars
    const response = await reportRequest.blob().then(blob => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.download = '';
      a.click();
    });
  };

  const onSearch = ({ target }) => {
    const { value } = target;
    if (typeof value === 'string' && value > 2) {
      setSearchKeyword(value);
    }
  };

  const onCommunitySelection = ({ target }) => {
    const { value } = target;
    // if (typeof value === 'string') {
      setCommunityResource(value);
      // getCommunityReport();
    // }
  };

  const reportColumns = [
    {
      title: 'Communities',
      field: 'name'
    },
    {
      title: 'Total Number of Farms',
      field: 'totalFarms'
    },
    {
      title: 'Download',
      field: 'download',
      render: () => (
        <button
          className='btn btn-outline-revenue text-center'
          onClick={() => handleReportDownload(props.original)}
        >
          Download <i className='fa fa-cloud-download-alt'></i>
        </button>
      )
    }
  ];

  const getCommunityReport = async () => {
    try {
      setIsFetching(true);
      const report = await apiService.get();
      setReport(report);
    } catch (error) {
    } finally {
      setIsFetching(false);
    }
  };

  const filteredReport = useMemo(
    () => report.filter(community => community.name.includes(searchKeyword)),
    [report, searchKeyword]
  );

  return (
    <>
      <Navbar
        navLinks={[
          {
            header: 'Reports',
            path: '/reports'
          }
        ]}
        small='Download community report'
      />
      <form method="post" target="_blank" action="http://everyfarmer.live:8080/everyfarmer-dashboard-rest/FarmersPrintout"> 
      <div className='bg-white p-3'>
        <div className='d-flex align-items-center flex-wrap mx-2'>
          <span className='d-flex'>
            <label htmlFor='community-report'>
              Community
            </label>
           
            <select
              value={communityResource}
              name='communityResourceId'
              id='communityResourceId'
              className='custom-select'
              onChange={onCommunitySelection}
            >
              <option value='no community'>Select...</option>
               {
               props.communityResourceList.map(item=>
                <option value={item.id}>{item.name}</option>
              )
              }
              
            </select>
          </span>
          
        <label htmlFor='farmerId' className=' mr-2'>
              Farmer TempId:
            </label>
          <input
            id="farmerId"
            className='mb-2 form-control w-25'
            type='search'
            placeholder='Search'
            name='farmerId'
           required="true"
          />
          <button className='text-white btn btn-small btn-revenue ml-4'>
            <i className='mx-2 fa fa-cloud-download-alt'></i>
            Search
          </button>
          
        </div>
        {/* <Spinner data={communityResourceList} isLoading={isFetching}>
          <MaterialTable
            columns={reportColumns}
            data={communityResourceList}
            style={{
              fontFamily: 'Nunito',
              width: '100%',
              boxShadow: 'none'
            }}
            options={{
              actionsColumnIndex: 4,
              sorting: true,
              showTitle: false,
              loadingType: 'linear',
              headerStyle: {
                backgroundColor: '#4f4f4f',
                color: '#FFF'
              }
            }}
            components={{
              CircularProgress: props => (
                <div style={{ backgroundColor: '#4f4f4f' }}>
                  <CircularProgress {...props} />
                </div>
              )
            }}
          />
        </Spinner> */}
      </div>
      </form>
    </>
  );
};

//export default Reports;

const mapStateToProps = state => {
  return {
    isLoading: state.comReducer.isLoading,
    communityResourceList: state.comReducer.communityResourceList
  };
};

export default connect(mapStateToProps, {
  getAllCommunityResource: getCommunityResource
})(Reports);

// function mapStateToProps(state) {
//   return;
//   getCommunityResource: getAllCommunityResource(state);
// }

// export default connect(mapStateToProps)(Reports);
