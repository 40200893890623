import React, { useState } from 'react'

const Image = ({ src, fallbackSrc, width = '' }) => {
  const [isImageFailed, setIsImageFailed] = useState(false)
  const handleImgError = () => setIsImageFailed(true)

  return isImageFailed ? (
    <img src={fallbackSrc} width={width} alt='user-logo' data-testid='user-logo' />
  ) : (
    <img src={src} onError={handleImgError} width={width} alt='user-logo' data-testid='user-logo' />
  )
}

export default Image
