import React, { Component } from 'react';
import { Route, NavLink } from 'react-router-dom';

import NewCommunityMembers from './CommunityMembers';
import NewCommunityChat from '../../components/community/CommunityChat';
import NewCommunityOverview from '../../components/community/CommunityOverview';
import NewCommunitiesMap from './CommunitiesMaps';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';

//This is the COmponent that loads when you go into a Community under a Community resource

class NewCommunityFeatures extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <Navbar
          navLinks={[
            {
              path: 'community-resource/community-resource-overview',
              header: ' Community Resource |'
            },
            {
              path: '/community-resource/communities',
              header: '| Communities |'
            },
            {
              path: '#',
              header: '|Community Members'
            }
          ]}
          small='Communities under the Community Resource'
        />

        <div className='container-fluid'>
          <div className='row my-2'>
            <ul className='navbar-nav mr-auto d-flex flex-row'>
              {/* <li className='nav-item'>
                <NavLink
                  to={this.props.match.url + '/community-overview/'}
                  className='p-3'
                  exact={true}
                  activeClassName='active-nav'
                >
                  Overview
                </NavLink>
              </li> */}
              <li className='nav-item'>
                <NavLink
                  to={this.props.match.url + '/community-members/'}
                  className='p-3'
                  exact={true}
                  activeClassName='active-nav'
                >
                  Members
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink
                  to={this.props.match.url + '/community-maps/'}
                  className='p-3'
                  exact={true}
                  activeClassName='active-nav'
                >
                  Map View
                </NavLink>
              </li>
              {/* <li className='nav-item'>
                <NavLink
                  to={this.props.match.url + '/community-chat/'}
                  className='p-3'
                  exact={true}
                  activeClassName='active-nav'
                >
                  Chat
                </NavLink>
              </li> */}
            </ul>
            <div className='filter-list'>
              <form>
                <input type='text' className='form-control pl-2' placeholder='Search' />
              </form>{' '}
            </div>
          </div>

          <div>
            {/* <Route
              exact
              path='/community-features/community-overview/'
              render={() => <NewCommunityOverview />}
            /> */}
            <Route
              exact
              path='/community-features/community-members/'
              render={() => <NewCommunityMembers />}
            />
            <Route
              exact
              path='/community-features/community-maps/'
              render={() => <NewCommunitiesMap />}
            />
            {/* <Route
              exact
              path='/community-features/community-chat/'
              component={() => <NewCommunityChat />}
            /> */}
            <Footer />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default NewCommunityFeatures;
