import React from 'react'
import 'chart.js'
import { ColumnChart } from 'react-chartkick'
import { Link } from 'react-router-dom'

import mappedArea from '../../../assets/map-ActiveSize.svg'
import mappedAreaG from '../../../assets/map-ActiveSize-green.svg'
import mappedAreaR from '../../../assets/map-ActiveSize-red.svg'

const FarmSizeAnalysis = props => {
  return (
    <div className='container-fluid bg-light p-3 mt-3'>
      <div className='d-flex justify-content-between align-items-center'>
        <h3 className='text-black-50 h4'>Farm Area Analysis</h3>
        <div className='border rounded text-center py-2 px-3 d-flex flex-sm-column flex-md-row ml-md-auto mr-2'>
          <img src={mappedArea} alt='farmer' className='mr-2' />

          <div>
            <h4 className='text-dark font-weight-light'>Total Farm Size (Ha)</h4>
            <p className='font-weight-bold font-fix mb-0'>
              {props.analytics ? props.analytics.hectaresTotalInCommunity : 0}
            </p>
          </div>
        </div>
        <div className='border rounded text-center py-2 px-3 d-flex mr-2 bg-revenue text-white'>
          <img src={mappedAreaG} alt='farmer' className='mr-2' />

          <div>
            <h4 className='text-white font-weight-light'>Greater Than 0.5 Ha</h4>
            <p className='font-weight-bold font-fix mb-0'>
              {props.analytics ? props.analytics.hectaresGreaterThen : 0}
            </p>
          </div>
        </div>
        <div className='border rounded text-center py-2 px-3 d-flex mr-2 bg-expend text-white'>
          <img src={mappedAreaR} alt='farmer' className='mr-2' />

          <div>
            <h4 className='text-white font-weight-light'>Less Than 0.5 Ha</h4>
            <p className='font-weight-bold font-fix mb-0'>
              {props.analytics ? props.analytics.hectaresLessThen : 0}
            </p>
          </div>
        </div>
      </div>
      {/* Farm Area/Size analysis */}
      <div className='row mt-5'>
        <div className=' mb-sm-2 mb-0 col-md-6 col-sm-12'>
          <ColumnChart
            // data={props.analytics.farmSizeGroupByName}
            data={[
              ['Sokoto', '500'],
              ['Kebbi', '700'],
              ['Birin', '890'],
              ['Kano', '600'],
              ['Benin', '550']
            ]}
            dataset={{ backgroundColor: '#96c63d' }}
            colors={['#96c63d']}
            suffix='Ha'
            xtitle='LGA'
            ytitle='Farm size'
            height={'80%'}
          />
          <h6 className='bg-white ml-5 p-2 my-4 text-center'>
            Total Farm Size Greater Than 0.5 (Hectares)
          </h6>
        </div>
        <div className='col-md-6 col-sm-12'>
          <ColumnChart
            // data={props.analytics.farmSizeGreaterGroupByName}
            data={[
              ['Sokoto', '200'],
              ['Kebbi', '145'],
              ['Birin', '690'],
              ['Kano', '300'],
              ['Benin', '550']
            ]}
            dataset={{ backgroundColor: '#c66868' }}
            colors={['#c66868']}
            suffix='Ha'
            xtitle='LGA'
            ytitle='Farm size'
            height={'80%'}
          />
          <h6 className='bg-white ml-5 p-2 my-4 text-center'>
            Total Farm Size Less Than 0.5 (Hectares)
          </h6>
        </div>
      </div>
      <div className='greener text-right font-18 underline'>
        <Link to='#'>...View more</Link>
      </div>
    </div>
  )
}
export default FarmSizeAnalysis
