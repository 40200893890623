import * as actionsType from './community.constant';

const initialCommState = {
  CommunityResourceFarmItems: {},
  communityResourceId: '',
  communityResourceName: '',
  communityResourceMappingEnabled: false,
  isProcessed: false,
  listDetailId: '',
  communityId: '',
  selectedFile: undefined,
  farmersMembers: [],
  error: undefined,
  fileUploadError: undefined,
  communityMessages: [],
  communityOverview: undefined,
  communityName: undefined,
  allCommunities: undefined,
  isLoggin: undefined,
  switchProcessedFarmers: undefined,
  isProcessed2: undefined,
  communityResourceList: [],
  renewToken: undefined,
  analyticsName: undefined,
  analytics: [],
  smsMessages: [],
  isReset: false,
  externalFarmerList: [],
  eops: [],
  isLoading: false
};

/**
 * this method is the reducer for community reducer named community.
 */
const community = (state = initialCommState, action) => {
  switch (action.type) {
    case actionsType.COMMUNITY_RESOURCE_FARMS:
      return Object.assign({}, state, {
        // farmItemsByCommunityResource: _.mapKeys(action.payload.farmItems, 'id')
      });

    case actionsType.TOGGLE_COMMUNITY_RESOURCE_MAPPING:
      return {
        ...state,
        communityResourceMappingEnabled: action.payload.mappingStatus
      };
    case actionsType.TOGGLELIST:
      return performToogle(state, action);
    case actionsType.COMMUNITYID:
      return updateCommunityId(state, action);

    case actionsType.COMMUNITYRESOURCEID:
      return updateCommunityResourceInfo(state, action);
    case actionsType.SELECTEDFARMERSFILE:
      return performSelectedFarmersFile(state, action);
    case actionsType.FETCH_COMMUNITY_RESOURCE:
      return { ...state, isLoading: true };
    case actionsType.GETFARMERSMEMBERS:
      return updateState(state, {
        farmersMembers: action.farmersMembers
      });
    case actionsType.SETERROR:
      return { ...state, isLoading: false };
    case actionsType.UPDATEFARMERSMEMBERS:
      return updateState(state, { farmersMembers: action.newFarmersMembers });
    case actionsType.FILESELECTEDERROR:
      return updateState(state, {
        fileUploadError: undefined,
        selectedFile: undefined
      });
    case actionsType.GETALLCOMMUNITYMESSAGES:
      return updateState(state, {
        communityMessages: action.communityMessages
      });
    case actionsType.COMMUNITYOVERVIEW:
      return updateStateObject(state, 'communityOverview', action.communityoverview);
    case actionsType.COMMUNITYNAME:
      return updateState(state, {
        communityId: action.communityId,
        communityName: action.communityName,
        isProcessed: action.isProcessed
      });
    case actionsType.ISLOGGIN:
      return updateState(state, { isLoggin: action.isloggin });
    case actionsType.GETALLCOMMUNITIES:
      return updateState(state, { allCommunities: action.allCommunities });
    case actionsType.SWITCHFARMERS:
      return updateState(state, {
        communityResourceName: action.communityResourceName,
        switchProcessedFarmers: action.processedFarmer,
        isProcessed: action.isProcessed
      });
    case actionsType.ISPROCESSED:
      return updateState(state, { isProcessed: action.isProcessed });
    case actionsType.ISPROCESSED2:
      return updateState(state, { isProcessed2: action.isProcessed2 });
    case actionsType.GETCOMMUNITYRESOURCE:
      return updateState(state, {
        communityResourceList: action.allcommunityResource,
        isLoading: false
      });
    case actionsType.RENEW_TOKEN:
      return updateState(state, { renewToken: action.data });
    case actionsType.ANALYTICSNAME:
      return updateState(state, { analyticsName: action.resourceName });
    case actionsType.ANALYTICS:
      return updateState(state, { analytics: action.analytics });
    case actionsType.MEMBERSMSMESSAGES:
      return updateState(state, { smsMessages: action.smsMessages });
    case actionsType.RESETSELECTEDMEMBERS:
      return updateState(state, { isReset: action.isReset });
    case actionsType.EXTERNALFARMERLIST:
      return updateState(state, {
        isLoading: true,
        externalFarmerList: action.externalFarmerList
      });
    case actionsType.EXTERNALFARMERLISTSUCCESS:
      return updateState(state, {
        externalFarmerList: action.externalFarmerList,
        isLoading: false
      });
    case actionsType.EOPS:
      return updateState(state, { eops: action.eops });
    default:
      return state;
  }
};
/**
 * when this method is called it update List detail id based on
 * if statement condition
 * @param {community reducer state} state
 * @param {community reducer action} action
 */
const performToogle = (state, action) => {
  const newState = Object.assign({}, state);

  if (newState.listDetailId === action.id) {
    newState.listDetailId = undefined;
  } else {
    newState.listDetailId = action.id;
  }
  return updateState(state, newState);
};
/**
 * when this method is called it assign a new community Id to
 * state community Id variable.
 * @param {community reducer state} state
 * @param {community reducer action} action
 */
const updateCommunityId = (state, action) => {
  return updateState(state, { communityId: action.communityId });
};

/**
 * when this method is called it assign a new Selected Farmer File event object
 * to selectedFile state variable.
 * @param {community reducer state} state
 * @param {community reducer action} action
 */
const performSelectedFarmersFile = (state, action) => {
  if (action.event.target.files[0].name.split('.')[1] === 'csv') {
    return updateState(state, {
      selectedFile: action.event.target.files[0],
      fileUploadError: ''
    });
  }
  if (action.event.target.files[0].name.split('.')[1] !== 'csv') {
    return updateState(state, { fileUploadError: 'Invalid file extension' });
  }
};

/**
 * when this method is called it assign a new community Resource Id to
 * state communityResourceId variable.
 * @param {community reducer state} state
 * @param {community reducer action} action
 */
const updateCommunityResourceInfo = (state, action) => {
  const {
    communityResourceId,
    communityResourceName,
    communityResourceMappingEnabled,
    isProcessed
  } = action.payload;
  return {
    ...state,
    communityResourceId,
    communityResourceName,
    communityResourceMappingEnabled,
    isProcessed
  };
};

/**
 * the method update community reducer state by using oldState and newState params.
 * @param {the old state of community reducer} oldState
 * @param {the new state of community reducer} newState
 */
const updateState = (oldState, newState) => {
  return {
    ...oldState,
    ...newState
  };
};

const updateStateObject = (oldState, objectName, newState) => {
  return {
    ...oldState,
    [objectName]: newState
  };
};
// const resetSelectedMembers = (state, action) => {
//     if (action.isReset === true) {

//     }
// }

export default community;
