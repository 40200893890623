import React, { Component } from 'react'
import moreIcon from '../../../assets/3Dots-white.svg'
import complainimage from '../../../assets/complain-image.svg'
import Navbar from '../../components/Navbar'

export default class FarmersLoggedResponse extends Component {
  render() {
    return (
      <div className='container-fluid'>
        <Navbar
          navLinks={[
            {
              path: '/new-farm-resource/logged-issues/',
              header: 'Logged Issues |'
            },
            {
              path: '#',
              header: '| Agents Response to Farmer'
            }
          ]}
          small='Farmer interaction with the agent'
        />
        {/* Left Side Section*/}
        <div className='row flex-column my-3 bg-white p-3'>
          <div className='text-center'>
            <h4>Detailed Information of Issue posted by client</h4>
            <h6>Client summary on the left. Responses from the agent can be on the right</h6>
          </div>
          <div className='row justify-content-around'>
            <div className='col-lg-6 col-md-6 bg-light p-5 text-left'>
              {/* Profile Section */}
              <div className='row justify-content-between'>
                <div className='border-bottom'>
                  <h4 className='font-weight-bold my-3 text-dark h5'>Issuer</h4>
                  <p className='mb-2'>Rice Decoloration to something else</p>
                </div>
                <div className='dropdown form-align'>
                  <div className='dropdown dropleft'>
                    <div
                      className='menuIconWrapper d-flex justify-content-center align-content-center mousePointer'
                      id='dropdownMenuButton'
                      data-toggle='dropdown'
                      aria-haspopup='true'
                      aria-expanded='false'
                    >
                      <img src={moreIcon} className='cursor' alt='More Icon' />
                    </div>
                    <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                      <button className='dropdown-item'>Mark As Important</button>
                      <button className='dropdown-item border-bottom'>Archive</button>
                      <button className='dropdown-item'>Delete</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row flex-column'>
                <div className='border-bottom'>
                  <h4 className='font-weight-bold my-3 text-dark h5'>Submitted By</h4>
                  <p className='mb-2'>*****</p>
                </div>
                <div className='border-bottom'>
                  <h4 className='font-weight-bold my-3 text-dark h5'>Contact Information</h4>
                  <p className='mb-2'>extension@everyfarmer.farm</p>
                </div>
              </div>

              {/* Details Section with Recent Activity */}
              <div className='row flex-column border-bottom'>
                <h4 className='font-weight-bold my-3 text-dark h5'>
                  {/* Job Duties and Responsibilities */}
                  First Notice Period
                </h4>
                <p className='mb-2'>13/3/2019</p>
              </div>
              <div className='row flex-column border-bottom'>
                <h4 className='font-weight-bold my-3 text-dark h5'>
                  {/* Job Duties and Responsibilities */}
                  Succession
                </h4>
                <p className='mb-2'>### ###</p>
              </div>
              <div className='row flex-column py-2 border-bottom'>
                <h4 className='font-weight-bold my-3 text-dark h5'>Average Numbers Affected</h4>
                <h6 className='mb-2'>####</h6>
              </div>
              <div className='row flex-column py-2 border-bottom'>
                <h4 className='font-weight-bold my-3 text-dark h5'>Period of Incident</h4>
                <h6 className='mb-2 mr-1'>2 weeks</h6>
              </div>
              <div className='row flex-column py-2 border-bottom'>
                <h4 className='font-weight-bold my-3 text-dark h5'>Proposed day of Visit</h4>
                <h6 className='mb-2'>14/4/2019</h6>
              </div>
              <div className='row flex-column py-2 border-bottom'>
                <h4 className='font-weight-bold my-3 text-dark h5'>Description</h4>
                <p className='mb-2'>******</p>
              </div>
              <div className='row flex-column py-2 border-bottom'>
                <h4 className='font-weight-bold my-3 text-dark h5'>Uploaded Media</h4>
                <div className='row bg-white p-2'>
                  <img src={complainimage} alt='complaint' />
                  <img src={complainimage} alt='complaint' className='mx-2' />
                </div>
              </div>
              <div className='row flex-column py-2 border-bottom'>
                <h4 className='font-weight-bold my-3 text-dark h5'>Further Information</h4>
                <p className='mb-2'>******</p>
              </div>
            </div>
            {/* Left Side Section Ends */}

            {/* Right Side Starts*/}
            <div className='col-lg-5 col-md-5 bg-light p-5'>
              <div className='row justify-content-between'>
                <div className='border-bottom'>
                  <h4 className='font-weight-bold my-3 text-dark h5'>Agent</h4>
                  <p className='mb-2'>Abdulrazak Bello</p>
                </div>
                <div className='dropdown form-align'>
                  <div className='dropdown dropleft'>
                    <div
                      className='menuIconWrapper d-flex justify-content-center align-content-center mousePointer'
                      id='dropdownMenuButton'
                      data-toggle='dropdown'
                      aria-haspopup='true'
                      aria-expanded='false'
                    >
                      <img src={moreIcon} className='cursor' alt='More Icon' />
                    </div>
                    <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                      <button className='dropdown-item'>Mark As Important</button>
                      <button className='dropdown-item border-bottom' href='#'>
                        Archive
                      </button>
                      <button className='dropdown-item' href='#'>
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row flex-column'>
                <div className='border-bottom'>
                  <h4 className='font-weight-bold my-3 text-dark h5'>Message Recieved On</h4>
                  <p className='mb-2'>*****</p>
                </div>
                <div className='border-bottom'>
                  <h4 className='font-weight-bold my-3 text-dark h5'>Approved Visiting Dates</h4>
                  <p className='mb-2'>#######</p>
                </div>
              </div>
              <div className='row flex-column'>
                <div className='border-bottom'>
                  <h4 className='font-weight-bold my-3 text-dark h5'>
                    Intermittent Visits will Continue for
                  </h4>
                  <p className='mb-2'>2-4 months</p>
                </div>
                <div className='border-bottom'>
                  <h4 className='font-weight-bold my-3 text-dark h5'>Cost</h4>
                  <p className='mb-2'>&#8358;2000</p>
                </div>
              </div>
              <div className='row flex-column'>
                <div className='border-bottom'>
                  <h4 className='font-weight-bold my-3 text-dark h5'>Further Information</h4>
                  <p className='mb-2'>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                    Ipsum has been the industry's standard dummy text ever since the 1500s
                  </p>
                </div>
                <div className='border-bottom'>
                  <h4 className='font-weight-bold my-3 text-dark h5'>Contact Information</h4>
                  <p className='mb-2'>abdulrazak@email.com</p>
                </div>
              </div>
              <div className='text-right'>
                <h4 className='font-weight-bold my-3 text-secondary h5'>Next</h4>
              </div>
            </div>

            {/* Right Side Ends*/}
          </div>
        </div>
        {/* Modal */}

        {/* preview  modal ends here */}
      </div>
    )
  }
}
