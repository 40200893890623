import React, { Component } from 'react'
import { Link, NavLink } from 'react-router-dom'
import ReactTable from 'react-table-v6'
import moreIcon from '../../../../../assets/3Dots.svg'
import assetmapview from '../../../../../assets/asset-map-view.svg'

export default class NewAssets extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const adminColumn = [
      {
        id: 'row',
        maxWidth: 100,
        minWidth: 50,
        width: 50,
        headerClassName: 'table-header py-2',
        filterable: false,
        Cell: row => {
          return (
            <div>
              <input type='checkbox' />
              {row.index + 1}
            </div>
          )
        }
      },
      {
        Header: 'Name',
        accessor: 'name',
        headerClassName: 'table-header py-3'
      },
      {
        Header: 'Short Description',
        accessor: 'shortDescription',
        headerClassName: 'table-header py-3'
      },
      {
        Header: 'Long Description',
        accessor: 'longDescription',
        headerClassName: 'table-header py-3'
      },
      {
        Header: 'Created On',
        accessor: 'createdOn',
        headerClassName: 'table-header py-3'
      },
      {
        Header: () => {
          return (
            <span className='text-center'>
              <i className='fas fa-ellipsis-h py-' />
            </span>
          )
        },
        Cell: props => (
          <div className='col-3'>
            <div className='dropdown'>
              <div
                className='menuIconWrapper d-flex justify-content-center align-content-center mousePointer'
                id='dropdownMenuButton'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
              >
                <img src={moreIcon} className='' alt='More Icon' />
              </div>
              <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                <button className='dropdown-item'>Edit</button>
                <button className='dropdown-item'>Delete</button>
                <button className='dropdown-item border-top'>View</button>
              </div>
            </div>
          </div>
        ),
        filterable: false,
        headerClassName: 'table-header py-3',
        sortable: false,
        width: 50,
        maxWidth: 50,
        minWidth: 50,

        style: {
          textAlign: 'center'
        }
      }
    ]

    const cbnColumn = [
      {
        Header: 'S/N',
        id: 'row',
        maxWidth: 100,
        minWidth: 50,
        width: 50,
        filterable: false,
        Cell: row => {
          return (
            <div>
              {' '}
              <input type='checkbox' />
              {row.index + 1}
            </div>
          )
        }
      },
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Short Description',
        accessor: 'shortDescription'
      },
      {
        Header: 'Long Description',
        accessor: 'longDescription'
      },
      {
        Header: 'Created On',
        accessor: 'createdOn'
      },

      {
        Header: 'View',
        Cell: props => (
          <span className='pointer'>
            {/* <Link to="/community/communitiesMembers/" onClick={() => this.props.handleViewCommunity(props.original.id)}> */}
            <Link
              to='/community/communitiesMembers/'
              onClick={() =>
                this.props.handleCommunityIdAndName(props.original.id, props.original.name, false)
              }
            >
              <i
                data-toggle='tooltip'
                title='View community!'
                className='fa fa-eye fa'
                name='view'
                aria-hidden='true'
              />
            </Link>
          </span>
        ),
        filterable: false,
        sortable: false,
        width: 50,
        maxWidth: 50,
        minWidth: 50,
        style: {
          textAlign: 'center'
        }
      }
    ]

    // eslint-disable-next-line no-unused-vars
    const filterMethod = (filter, row, column) => {
      const id = filter.pivotId || filter.id
      return row[id] !== undefined
        ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
        : true
    }

    return (
      <React.Fragment>
        <div className='container-fluid bg-white '>
          <div className='row bg-white'>
            <div className='col-md-12 text-right d-flex w-100 py-3 align-items-center'>
              <input type='checkbox' name='' className='mx-2' />
              <select className='mr-3 custom-select form-control w-20'>
                <option value='Select actions...'>Select actions...</option>
                <option value='Nil'>Nil</option>
              </select>
              <i className='fas fa-filter text-black-50 input-prepend' />
              <input
                className='mr-auto form-control w-20'
                type='search'
                placeholder='Search'
                // onSubmit={filterResource(e)}
              />
              <Link to='/new-assets/map-view/' className='mx-2 text-revenue'>
                <img src={assetmapview} alt='Map View' className='mx-2' />
                View Assets on Map
              </Link>
              <NavLink to='/add-new-asset' className='btn btn-revenue text-white p-2'>
                <i className='fas fa-plus-circle fa-sm mx-2' />
                Add Asset
              </NavLink>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-12'>
            <ReactTable
              columns={localStorage.getItem('role') === 'ADMIN' ? adminColumn : cbnColumn}
              className='-striped -highlight'
              data={this.props.allCommunities}
              defaultPageSize={10}
              noDataText={'Loading... Please Wait'}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }
}
