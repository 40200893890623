import {
  EDIT_AGENT_DATA,
  RESET_ISLOADING,
  TOGGLE_SERVICE,
  TOGGLE_SERVICE_ACTIVATION,
  UPDATE_SERVICE_PROVIDERS
} from './serviceProvider.constants';

/**
 * Creates an `UPDATE_SERVICE_PROVIDERS` action i.e to update for a single service provider or adding a new provider
 * @param {*} providerData {object} : The service provider's data
 */
export const updateServiceProviders = providerData => ({
  type: UPDATE_SERVICE_PROVIDERS,
  payload: { providerData }
});

/**
 * Creates an `EDIT_AGENT_DATA` action i.e to update for an agent's data
 * @param {*} agentData {object} : The agent's data
 */
export const handleEditAgentData = agentData => ({
  type: EDIT_AGENT_DATA,
  payload: agentData
});

export const resetIsLoading = _ => ({
  type: RESET_ISLOADING
});

const changeServiceStatus = action => {
  return (providerId, service, status) => ({
    type: action,
    payload: { providerId, service, status }
  });
};

/**
 * Creates a `TOGGLE_SERVICE` action i.e to request for a service activation change
 * @param {string} service : The service to be activated
 * @param {number} subResourceID {Number} : The Id of the subResource that intends to active a service
 */
export const toggleService = (providerId, service, status) => {
  return {
    type: TOGGLE_SERVICE,
    payload: { providerId, service, status }
  };
};

/**
 * Creates a `TOGGLE_SERVICE_ACTIVATION` action i.e to update a service activation status
 * @param {string} service {string} : The service to be activated
 * @param {number} subResourceID {Number} : The Id of the subResource that intends to active a service
 */
export const toggleServiceActivation = changeServiceStatus(TOGGLE_SERVICE_ACTIVATION);
