import React, { Component } from 'react';
import { Form, InputGroup } from 'react-html5-form';
import Swal from 'sweetalert2';
import loading from '../../../../../assets/loader.gif';
import BaseUrl from '../../../../../security/base-url';
import template from '../../../../../assets/importtemplate.csv';

class ImportInputs extends Component {
  state = {
    isUploaded: false,
    message: '',
    imageId: -1,
    isDeleteButton: false,
    isUploadingFile: false,
    importType:''
  };

  componentDidMount() {
    this.findCommunities();
  }

  displayError = error => {
    Swal({
      title: 'Error',
      type: 'error',
      html: error,
      showCloseButton: true,
      community: ''
    });
  };

  onSelectUniqueType= e => {
    this.setState({  importType: e.target.value });
  };

  handleFormSubmit = form => {
    console.log('Submit => ' + this.state.community);
    this.setState({ isUploadingFile: true });
    const formData = new FormData(form.getRef().current);

    const requestData = {
      method: 'POST',
      body: formData,
      headers: {
        accept: 'application/json'
      }
    };


    fetch(
      BaseUrl.url_dashbord +
        '/farmresources/import_input_distribution/' + this.state.importType+'/'+
        this.props.resourceId +
        '/' +
        this.state.community,
      requestData
    )
      .then(response => response.json())
      .then(data => {
        if (data.code == 200) {
          console.log("DATA " ,data)
          const resMsg = (
            <span>
              {' '}
              <strong>Total Data Uploaded:</strong>
              {data.totalCount} <br />
              <strong>Total Input Items: </strong> {data.totalInputItems}
              <br/>
              <strong>Total Farmers Updated:</strong> {data.totalFarmers}
              <br />
            <textarea cols="12" rows="12">{data.data}</textarea>
            </span>
          );
          this.setState({
            isUploaded: true,
            message: resMsg,
            imageId: data.code
          });
          
          // this.props.onRefresh();
        } else {
          this.setState({
            isUploaded: false,
            message: 'Error uploading',
            isUploadingFile: false
          });
          this.displayError(data.message);
          this.setState({ isUploadingFile: false });
          this.setState({
            isUploaded: false,
            message: data.message
          });
        }
      })
      .catch(error => {
        this.setState({ isUploadingFile: false });
        this.setState({
          isUploaded: false,
          message: 'Upload Failed and Error occured'
        });

        this.displayError('Upload Failed and Error occured');
      });
  };

  onSelectCommunity = e => {
    this.setState({ community: e.target.value });
  };

  findCommunities = () => {
    const requestData = {
      method: 'GET',
      headers: {
        accept: 'application/json'
      }
    };

    fetch(BaseUrl.url_dashbord + '/community-resource', requestData)
      .then(res => res.json())
      .then(data => {
        this.setState({ communities: data });
        this.setState({ community: data[0].id });
      })
      .catch(error => {
        // this.displayError("Upload Failed and Error occured");
      });
  };
  downloadFile = () => {
    let a = document.createElement('a');
    a.href = { template };
    a.setAttribute('download', '');
    document.body.appendChild(a);
    a.click();
  };

  render() {
    return (
      <div>
        <div className='col-md-10'>
          <Form id='myForm' onSubmit={this.handleFormSubmit}>
            {({ error, valid, sumitted, prestine, form, submitting }) => (
              <>
                <div
                  className='modal fade'
                  id='uploadInputs'
                  tabIndex='-1'
                  role='dialog'
                  aria-labelledby='exampleModalCenterTitle'
                  aria-hidden='true'
                >
                  <div className='modal-dialog modal-dialog-centered' role='document'>
                    <div className='modal-content'>
                      <div className='modal-header'>
                        <h4>Import Input Distribution Data</h4>
                        <button
                          type='button'
                          className='close'
                          data-dismiss='modal'
                          aria-label='Close'
                        >
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      {/* BEGIN MODAL BODY */}

                      {this.state.isUploaded ? (
                        <div className='modal-body'>
                          <p className='row flex-row justify-content-around my-3'>
                            {this.state.message}
                          </p>
                        </div>
                      ) : (
                        <div className='modal-body'>
                          <InputGroup tag='div' validate={['com']}>
                            {({ error }) => (
                              <>
                                <div className='row justify-content-center my-3'>
                                  <select
                                    required
                                    className='file-form-control col-md-10 form-control'
                                    name='com'
                                    onChange={this.onSelectCommunity}
                                  >
                                    {this.state.communities
                                      ? this.state.communities.map(item => (
                                          <option key={item.id} value={item.id}>
                                            {item.name}
                                          </option>
                                        ))
                                      : ''}
                                  </select>
                                </div>
                                <div className='row'>
                                  <div className='col-md-6'>{error}</div>
                                </div>
                              </>
                            )}
                          </InputGroup>
                          <InputGroup
                            tag='div'
                            className='row justify-content-center my-3'
                            validate={['importType']}
                          >
                            {({ error, valid }) => (
                              <>
                                <div className='col-md-8'>
                                  <select
                                    required
                                    name='importType'
                                    id='importType'
                                    className={`form-control ${!valid && 'is-invalid'}`}
                                    onChange={this.onSelectUniqueType}
                                  >
                                    <option>Select Import Type</option>
                                    <option value='BVN'>BVN</option>
                                    <option value='TEMP_ID'>TEMP_ID</option>
                                    <option value='CBN_ID'>CBN_ID</option>
                                  </select>
                                </div>
                                <div className='row'>
                                  <div className='col-md-6'>{error}</div>
                                </div>
                              </>
                            )}
                          </InputGroup>
                          <InputGroup tag='div' validate={['file']}>
                            {({ error }) => (
                              <>
                                <div className='row justify-content-around'>
                                  <small>Maximum file size is 25MB</small>
                                  <small>
                                    <a href={template} className='greener cursor'>
                                      Download Import Template
                                    </a>
                                  </small>
                                </div>
                                <div className='row justify-content-around my-3'>
                                  <input
                                    size='25000000'
                                    accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                                    required
                                    className='file-form-control col-md-6'
                                    type='file'
                                    name='file'
                                  />

                                  {this.state.isUploadingFile && (
                                    <img
                                      src={loading}
                                      width='20%'
                                      height='25%'
                                      alt='logo'
                                      className='col-md-2 d-inline'
                                    />
                                  )}
                                </div>
                                <div className='row'>
                                  <div className='col-md-6'>{error}</div>
                                </div>
                              </>
                            )}
                          </InputGroup>
                        </div>
                      )}

                      {/* END MODAL BODY */}
                      <div className='modal-footer'>
                        <button
                          disabled={this.state.isUploadingFile}
                          onClick={() => {}}
                          className='btn btn-revenue text-white px-3'
                        >
                          Upload
                        </button>
                        {this.state.isUploaded ? (
                          <button
                            type='button'
                            className='btn btn-sm btn-revenue text-white'
                            data-dismiss='modal'
                            onClick={() => {
                              this.setState({
                                isUploaded: false,
                                message: 'Error uploading',
                                isUploadingFile: false
                              });
                            }}
                          >
                            Continue
                          </button>
                        ) : (
                          <button
                            disabled={this.state.isUploadingFile}
                            type='button'
                            className='btn btn-expend text-white'
                            data-dismiss='modal'
                            onClick={() => {}}
                          >
                            Cancel
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Form>
        </div>
      </div>
    );
  }
}

export default ImportInputs;

// <button
//             className="btn btn-outline-revenue  btn-sm px-3 mr-2"
//             data-toggle="modal"
//             data-target="#importFarmsModal">
//             <i className="fas fa-upload fa-sm mx-2" />
//             Import Farms
//           </button>

// {/* Import Farms Modal */}
// <div
//   className="modal fade"
//   id="importFarmsModal"
//   tabIndex={-1}
//   role="dialog"
//   aria-labelledby="exampleModalLabel"
//   aria-hidden="true">
//   <div className="modal-dialog" role="document">
//     <div className="modal-content">
//       <div className="modal-header">
//         <h4>Upload CSV file</h4>
//         <button
//           type="button"
//           className="close"
//           data-dismiss="modal"
//           aria-label="Close">
//           <span aria-hidden="true">×</span>
//         </button>
//       </div>
//       <div className="modal-body p-4">
//         <form>
//           <input
//             type="file"
//             className="form-control"
//             name="farmers-list"
//             accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
//           />
//           <div className="text-right">
//             <button
//               type="button"
//               className="btn btn-expend text-white mx-3"
//               data-dismiss="modal">
//               Close
//             </button>
//             <button
//               type="button"
//               className="btn btn-revenue text-white">
//               Save changes
//             </button>
//           </div>
//           {this.state.test.length === 0 ? (
//             <div className="d-flex justify-content-center">
//               <img src={Loader} alt="loader" className="text-center" />
//             </div>
//           ) : (
//             <div>
//               <p>Uploaded Successfully</p>
//             </div>
//           )}
//         </form>
//       </div>
//     </div>
//   </div>
// </div>
// {/* Import Farms ends here */}
