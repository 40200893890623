import React, { Component } from 'react'
import 'chart.js'
import { ColumnChart } from 'react-chartkick'
import { Link } from 'react-router-dom'

export default class FertilizerAnalysis extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data1: [
        ['B/Kebbi', 3326],
        ['Birnin Kebbi', 1522],
        ['Argungu', 1181],
        ['Augie', 932],
        ['Bunza', 824]
      ],
      data2: [
        ['Bagudu', 15],
        ['K/Besse', 30],
        ['Sakaba', 15],
        ['D/Kamba', 30]
      ],

      data3: [
        { name: 'LGA', data: { Bagudu: 16, 'K/Besse': 12, Sakaba: 10 } },
        {
          name: 'Fertlizer Blend 15',
          data: { Bagudu: 5, 'K/Besse': 3, Sakaba: 7 }
        },
        {
          name: 'Fertlizer Blend 30',
          data: { Bagudu: 3, 'K/Besse': 2, Sakaba: 4 }
        }
      ]
    }
  }

  render() {
    return (
      <div className='container-fluid bg-light p-3'>
        <h3 className='text-black-50 h4'>Fertilizer Analysis</h3>

        <div className='row mt-5'>
          <div className='col-md-6 col-sm-12'>
            <ColumnChart
              colors={['#96c63d']}
              data={this.state.data1}
              dataset={{ backgroundColor: '#96c63d' }}
              xtitle='LGA'
              ytitle='Number of farmers'
              height={'80%'}
            />
            <h6 className='bg-white ml-5 p-2 my-4 text-center'>
              Number Of Farmers To Organic Fertilizer
            </h6>
          </div>
          <div className='col-md-6 col-sm-12'>
            <ColumnChart
              data={this.state.data1}
              dataset={{ backgroundColor: '#c66868' }}
              colors={['#c66868']}
              height={'80%'}
              xtitle='LGA'
              ytitle='Number of farmers'
            />
            <h6 className='bg-white ml-5 p-2 my-4 text-center'>
              Total Farm Size greater than 0.5 Hectares
            </h6>
          </div>
        </div>
        <div className='row mt-5'>
          <div className='col-md-6'>
            <ColumnChart
              data={this.state.data3}
              colors={['#96c63d', '#0085FF', '#c4c4c4']}
              height={'80%'}
              xtitle='LGA'
              ytitle='Amount Of Fertilizer Used'
            />
            <h6 className='bg-white ml-5 p-2 my-4 text-center'>Fertilizer Consumption Per LGA</h6>
          </div>
        </div>
        <div className='greener text-right font-18 underline'>
          <Link to='#'>...View more</Link>
        </div>
      </div>
    )
  }
}
