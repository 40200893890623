import { useReducer } from 'react';
import swal from 'sweetalert2';
import BaseUrl from '../../security/base-url';
import { api } from '../api/api.config';
import apiService from '../api/apiService';

const usePostingForm = () => {
  const initialState = {
    communities: [],
    communityResources: [],
    taskGroups: [],
    // totalFarmers: 0,
    // lastCount: 0,
    assignedResource: 0,
    selectedCommunityResource: '',
    selectedTaskGroup: '',
    selectedCommunities: [],
    communityStatError: ''
  };

  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_COMMUNITY_RESOURCES':
      case 'GET_COMMUNITIES':
      case 'GET_ASSIGNED_TASKS':
      case 'SELECT_COMMUNUTIY_RESOURCE':
      case 'SELECT_COMMUNITIES':
      case 'SELECT_TASK_GROUP':
      case 'ALLOCATE_FARMERS':
      case 'GET_COMMUNITY_STATUS':
      case 'GET_COMMUNITY_STATUS_ERROR':
        return { ...state, ...{ ...action.payload } };
      case 'CLEAR_POSTING_FORM':
        return {
          ...state,
          ...{
            communities: [],
            communityResources: [],
            // lastCount: 0,
            selectedAgents: [],
            selectedCommunityResource: {},
            selectedCommunities: [],
            selectedCommunityId: 0,
            assignedResource: 0,
            selectedTaskGroup: {},
            // totalFarmers: 0
          }
        };
      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  const selectCommunityResource = e=> {
    console.log(e.target.value)
    // console.log(e.target.dataresourceidx)
    // console.log(e.target.dataset[`dataresourceidx`])
    // const idx = e.target.dataset[`data-resourceidx`];
    const { id, name } = state.communityResources[e.target.value];
    getCommunities(id);
    getAssignedTasks(id);
    dispatch({
      type: 'SELECT_COMMUNUTIY_RESOURCE',
      payload: { selectedCommunityResource: { id, name } }
    });
  };

  const selectCommunities = e => {
    console.log('e.target :', e.target.attributes['data-communityIdx'].value);
    const { dataset, checked, value } = e.target;
    console.log(dataset)
    const idx =  e.target.attributes['data-communityIdx'].value;
    console.log(idx)
    const { id } = state.communities[idx];
    const isMultiple = value === 'select-all';
    let allCommunities = Array.from(
      document.querySelectorAll('input.community[data-communityIdx]')
    ).slice(1);
    let newState = { assignedResource: 0 };

    e.persist();
    if (checked && isMultiple) {
      allCommunities.map(elem => (elem.checked = true));
      newState.selectedCommunities = [...state.communities];
    } else if (!checked && isMultiple) {
      allCommunities.forEach(elem => (elem.checked = false));
      newState.selectedCommunities = [];
    } else if (checked && !isMultiple) {
      getCommunityStats(id);
      newState.selectedCommunities = [...state.selectedCommunities, state.communities[idx]];
    } else if (!checked && !isMultiple) {
      newState.selectedCommunities = [...state.selectedCommunities.filter(elem => elem.id !== id)];
      newState.lastCount = 0;
      newState.totalFarmers = 0;
    }
    dispatch({ type: 'SELECT_COMMUNITIES', payload: newState });
  };

  const selectTaskGroup = ({ target }) => {
  
    const taskGroupId = target.attributes['data-taskGroupId'].value
    const { id, groupName } = state.taskGroups[taskGroupId];
    dispatch({
      type: 'SELECT_TASK_GROUP',
      payload: {
        selectedTaskGroup: {
          id,
          name: groupName.toLowerCase()
        }
      }
    });
  };

  const selectFarmers = e => {
    const { lastCount, selectedCommunities } = state;
    const assignedResource = Number(e.target.value);
    const [chosenCommmunity, ...otherCommunities] = selectedCommunities.reverse();
    dispatch({
      type: 'ALLOCATE_FARMERS',
      payload: {
        assignedResource,
        selectedCommunities: [
          ...otherCommunities,
          {
            ...chosenCommmunity,
            startFarmerPosition: lastCount,
            endFarmerPosition: lastCount + assignedResource
          }
        ]
      }
    });
  };

  const clearPostingForm = () => dispatch({ type: 'CLEAR_POSTING_FORM' });

  const renderCommunities = () =>
    Object.values(state.selectedCommunities).length > 0 &&
    state.selectedCommunities.map(c => `${c.name}, `);

  // const availableFarmers = state.totalFarmers - state.lastCount;

  // const farmersLeft = state.availableFarmers - state.assignedResource;

  const getCommunityStats = async communityId => {
    try {
      const { code, lastCount, totalFarmer } = await apiService.get(
        `/agents/lastfarmer/${communityId}`
      );
      dispatch({
        type: 'GET_COMMUNITY_STATUS',
        payload: {
          totalFarmers: code !== -1 ? totalFarmer : 0,
          lastCount: code !== -1 ? lastCount : 0
        }
      });
    } catch (err) {
      dispatch({
        type: 'GET_COMMUNITY_STATUS_ERROR',
        payload: {
          communityStatError:
            'Unable to retrieve the total available farmers. Select another community.'
        }
      });
    }
  };

  const getAssignedTasks = async communityId => {
    try {
      const data = await apiService.get(`/postinggroup/${communityId}`);
      dispatch({
        type: 'GET_ASSIGNED_TASKS',
        payload: { taskGroups: data.reverse() }
      });
    } catch (reason) {
      console.log(reason);
    }
  };

  const getCommunities = async communityResoureId => {
    try {
      const fetchCommunites = await apiService.get(
        `/community-resource/communities/${communityResoureId}`
      );
      dispatch({
        type: 'GET_COMMUNITIES',
        payload: {
          communities: fetchCommunites.code !== -1 ? fetchCommunites : []
        }
      });
    } catch (err) {
      console.error('getCommunities:', err);
    }
  };

  const postAgent =  postInfo => {
    try {
      console.log("POST INFO")
      console.log(JSON.stringify(postInfo));
    
      fetch(BaseUrl.url_dashbord+'/agents/postagent/',{
        method:"post",
        headers:{
          "content-type":"application/json"
        },
        body:JSON.stringify(postInfo)
      }).then(res=>res.json())
      .then(data=>{
        console.log("DATA")
        console.log(data)
        swal({
          title: 'Success!',
          text: 'New agent posted successfully!',
          type: 'success',
          animation: true
        });
      }).catch(error=>{
        console.log(error)
        swal({
          title: 'Error!',
          text: 'Error while posting details!',
          type: 'error',
          animation: true
        });
      })
       
    } catch (error) {
      console.log(error)
      swal({
        title: 'Error!',
        text: 'Error while posting details!',
        type: 'error',
        animation: true
      });
    } finally {
      clearPostingForm();
    }
  };

  return {
    state,
    dispatch,
    selectCommunityResource,
    selectCommunities,
    selectTaskGroup,
    selectFarmers,
    clearPostingForm,
    renderCommunities,
    // availableFarmers,
    // farmersLeft,
    postAgent
  };
};

export default usePostingForm;
