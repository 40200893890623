import $ from 'jquery'
import React, { Component } from 'react'
import { Form, InputGroup } from 'react-html5-form'
import Swal from 'sweetalert2'
import BaseUrl from '../../../security/base-url'
import '../../pages/community/communityStyle.css'

class CommunitiesForms extends Component {
  state = {
    updateCommunity: {
      id: undefined,
      name: '',
      shortDescription: '',
      longDescription: '',
      communityResourceId: {
        id: undefined
      },
      lga: '',
      status: '',
      createdOn: ''
    },
    communitiesList: [],
    isEditMode: false
  }

  shouldComponentUpdate(props) {
    const { valueClick } = this.props
    if (props.valueClick !== valueClick) {
      this.updateState(props.community, props.valueClick)
    }
  }

  /**
   * This method create new Community and Community
   */

  handleCommunity = form => {
    const formData = new FormData(form.getRef().current)

    if (this.state.isEditMode === true) {
      const updateCommunity = this.state.updateCommunity
      updateCommunity['name'] = formData.get('name')
      updateCommunity['shortDescription'] = formData.get('shortDescription')
      updateCommunity['longDescription'] = formData.get('longDescription')
      updateCommunity['lga'] = formData.get('lga')
      this.setState(
        { updateCommunity },
        //first step setState call back method.
        () => {
          this.setState(
            prevState => ({
              ...prevState,
              updateCommunity: {
                ...prevState.updateCommunity,
                communityResourceId: {
                  id: this.props.communityResourceId * 1
                }
              }
            }),
            //second step setState call back method.
            () => {
              let requestData = {
                method: 'PUT',
                body: JSON.stringify(this.state.updateCommunity),
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json'
                }
              }
              fetch(BaseUrl.url_dashbord + '/community-resource/community/', requestData)
                .then(response => response.json())
                .then(data => {
                  if (data.message === 'success') {
                    this.props.getAllCommunities(this.props.communityResourceId)
                    Swal({
                      title: 'Success!',
                      text: 'Community updated successfully!',
                      type: 'success',
                      animation: true
                    })
                    document.getElementById('resourceForm').reset()
                    this.resetState()
                    $('#communitiesModal').modal('hide')
                  } else {
                    Swal({
                      title: 'Error!',
                      text: 'Error occur while updating community.',
                      type: 'error',
                      animation: true
                    })
                    document.getElementById('resourceForm').reset()
                    this.resetState()
                    $('#communitiesModal').modal('hide')
                  }
                })
                .catch(error => {})
            }
          )
        }
      )
    }
    if (this.state.isEditMode === false) {
      const data = {
        name: formData.get('name'),
        shortDescription: formData.get('shortDescription'),
        longDescription: formData.get('longDescription'),
        lga: formData.get('lga')
      }
      let requestData = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
      fetch(
        BaseUrl.url_dashbord + '/community-resource/community/' + this.props.communityResourceId,
        requestData
      )
        .then(response => response.json())
        .then(data => {
          if (data.message === 'success') {
            this.props.getAllCommunities(this.props.communityResourceId)
            Swal({
              title: 'Success!',
              text: 'New community added!',
              type: 'success',
              animation: true
              // confirmButtonText: 'Cool'
            })
            document.getElementById('resourceForm').reset()
            this.resetState()
            $('#communitiesModal').modal('hide')
          } else {
            Swal({
              title: 'Error!',
              text: 'Error occur while adding new community.',
              type: 'error',
              animation: true
              // confirmButtonText: 'Cool'
            })
            document.getElementById('resourceForm').reset()
            this.resetState()
            $('#communitiesModal').modal('hide')
          }
        })
        .catch(error => {})
    }
  }
  updateState = (data, valueClick) => {
    if (valueClick > 0) {
      this.setState({ updateCommunity: data, isEditMode: true }, () => {
        // clear the previous value
        document.getElementById('resourceForm').reset()
        document.getElementById('name').value += data.name
        document.getElementById('shortDescription').value += data.shortDescription
        document.getElementById('longDescription').value += data.longDescription
        document.getElementById('lga').value += data.lga ? data.lga : ''
        document.getElementById('community_resource').click()
      })
    }
  }

  resetState = () => {
    this.setState({ isEditMode: false })
  }
  resetInputText = () => {
    if (this.state.isEditMode === false) {
      document.getElementById('resourceForm').reset()
    }
  }

  render() {
    const { isMappingEnabled } = this.props
    return (
      <div>
        {/* Button trigger modal to add community resource */}
        <button
          id='community_resource'
          type='button'
          className={
            /ADMIN/i.test(localStorage.getItem('role'))
              ? 'btn btn-revenue text-white btn-sm px-3 mr-2'
              : 'hidden'
          }
          data-toggle='modal'
          data-target='#communitiesModal'
          onClick={() => this.resetInputText()}
        >
          Add Community
        </button>
        {/* start modal body for community  */}
        <div
          className='modal fade'
          tabIndex='-1'
          id='communitiesModal'
          data-backdrop='static'
          data-keyboard='false'
          role='dialog'
          aria-labelledby='myLargeModalLabel'
          aria-hidden='true'
        >
          <div className='modal-dialog modal-dialog-centered'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h4>{this.state.isEditMode === false ? 'Add Community' : 'Edit Community'}</h4>
                <button
                  type='button'
                  className='close'
                  data-dismiss='modal'
                  aria-label='Close'
                  onClick={this.resetState}
                >
                  <span aria-hidden='true'>×</span>
                </button>
              </div>
              <div>
                <Form id='resourceForm' name='resourceForm' onSubmit={this.handleCommunity}>
                  {({ error, valid, submitting, pristine, form }) => (
                    <div className='p-2'>
                      {/* start resource name input */}
                      <InputGroup tag='div' validate={['name']}>
                        {({ error, valid }) => (
                          <div className='col-md-12 mb-2'>
                            <input
                              type='text'
                              required
                              className={`form-control form-control-sm ${!valid && 'is-invalid'}`}
                              id='name'
                              name='name'
                              placeholder='Enter community name'
                            />
                            {error && <div className='invalid-feedback'>{error}</div>}
                          </div>
                        )}
                      </InputGroup>
                      {/* end resource name input */}
                      {/* start short description input */}
                      <InputGroup tag='div' validate={['shortDescription']}>
                        {({ error, valid }) => (
                          <div className='col-md-12 mb-2'>
                            <textarea
                              className={`form-control myTextArea form-control-sm ${!valid &&
                                'is-invalid'}`}
                              required
                              id='shortDescription'
                              name='shortDescription'
                              placeholder='Enter community short description'
                              rows='2'
                            />
                            {error && <div className='invalid-feedback'>{error}</div>}
                          </div>
                        )}
                      </InputGroup>
                      {/* end short description input */}
                      {/* start resource description input */}

                      <InputGroup tag='div' validate={['longDescription']}>
                        {({ error, valid }) => (
                          <div className='col-md-12 mb-2'>
                            <textarea
                              className={`form-control myTextArea form-control-sm ${!valid &&
                                'is-invalid'}`}
                              required
                              id='longDescription'
                              name='longDescription'
                              placeholder='Enter community long description'
                              rows='3'
                            />
                            {error && <div className='invalid-feedback'>{error}</div>}
                          </div>
                        )}
                      </InputGroup>
                      {isMappingEnabled && (
                        <InputGroup tag='div' validate={['lga']}>
                          {({ error, valid }) => (
                            <div className='col-md-12'>
                              <select
                                className={`form-control form-control-sm ${!valid && 'is-invalid'}`}
                                required
                                id='lga'
                                name='lga'
                                rows='3'
                              >
                                <option value='none'>Enter community LGA</option>
                                {this.state.lga &&
                                  this.state.lga.map((lga, idx) => (
                                    <option value={lga} key={`lga${idx}`}>
                                      {lga}
                                    </option>
                                  ))}
                              </select>
                              {error && <div className='invalid-feedback'>{error}</div>}
                            </div>
                          )}
                        </InputGroup>
                      )}
                      {/* end resource description input */}
                      <br />
                      <div className='modal-footer'>
                        <button
                          type='button'
                          className='btn btn-expend text-white'
                          data-dismiss='modal'
                          onClick={this.resetState}
                        >
                          Close
                        </button>
                        <button
                          type='submit'
                          id='submitButton'
                          name='submitButton'
                          className={
                            this.state.isEditMode === false
                              ? 'btn btn-revenue text-white float-right'
                              : 'hidden'
                          }
                          disabled={pristine || submitting}
                        >
                          Add
                        </button>
                        <button
                          type='submit'
                          id='updateButton'
                          name='updateButton'
                          className={
                            this.state.isEditMode === true
                              ? 'btn btn-revenue text-white float-right'
                              : 'hidden'
                          }
                          disabled={pristine || submitting}
                        >
                          Save changes
                        </button>
                      </div>
                    </div>
                  )}
                </Form>
              </div>
            </div>
          </div>
        </div>
        {/* end modal body for community resource  */}
      </div>
    )
  }
}

export default CommunitiesForms
