import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { farmerissues } from '../../components/dataTable/columnTitles'
import DataTable from '../../components/dataTable/DataTable'
import Navbar from '../../components/Navbar'

export default class FarmerIssues extends Component {
  constructor(props) {
    super(props)
    this.state = {
      farmissue: [
        {
          id: 1,
          issue: 'Rice Decoloration Help!!',
          visiting: 14 - 10 - 2019,
          contact: 'everyfarmer@email.com',
          location: 'Yauri, Sokoto North'
        },
        {
          id: 2,
          issue: 'Cotton Withering Oo!!',
          visiting: 10 - 10 - 2019,
          contact: 'nswitch@email.com',
          location: 'Yauri, Sokoto North'
        }
      ]
    }
  }
  render() {
    const { issueId } = this.props.match.params
    return (
      <div className='container-fluid main-content'>
        <Navbar
          navLinks={[
            {
              path: '/resources/extension',
              header: `Farmer's Logged Issuses`
            }
          ]}
          small='All issues logged from this user'
        />

        <div className='row mx-0 bg-white'>
          <div className='col-sm-12 text-right d-flex w-100 py-3 align-items-center'>
            <input type='checkbox' name='' className='mx-2' />
            <select className='mr-3 custom-select form-control w-20'>
              <option value='Select actions...'>Select actions...</option>
              <option value='Nil'>Nil</option>
            </select>
            <button className='btn btn-secondary'>Apply</button>
            <i className='fas fa-filter text-black-50 input-prepend' />

            <input
              className='mr-auto form-control w-20'
              type='search'
              placeholder='Search'
              // onSubmit={filterResource(e)}
            />
            <Link to='/issues-info' className='btn btnexpend mx-2 text-revenue'>
              Next View
            </Link>
            {/* <NavLink
                to="/add-new-asset"
                className="btn btn-sm btn-revenue text-white p-2">
                <i className="fas fa-plus fa-sm mx-2" />
                <span>Add Schedule</span>
              </NavLink> */}
          </div>
          <div className='col-md-12'>
            {/* <ReactTable
                       className="-striped -highlight"
                data={this.props.allCommunities}
                defaultPageSize={10}
                noDataText={"Loading... Please Wait"}
              /> */}
            <DataTable
              columnTitle={farmerissues}
              className='-striped -highlight w-100'
              getRowData={this.getcapturedDataInfo}
              rowUrl={`issues-info/${issueId}`}
              data={this.state.farmissue}
              defaultPageSize={10}
            />
          </div>
        </div>
      </div>
    )
  }
}
