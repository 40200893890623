//generic filter method for all component
export const getFilteredData = (data, searchValue, tagId) => {
  switch (tagId) {
    case 'communityResourceTag':
      return defaultFilter(data, searchValue);
    case 'communitiesTag':
      return communitiesFilter(data, searchValue);
    case 'processesFarmerTag':
      return processedFarmerFilter(data, searchValue);
    case 'serviceProviderTag':
      return defaultFilter(data, searchValue);
    case 'farmersmembersTag':
      return farmerMembersFilter(data, searchValue);
    case 'farmersTag':
      return farmersFilter(data, searchValue);
    case 'sharedAgentsTag':
      return sharedAgentsFilter(data, searchValue);
    case 'sharedAgencyTag':
      return sharedAgencyFilter(data, searchValue);
    case 'exFarmersFilterTag':
      return exFarmersFilter(data, searchValue);
    case 'assignedTaskFilterTag':
      return assignedTaskFilter(data, searchValue);
    default:
      return defaultFilter(data, searchValue);
  }
};

//default filter using only name to filter
const defaultFilter = (data, searchValue) => {
  let filteredData = data.filter(({ name }) => {
    // console.log('name', name, searchValue);
    return name?.toLowerCase().includes(searchValue.toLowerCase());
  });
  return filteredData;
};

const exFarmersFilter = (data, searchValue) => {
  let filteredData = data.filter(data => {
    if (data.name === '.' || data.phone === '.') {
      if (
        data.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
        data.phone.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
        data.bvn.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
      ) {
        return true;
      }
    } else {
      if (
        //data.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
        // data.phone.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
        data.bvn.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
      ) {
        return true;
      }
    }
    return false;
  });
  return filteredData;
};

const sharedAgentsFilter = (data, searchValue) =>{
  
  return Object.values(data).filter(
    data =>{

    
     return  (data.surname != undefined && data.surname.toLowerCase().includes(searchValue.toLowerCase())) ||
      (data.state != undefined && data.state.toLowerCase().includes(searchValue.toLowerCase())) ||
      (data.phone != undefined && data.phone.toLowerCase().includes(searchValue.toLowerCase())) ||
      (data.status != undefined && data.status.toLowerCase().includes(searchValue.toLowerCase()))

    }
  );
}

const assignedTaskFilter = (data, searchValue) => {
  let filteredData = data.filter(data => {
    if (
      data.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
      data.community_name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
      data.number.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
      data.status.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
    ) {
      return true;
    }
    return false;
  });
  return filteredData;
};

// const exFarmersFilter = (data, searchValue) => {
//     console.log(searchValue);
//     let filteredData = data.filter(data => {
//         console.log(data);
//         if (data.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
//          data.gender.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
//          data.bvn.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1) {
//             return true;

//         }
//     });
//     return filteredData;
// }

const sharedAgencyFilter = (data, searchValue) => {
  let filteredData = data.filter(data => {
    
    if (
      data.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
      data.status.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
    ) {
      return true;
    }
    return false;
  });
  return filteredData;
};

//implement your custom search here
//communities filter
const communitiesFilter = (data, searchValue) => {
  let filteredData = data.filter(data => {
    if (
      data.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
      data.shortDescription.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
      data.longDescription.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
      data.createdOn.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
    ) {
      return true;
    }
    return false;
  });

  return filteredData;
};

//processed farmer filter
const processedFarmerFilter = (data, searchValue) => {
  let filteredData = data.filter(data => {
    if (
      !data.firstName ||
      !data.surname ||
      !data.othername ||
      !data.bvn ||
      !data.phone ||
      !data.createdOn
    ) {
      return data;
    }
    return (
      data.firstName.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
      data.surname.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
      data.othername.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
      data.bvn.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
      data.phone.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
      data.createdOn.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
    );
  });

  return filteredData;
};

const farmerMembersFilter = (data, searchValue) => {
  let filteredData = data.filter(
    data =>
      data.firstName.toLowerCase().includes(searchValue.toLowerCase()) ||
      data.surname.toLowerCase().includes(searchValue.toLowerCase()) ||
      data.type.toLowerCase().includes(searchValue.toLowerCase())
  );

  return filteredData;
};

const farmersFilter = (data, searchValue) => {
  let filteredData = data.filter(data => {
    if (!data.name || !data.type || !data.surname) {
      return data;
    }
    return (
      data.name.toLowerCase().includes(searchValue.toLowerCase()) ||
      data.surname.toLowerCase().includes(searchValue.toLowerCase()) ||
      data.type.toLowerCase().includes(searchValue.toLowerCase())
    );
  });
  return filteredData;
};
