import React, { Fragment } from 'react';
import { compose, withProps } from 'recompose';

import { withScriptjs, withGoogleMap, GoogleMap, Polygon } from 'react-google-maps';

const deriveDefaultCoords = farms => {
 
//   const firstExistingCoords = farms.find(farms => farms.coords.length > 1);
    
    const data = JSON.parse(farms[0].coords);
    
  return data[0];
};

const convertStringJSONValuesToNumbers = data => {
  var obj = [];
  data.map(datum => {
    var item = {};
    item['lat'] = parseFloat(datum.lat);
    item['lng'] = parseFloat(datum.lng);
    obj.push(item);
  });
  return obj;
};

const mapClicked = () => {
  // console.log("Map Clicked");
};

const mouseDown = evt => {
  // console.log(evt.latLng.lat());x
  // console.log(evt.latLng.lng())
}; 
   
const FarmsOnMap = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyCpxNJ4OZjOzEBggaJf_ntGCBO9CO800ko&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <h2 className='h2'>Loading map...</h2>,
    containerElement: <div style={{ height: `800px`, margin: '10px' }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(
  ({
    farms, 
    mapIdType
  }) => (
    <GoogleMap
      onClick={mapClicked}
      defaultZoom={9}
      mapTypeId='hybrid'
      defaultCenter={{
        lat: parseFloat(deriveDefaultCoords(farms).lat),
        lng: parseFloat(deriveDefaultCoords(farms).lng)
      }}
    
            center={{
         lat: parseFloat(deriveDefaultCoords(farms).lat),
        lng: parseFloat(deriveDefaultCoords(farms).lng)
    }}
    >
      {farms.map &&
        farms.map((farm, i) => {
          return (
            <Fragment key={farm.farmId}>
              <Polygon
                  
                editable={false} 
                path={convertStringJSONValuesToNumbers(JSON.parse(farm.coords))}
                options={{
                  fillColor: farm.status === 'COMPLETED' ? '#00a650' : '#FA8072',
                  fillOpacity: 0.4,
                  strokeColor: farm.status === 'COMPLETED' ? '#0099ff' : '#420D09',
                  strokeOpacity: 1,
                  strokeWeight: 4
                }}
                getPaths={() => {
                  alert('poly');
                }}
              />
            </Fragment>
          );
        })}
    </GoogleMap>
  )
);

export default FarmsOnMap;
