import React, { Component } from 'react';
import Swal from 'sweetalert2';
import InfiniteScroll from 'react-infinite-scroll-component';
import Navbar from '../../components/Navbar';
import Toolbar from '../../components/Toolbar';
import Spinner from '../../components/Spinner';
import BASEURL from '../../../security/base-url';
import AssignedTaskForm from '../../components/serviceProvider/assignedTaskList/AssignedTaskForm';
import TaskSummaryItem from '../../components/serviceProvider/assignedTaskList/TaskSummaryItem';
import TaskSummaryTools from '../../components/serviceProvider/assignedTaskList/TaskSummary-Tools';
import apiService from '../../api/apiService';

export default class AssignedTaskSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assignedTasks: [],
      hasUpdated: false,
      isLoading: false,
      // postedAgentList: [],
      agentLength: 0
    };
  }

  componentDidMount() {
    this.getPostingList();
  }

  createAssignedTask = async () => {
    let newTaskForce = {
      communityResourceId: document.getElementById('community').value,
      costPerFarmer: document.getElementById('cost').value,
      groupName: document.getElementById('taskname').value,
      groupDescription: document.getElementById('description').value,
      services: document.getElementById('service').value
    };
    try {
      const createTaskRes = await apiService.post('/postinggroup/', newTaskForce);
      if (createTaskRes.message === 'success') {
        Swal({
          title: 'Success!',
          text: 'New season created successfully!',
          type: 'success',
          animation: true
        });
        this.getPostingList();
      } else {
        Swal({
          title: 'Error!',
          text: 'Error while creating season!',
          type: 'error',
          animation: true
        });
      }
    } catch (error) {
      Swal({
        title: 'Error!',
        text: 'Error while creating season!',
        type: 'error',
        animation: true
      });
    } finally {
      document.getElementById('assignedTaskId').reset();
      document.getElementById('assignedTaskClose').click();
    }
  };

  userAccountId = localStorage.getItem('userAccountId');
  getPostingList() {
    this.setState({ isLoading: true });
    fetch(BASEURL.url_dashbord + '/postinggroup/findall/' + this.userAccountId, {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    })
      .then(response => response.json())
      .then(posted => {
        // this.setState({
        //   // postedAgentList: posted,
        //   /* TODO: remove if not in use */
        //   // agentLength: posted.length
        // })
        console.log("posted")
        console.log(posted)

        this.setState({
          assignedTasks: posted,
          agentSize: 0,
          isLoading: false
        });
         
        
      })
      .catch(err => {
        this.setState({ isLoading: false });
      });
  }

  fetchMoreData = () => {
    // a fake async api call like which sends
    // 20 more records in 1.5 secs
    setTimeout(() => {
      this.setState({
        assignedTasks: this.assignedTasks.concat([])
      });
    }, 1500);
  };

  handleUpdateForm = task => {
    this.setState({ hasUpdated: true });
    let editBtn = document.getElementById('editAssignedTask');
    editBtn.setAttribute('data-target', '#assignedTaskModal');
    document.getElementById('taskname').value = task.name;
    editBtn.click();
  };

  resetUpdateState = () => {
    document.getElementById('assignedTaskClose').click();
    this.setState({ hasUpdated: false });
    document.getElementById('taskname').value = '';
  };

  render() {
    const { assignedTasks, isLoading } = this.state;
    return (
      <>
        <Navbar
          navLinks={[
            {
              path: `/new-service-provider-resource/#`,
              header: 'Service Provider |'
            },
            {
              path: `#`,
              header: `| Shared Agency |`
            },
            {
              path: `#`,
              header: `| Assigned Tasks`
            }
          ]}
          small='View and manage service provider'
        />

        <div className='bg-white mt-1 px-3 py-2'>
          <Toolbar tools={TaskSummaryTools} />
          <Spinner isLoading={isLoading} data={assignedTasks}>
            <div className='w-100 fix-height revenue-scrollbar p-2'>
              <InfiniteScroll
                dataLength={assignedTasks.length} //This is important field to render the next data
                next={this.fetchMoreData}
                hasMore={true}
                loader={<h4>Loading...</h4>}
                endMessage={
                  <p style={{ textAlign: 'center' }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                }
              >
                {this.state.assignedTasks.map((task, i) => (
                  <TaskSummaryItem
                    {...this.props}
                    key={task.name + i}
                    handleUpdateForm={this.handleUpdateForm}
                    taskInfo={task}
                  />
                ))}
              </InfiniteScroll>
            </div>
          </Spinner>
        </div>
        <AssignedTaskForm
          hasUpdated={this.state.hasUpdated}
          resetUpdateState={this.resetUpdateState}
          createAssignedTask={this.createAssignedTask}
          onGetPosting={() => this.getPostingList()}
        />
      </>
    );
  }
}
