import React from 'react';
import { withRouter } from 'react-router-dom';

import InputDashlet from './InputDashlet';
import DashletSkeleton from '../../components/skeleton/DashletSkeleton';

const CommunityResourceOverview = props => {
  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-md-12'>
          {props.resource ? (
            <div className='card shadow'>
              {' '}
              <div className='bg-secondary p-2'>
                <p className='font-18 font-weight-bold mb-0'>{props.resource.name}</p>
              </div>
              <div className='p-3'>
                <p>
                  <strong className='mr-2'>Description:</strong>
                  {props.resource.descritpion}
                </p>
                <p>
                  <strong className='mr-2'>Season:</strong>
                  {props.resource.season}
                </p>
                <p>
                  <strong className='mr-2'>Farmers:</strong>
                  {props.resource.farmers}
                </p>
                <p>
                  <strong className='mr-2'>Created On:</strong>
                  {props.resource.date}
                </p>
              </div>
            </div>
          ) : (
            <DashletSkeleton />
          )}
        </div>
      </div>
      <div className='row my-4'>
        <div className='col-md-12'>
          <div className='card'>
            <div className='bg-secondary p-2 d-flex justify-content-between'>
              <p className='font-18 font-weight-bold mb-0'>Economies of Production</p>
              <div>
                {/* <Link
                  to='/community-resource/settings/'
                  className='btn btn-revenue text-white'
                >
                  Add/Edit Input
                </Link> */}
              </div>
            </div>
            {props.eops ? (
              <div className='d-flex flex-row flex-wrap p-2'>
                <InputDashlet eops={props.eops} handleDeleteEOP={props.handleDeleteEOP} />
              </div>
            ) : (
              <div className='d-flex justify-content-center flex-row flex-wrap p-4'>
                <i className='fas fa-folder-open fa-2x text-muted'></i>
                <h5 className='font-weight-normal w-75 text-center'>
                  You haven't added any EOP yet. Goto Settings and add them to reflect here
                </h5>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default withRouter(CommunityResourceOverview);
