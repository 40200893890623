import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import alerts from './app/api/api.reducer';
import auth from './app/app.reducer';
import centralDashboard from './app/pages/centralDashboard/centralDashboard.reducer';
import community from './app/pages/community/community.reducer';
import farmResource from './app/pages/farm/farm.reducer';
import { REQUEST_ASSET } from './app/pages/login/login.constant';
import assets from './app/pages/login/login.reducer';
import serviceProvider from './app/pages/serviceProvider/serviceProvider.reducer';
import trash from './app/pages/trash/trash.reducer';

//Add your Reducer Here
const appReducer = combineReducers({
  auth,
  alerts,
  assets,
  centralDashboard,
  comReducer: community,
  farmResource,
  serviceProviderResource: serviceProvider,
  trash
});

const rootReducer = (state, action) => {
  if (action.type === REQUEST_ASSET) {
    // for all keys defined in your persistConfig(s)
    storage.removeItem('persist:root');
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
