import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import error from '../../../assets/404-error.svg';

const ErrorPage = memo(props => (
  <div className='container-fluid'>
    <div className='container'>
      <div className='row p-3'>
        <NavLink to='/' data-error-button className='btn btn-revenue '>
          {' '}
          <i className='fa fa-home' aria-hidden='true' /> Go Back Home
        </NavLink>
        <h3 data-error-text className='mx-5'>
          The page {props.location.pathname} doesn't exist.
        </h3>
      </div>
      <section data-description='error-message' data-error-message className='text-center'>
        <img src={error} alt='error' width='60%' />
        <h1>404 Page Not Found</h1>
      </section>
    </div>
  </div>
));
export default ErrorPage;
