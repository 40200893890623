import PropTypes from 'prop-types'
import React from 'react'
import Navbar from '../../components/Navbar'

const AddJobPosting = props => {
  const {
    match: {
      params: { sharedAgencyId, serviceProviderId }
    },
    location: { pathname }
  } = props
  return (
    <div>
      <Navbar
        navLinks={[
          {
            header: `| Shared Agency |`,
            path: `/${serviceProviderId}/new-shared-agency/${sharedAgencyId}`
          },
          {
            header: `| Job Postings |`,
            path: `/${serviceProviderId}/new-shared-agency/${sharedAgencyId}/JobPosting`
          },
          {
            header: '| Create Job Advert',
            path: `${pathname}`
          }
        ]}
        small='Add new job details here'
      />
      <div className='d-flex flex-column align-items-center my-3'>
        <h4>
          Boxes with ' <span className='red'>*</span> ' must be filled for form to submit
        </h4>
        <p>Progress is automatically saved</p>
      </div>
      <div className='d-flex justify-content-center bg-light'>
        <form className='w-40 my-4'>
          <div className='form-group'>
            <label htmlFor='job-title'>
              Job Title <span className='red'>*</span>
            </label>
            <input type='text' className='form-control' id='job-title' required />
          </div>
          <div className='form-group'>
            <label htmlFor='jobsSchedule'>
              Job Schedule <span className='red'>*</span>
            </label>
            <div className='row'>
              <div className='col-sm-6'>
                <div className='d-flex align-items-center'>
                  <input
                    className='w-auto mr-2 custom-radio'
                    type='radio'
                    name='fulltime'
                    required
                  />
                  <label htmlFor='fullTime'>Full Time </label>
                </div>
                <div className='d-flex align-items-center'>
                  <input className='w-auto mr-2  custom-radio' type='radio' name='parttime' />
                  <label htmlFor='parttime'>Part-Time </label>
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='d-flex align-items-center'>
                  <input className='w-auto mr-2 custom-radio' type='radio' name='remote' />
                  <label htmlFor='remote'>Remote </label>
                </div>
                <div className='d-flex align-items-center'>
                  <input className='w-auto mr-2 custom-radio' type='radio' />
                  <label htmlFor='onsite'>Onsite </label>
                </div>
              </div>
            </div>
          </div>
          <div className='form-group'>
            <label htmlFor='hoursPerWeek'>
              Hours Per Week (For Part-Time Only)<span className='red'>*</span>
            </label>
            <input type='text' className='form-control' required />
          </div>
          <div className='form-group'>
            <label htmlFor='duties'>Duties & Responsibilites</label>
            <textarea id='supplierdescription' className='form-control' rows='4' />
          </div>
          <div className='row py-3'>
            <div className='col-md-6'>
              <label htmlFor='joblocation'>Job Location</label>
              <select name='joblocation' className='form-control'>
                <option value='sokoto'>Sokoto</option>
              </select>
            </div>
            <div className='col-md-6'>
              <label htmlFor='noofemployee'>
                Employees Needed
                <span className='red'>*</span>
              </label>
              <select name='noofemployee' className='form-control'>
                <option value='1'>1</option>
              </select>
            </div>
          </div>
          <div className='row py-3'>
            <div className='col-sm-6 w-100'>
              <label className='w-sm-100' htmlFor='experience'>
                Experience
              </label>
              <select name='experience' className='form-control'>
                <option value='1-2'>1-2 years</option>
                <option value='3-4'>3-4 years</option>
                <option value='5+'>5 years and more</option>
              </select>
            </div>
            <div className='col-sm-6'>
              <label htmlFor='closingdate'>Closing Date</label>
              <div className='d-flex form-row'>
                <select name='date' id='' className='form-control w-25'>
                  <option value='DD'>DD</option>
                  <option value='1'>1</option>
                </select>
                <select name='month' className='form-control w-25 ml-3'>
                  <option value='MM'>MM</option>
                  <option value='jan'>January</option>
                  <option value='feb'>February</option>
                  <option value='mar'>March</option>
                  <option value='apr'>April</option>
                  <option value='may'>May</option>
                  <option value='june'>June</option>
                  <option value='july'>July</option>
                  <option value='aug'>August</option>
                  <option value='sept'>September</option>
                  <option value='oct'>October</option>
                  <option value='nov'>November</option>
                  <option value='dec'>December</option>
                </select>
                <select name='year' className='form-control ml-3 w-25'>
                  <option className='form-control' value='YY'>
                    YY
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div className='form-group' />
          <div className='form-group' />
          <div className='text-center'>
            <button type='submit' className='btn btn-expend text-white mr-3 '>
              Cancel
            </button>
            <button type='submit' className='btn btn-revenue text-white'>
              Publish
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

AddJobPosting.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default AddJobPosting
