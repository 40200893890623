import React, { Component } from 'react'
import erg from '../../../assets/erg-logo.PNG'
import rifan from '../../../assets/rifan.PNG'
import unity from '../../../assets/unity-logo.jpg'
import BaseUrl from '../../../security/base-url'

export default class FarmersDetailsQR extends Component {
  state = {
    id: 0,
    farmersList: [],
    listItems: ''
  }

  componentDidMount() {
    this.getFarmersList()
  }

  getFarmersList() {
    fetch(
      // BaseUrl.url_dashbord + "/farmers/farmersbycommunity/" + this.props.match.params.id, {
      BaseUrl.url_dashbord + '/farmers/farmersbycommunity/' + 11,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json'
        }
      }
    )
      .then(response => response.json())
      .then(res => {
        this.setState({
          farmersList: res
        })
        console.log(res)
      })
  }

  render() {
    return (
      <div className='container'>
        <h3>Farmer's Details</h3>

        {this.state.farmersList
          ? this.state.farmersList.map(farmer => {
              return (
                <div className='row justify-content-center'>
                  <div className='col-lg-4 col-md-6 col-sm-12'>
                    <div className='card p-2'>
                      <img src={rifan} className='mx-auto' width='30%' alt='provider profile' />
                      <img
                        src={farmer.profileImage}
                        className='turn mx-auto my-3'
                        width='50%'
                        alt='profile'
                      />
                      <div className='row card-body'>
                        <div className='col-12'>
                          <p>
                            Name: {farmer.firstName} {farmer.surname}
                          </p>
                          <p>Reg No: {farmer.rifanNumber}</p>
                          <p>City: {farmer.ward}</p>
                          <p>LGA: Kebbe</p>
                          <p>State: Sokoto</p>
                        </div>
                      </div>

                      <div className='d-flex flex-column justify-content-center'>
                        <img
                          src={`data:image/jpeg;base64,${farmer.qrimage}`}
                          className='mx-auto'
                          width='76px'
                          height='76px'
                          alt='profile'
                        />
                      </div>
                      <div className='d-flex justify-content-between my-1'>
                        <img src={unity} alt='unity' className='mx-auto' width='20%' />
                        <img src={erg} alt='erg' className='mx-auto' width='20%' />
                      </div>
                      <small>
                        Property of Rice Farmers Association of Nigeria. If found, please return to
                        RIFAN House, No. 24 Ekoro-Oruro Street, Off Osun Cresent, Maitama, Abuja OR
                        CALL: 08080122000{' '}
                      </small>
                    </div>
                  </div>
                </div>

                //             <div className="row">
                //                 <div className="col-lg-4 col-md-6 col-sm-12">
                //                     <div className="card border">
                //                         <img src={farmer.profileImage} className="card-img-top" alt="profile-image" />
                //                         <h3 className="card-header bg-dark text-white">{farmer.firstName} {farmer.surname}</h3>
                //                         <div className="row card-body">
                //                             <div className="col-8">

                //                             </div>
                //                             <div className="col-4">
                //                                 <img className="w-75" src={`data:image/jpeg;base64,${farmer.qrimage}`} />
                //                                 {/* <img src={qr} className="card-img-top" alt="profile-image" /> */}
                //                             </div>
                //                             {/* <div className="my-1">
                //                                 <img
                //                                     src={cardbrands}
                //                                     className="card-img-top"
                //                                     alt="profile-image"
                //                                 />
                //                             </div> */}
                //                             {/* <small>
                //                                 Property of Rice Farmers Association of Nigeria. If found,
                //                                 please return to RIFAN House, No. 24 Ekoro-Oruro Street, Off
                //   Osun Cresent, Maitama, Abuja OR CALL: 08080122000{" "}
                //                             </small> */}
                //                         </div>
                //                     </div>
                //                 </div> <br />

                //             </div>
              )
            })
          : ''}
      </div>
      // <div className="container-fluid">
      //     <div className="panel panel-body">
      //         {listItems}
      //     </div>

      // </div>
    )
  }
}
