//List of Action Types for Comunity
export const TOGGLELIST = 'TOGGLELIST';
export const COMMUNITYID = 'COMMUNITYID';
export const COMMUNITYRESOURCEID = 'COMMUNITYRESOURCEID';
export const SELECTEDFARMERSFILE = 'SELECTEDFARMERSFILE';
export const GETFARMERSMEMBERS = 'GETFARMERSMEMBERS';
export const SETERROR = 'SETERROR';
export const SETSUCCESS = 'SETSUCCESS';
export const RESETERROR = 'RESETERROR';
export const FILESELECTEDERROR = 'FILESELECTEDERROR';
export const FILTERFARSMERMEMBERS = 'FILTERFARSMERMEMBERS';
export const GETALLCOMMUNITYMESSAGES = 'GETALLCOMMUNITYMESSAGES';
export const COMMUNITYOVERVIEW = 'COMMUNITYOVERVIEW';
export const COMMUNITYNAME = 'COMMUNITYNAME';
export const ISLOGGIN = 'ISLOGGIN';
export const GETALLCOMMUNITIES = 'GETALLCOMMUNITIES';
export const SWITCHFARMERS = 'SWITCHFARMERS';
export const ISPROCESSED = 'ISPROCESSED';
export const ISPROCESSED2 = 'ISPROCESSED2';
export const GETCOMMUNITYRESOURCE = 'GETCOMMUNITYRESOURCE';
export const UPDATEFARMERSMEMBERS = 'UPDATEFARMERSMEMBERS';
export const MEMBERSMSMESSAGES = 'MEMBERSMSMESSAGES';
export const RESETSELECTEDMEMBERS = 'RESETSELECTEDMEMBERS';
export const EXTERNALFARMERLIST = 'EXTERNALFARMERLIST';
export const EXTERNALFARMERLISTSUCCESS = 'EXTERNALFARMERLISTSUCCESS';
export const RENEW_TOKEN = 'RENEW_TOKEN';
export const ANALYTICSNAME = 'ANALYTICSNAME';
export const ANALYTICS = 'ANALYTICS';
export const EOPS = 'EOPS';
export const FETCH_COMMUNITY_RESOURCE = 'FETCH_COMMUNITY_RESOURCE';
export const COMMUNITY_RESOURCE_FARMS = 'COMMUNITY_RESOURCE_FARMS';
export const TOGGLE_COMMUNITY_RESOURCE_MAPPING = 'TOGGLE_COMMUNITY_RESOURCE_MAPPING';
