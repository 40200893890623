import * as ACTION_TYPES from './farm.constant'
import _ from 'lodash'

const initialFarmResourceState = {
  selectedFarmResource: '',
  selectedFarmItemId: '',
  farmItems: {},
  farmItemsByCommunityResource: {},
  message: '',
  isLoading: false
}
const farmResource = (state = initialFarmResourceState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SELECT_FARM_RESOURCE:
      return Object.assign({}, state, {
        selectedFarmResource: action.selectedFarmResource
      })
    case ACTION_TYPES.SELECT_FARM_ITEM:
      return Object.assign({}, state, {
        selectedFarmItemId: action.selectedFarmItemId
      })
    case ACTION_TYPES.FETCH_FARMS:
      return { ...state, isLoading: true }
    case ACTION_TYPES.FETCH_FARMS_SUCCESS:
      return Object.assign({}, state, {
        farmItems: _.mapKeys(action.farmItems, 'id'),
        isLoading: false
      })
    case ACTION_TYPES.FETCH_FARMS_ERROR:
      return Object.assign({}, state, {
        isLoading: false
      })
    case ACTION_TYPES.FETCH_FARMS_BY_COMMUNITY_RESOURCE:
      return Object.assign({}, state, {
        farmItemsByCommunityResource: _.mapKeys(action.farmItems, 'id'),
        
      })
    case ACTION_TYPES.UPDATE_FARM_FEATURE:
      return Object.assign({}, state, {
        farmItems: action.farmItems
      })
    case ACTION_TYPES.UPDATE_FARM_FEATURE_ERROR:
      return Object.assign({}, state, {
        message: action.message
      })
    case ACTION_TYPES.DELETE_FARM_ITEM:
      return Object.assign({}, state, {
        farmItemId: action.farmItemId
      })

    case ACTION_TYPES.DELETE_FARM_ITEM_ERROR:
      return Object.assign({}, state, {
        message: action.message
      })

    default:
      return state
  }
}

export default farmResource
