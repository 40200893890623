export const RESTORE_TRASH = 'RESTORE_TRASH'
export const DELETE_TRASH = 'DELETE_TRASH'

/**
 * Create an `DELETE_TRASH` action i.e to update for a single service provider or adding a new provider
 * @param {*} agentData {object}
 */
export const deleteTrashItem = trashId => ({
  type: DELETE_TRASH,
  payload: trashId
})

export const restoreTrashItem = ({ target }) => ({
  type: RESTORE_TRASH,
  payload: target.name
})
