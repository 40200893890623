import React, { useState, useEffect } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import MaterialTable from 'material-table';
import ResourceListSkeleton from '../skeleton/ResourceListSkeleton';
import { recoveryColumns } from '../../utils/tablecolumns';
import BaseUrl from '../../../security/base-url';

const InputDetails = props => {
  const [inputDetails, setInputDetails] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    fetch(
      BaseUrl.url_dashbord + '/input/issues_details/' + props.match.params.inputId,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json'
        }
      },
      { signal: signal }
    )
      .then(response => response.json())
      .then(response => {
        setInputDetails(response.data);
      })
      .catch(error => {
        console.log(error);
      });
    return () => {
      abortController.abort();
    };
  }, []);

  // Found on Community
  let inputsColumns = [
    {
      title: 'Input Name',
      field: 'farmerName'
    },
    {
      title: 'Quantity Allocated',
      field: 'qtyAllocated'
    },
    {
      title: 'Quantity Collected',
      field: 'qtyCollected'
    }
  ];

  return (
    <>
      <div className='row my-3'>
        <div className='col-md-12'>
          <div className='card'>
            <div className='bg-secondary p-2 d-flex flex-column justify-content-between'>
              <p className='font-18 font-weight-bold my-1'>
                Summary of {props.location.state.inputs.inputName} Input Distribution Details
              </p>
              <div>
                {inputDetails ? (
                  <MaterialTable
                    // onRowClick={(_, inputs) =>
                    //   props.history.push(`/community-resource/inputs/inputsdetails/${inputs.id}`)
                    // }
                    columns={inputsColumns}
                    data={inputDetails}
                    style={{
                      fontFamily: 'Nunito',
                      width: '100%',
                      boxShadow: 'none'
                    }}
                    options={{
                      actionsColumnIndex: 4,
                      sorting: true,
                      showTitle: false,
                      exportButton: true,
                      searchFieldAlignment: 'left',
                      loadingType: 'linear',
                      headerStyle: {
                        backgroundColor: '#4f4f4f',
                        color: '#FFF'
                      }
                    }}
                    components={{
                      CircularProgress: props => (
                        <div style={{ backgroundColor: '#4f4f4f' }}>
                          <CircularProgress {...props} />
                        </div>
                      )
                    }}
                  />
                ) : (
                  <ResourceListSkeleton />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

//export default Reports;

// const mapStateToProps = state => {
//   return {
//     isLoading: state.comReducer.isLoading,
//     communityResourceList: state.comReducer.communityResourceList
//   };
// };

export default withRouter(InputDetails);

// function mapStateToProps(state) {
//   return;
//   getCommunityResource: getAllCommunityResource(state);
// }

// export default connect(mapStateToProps)(Reports);
