import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { api } from '../../api/api.config';
import FarmersInCommunity from '../../components/community/FarmersInCommunity';
import FarmersInfo from '../../components/community/FarmersInfo';
import Navbar from '../../components/Navbar';
import Spinner from '../../components/Spinner';
import { extractResourceLink } from '../../utils/extractResourceLink';
import { getAgentPostings } from './serviceProvider.selectors';

class CapturedDataDetails extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired,
    posting: PropTypes.object.isRequired
  };
  constructor(props) {
    super(props);
    this.state = {
      capturedDataDetails: [],
      farmerInfo: {},
      isLoading: false,
      pageNum: 1,
      pageLimit: 300,
      totalPages: 1,
      batchData: []
    };
  }

  componentDidMount() {
    const { pageNum, pageLimit } = this.state;
    this.setState({ isLoading: true });
    try {
      this.getBatchData(pageNum - 1, pageLimit - 1);
    } catch (reason) {
      // eslint-disable-next-line no-console
      console.error(reason);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  getBatchData = async start => {
    const { posting } = this.props;
    const { href } = extractResourceLink(posting.links, 'data-capture');
    const { data, status } = await api.get(href);

    if (status === 200) {
      const farms = JSON.parse(data.list);
      const capturedDataDetails = farms.map(farm => {
        farm.coords = JSON.parse(farm.coords);
        farm.prints = JSON.parse(farm.prints);
        farm.nextOfKin = farm.nextOfKin ? JSON.parse(farm.nextOfKin) : {};
        farm.agents = !Array.isArray(farm.agents) ? JSON.parse(farm.agents) : farm.agents;
        return farm;
      });
      this.setState(prevState => {
        const newCapturedData = [...prevState.capturedDataDetails, ...capturedDataDetails];
        return {
          capturedDataDetails: newCapturedData,
          batchData: newCapturedData.slice(start),
          totalPages: Math.ceil(data.totalProcessed / prevState.pageLimit)
        };
      });
    } else {
      throw new Error(data.message);
    }
  };

  handleViewFarmerData = farmer => () => {
    if (this.state.farmerInfo.bvn === farmer.bvn) return;
    else {
      this.setState({ farmerInfo: {} });
      setTimeout(() => {
        this.setState({ farmerInfo: farmer });
      }, 300);
    }
  };

  updateMapView = isNextPage => () => {
    const { capturedDataDetails, totalFarms, totalPages, pageLimit, pageNum } = this.state;
    const page =
      isNextPage === 1 && pageNum < totalPages
        ? pageNum + 1
        : isNextPage === -1 && pageNum > 1
        ? pageNum - 1
        : pageNum;
    const num = pageLimit * page;
    const start = num - pageLimit;
    const stop = num > totalFarms ? totalFarms : num;

    if (isNextPage === -1) {
      this.setState({
        batchData: capturedDataDetails.slice(start, stop),
        pageNum: page
      });
      return;
    }
    this.getBatchData(start, stop - 1);
    this.setState({ pageNum: page });
  };

  render() {
    const { batchData, capturedDataDetails, pageNum, totalPages, farmerInfo } = this.state;

    return (
      <>
        <Navbar
          navLinks={[
            {
              path: `/3/new-shared-agency/2`,
              header: `Shared Agency |`
            },
            {
              path: `/3/new-shared-agency/${this.props.match.params.agentId}/captured-data`,
              header: '| Assigned Task |'
            },
            {
              path: `#`,
              header: '| Captured Data'
            }
          ]}
          small='Review of Captured Data from Agent'
        />
        <div className='bg-white mx-n2'>
          <div className='d-flex justify-content-end align-items-baseline p-3'>
            <button
              className='btn btn-light text-black-50'
              onClick={this.updateMapView(-1)}
              disabled={pageNum === 1 ? true : false}
            >
              <i className='fa fa-arrow-left font-weight-bold' />
            </button>
            <span className='h6 mx-2'>{`${pageNum}/${totalPages}`}</span>
            <button
              onClick={this.updateMapView(1)}
              disabled={pageNum === totalPages ? true : false}
              className='btn btn-light text-black-50'
            >
              <i className='fa fa-arrow-right font-weight-bold' />
            </button>
          </div>
          <div className='d-flex py-3 flex-wrap'>
            <Spinner isLoading={this.state.isLoading} data={capturedDataDetails}>
              <div className='col-md-8'>
                <FarmersInCommunity farms={batchData} viewFarmer={this.handleViewFarmerData} />
              </div>
              <FarmersInfo farmerInfo={farmerInfo} />
            </Spinner>
          </div>
        </div>
      </>
    );
  }
}

export default connect((state, props) => ({
  posting: getAgentPostings(state)[props.match.params.communityId]
}))(CapturedDataDetails);
