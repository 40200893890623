import React from 'react'
const { compose, withStateHandlers } = require('recompose')
const { InfoBox } = require('react-google-maps/lib/components/addons/InfoBox')
const { Marker } = require('react-google-maps')

const MarkerWithInfoBox = compose(
  withStateHandlers(
    () => ({
      isInfoBoxOpen: false
    }),
    {
      onToggleOpen: ({ isInfoBoxOpen }) => () => ({
        isInfoBoxOpen: !isInfoBoxOpen
      })
    }
  )
)(props => {
  
  var farm = props.farms[props.marker.id]
  return (
    props.marker.point && (
      <Marker
        onClick={props.onToggleOpen}
        key={props.marker.id}
        position={{ lat: parseFloat (props.marker.point.lat), lng: parseFloat(props.marker.point.lng )}}
      >
        {props.isInfoBoxOpen && (
          <InfoBox
            position={{
              lat: props.marker.point.lat,
              lng: props.marker.point.lng
            }}
            onCloseClick={props.onToggleOpen}
            options={{ closeBoxURL: ``, enableEventPropagation: true }}
          >
            <div
              style={{
                backgroundColor: `#404040`,
                height: '146px',
                width: '160px',
                border: '0.1px solid',
                borderRadius: '10px',
                padding: '0px'
              }}
            >
              <div
                style={{
                  fontSize: `9px`,
                  fontWeight: 'bold',
                  color: '#252D3A',
                  backgroundColor: '#fff',
                  padding: '10px',
                  borderTopLeftRadius: '10px',
                  borderTopRightRadius: '10px',
                  borderBottomLeftRadius: '2px',
                  borderBottomRightRadius: '2px'
                }}
              >
                <span>Farm Details</span>
              </div>
              <div style={{ color: '#fff', padding: '10px', fontSize: '10px' }}>
                <span style={{ paddingRight: '2px' }}>
                  <p>Name: {farm.name}</p>
                  <p>Farm Size: {(farm.estimatedSize * 0.0001).toFixed(2)} Hect</p>
                </span>
              </div>
            </div>
          </InfoBox>
        )}
      </Marker>
    )
  )
})

export default MarkerWithInfoBox
