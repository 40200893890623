import React, { PureComponent } from 'react';
import swal from 'sweetalert2';
import apiService from '../../api/apiService';
import { SphericalUtil } from 'node-geometry-library';

class FarmersInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      mapRejectionSuccess: false,
      passportRejectionSuccess: false,
      nextOfKinRejectionSuccess: false,
      biometricRejectionSuccess: false
    };
  }

  handleDisapproval = async (e, data) => {
    const {
      farmerInfo: { id, agents }
    } = this.props;
    try {
      const disapprovalRes = await apiService.update(
        `/evaluation/farmer/${id}/${agents[agents.length - 1].id}/${data}/reject`
      );
      const rejectedItem = `${data.toLowerCase()}RejectionSuccess`;
      this.setState({ [rejectedItem]: true });
      if (disapprovalRes.code === 200) {
        this.setState({ [rejectedItem]: true });
      } else {
        throw Error();
      }
    } catch (error) {
      swal({
        title: 'Error',
        text: `Error occurred while trying to disapprove data.`,
        type: 'error',
        animation: true
      });
    }
  };

  getApprovalIconClass = isRejected => `cursor font-18 border p-2 rounded ml-auto fas fa-window-close fa-1x ${
    isRejected ? 'text-danger' : 'text-black-50'
  }
  `;

  render() {
    const { farmerInfo: data, area, onFlagCompleted, onFlagNotCompleted, onResetFarm ,onDeleteFarm} = this.props;
    const {
      passportRejectionSuccess,
      mapRejectionSuccess,
      nextOfKinRejectionSuccess,
      biometricRejectionSuccess
    } = this.state;
    return (
      <>
        {Object.keys(data).length !== 0 ? (
          <>
            <h4 className='font-weight-bold my-4'>Farm Information</h4>
            <div className='d-flex'>
              <button className='btn btn-revenue btn-sm text-white' onClick={onFlagCompleted}>
                Completed
              </button>
              <br />
              <button
                className='btn btn-warning btn-sm text-white'
                onClick={onFlagNotCompleted}
              >
                Not completed
              </button>
               
            </div>
            <div className='d-flex mt-3'>
                
              <button className='btn btn-danger btn-sm m-1' onClick={onResetFarm}>
                Reset Farm
              </button>
              <br />
              <button className='btn btn-danger btn-sm m-1' onClick={onDeleteFarm}>
                Delete Farm
              </button>
            </div>
            <h6 className='font-weight-bold text-black-50 my-3'>Farmers Bio Data</h6>
            <div className='text-black-50 d-flex flex-column align-items-start'>
              {data.displayImage && (
                <div className='justify-content-between w-100 align-items-center d-flex py-1'>
                  <span>
                    <img
                      src={data.displayImage}
                      alt="farmer's profile"
                      className='rounded mr-3 w-md-25'
                    />
                  </span>

                  {/* <button onClick={() => this.handleDisapproval('PASSPORT')} className='btn btn-sm'>
                    <i className={this.getApprovalIconClass(passportRejectionSuccess)} />
                  </button> */}
                </div>
              )}
              
              <div className=' justify-content-between w-100 align-items-center py-1 d-flex'>
                <h6 className='p-1'>
                  <span className='font-weight-bold'>TEMP ID:</span> {data.tempId}
                </h6>
              </div>
              <div className=' justify-content-between w-100 align-items-center py-1 d-flex'>
                <h6 className='p-1'>
                  <span className='font-weight-bold'>BVN:</span> {data.bvn}
                </h6>
              </div>
              <div className=' justify-content-between w-100 align-items-center py-1 d-flex'>
                <h6 className='p-1'>
                  <span className='font-weight-bold'>Name:</span> {data.name}
                </h6>
              </div>
              {/* <div className=' justify-content-between w-100 align-items-center py-2 d-flex'>
                <h6 className='p-1'>
                  <span className='font-weight-bold'>Map</span>
                </h6>
                <button onClick={() => this.handleDisapproval('MAP')} className='btn btn-sm'>
                  <i className={this.getApprovalIconClass(mapRejectionSuccess)} />
                </button>
              </div> */}
              <div className=' justify-content-between w-100 align-items-center py-2 d-flex'>
                <h6 className='p-1'>
                  <span className='font-weight-bold'>Farm Name:</span> {data.farmName} 
                </h6>
              </div>
              <div className=' justify-content-between w-100 align-items-center py-2 d-flex'>
                <h6 className='p-1'>
                  <span className='font-weight-bold'>Farm Address:</span> {data.farmAddress} 
                </h6>
              </div>
              <div className=' justify-content-between w-100 align-items-center py-2 d-flex'>
                <h6 className='p-1'>
                  <span className='font-weight-bold'>Map Size:</span> {data.farmSize.toFixed(5)} Hectares
                </h6>
              </div>
              <div className=' justify-content-between w-100 align-items-center py-2 d-flex'>
                <h6 className='p-1'>
                  <span className='font-weight-bold'>Editing Size:</span> {area.toFixed(5)} Hectares
                </h6>
              </div>
              {data && data.nextOfKin && data.nextOfKin.name ? (
                <div className='justify-content-between w-100 align-items-center py-2 d-flex'>
                  <h6 className='p-1'>
                    <span className='font-weight-bold d-block my-2'>Next of kin</span>
                    <span className='d-block text-capitalize'>
                      <p className='b'>Name: </p>
                      {data.nextOfKin.name.toLowerCase()}
                    </span>
                    <span className='d-block text-capitalize mt-2'>
                      <p className='b'>Relationship:</p>
                      {data && data.nextOfKin && data.nextOfKin.relationship
                        ? data.nextOfKin.relationship.toLowerCase()
                        : ''}
                    </span>
                  </h6>
                  {/* <button
                    onClick={() => this.handleDisapproval('NEXTOFKIN')}
                    className='btn btn-sm'
                  >
                    <i className={this.getApprovalIconClass(nextOfKinRejectionSuccess)} />
                  </button> */}
                </div>
              ) : (
                ''
              )}

              {data.prints && data.prints.length > 0 ? (
                <div className=' justify-content-between w-100 align-items-center py-2 d-flex'>
                  <h6 className='p-1 font-weight-bold'>Fingerprints: </h6>
                  <button
                    onClick={() => this.handleDisapproval('BIOMETRIC')}
                    className='btn btn-sm'
                  >
                    <i className={this.getApprovalIconClass(biometricRejectionSuccess)} />
                  </button>
                </div>
              ) : (
                ''
              )}

              <div className='d-flex justify-content-between w-100 align-items-center p-2'>
                {data.prints.map((print, i) => (
                  <div key={`finger${i}`}>
                    <img src={print} alt='fingerprint' className='rounded' width='65%' />
                  </div>
                ))}
              </div>
              {data && data.agents && data.agents.length > 0 ? (
                <h6 className='p-1'>
                  <h6 className='font-weight-bold text-black-50 my-3'>Assigned Agents</h6>
                  {data.agents.reverse().map((agent, i) => (
                    <div
                      className={i === 0 ? 'text-revenue py-2' : 'text-black-50 py-2'}
                      key={agent.name}
                    >
                      <span className='text-capitalize mr-1'>{agent.name}</span>({agent.phone})
                    </div>
                  ))}
                </h6>
              ) : (
                ''
              )}
            </div>
          </>
        ) : (
          <div className='card shadow my-2 p-3 mx-auto my-auto'>
            <div className='card-body text-center'>
              <i className='fa fa-street-view fa-3x my-2' aria-hidden='true' />
              <p className='font-18 text-left text-black-50'>
                Click the marker on the map to view farm information.
              </p>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default FarmersInfo;
