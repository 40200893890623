import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
//import BaseUrl from '../../../security/base-url';
import FarmResourceItemList from '../../components/farm/farmresource/farmresource/FarmResourceItemList';
import NewFarmResourceModal from '../../components/farm/farmresource/utils/NewFarmResourceModal';
import Navbar from '../../components/Navbar';
import Spinner from '../../components/Spinner';
import { api } from '../../api/api.config';
import { getFarmResourceList, getResourceLink, getSelectedFarmResource } from './farm.selectors';
import notify from '../../helpers/notify';
const { error, success } = notify();

const FarmResource = props => {
  const { serverLink, selectedFarmResource } = props;

  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [resources, setResources] = useState([]);
  const [inputs, setInputs] = useState([]);
  const [states, setStates] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [enableCommunityResource, setEnableCommunityResurce] = useState(false);
  /**
   *
   * Fetch all the farmer's farm resources from the api
   */
  // TODO: Move data request into a /farm.saga
  // NOTE: all server request should be abstracted!!

  useEffect(() => {
    getFarmResources();
    getStates();
    getSeasons();
  }, []);

  const userAccountId = localStorage.getItem('userAccountId');

  const getFarmResources = async () => {
    setIsLoading(true);
    let response;
    try {
      response = await api.get(`/resource/farm-resource/${userAccountId}`);
      const reverseData = response.data.list.reverse();
      //console.log('qqq', reverseData);
      setResources(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getStates = async () => {
    let response;
    try {
      response = await api.get('/states');
      setStates(response.data.list);
    } catch (error) {
      console.log(error);
    }
  };

  const getSeasons = async () => {
    let response;
    try {
      response = await api.get(`/cbn_dashboard_rest/seasons/${userAccountId}`);
      setSeasons(response.data.list);
    } catch (error) {
      console.log(error);
    }
  };

  // Manage the inputs on search form and other form fields

  const onChange = event => {
    event.persist();
    setInputs(inputs => ({ ...inputs, [event.target.name]: event.target.value }));
  };

  const onSearch = event => {
    setSearch(event.target.value);
  };

  const handleEnableCommunityResource = () => {
    setEnableCommunityResurce(!enableCommunityResource);
  };

  const createFarmResource = async () => {
    api
      .post(
        enableCommunityResource === false
          ? `/resource/farm-resource/${this.userAccountId}`
          : `/resource/farm-resource/with_community_resource/${userAccountId}`,
        inputs
      )
      .then(res => {
        if (res.status === 200) {
          document.getElementById('addFarmResource').reset();
          document.getElementById('addFarmResourceModal').click();
          success('Farm Resource added successfully');
        }
        getFarmResources();
      })
      .catch(error => error(error));
  };

  //   /**
  //    * Add a farm resource
  //    */

  //    *  Delete a farm resource from the api

  const handleDeleteResource = id => {
    // fetch(BaseUrl.url_dashbord + '/farmresources/' + id, {
    //   method: 'DELETE',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     Accept: 'application/json'
    //   }
    // })
    //   .then(res => {
    //     if (res.status === 200) {
    //       res.json().then(resources => {
    //         this.setState({ resources });
    //       });
    //       success('Resource deleted successfully');
    //     } else {
    //       error('Error Deleting Resource');
    //     }
    //   })
    //   .catch(err => console.log(err));
  };

  // CLick a selected farm to move to next page

  const handleSelectFarm = () => {
    this.props.history.push('/new-view-farms');
  };

  return (
    <div className='container-fluid main-content'>
      <Navbar
        navLinks={[
          {
            path: '/resources/farm',
            header: 'Farm Resource'
          }
        ]}
        small='Add or remove farm resources on the platform'
      />

      <div className='col-md-12'>
        {/* New Component Starts */}

        <div className='row bg-white'>
          <div className='col-md-12 pl-4 text-right d-flex w-100 py-3 align-items-center'>
            <input type='checkbox' name='' className='mx-2' />
            <select className='mr-3 custom-select form-control w-20'>
              <option value='Select actions...'>Select actions...</option>
              <option value='Nil'>Nil</option>
            </select>
            <i className='fas fa-filter text-black-50 input-prepend' />
            <input
              name='search'
              className='mr-auto form-control w-20'
              type='search'
              placeholder='Search'
              value={search}
              onChange={onSearch}
            />

            <span className='badge badge-pill badge-warning p-1'>
              {resources.totalFarms} Total Farm Resources
            </span>

            <button
              type='button'
              className='btn btn-revenue text-white btn-sm px-3 ml-2'
              data-toggle='modal'
              data-target='#addFarmResourceModal'
            >
              <i className='fas fa-plus fa-sm mr-3' />
              <span> Add Farm Resource </span>
            </button>
          </div>

          <Spinner isLoading={isLoading} data={resources.list}>
            <FarmResourceItemList
              data={resources.list}
              search={search}
              onDelete={handleDeleteResource}
              onSelect={handleSelectFarm}
            />
          </Spinner>
        </div>
      </div>

      <NewFarmResourceModal
        inputs={inputs}
        onChange={onChange}
        states={states}
        createFarmResource={createFarmResource}
        selectedFarmResource={selectedFarmResource}
        enableCommunityResource={enableCommunityResource}
        handleEnableCommunityResource={handleEnableCommunityResource}
      />

      {/* Delete farm Modal */}
      <div
        className='modal'
        id='deleteFarmResourceModal'
        tabIndex={-1}
        role='dialog'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered modal-sm' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                <span aria-hidden='true'>×</span>
              </button>
            </div>
            <div className='modal-body'>
              <p>Are You Sure?</p>
            </div>

            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-revenue btn-sm text-white'
                data-dismiss='modal'
                onClick={e => this.deleteResource(e)}
              >
                Yes
              </button>

              <button
                type='button'
                className='btn btn-expend text-white btn-sm'
                data-dismiss='modal'
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* // Update Farm Resource Modal */}
      {/* 
      <div className='col-md-12'>
        <Form id='editFarmResource' onSubmit={e => updateFarmResource(e)}>
          {() => (
            <div>
              <div
                className='modal'
                id='editFarmResourceModal'
                tabIndex={-1}
                role='dialog'
                aria-hidden='true'
              >
                <div className='modal-dialog' role='document'>
                  <div className='modal-content'>
                    <div className='modal-header'>
                      <h5>Update Farm Resource</h5>
                      <button
                        type='button'
                        className='close'
                        data-dismiss='modal'
                        aria-label='Close'
                      >
                        <span aria-hidden='true'>×</span>
                      </button>
                    </div>
                    <div className='modal-body'>
                      <div className='form-row'>
                        <div className='form-group col-md-12'>
                          <InputGroup validate={['name']}>
                            {({ error }) => (
                              <div>
                                <input
                                  required
                                  className='form-control'
                                  placeholder='Resource Name'
                                  name='name'
                                  value={inputs.name}
                                  onChange={onChange}
                                />
                                {displayError(error)}
                              </div>
                            )}
                          </InputGroup>
                        </div>
                      </div>
                      <div className='form-group col-md-12'>
                        <InputGroup validate={['description']}>
                          {({ error }) => (
                            <div>
                              <textarea
                                required
                                className='form-control'
                                placeholder='Resource Description'
                                name='description'
                                value={inputs.description}
                                onChange={onChange}
                              />
                              {displayError(error)}
                            </div>
                          )}
                        </InputGroup>
                      </div>
                      <button type='submit' className='btn btn-revenue text-white my-2'>
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Form>
      </div> */}

      {/* // Update Farm resource modal ends here */}
    </div>
  );
};
FarmResource.propTypes = {
  serverLink: PropTypes.object
};

const mapStateToProps = state => ({
  isLoading: state.farmResource.isLoading,
  farmResources: getFarmResourceList(state),
  selectedFarmResource: getSelectedFarmResource(state),
  serverLink: getResourceLink(state)
});

export default connect(mapStateToProps)(FarmResource);

// const getCreateLink = () => {
//   let createResourceLink = jsonQuery('links[rel=create]', {
//     data: resources
//   }).value;
//   if (createResourceLink) {
//     console.log(2222, createResourceLink.href);
//     return createResourceLink.href;
//   }
// };

// const extractLinks = resources => {
//   const resolvedLink = resources.links.reduce((acc, key) => ((acc[key.rel] = key), acc), {});
//   return resolvedLink;
// };

/**
//    * Get the farm id
//    */
//   openDeleteFarmResourceModal = id => {
//     this.setState({
//       id: id
//     })
//   }

//   /**
//    * Get the farm id
//    */
// openEditFarmResourceModal = (id, name, description, status) => {
//   this.setState({
//     id: id,
//     name: name,
//     description: description,
//     status: status
//   })
// }
