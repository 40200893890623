import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, Redirect, Route, Switch, withRouter } from 'react-router-dom';
import BaseUrl from '../../../security/base-url';
import CommunityResourceOverview from '../../components/community/CommunityResourceOverview';
import CommunitySettings from '../../components/community/CommunitySettings';
import Evaluation from '../../components/community/Evaluation';
import InputDetails from '../../components/community/InputDetails';
import Inputs from '../../components/community/Inputs';
import Recovery from '../../components/community/Recovery';
import RecoveryDetails from '../../components/community/RecoveryDetails';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import NewCommunitiesMap from '../community/CommunitiesMaps';
import Communities from './Communities';
import * as actions from './communities.actions';

//This is the COmponent that loads when you go into a Community Resource
class CommunityResourceFeatures extends Component {
  constructor(props) {
    super(props);
    this.state = {
      communityResource: {}
    };
  }
  componentDidMount() {
    this.props.getEOPS(this.props.communityResourceId);
    this.initCommunityResource(this.props.communityResourceId);
  }

  initCommunityResource = id => {
    fetch(BaseUrl.url_dashbord + '/community-resource/find/' + id, {
      method: 'get',
      headers: {
        accept: 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => {
        this.setState({ communityResource: data });
      })
      .catch(err => {
        console.log(err);
      });
  };

  handleDeleteEOP = eopId => {
    this.props.deleteEOP(eopId, this.props.communityResourceId);
  };

  render() {
    const {
      communityResourceName,
      communityResourceId,
      eops,
      isMappingEnabled,
      toggleCommunityResourceMapping
    } = this.props;
    return (
      <React.Fragment>
        <Navbar
          navLinks={[
            {
              path: '/resources/community',
              header: ' Community Resource |'
            },
            {
              path: '#',
              header: '| Communities'
            }
          ]}
          small='Communities and features under the Community Resource'
        />

        <div className='container-fluid'>
          {/* <div className='row my-3'>
            <h3 className='font-18 m-3 lead'>{communityResourceName}</h3>
          </div> */}
          <div className='row my-2 px-2'>
            <ul className='navbar-nav mr-auto d-flex flex-row'>
              <li className='nav-item'>
                <NavLink
                  to='/community-resource/community-resource-overview/'
                  className='p-3'
                  exact={true}
                  activeClassName='active-nav'
                >
                  Overview
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink
                  to='/community-resource/communities'
                  className='p-3'
                  exact={true}
                  activeClassName='active-nav'
                >
                  Communities
                </NavLink>
              </li>

              <li className='nav-item'>
                <NavLink
                  to='/community-resource/evaluate'
                  className='p-3'
                  exact={true}
                  activeClassName='active-nav'
                >
                  Evaluate
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink
                  to='/community-resource/inputs'
                  className='p-3'
                  exact={true}
                  activeClassName='active-nav'
                >
                  Inputs
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink
                  to='/community-resource/recovery'
                  className='p-3'
                  exact={true}
                  activeClassName='active-nav'
                >
                  Recovery
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink
                  to='/community-resource/settings'
                  className='p-3'
                  exact={true}
                  activeClassName='active-nav'
                >
                  Settings
                </NavLink>
              </li>
            </ul>
            <div className='filter-list'></div>
          </div>
          <div className='my-4'>
            <Switch>
              <Redirect from='/community-resource' to='' exact strict />
              <Route
                exact
                path='/community-resource/community-resource-overview'
                render={() => (
                  <CommunityResourceOverview
                    id={communityResourceId}
                    resource={this.state.communityResource}
                    eops={eops}
                    handleDeleteEOP={this.handleDeleteEOP}
                  />
                )}
              />
              <Route
                exact
                path='/community-resource/communities'
                render={() => <Communities isMappingEnabled={isMappingEnabled} />}
              />

              <Route
                exact
                path='/community-resource/evaluate'
                render={() => <Evaluation communityResourceId={communityResourceId} />}
              />
              <Route
                exact
                path='/community-resource/map-view'
                render={() => <NewCommunitiesMap />}
              />

              <Route
                exact
                path='/community-resource/inputs/'
                render={() => <Inputs communityResourceId={communityResourceId} />}
              />
              <Route
                exact
                path='/community-resource/inputdetails/:inputId'
                render={() => <InputDetails />}
              />
              <Route
                exact
                path='/community-resource/recovery/'
                render={() => <Recovery communityResourceId={communityResourceId} />}
              />
              <Route
                exact
                path='/community-resource/recoverydetails/:farmerId'
                render={() => <RecoveryDetails />}
              />
              <Route
                exact
                path='/community-resource/settings'
                render={() => (
                  <CommunitySettings
                    isMappingEnabled={isMappingEnabled}
                    toggleCommunityResourceMapping={toggleCommunityResourceMapping}
                  />
                )}
              />
            </Switch>
          </div>
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    communityResourceList: state.comReducer.communityResourceList,
    communityResourceId: state.comReducer.communityResourceId,
    communityResourceName: state.comReducer.communityResourceName,
    isMappingEnabled: state.comReducer.communityResourceMappingEnabled,
    eops: state.comReducer.eops,
    communityResource: state.comReducer
  };
};
const mapDispatchToProps = {
  getEOPS: actions.getEOP,
  getCommunityName: actions.handleCommunityIdAndName,
  deleteEOP: actions.deleteEOP,
  toggleCommunityResourceMapping: actions.toggleCommunityResourceMapping
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CommunityResourceFeatures));
