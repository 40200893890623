import swal from 'sweetalert2';

// Handles Notifications on the app

const notify = () => {
  const error = reason =>
    swal({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      title: 'Error!',
      text: reason,
      type: 'error',
      timer: 5000,
      animation: true
    });

  const success = reason =>
    swal({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      title: 'Success!',
      text: reason,
      type: 'success',
      timer: 5000,
      animation: true
    });

  const info = reason =>
    swal({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      title: 'Info!',
      text: reason,
      type: 'info',
      timer: 8000,
      animation: true
    });

  return {
    info,
    success,
    error
  };
};

export default notify;
