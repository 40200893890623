import PropTypes from 'prop-types'
import React, {useState} from 'react'
import { Link, Route, Switch } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import goright from '../../../assets/goright.svg'
import Footer from '../Footer'
import Navbar from '../Navbar'
import './walletstyle.css'
import WithSubRoutes from '../../HOC/WithSubRoutes'
import InfoDashlet from '../centralDashboard/InfoDashlet'

function MainWallet({ match, routes, props}) {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className='container-fluid'>
      <Navbar
        navLinks={[
          {
            path: '/',
            header: ' Overview |'
          },
          {
            path: '#',
            header: '| Wallet'
          }
        ]}
        small='Manage wallet, and payments'
      />
      <div className='row justify-content-between'>
        {/* Left Section */}
        <div className='inner-sidebar'>
          {/* <div className="card text-white bg-dark">
            <div className="p-2">
              <h4 className="card-title">Total Credits</h4>
              <p className="card-text">1,000,000 credits</p>
            </div>
            <a href="#" className="btn btn-success small-font-fix">
              Get 10% off all resources{" "}
              <img src={goright} alt="supplies" className="mx-2" />
            </a>
          </div> */}
          <ul className='list-unstyled components inner-sidebar__nav mt-lg-3'>
            <li className='nav-item'>
              <Link
                to={`${match.url}/payments`}
                className='nav-link text-white'
                activeclassname='resource'
              >
                <i className='fa fa-credit-card mx-2 d-inline' aria-hidden='true' /> Payments
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to={`${match.url}/tx-history`}
                className='nav-link text-white'
                activeclassname='resource'
              >
                <i className='fa fa-coins mx-2 d-inline' aria-hidden='true' /> Transaction History
              </Link>
            </li>
            {/* <li className="nav-item">
              <NavLink to={`${match.url}/wallet`} className="nav-link">
                Community Wallet
              </NavLink>
            </li> */}
          </ul>
        </div>
        {/* Right Section  */}
        <div className='wallet-content'>
          <div className='d-flex justify-content-around p-1'>
            <div className='d-flex'>
                <InfoDashlet
                  title='Revenue'
                  value='1300000NGN'
                />             
              
                <InfoDashlet
                  title='Expenditure'
                  value='2300000NGN'
                />
            </div>
            <div>
              <div>
                <div className='d-flex justify-content-between'>
                  <small className='font-fix mr-2 mt-2'>Balance:</small>
                  <p className='font-weight-bold'>
                    250,000 <span className='text-secondary'>NGN</span>
                  </p>
                </div>
                <div className='d-flex justify-content-between'>
                  <small className='font-fix'>Wallet Account Number:</small>
                  <p className='card-text'>5458 8124 FH</p>
                </div>
              </div>
              <button className='btn btn-success btn-block text-white small-font-fix' onClick={handleShow}>
                Fund Wallet <img src={goright} alt='supplies' className='mx-2' />
              </button>
              
            </div>
           
          </div>
          {/* Transactions Loading Section */}
          <div className='my-2'>
            <Switch>
                {/* Pattern for rendering nested routes in React */}
             {routes.map((route, i) => (
                <WithSubRoutes key={`${route.path}${i}`} {...route} />
              ))}
            <Route
              exact
              path={match.path}
              render={() => (
                <div
                  className='d-flex flex-column justify-content-center align-items-center'
                  style={{ minHeight: '50vh' }}
                >
                  <i className='fa fa-coins fa-7x mx-2 chartbar m-3' aria-hidden='true' />
                  <h2 className='h2 font-weight-normal' data-trash-text>
                    Choose your options from the menu on the left
                  </h2>
                </div>
              )}
            />
            </Switch>
            <Footer />
          </div>
        </div>
      </div>
      <Dialog
        open={show}
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Fund Your Wallet"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
           Send money to the account details below, and your wallet will be funded instantly
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button onClick={handleClose} color="primary">
            Agree
          </Button>
        </DialogActions>
      </Dialog> 
    </div>
  )
}

MainWallet.propTypes = {
  match: PropTypes.object.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default MainWallet
