import { call, put, select } from 'redux-saga/effects';
import {
  alertFailed,
  alertSuccess,
  clearAlertMessage,
  fetchData,
  fetchFailed,
  fetchSuccess
} from '../../api/api.actions';
import { api } from '../../api/api.config';
import apiService from '../../api/apiService';
import { resetIsLoading, toggleServiceActivation } from './serviceProvider.actions';

import {
  getResourcesLink,
  getCreateResourceLink,
  getServiceProviders,
  getCurrentServiceProvider
} from './serviceProvider.selectors';
import { extractResourceLink } from '../../utils/extractResourceLink';
import BaseUrl from '../../../security/base-url';

// workers
export function* fetchServiceProviders() {
  const { method, href } = yield select(getResourcesLink);
  try {
    const payload = yield call([api, method.toLowerCase()], href);
    yield put(fetchSuccess('SERVICE_PROVIDERS', payload.data));
  } catch (error) {
    yield put(fetchFailed('SERVICE_PROVIDERS', error.message));
  }
}
// workers
export function* fetchSharedAgencies({ payload: id }) {
  const { data: serviceProviders } = yield select(getServiceProviders);
  const { method, href } = extractResourceLink(serviceProviders[id].links, 'shared-agency');

  try {
    const payload = yield call([api, method.toLowerCase()], href);
    yield put(fetchSuccess('SHARED_AGENCIES', payload.data));
  } catch (error) {
    yield put(fetchFailed('SHARED_AGENCIES', error.message));
  }
}

export function* toggleServiceStatus({ payload }) {
  const { providerId, service, status } = payload;
  const providers = yield select(getServiceProviders);
  const provider = providers.data[providerId];
  const resource = `${status}-${service
    .trim()
    .toLowerCase()
    .split(' ')
    .join('-')}`;
  const { href, method } = extractResourceLink(provider.links, resource);
  try {
    yield call([api, method.toLowerCase()], href);
    yield put(toggleServiceActivation(providerId, service));
    yield call(fetchServiceProviders());
  } catch (reason) {
    yield put(alertFailed("Unable to update this service's status"));
  } finally {
    yield put(clearAlertMessage());
  }
}

export function* saveServiceProvider({ payload: { providerData } }) {
  try {
    const { method, href } = yield select(getCreateResourceLink);
    const payload = yield call([api, method.toLowerCase()], href, providerData);

    if (payload.code === -1) {
      throw Error(payload.message);
    }
    yield put(alertSuccess(payload.message));
    yield put(fetchData('SERVICE_PROVIDERS'));
  } catch (reason) {
    alertFailed(`Failed to save resource. Please try again`);
  } finally {
    yield put(clearAlertMessage());
  }
}

//Agents Section
export function* fetchAgents({ payload: { sharedAgencyId } }) {
  try {
   
    const { agencies } = yield select(getCurrentServiceProvider);
    // const { method, href } = extractResourceLink(agencies[sharedAgencyId].self, 'self');
     const payload = yield call([api, "get"], BaseUrl.url_dashbord+"/agents/bysharedagency/"+sharedAgencyId);
    yield put(fetchSuccess('AGENTS', payload));
  } catch (reason) {
    console.log(reason);
    yield put(fetchFailed(reason.message));
  } finally {
    yield put(resetIsLoading());
  }
}
export function* fetchAgentCapturedData({ payload: { agentId } }) {
  try {
    const payload = yield call([api, 'get'], `/agent-posting/${agentId}`);
    yield put(fetchSuccess('CAPTURED_DATA', payload.data));
  } catch (reason) {
    yield put(fetchFailed('CAPTURED_DATA', reason.message));
  } finally {
    yield put(resetIsLoading());
  }
}

export function* updateAgentData({ payload: { agentData } }) {
  try {
    const agentUpdateRes = yield call([apiService, 'update'], '', agentData);
    if (agentUpdateRes.code === undefined) {
      yield put(alertSuccess("Agent's info has been updated."));
    } else {
      yield put(alertFailed("Agent's info failed to update successfully."));
    }
  } catch (error) {}
}
