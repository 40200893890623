import React from 'react'

const PersonalInfo = ({ formRef, formState, pageNum, prevPage, nextPage }) => {
  return (
    <form onSubmit={nextPage} ref={formRef}>
      <>
        <div className='py-2'>
          <label htmlFor='bvn' className='d-flex'>
            BVN <span className='red'>*</span>
          </label>
          <input
            type='text'
            className='form-control border-0'
            defaultValue={formState && formState.bvn}
          />
        </div>

        <div className='py-2 row'>
          <div className='col-sm-6'>
            <label htmlFor='bank' className='d-flex'>
              Bank Name <span className='red'>*</span>
            </label>
            <input
              name='bank'
              type='text'
              className='form-control border-0'
              defaultValue={formState && formState.bank}
            />
          </div>

          <div className='col-sm-6'>
            <label htmlFor='accountNumber' className='d-flex'>
              Account Number <span className='red'>*</span>
            </label>
            <input
              name='accountNumber'
              type='text'
              className='form-control border-0'
              defaultValue={formState && formState.accountNumber}
            />
          </div>
        </div>

        <div className='py-2'>
          <label htmlFor='accountName' className='d-flex'>
            Account Name <span className='red'>*</span>
          </label>
          <input
            name='accountName'
            type='text'
            className='form-control border-0'
            defaultValue={formState && formState.accountName}
          />
        </div>

        <div className='d-flex justify-content-center align-items-baseline py-2'>
          <button onClick={prevPage} className='btn btn-secondary text-white mx-3'>
            Prev
          </button>
          <span className='px-md-5 px-sm-3'>{`${pageNum}/3`}</span>
          <button type='submit' className='btn btn-revenue text-white ml-3'>
            Submit
          </button>
        </div>
      </>
    </form>
  )
}

export default PersonalInfo
