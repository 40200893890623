import PropTypes from 'prop-types'
import React, { Component } from 'react'

class AppErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.any
  }

  constructor(props) {
    super(props)
    this.state = {
      hasError: false
    }
  }

  componentDidCatch() {
    this.setState({ hasError: true })
  }
  render() {
    if (this.state.hasError) {
      return (
        <div className='container-fluid h-100 w-80 my-5 text-capitalize text-monospace border-left border-danger'>
          <h3 data-testid='error' className='m-auto'>
            Oooops!
            <br />
            Looks like EveryFarmer dashboard is currently down!{' '}
          </h3>
        </div>
      )
    }
    return this.props.children
  }
}

export default AppErrorBoundary
