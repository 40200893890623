import React, { Component } from 'react'

// import Map from '../../maps/map';
import { NavLink } from 'react-router-dom'
import FarmMap from '../../../maps/FarmMap'

class NewAreaMapping extends Component {
  state = {
    markers: []
  }

  render() {
    return (
      <div>
        <div className='d-flex justify-content-between map-header text-white'>
          <div>
            <h3>Farm Area</h3>
          </div>
          <div>
            <NavLink to='/add-new-asset' className='btn btn-outline-light p-2'>
              <i className='fas fa-plus-circle fa-sm mx-2' />
              Add Asset
            </NavLink>
          </div>
        </div>

        <FarmMap center={{ lat: 9.078013, lng: 7.403295 }} />

        {/* <Map markers={this.state.markers} /> */}
      </div>
    )
  }
}
export default NewAreaMapping
