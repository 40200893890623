import * as MESSAGES from '../../utils/messages';
import {
  METHOD_DELETE,
  METHOD_GET,
  METHOD_POST,
  METHOD_PUT,
  PATH_FARM
} from '../../utils/rest-config';
import * as ACTION_TYPES from './farm.constant';

export function selectFarmResource(selectedFarmResource) {
  return {
    type: ACTION_TYPES.SELECT_FARM_RESOURCE,
    selectedFarmResource
  };
}

export function selectFarmItem(selectedFarmItemId) {
  return {
    type: ACTION_TYPES.SELECT_FARM_ITEM,
    selectedFarmItemId
  };
}

function fetchFarms(farmResourceId) {
  return {
    type: ACTION_TYPES.FETCH_FARMS,
    payload: farmResourceId
  };
}
function recieveFarmItems(farmItems) {
  return {
    type: ACTION_TYPES.FETCH_FARMS_SUCCESS,
    farmItems
  };
}

function fetchFarmItemsError(message) {
  return {
    type: ACTION_TYPES.FETCH_FARMS_ERROR,
    message
  };
}

export function fetchFarmItems(selectedFarmResourceId) {
  return async dispatch => {
    dispatch(fetchFarms());
    return fetch(PATH_FARM + '/byresource/' + selectedFarmResourceId, METHOD_GET)
      .then(res => (res.status === 200 ? res.json() : Promise.reject()))
      .then(data => dispatch(recieveFarmItems(data)))
      .catch(e => {
        dispatch(fetchFarmItemsError(MESSAGES.ERROR_FETCHING));
        console.log(e);
      });
  };
}

/************************************
 * FIND FARMS BY COMMUNITY RESOURCE
 *************************************/
function recieveFarmItemsByCommunityResource(farmItems) {
  return {
    type: ACTION_TYPES.FETCH_FARMS_BY_COMMUNITY_RESOURCE,
    farmItems
  };
}

function fetchFarmItemsCommunityResourceError(message) {
  return {
    type: ACTION_TYPES.FETCH_FARMS_BY_COMMUNITY_RESOURCE_ERROR,
    message
  };
}

export function fetchFarmItemsByCommunityResource(selectedFarmResourceId) {
  return dispatch => {
    dispatch(recieveFarmItemsByCommunityResource([]));
    return fetch(PATH_FARM + '/by_commmunity_resource/' + selectedFarmResourceId, METHOD_GET)
      .then(res => res.json().then(data => ({ res, data })))
      .then(({ res, data }) => {
        if (res.status === 200) {
          dispatch(recieveFarmItemsByCommunityResource(data));
        } else {
          dispatch(fetchFarmItemsCommunityResourceError(MESSAGES.ERROR_FETCHING));
          return Promise.reject();
        }
      })
      .catch(e => {
        dispatch(fetchFarmItemsCommunityResourceError(MESSAGES.ERROR_FETCHING));
        console.log(e);
      });
  };
}

/**
 * Changes the feature linked to
 * the farm.
 * @param {*} feature can be SOIL_MAPPING,AREA_MAPPING,ASSET
 * @param {*} farmId this Id of the Farm
 * @param {*} status   can either be true or false
 */
export function manageFarmFeature(farmItems, feature, farmId, status) {
  return dispatch => {
    return fetch(PATH_FARM + `/features/${farmId}/${feature}/${status}`, METHOD_PUT)
      .then(res => {
        if (res.status === 200) {
          dispatch(recieveFarmFeatureUpdates(farmItems, farmId, feature, status));
        } else {
          throw Error();
        }
      })
      .catch(e => {
        dispatch(manageFarmFeatureError(MESSAGES.ERROR_UPDATING));
        console.log(e);
      });
  };
}

function manageFarmFeatureError(message) {
  return {
    type: ACTION_TYPES.UPDATE_FARM_FEATURE_ERROR,
    message
  };
}

/**
 *
 * @param {*} farmItems List of FarmItems in memmory
 * @param {*} farmId The ID of the Farm in Question
 * @param {*} feature It can be SOIL_MAPPING, ASSET, AREA_MAPPING
 * @param {*} status true or false
 */
function recieveFarmFeatureUpdates(farmItems, farmId, feature, status) {
  let item = farmItems[`${farmId}`];

  switch (feature) {
    case 'SOIL_MAPPING':
      item.isSoilMappingEnabled = status;
      break;
    case 'AREA_MAPPING':
      item.isAreaMappingEnable = status;
      break;
    case 'ASSET':
      item.isAssetEnabled = status;
      break;
    default:
      return;
  }

  farmItems[farmId] = item;

  return {
    type: ACTION_TYPES.UPDATE_FARM_FEATURE,
    farmItems
  };
}

function removeFarmItemSuccess(farmItemId) {
  return {
    type: ACTION_TYPES.DELETE_FARM_ITEM,
    farmItemId
  };
}

function removeFarmItemError(message) {
  return {
    type: ACTION_TYPES.DELETE_FARM_ITEM_ERROR,
    message
  };
}
export function removeFarmItem(farmItems, farmItemId) {
  return dispatch => {
    return fetch(PATH_FARM + '/' + farmItemId, METHOD_DELETE)
      .then(res => {
        if (res.status === 200) {
          dispatch(removeFarmItemSuccess(farmItems, farmItemId));
          dispatch(fetchFarmItems(farmItemId));
        } else {
          throw Error();
        }
      })
      .catch(e => {
        dispatch(removeFarmItemError(MESSAGES.ERROR_DELETING));
        console.log(e);
      });
  };
}

//CREATE FARM
function createFarmItemSuccess(farmItem) {
  return {
    type: ACTION_TYPES.CREATE_FARM,
    farmItem
  };
}

//eslint-disable-next-line no-unused-vars
function uploadFarmSuccess(farmItems) {
  return {
    type: ACTION_TYPES.IMPORT_FARMS,
    farmItems
  };
}

function createFarmItemError(message) {
  return {
    type: ACTION_TYPES.CREATE_FARM_ERROR,
    createFarmItemErrorMessage: message
  };
}

/**
 * Action Creator for creating FarmItem
 * @param {*} resouceId
 * @param {*} farmItem
 */
export function createFarmItem(resouceId, farmItem) {
  //console.log('RESOURCE ID = > ' + resouceId + ' FARM ITEM + ' + JSON.stringify(farmItem))

  return dispatch => {
    return fetch(PATH_FARM + '/' + resouceId, METHOD_POST(farmItem))
      .then(res => res.json().then(data => ({ res, data })))
      .then((res, data) => {
        if (res.status === 200) {
          dispatch(createFarmItemSuccess(data));
        } else {
          throw Error();
        }
      })
      .catch(e => {
        dispatch(createFarmItemError(MESSAGES.ERROR_INSERTING));
        console.log(e);
      });
  };
}

export function importFarmItems() {}
