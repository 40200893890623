import React, { Component } from 'react'

import moreIcon from '../../../assets/3Dots.svg'
import { Form, InputGroup } from 'react-html5-form'
import { Swal } from 'sweetalert2'
import Navbar from '../../components/Navbar'

class CommunityFarmersProfile extends Component {
  constructor() {
    super()
    this.state = {
      farmer: '',
      firstName: '',
      surname: '',
      email: '',
      address: '',
      phone: '',
      gender: 'M',
      profile: '',
      resources: '',
      communities: '',
      farms: ''
    }
  }

  componentDidMount() {
    this.getData()
  }

  displayError = error => {
    return (
      error && (
        <div className='col-md-12'>
          <p style={this.errorstyles}>{error}</p>
        </div>
      )
    )
  }

  // Update the value of the corresponding state field
  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  updateProfile() {
    const data = {
      firstName: this.state.firstName,
      surname: this.state.surname,
      email: this.state.email,
      address: this.state.address,
      phone: this.state.phone,
      gender: this.state.gender
    }
    console.log(JSON.stringify(data))
    let requestData = {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }
    fetch(
      'http://everyfarmer.farm:3579/everyfarmer-dashboard-rest/resources/dashboard/farmers',
      requestData
    )
      .then(response => response.json())
      .then(data => {
        if (data.code === 0) {
          Swal({
            title: 'Success!',
            text: 'Customer updated successfully.',
            type: 'success',
            animation: true
          })
          document.getElementById('editFarmerModal').click()
          document.getElementById('editFarmerForm').reset()
        } else {
          Swal({
            title: 'Error!',
            text: data.message,
            type: 'success',
            animation: true
          })
        }
      })
      .catch(error => {
        Swal({
          title: 'Error!',
          text: error,
          type: 'success',
          animation: true
        })
      })
  }

  getData() {
    fetch(
      'https://everyfarmer.farm:3579/everyfarmer-dashboard-rest/resources/dashboard/farmers/farmer',
      {
        method: 'GET',
        headers: {
          Accept: 'application/json'
        }
      }
    ).then(resp => {
      resp.json().then(res => {
        this.setState({
          farmer: res,
          firstName: res.firstName,
          surname: res.surname,
          phone: res.phone,
          address: res.address,
          email: res.email,
          gender: res.gender,
          profile: res.displayImage,
          resources: res.resources,
          farms: res.farms,
          communities: res.communities
        })
        console.log(this.state.farmer)
      })
    })
  }

  render() {
    return (
      <div className='m-2'>
        <Navbar
          header='&larr; Back to Area Mapping | Farmers Community Profile'
          small='Farmers Profile in the Community'
        />
        <div className='container-fluid'>
          {/* Profile Section */}
          <div className='row my-2'>
            <div className='col-lg-3'>
              <img src={this.state.displayImage} className='d-inline' alt='profile' width='100%' />
            </div>
            <div className='col-lg-9'>
              <div className='row'>
                <div className='col-lg-3'>
                  <h5 className='d-inline my-1'>
                    {this.state.firstName}Name Here {this.state.surname}
                  </h5>

                  <h6 className='text-secondary my-1'>{this.state.email} Job Description Here</h6>
                </div>
                <div className='col-lg-3 border-left'>
                  <h6 className='text-secondary my-3'>
                    <i className='fas fa-envelope fa-lg mx-2' />
                    {this.state.email} Email Here
                  </h6>
                  <h6 className='text-secondary'>
                    <i className='fas fa-phone fa-lg mx-2' />
                    Phone Here{this.state.phone}
                  </h6>
                </div>
              </div>
              <div className='row'>
                <h6>
                  <span className='badge badge-light p-2'>{this.state.farms} Farms</span>
                </h6>
                <h6>
                  <span className='badge badge-light mx-2 p-2'>
                    {this.state.resources} Resources
                  </span>
                </h6>
                <h6>
                  <span className='badge badge-light p-2'>
                    {this.state.communities} Communities
                  </span>
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className='container-fluid'>
          <div className='row bg-light'>
            <div className='col'>
              <h4>Gender</h4>
              <p>{this.state.gender}</p>
            </div>
          </div>
        </div>
        {/* Details Section with Recent Activity */}
        <div className='container-fluid'>
          <div className='row border-top'>
            <div className='col'>
              <div className='p-4'>
                <h4 className='thick'>Duties and Responsibilities</h4>
                <p className='w-50'>
                  Drive and control farm equipment to till soil and to plant, cultivate, and harvest
                  crops. May perform tasks, such as crop baling or hay bucking. May operate
                  stationary equipment to perform post-harvest tasks, such as husking, shelling,
                  threshing, and ginning.
                </p>
                <div className='d-flex'>
                  <button
                    className='btn-sm btn-revenue px-2 text-white border-0 mr-3'
                    data-toggle='modal'
                    data-target='#editFarmerModal'
                  >
                    Edit Profile
                  </button>
                  <div className='dropdown'>
                    <div
                      className='menuIconWrapper d-flex justify-content-center align-content-center mousePointer'
                      id='dropdownMenuButton'
                      data-toggle='dropdown'
                      aria-haspopup='true'
                      aria-expanded='false'
                    >
                      <img src={moreIcon} className='' alt='More Icon' />
                    </div>
                    <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                      <button className='dropdown-item' href='#'>
                        Make Admin
                      </button>
                      <button className='dropdown-item' href='#'>
                        Suspend User
                      </button>
                      <button className='dropdown-item border-top' href='#'>
                        Remove User
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col'>
              <h4>Recent Activity</h4>
              <ul className='list-group'>
                <li className='list-group-item d-flex justify-content-between align-items-center'>
                  <div>
                    {this.state.firstName} added a{' '}
                    <span className='font-weight-bold'>new Farm </span> for{' '}
                    <span className='b'>500 Credits.</span>
                  </div>
                  <div>
                    <p className='thick d-inline mx-3'>9:50pm</p>
                    <span className='badge badge-secondary p-1'>...</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className='col-md-12'>
          <Form id='editFarmerForm' onSubmit={e => this.updateProfile(e)}>
            {({ error }) => (
              <>
                <div
                  className='modal fade'
                  id='editFarmerModal'
                  role='dialog'
                  aria-labelledby='exampleModalCenterTitle'
                  aria-hidden='true'
                >
                  <div className='modal-dialog modal-dialog-centered' role='document'>
                    <div className='modal-content bg-grey text-dark'>
                      <div className='modal-header'>
                        <h5 className='modal-title' id='exampleModalLongTitle'>
                          Update Profile
                        </h5>
                        <button
                          type='button'
                          className='close'
                          data-dismiss='modal'
                          aria-label='Close'
                        >
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <div className='modal-body'>
                        <div className='form-row'>
                          <div className='form-group col-md-6'>
                            <label>First Name</label>
                            <InputGroup validate={['firstName']}>
                              {({ error }) => (
                                <div>
                                  <input
                                    required
                                    className='form-control form-control-sm'
                                    value={this.state.firstName}
                                    onChange={e => this.onChange(e)}
                                    name='firstName'
                                    placeholder='First Name'
                                  />
                                  {this.displayError(error)}
                                </div>
                              )}
                            </InputGroup>
                          </div>

                          <div className='form-group col-md-6'>
                            <label>Surname</label>
                            <InputGroup validate={['surname']}>
                              {({ error }) => (
                                <div>
                                  <input
                                    required
                                    className='form-control form-control-sm'
                                    value={this.state.surname}
                                    onChange={e => this.onChange(e)}
                                    name='surname'
                                    placeholder='Surname'
                                  />
                                  {this.displayError(error)}
                                </div>
                              )}
                            </InputGroup>
                          </div>
                        </div>

                        <div className='form-row'>
                          <div className='form-group col-md-6'>
                            <label>Email</label>
                            <InputGroup validate={['email']}>
                              {({ error }) => (
                                <div>
                                  <input
                                    required
                                    value={this.state.email}
                                    className='form-control form-control-sm'
                                    onChange={e => this.onChange(e)}
                                    name='email'
                                    type='email'
                                    placeholder='Email'
                                  />
                                  {this.displayError(error)}
                                </div>
                              )}
                            </InputGroup>
                          </div>

                          <div className='form-group col-md-6'>
                            <label>Phone Number</label>
                            <InputGroup validate={['phone']}>
                              {({ error }) => (
                                <div>
                                  <input
                                    required
                                    value={this.state.phone}
                                    className='form-control form-control-sm'
                                    onChange={e => this.onChange(e)}
                                    name='phone'
                                    placeholder='Phone Number'
                                  />
                                  {this.displayError(error)}
                                </div>
                              )}
                            </InputGroup>
                          </div>
                        </div>

                        <div className='form-row'>
                          <div className='form-group col-md-12'>
                            <label>Address</label>
                            <InputGroup validate={['address']}>
                              {({ error }) => (
                                <div>
                                  <textarea
                                    required
                                    value={this.state.address}
                                    className='form-control form-control-sm'
                                    onChange={e => this.onChange(e)}
                                    name='address'
                                    placeholder='Address'
                                  />
                                  {this.displayError(error)}
                                </div>
                              )}
                            </InputGroup>
                          </div>
                        </div>

                        <div className='form-group'>
                          <div className='row'>
                            <legend className='col-form-label col-sm-2 pt-0'>Gender</legend>
                            <div className='col-sm-6'>
                              <div className='form-check'>
                                <input
                                  type='radio'
                                  className='form-check-input'
                                  id='male'
                                  name='gender'
                                  value={'M'}
                                  onChange={e => this.onChange(e)}
                                  checked={this.state.gender === 'M'}
                                />
                                <label htmlFor='male' className='form-check-label'>
                                  Male
                                </label>
                              </div>
                              <div className='form-check'>
                                <input
                                  id='female'
                                  className='form-check-input'
                                  name='gender'
                                  type='radio'
                                  value={'F'}
                                  onChange={e => this.onChange(e)}
                                  checked={this.state.gender === 'F'}
                                />
                                <label htmlFor='female' className='form-check-label'>
                                  Female
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='modal-footer'>
                        <button type='button' className='btn btn-warning' data-dismiss='modal'>
                          Close
                        </button>
                        <button type='submit' className='btn btn-success'>
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* End Edit Customer Modal */}
                </div>
              </>
            )}
          </Form>
        </div>
      </div>
    )
  }
}
export default CommunityFarmersProfile
