import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import moreIcon from '../../../../assets/3Dots.svg';

// const TaskListInfo = ({ agents, sharedAgencyId, taskId }) => (
//   <>
//     {agents.map(({ name, email, address, number }, i) => (
//       <div key={name + i} className='bg-light p-2 row align-items-center mx-0 border rounded'>
//         <div className='col-sm-1'>
//           <input type='checkbox' name='' id='' />
//         </div>
//         <div className='col-sm-2'>
//           <span>{name}</span>
//         </div>
//         <div className='col-sm-2'>
//           <span>{number}</span>
//         </div>
//         <div className='col-sm-3'>
//           <span>{email}</span>
//         </div>
//         <div className='col-sm-4'>
//           <span>{address}</span>
//         </div>
//       </div>
//     ))}
//     <div className='text-right'>
//       <Link
//         className='py-1 text-revenue underline h6'
//         to={`/${sharedAgencyId}/assigned-tasks/${taskId}`}
//       >
//         ...More
//       </Link>
//     </div>
//   </>
// )
class TaskSummaryItem extends PureComponent {
  handleActivation = () => {};

  handleDeleteTask = () => {};

  updateForm = taskInfo => () => this.props.handleUpdateForm(taskInfo);

  renderTaskBadge = task => {
    const badge = {
      'Input Distribution': 'badge-info',
      'Input Data': 'badge-primary',
      KYF: 'badge-revenue',
      Mapping: 'badge-success',
      Extension: 'badge-secondary',
      '': 'border border-secondary '
    };
    return `${badge[task]} badge badge-pill mx-2 align-self-center`;
  };

  render() {
    const { taskInfo } = this.props;
     

    return (
      <div className='mb-5' key={taskInfo.id}>
        <div className='d-flex'>
          <h3 className='display-5 my-1'>{`${taskInfo.group_name}`}</h3>
          <span className={this.renderTaskBadge(taskInfo.services)}>
            Task: {`${taskInfo.services}`}
          </span>
        </div>
        <div className='mb-3'>
          <span className='bg-revenue rounded font-weight-bold p-2 mr-2 text-white'>
            Agents: {taskInfo.agentCount }
          </span>
          <span className='bg-revenue rounded font-weight-bold p-2 mr-2 text-white'>
            Task: {`${taskInfo.services}`}
          </span>
          <span className='bg-revenue rounded font-weight-bold p-2 mr-2 text-white '>
            Cost Per Farmer: {`${taskInfo.cost_per_farmer}`}
          </span>
          <span className='dropdown dropright p-2'>
            <span
              className='d-flex justify-content-center pointer'
              id='dropdownMenuButton'
              data-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded='false'
            >
              <img src={moreIcon} className='cursor px-2' alt='More Icon' />
            </span>
            <span className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
              <button
                data-toggle='modal'
                className='dropdown-item'
                id='editAssignedTask'
                onClick={this.updateForm(taskInfo)}
              >
                Edit
              </button>
              <button className='dropdown-item' data-toggle='modal' onClick={this.handleActivation}>
                Suspend group
              </button>
              <button className='dropdown-item' data-toggle='modal' onClick={this.handleDeleteTask}>
                Delete
              </button>
            </span>
          </span>
        </div>
        {/* {taskInfo.agents && taskInfo.agents.length >= 1 ? (
          <TaskListInfo
            agents={taskInfo.agents}
            sharedAgencyId={this.props.match.params.sharedAgencyId}
            taskId={taskInfo.id}
          />
        ) : (
          <h6 className='text-center font-weight-normal bg-light p-2'>
            No agents assigned to this task.
          </h6>
        )} */}
      </div>
    );
  }
}

export default TaskSummaryItem;
