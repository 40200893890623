import { actions } from '../../api/api.constant'

const { REQUEST_TRASH, REQUEST_TRASH_SUCCESS, REQUEST_TRASH_FAILED } = actions

export const initialTrashState = {
  trashItems: [],
  isLoading: false
}

const trash = (state = initialTrashState, { type, payload } = {}) => {
  switch (type) {
    case REQUEST_TRASH:
      return { ...state, isLoading: true }
    case REQUEST_TRASH_SUCCESS:
      return {
        ...state,
        trashItems: payload,
        isLoading: false
      }
    case REQUEST_TRASH_FAILED:
    default:
      return { ...state, isLoading: false }
  }
}

export default trash
