import BaseUrl from '../../security/base-url'

export const PATH_FARM = BaseUrl.url_dashbord + '/farms'

export const METHOD_DELETE = {
  method: 'delete',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
}

export const METHOD_GET = {
  method: 'get',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
}
const updateMethod = method => {
  return () => body => {
    const bodyData = body ? JSON.stringify(body) : '{}'
    return {
      method: method,
      body: bodyData,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }
  }
}

export const METHOD_POST = updateMethod('POST')

export const METHOD_PUT = updateMethod('PUT')
