import _ from 'lodash';
import React, { Suspense, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MaterialTable from 'material-table';
import { CircularProgress } from '@material-ui/core';
import farmers from '../../../assets/farmers.svg';
import AddUpdateFarmItem from '../../components/farm/farmresource/farmresource/AddUpdateFarmItem';
import FarmItem from '../../components/farm/farmresource/farmresource/FarmItem';
import FarmsToolbar from '../../components/farm/farmresource/farmresource/FarmsToolbar';
import UploadFarms from '../../components/farm/farmresource/utils/UploadFarms';
import UploadFarmMaps from '../../components/farm/farmresource/utils/UploadFarmsMaps';
import UploadInputs from '../../components/farm/farmresource/utils/ImportInputs';
import ResourceListSkeleton from '../../components/skeleton/ResourceListSkeleton';
import Navbar from '../../components/Navbar';
import Spinner from '../../components/Spinner';
import Toolbar from '../../components/Toolbar';
import { createFarmItem, fetchFarmItems, removeFarmItem } from './farm.actions';

const FarmItemList = props => {
  const { farmItems, selectedFarmResource, isLoading } = props;

  const [search, setSearch] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItemForEdit, setSelectedItemForEdit] = useState(undefined);

  // Pull farms inside farm resource
  useEffect(() => {
    props.dispatch(fetchFarmItems(selectedFarmResource.id));
  }, []);

  // Reloads farms after bulk upload
  const handleRefreshList = () => {
    props.dispatch(fetchFarmItems(selectedFarmResource.id, isLoading));
  };

  // Deletes selected farm(s)
  const handleDelete = farmItemId => {
    props.dispatch(removeFarmItem(farmItems, farmItemId));
  };

  // Manages state of search input value
  const onChange = event => {
    setSearch(event.target.value);
  };

  // Render Farms List - FarmItem.jsx no longer needed
  // const renderList = farmItems => {
  //   return _.map(farmItems, farmItem => {
  //     return (
  //       <FarmItem
  //         key={farmItem.id}
  //         data={farmItem}
  //         FarmResourceDetail={farmItem.id}
  //         onDelete={handleDelete}
  //         onEdit={handleEdit}
  //       />
  //     );
  //   });
  // };

  // For adding individual farm
  const handleAddNew = () => {
    setSelectedItemForEdit(undefined);
    setIsEdit(false);
  };

  // Handles Farm Edit
  const handleEdit = selectedItem => {
    setSelectedItemForEdit(selectedItem);
    setIsEdit(true);
  };

  // Handles Farm Item creation
  const handleSubmitCreateNewFarmItem = farmItem => {
    //console.log('RES => ' + JSON.stringify(selectedFarmResource))
    props.dispatch(createFarmItem(selectedFarmResource.id, farmItem));
  };

  // Filter Farm Map data coming from database
  const filterFormMapDisplay = () => {
    // eslint-disable-next-line no-unused-vars
    let filteredData = _.filter(_.toArray(farmItems), farmItem => {
      //console.log('DATA ' + JSON.stringify(farmItem))
      if (farmItem.pointMarkers) return true;
    });
  };

  const openImportFarm = () => {
    setIsModalOpen(true);
  };

  // For managing search filter
  let filteredData = _.filter(farmItems, farmItem => {
    if (farmItem != null && farmItem.name.toLowerCase().indexOf(search.toLowerCase()) !== -1)
      if (farmItem.name.toLowerCase().indexOf(search.toLowerCase()) !== -1) {
        return true;
      }
  });

  const farmItemColumn = [
    {
      title: 'Farm Name',
      field: 'name'
    },
    {
      title: 'Address',
      field: 'address'
    },
    {
      title: 'City',
      field: 'city'
    }
    // {
    //   title: 'View/Manage',
    //   field: 'view'
    // render: () => (
    // <button
    //   className='btn btn-outline-revenue text-center'
    //   onClick={this.props.dispatch(selectFarmItem(this.props.data.id))}
    // >
    //   View/Manage <i className='fa fa-cloud-download-alt'></i>
    //</button>
    //)
    //}
  ];

  // REACT WINDOW IMPLEMENTATION

  // const filterData = (index, style) => {
  //   let filteredData = data.filter(resource => {
  //     if (resource.name.toLowerCase().indexOf(search.toLowerCase()) !== -1) {
  //       return true;
  //     }
  //     return false;
  //   });
  //   return filteredData.map(resource => (
  //     <li
  //       key={resource.id}
  //       className="list-group-item d-flex p-2 bg-list-outer"
  //       style={style}>
  //       <FarmResourceItem item={resource} data={resource[index]} {...props} />
  //       {/* Row [index] */}
  //     </li>
  //   ));
  // };

  // const renderList = (index, style) => {
  //   return (
  //     <li
  //       key={fixedData.id}
  //       className="list-group-item d-flex p-2 bg-list-outer"
  //       style={style}>
  //       <FarmResourceItem item={fixedData} data={fixedData[index]} {...props} />
  //       {/* Row [index] */}
  //     </li>
  //   );
  // };

  //  <div className="w-100 fix-height">
  //         <ul className="">
  // <AutoSizer>
  //   {({ height, width }) => (
  //     <List
  //       className="list-group revenue-scrollbar px-2 pb-1 bg-white"
  //       height={500}
  //       itemCount={data.length}
  //       itemSize={40}
  //       overscanCount={4}
  //       width={width}>
  //       {renderList}
  //     </List>
  //   )}
  // </AutoSizer>
  //      </ul>
  // </div>

  return (
    <div className='container-fluid'>
      <Navbar
        navLinks={[
          {
            path: '/resources/farm/',
            header: 'Farm Resources |'
          },
          {
            path: '#',
            header: `${selectedFarmResource.name}| Farms`
          }
        ]}
        small='List of Farms Under the Farm Resource'
      />
      <div className='bg-white'>
        <Toolbar
          {...props}
          tools={FarmsToolbar}
          //handleSelectedRows={handleSelectedRows}
          onSearch={onChange}
        />
        <div className='container'>
          <div className='row'>
            <div className='col'>
              <div className='card bg-revenue text-white'>
                <div className='card-body d-flex justify-content-around p-2'>
                  <div>
                    <img src={farmers} alt='farmers' width='50' className='d-inline mx-3' />
                  </div>
                  <div>
                    <p className=' d-inline'>Total Farms</p>
                    <h2 className='card-text text-white my-2'>{Object.keys(farmItems).length}</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className='col'>
              <div className='card bg-revenue text-white'>
                <div className='card-body d-flex justify-content-around p-2'>
                  <div>
                    <img src={farmers} alt='farmers' width='50' className='d-inline mx-3' />
                  </div>
                  <div>
                    <p className='d-inline'>Total Farms linked</p>

                    <h2 className='card-text my-2 text-white'>
                      {Object.keys(farmItems).length ? Object.keys(farmItems).length : 0}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className='col'>
                <div className='card bg-revenue text-white'>
                  <div className='card-body d-flex justify-content-around p-2'>
                    <div>
                      <img src={farmers} alt='farmers' width='50' className='d-inline mx-3' />
                    </div>
                    <div>
                      <p className='d-inline'>Total Communities</p>

                      <h2 className='card-text my-2 text-white'>
                        {Object.keys(this.props.farmItems).length
                          ? Object.keys(this.props.farmItems).length
                          : 0}
                      </h2>
                    </div>
                  </div>
                </div>
              </div> */}
          </div>
        </div>

        {/* Render empty if the list of Farms is not uploaded yet */}
        <div className='col-md-12 py-2 fix-height fix-height-75 revenue-scrollbar'>
          <Spinner data={filteredData} isLoading={isLoading}>
            {/* <Suspense fallback={<ResourceListSkeleton />}> {renderList(filteredData)} </Suspense> */}
            <MaterialTable
              columns={farmItemColumn}
              data={filteredData}
              style={{
                fontFamily: 'Nunito',
                width: '100%',
                boxShadow: 'none'
              }}
              options={{
                actionsColumnIndex: 4,
                sorting: true,
                showTitle: false,
                loadingType: 'linear',
                headerStyle: {
                  backgroundColor: '#4f4f4f',
                  color: '#FFF'
                }
              }}
              components={{
                CircularProgress: props => (
                  <div style={{ backgroundColor: '#4f4f4f' }}>
                    <CircularProgress {...props} />
                  </div>
                )
              }}
            />
          </Spinner>
        </div>
      </div>
      <UploadFarms resourceId={selectedFarmResource.id} onRefresh={handleRefreshList} />
      <UploadFarmMaps resourceId={selectedFarmResource.id} />
      <UploadInputs  resourceId={selectedFarmResource.id}/>

      <AddUpdateFarmItem
        isEdit={isEdit}
        // onUpdate={handleUpdateFarmItem}
        onCreate={handleSubmitCreateNewFarmItem}
        data={selectedItemForEdit}
      />
    </div>
  );
};
const mapStateToProps = state => {
  return {
    isLoading: state.farmResource.isLoading,
    selectedFarmResource: state.farmResource.selectedFarmResource,
    farmItems: state.farmResource.farmItems,
    selectedFarmItemId: state.farmResource.selectedFarmItemId
  };
};

export default withRouter(connect(mapStateToProps)(FarmItemList));
