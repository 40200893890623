import React, { useEffect, useState } from 'react';
import jsonQuery from 'json-query';
import Navbar from '../../../app/components/Navbar';
import fprint from '../../../assets/f-print-sample.png';
import placeholder from '../../../assets/placeholder.jpg';
import Loader from '../../../assets/loader.gif';
import { farmerseop } from '../../components/dataTable/columnTitles';
import { api } from '../../api/api.config';
import notify from '../../helpers/notify';
const { error } = notify();

const ViewFarmersProfile = props => {
  const [farmersData, setfarmersData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getFarmerProfile = async id => {
    setIsLoading(true);
    try {
      let response = await api.get('/farmer/' + id);

      setfarmersData(response.data);
      setIsLoading(false);
      //console.log(response.data);
    } catch (e) {
      error(e);
    }
  };

  // Extract next of Kin from returned data
  let nextOfKin = jsonQuery('nextOfKin', {
    data: farmersData
  });
  // Extract images from returned data
  let images = jsonQuery('images', {
    data: farmersData
  });
  // Extract images from returned data
  let guarantor = jsonQuery('guarantor', {
    data: farmersData
  });

  useEffect(() => {
    const fid = props.match.params.farmerId;
    getFarmerProfile(fid);
  }, []);

  return (
    <div className='container-fluid'>
      <Navbar
        navLinks={[
          {
            path: '#',
            header: 'Farmers Profile'
          }
        ]}
        small='All the personal information of a farmer'
      />
      <div className='p-4 my-3 bg-white'>
        {/* Profile Section */}
        {/* <button id="cvbutton" hidden data-toggle="modal" data-target="#cvModal" >cv hidden button</button> */}
        <div className='row border-bottom'>
          <div className='row'>
            <div className='col-sm-3'>
              {isLoading ? (
                <img
                  src={Loader}
                  className='d-inline mx-auto'
                  alt='profile'
                  width='50%'
                  style={{ width: 30, height: 30 }}
                />
              ) : (
                <img
                  src={images.value.passportImage ? images.value.passportImage : placeholder}
                  className='d-inline mx-auto'
                  alt='profile'
                  width='50%'
                  style={{ width: 100, height: 100 }}
                />
              )}
            </div>
            <div className='col-sm-9'>
              <div className='d-flex py-2 justify-content-around'>
                <div className=' d-flex flex-column pr-1'>
                  <h4 className='font-weight-bold py-1 text-dark'>
                    {farmersData.firstName ? farmersData.firstName : 'Not Available'}
                  </h4>
                  <h4 className='font-weight-bold py-1 text-dark'>
                    {farmersData.firstName ? farmersData.surname : 'Not Available'}
                  </h4>
                  <h6 className='text-secondary py-1'>
                    <span className='font-weight-bold'>Gender:</span>
                    <span className='mx-2'>
                      {farmersData.gender ? farmersData.gender : 'Not available'}
                    </span>
                  </h6>
                </div>
                <div className='border-left d-flex flex-column'>
                  <h6 className='text-secondary px-2 py-1 d-flex'>
                    <i className='fas fa-envelope mr-2' />
                    {/* {response.data.email} */}
                  </h6>
                  <h6 className='text-secondary px-2 py-1 d-flex'>
                    <i className='fas fa-phone mr-2' />
                    {farmersData.phone ? farmersData.phone : 'No phone number'}
                  </h6>
                  <h6 className='text-secondary px-2 py-1 d-flex'>
                    <span className='font-weight-bold mr-1'>BVN: </span>
                    <span>{farmersData.bvn ? farmersData.bvn : 'No BVN'}</span>
                  </h6>
                </div>
              </div>
              {/* <div className="d-flex mt-2">
                <h4>
                  <span className="badge badge-light border p-2 small-font-fix">
                    {this.state.response.data.totalFarms}{" "}
                    <span className="text-black-50">Farms</span>
                  </span>
                </h4>

                <h4>
                  <span className="badge badge-light border p-2 small-font-fix">
                    0 <span className="text-black-50">Resources</span>
                  </span>
                </h4>
                <h4>
                  <span className="badge badge-light border p-2 small-font-fix">
                    0 <span className="text-black-50">Communities</span>
                  </span>
                </h4>
              </div> */}
            </div>
          </div>
        </div>
        {/* Details Section with Recent Activity */}
        <div className='row flex-column border-bottom'>
          {/* <h4 className="font-weight-bold my-3 text-dark h5">
          
            Address
          </h4> */}
          {/* <p className='mb-2'>{response.data.address}</p> */}
          {/* <div className="d-flex align-items-center mb-2">
            <button
              className="btn btn-sm btn-revenue text-light"
              data-toggle="modal"
              data-target="#editProfileModal">
              Edit Profile
            </button>
            <div
              className="menuIconWrapper mousePointer ml-3 p-2 d-flex align-items-center justify-content-center"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false">
              <img src={dots} alt="More Icon" />
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton">
                <a className="dropdown-item" href="#">
                  Activate/Suspend Agent
                </a>
                <a className="dropdown-item" href="#">
                  Add to program
                </a>
                <div className="border-top">
                  <a className="dropdown-item" href="#">
                    Delete
                  </a>
                </div>
              </div>
            </div>
          </div> */}
          {/*<div className="d-flex align-items px-2 py-2 mb-2 align-content-center justify-content-between bg-light">
            <div className="d-flex flex-column justify-content-between">
              <h6>Current Posting:</h6>
               <p className="font-weight-bold d-flex">{posting.join(", ")}</p> 
            </div>
            <div className="border-left px-3 my-auto text-revenue">
              <Link to="" className="border-bottom">
                Edit posting
              </Link>
            </div>
          </div>*/}
        </div>
        {/* <div className="row flex-column py-2 border-bottom">
          <h4 className="font-weight-bold my-3 text-dark h5">Gender</h4>
          <h6 className="mb-2">{this.state.response.data.gender}</h6>
        </div> */}
        {/* <div className="row flex-column py-2 border-bottom">
          <h4 className="font-weight-bold my-3 text-dark h5">
            Login Details
          </h4>
          <h6 className="mb-2 mr-1">
            Username: <span>###</span>
          </h6>
          <h6 className="mb-2 mr-1" />
          <div>
            <button className="btn btn-sm btn-revenue text-light">
              Change
            </button>
          </div>
        </div> */}
        {/* <div className='row flex-column py-2 border-bottom'>
          <h5 className='font-weight-bold my-3 text-dark h5'>BVN</h5>
          {/* <h6 className='mb-2'>{response.data.bvn}</h6> 
        </div>*/}

        <div className='row flex-column py-2 border-bottom'>
          <h5 className='font-weight-bold my-3 text-dark h5'>Next of Kin</h5>
          <p className='mb-2'>
            Name:
            <span className='mx-2'>
              {nextOfKin.value.surname ? nextOfKin.value.surname : 'Not Available'}
            </span>
            {nextOfKin.value.firstName}
          </p>
          <p className='mb-2'>
            Phone: {nextOfKin.value.phone ? nextOfKin.value.phone : 'Not Available'}
          </p>
          <p className='mb-2'>
            Relationship:{' '}
            {nextOfKin.value.relationship ? nextOfKin.value.relationship : 'Not Available'}
          </p>
        </div>

        <div className='row flex-column py-2 border-bottom'>
          <h5 className='font-weight-bold my-3 text-dark h5'>Guarantor</h5>
          <p className='mb-2'>
            Name:<span className='mx-2'>{guarantor.value.surname}</span>
            {guarantor.value.firstName ? guarantor.value.firstName : 'Not Available'}
          </p>
          <p className='mb-2'>
            Phone: {guarantor.value.phone ? guarantor.value.phone : 'Not Available'}
          </p>
        </div>
        <div className='row flex-column py-2 border-bottom'>
          <h4 className='font-weight-bold my-3 text-dark h5'>Biometrics</h4>
          <div className='row'>
            <div className='col-md-3 border text-center'>
              <small>
                <img
                  src={images.value.rightIndexImage ? images.value.rightIndexImage : fprint}
                  height='60px'
                  alt='right-index'
                />
              </small>
            </div>
            <div className='col-md-3 border text-center'>
              <small>
                <img
                  src={images.value.leftIndexImage ? images.value.leftIndexImage : fprint}
                  height='60px'
                  alt='left-index'
                />
              </small>
            </div>
            <div className='col-md-3 border text-center'>
              <small>
                <img
                  src={images.value.rightThumbImage ? images.value.rightThumbImage : fprint}
                  height='60px'
                  alt='right-thumb'
                />
              </small>
            </div>
            <div className='col-md-3 border text-center'>
              <small>
                <img
                  src={images.value.leftThumbImage ? images.value.leftThumbImage : fprint}
                  height='60px'
                  alt='left-thumb'
                />
              </small>
            </div>
          </div>
        </div>
        {/* <div className='row flex-column py-2 border-bottom'>
          <h4 className='font-weight-bold my-3 text-dark h5'>
            Farm Location
          </h4>
          <div className='border'> <FarmMap mapMarkers = {}/> </div>
        </div>
*/}
        <div className='row'>
          <div className='col-md-12'>
            <h5 className='font-weight-bold my-3 text-dark h5'>Inputs Collected in Community</h5>
            <select
              required
              className='file-form-control col-md-10 form-control'
              name='com'
              // onChange={this.onSelectCommunity}
            >
              <option value='Choose Season'>Choose Season</option>
              {farmersData.communities ? (
                farmersData.communities.map(item => (
                  <option key={item.communityId} value={item.communityId}>
                    {item.communityResourceName}
                  </option>
                ))
              ) : (
                <option value='Choose Season'>Not in any community</option>
              )}
            </select>
            {/* {farmer.eops === 0 ? (
              <div className='d-flex justify-content-center w-25 mx-auto'>
                <img src={Loader} alt='loader' className='my-auto' />
              </div>
            ) : (
              <Spinner isLoading={isLoading} data={this.state.inputs}>
                <ReactTable
                  columns={farmerseop}
                  className='-striped -highlight'
                  data={inputs}
                  defaultPageSize={10}
                  noDataText={'Loading... Please Wait'}
                />
              </Spinner>
            )} */}
          </div>

          {/* End inputs table */}
        </div>
      </div>
    </div>
  );
};

export default ViewFarmersProfile;

// class ViewFarmersProfile extends Component {
//   constructor() {
//     super()
//     this.state = {
//       farmer: '',
//       collectedQuantity: '',
//       quantityPerHectare: '',
//       id: '',
//       farmerProfile: {},
//       inputs: {},
//       isLoading: false
//     }
//   }

//   componentDidMount() {
//     //this.getData();

//     this.getInputs(fid)
//   }

// displayError = error => {
//   return (
//     error && (
//       <div className='col-md-12'>
//         <p style={this.errorStyles}>{error}</p>
//       </div>
//     )
//   )
// }

// Update the value of the corresponding state field
// onChange = e => {
//   this.setState({
//     [e.target.name]: e.target.value
//   })
// }

// openGiveInputModal(id, quantityPerHectare) {
//   this.setState({
//     id: id,
//     quantityPerHectare: quantityPerHectare
//   })
// }

// getData() {
//   const fId = {
//     bvn: this.props.match.params.bvn
//   }

//   fetch(BaseUrl.url_dashbord + '/farmers/qr', {
//     method: 'POST',
//     body: JSON.stringify(fId),
//     headers: {
//       Accept: 'application/json',
//       'Content-Type': 'application/json'
//     }
//   }).then(resp => {
//     resp.json().then(res => {
//       this.setState({ farmer: res })
//     })
//     console.log('rrrrrrrrrrr', this.state.farmer)
//   })
// }

// GEt Inputs

// getInputs = async id => {
//   this.setState({ isLoading: true })
//   try {
//     const inputRequest = await fetch(
//       BaseUrl.url_dashbord + '/input-distribution-collected/' + id,
//       METHOD_GET
//     )
//     const inputResponse = await inputRequest.json()

//     if (inputResponse.status === 204) {
//       throw Error(inputResponse.message)
//     } else {
//       this.setState({ inputs: inputResponse })
//     }
//   } catch (e) {
//     error(e)
//   } finally {
//     this.setState({ isLoading: false })
//   }
// }

// fetch(BaseUrl.url_dashbord + "input-distribution-collected"/ + id
// giveInputOut() {
//   if (this.state.collectedQuantity > this.state.quantityPerHectare) {
//     Swal({
//       title: "Error!",
//       text: "Quantity given cannot be greater than expected quantity",
//       type: "error",
//       animation: true
//     });

//     this.setState({
//       collectedQuantity: ""
//     });
//     return;
//   }

//   let input = {
//     id: this.state.id,
//     collectedQuantity: this.state.collectedQuantity
//   };

//   let requestData = {
//     method: "PUT",
//     body: JSON.stringify(input),
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application/json"
//     }
//   };
//   fetch(BaseUrl.url_dashbord + "input-distribution", requestData)
//     .then(response => response.json())
//     .then(data => {
//       if (data.code === 0) {
//         Swal({
//           title: "Success!",
//           // text: "Successful",
//           type: "success",
//           animation: true
//         });
//         this.getData();
//       } else {
//         Swal({
//           title: "Error!",
//           text: data.message,
//           type: "error",
//           animation: true
//         });
//       }

//       this.setState({
//         collectedQuantity: ""
//       });
//       document.getElementById("giveInputModal").click();
//     })
//     .catch(error => {
//       alert(error);
//     });
// }

//   render() {

//   }
