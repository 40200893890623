import { CircularProgress } from '@material-ui/core';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { api } from '../../api/api.config';
import Navbar from '../../components/Navbar';
import Spinner from '../../components/Spinner';
import { extractResourceLink } from '../../utils/extractResourceLink';
import { agentPostingDetailsColumns } from '../../utils/tablecolumns';
import { getAgentPostings } from './serviceProvider.selectors';

class AgentPostingDetails extends PureComponent {
  static propTypes = {
    match: PropTypes.shape({ params: PropTypes.objectOf({ id: PropTypes.string }) }),
    posting: PropTypes.object.isRequired
  };
  state = {
    data: []
  };
  async componentDidMount() {
    this.setState({ isLoading: true });
    try {
      const { posting } = this.props;
      const { href } = extractResourceLink(posting.links, 'data-capture');
      const { data } = await api.get(href);
      this.setState({ data: data.list });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  render() {
    const { data, isLoading } = this.state;

    return (
      <section className='bg-white my-3 '>
        <div className='pt-6'>
          <Navbar
            navLinks={[
              {
                path: `/3/new-shared-agency/${this.props.match.params.agentId}/captured-data`,
                header: ' Assigned Task |'
              },
              {
                path: `#`,
                header: '| Captured Data'
              }
            ]}
            small='Review of Captured Data from Agent'
          />
          <Spinner isLoading={isLoading} data={data}>
            <MaterialTable
              columns={agentPostingDetailsColumns}
              data={data}
              style={{
                fontFamily: 'Nunito',
                width: '100%',
                boxShadow: 'none'
              }}
              options={{
                actionsColumnIndex: 4,
                sorting: true,
                showTitle: false,
                exportButton: true,
                loadingType: 'linear',
                headerStyle: {
                  backgroundColor: '#4f4f4f',
                  color: '#FFF'
                }
              }}
              components={{
                CircularProgress: props => (
                  <div style={{ backgroundColor: '#4f4f4f' }}>
                    <CircularProgress {...props} />
                  </div>
                )
              }}
            />
          </Spinner>
        </div>
      </section>
    );
  }
}

export default connect((state, props) => ({
  posting: getAgentPostings(state)[props.match.params.communityId]
}))(AgentPostingDetails);
