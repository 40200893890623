import React, { Component } from 'react'

class Payments extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <div className='container'>
        <div className='row my-5'>
          <div className='dropdown'>
            <button
              className='btn btn-light border dropdown-toggle'
              type='button'
              id='dropdownMenuButton'
              data-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded='false'
            >
              Choose a task or posting from the below
            </button>
            <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
              {this.props.communityResourceList
                ? this.props.communityResourceList.map((communityResource, index) => {
                    return (
                      <button
                        key={index}
                        className='dropdown-item'
                        button
                        onClick={() => {
                          this.initAnalytics(communityResource.name, communityResource.id)
                          this.setState({ communitySelected: true })
                          this.props.fetchFarmItemsByCommunityResource(communityResource.id)
                        }}
                      >
                        {communityResource.name}
                      </button>
                    )
                  })
                : 'No Items....'}
            </div>
          </div>
          <button className='btn btn-revenue text-white mx-4'>Pay</button>
        </div>
      </div>
    )
  }
}

export default Payments
