import React, { Component } from 'react'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import BASEURL from '../../../security/base-url'
import * as actions from '../../pages/community/communities.actions'
import CommunityMembers from '../../pages/community/CommunityMembers'

class NewCommunityChat extends Component {
  state = {
    isSelectAll: false,
    selectedMembers: [],
    selectedNumbers: [],
    message: '',
    isCheck: false
  }
  componentDidMount() {
    this.props.getMemberSmsMessages(this.props.communityId)
  }
  updateSelectedMembers = oldSelectedMembers => {
    this.setState({ selectedMembers: oldSelectedMembers })
  }
  resetFields = () => {
    document.getElementById('message').value = ''
    this.setState({ selectedNumbers: [] })
  }
  justTest = () => {
     
  }
   
  sendMessage = e => {
    e.preventDefault()
    this.setState(
      {
        selectedNumbers: [],
        message: document.getElementById('message').value
      },
      () => {
        this.state.selectedMembers.forEach(m => this.state.selectedNumbers.push({ phone: m.phone }))
        if (this.state.selectedNumbers !== []) {
          const data = {
            message: this.state.message,
            numbers: this.state.selectedNumbers
          }
          const headersConfig = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            }
          }

          fetch(
            BASEURL.url_dashbord +
              '/community-resource/sendmessagetomembers/' +
              this.props.communityId,
            headersConfig
          )
            .then(response => response.json())
            .then(data => {
              if (data.code === 0) {
                this.props.getMemberSmsMessages(this.props.communityId)

                Swal({
                  title: 'Success!',
                  text: 'Message send successfully!',
                  type: 'success',
                  animation: true
                })
                this.resetFields()
                this.props.resetSelectedMembers(true)
              } else {
                Swal({
                  title: 'Error!',
                  text: 'Error while trying to send message!',
                  type: 'error',
                  animation: true
                })
              }
            })
            .catch(error => {
              Swal({
                title: 'Error!',
                text: 'Error while trying to send message!',
                type: 'error',
                animation: true
              })
            })
        }
      }
    )
  }
  disabledButton = () => {
    return this.state.message === '' && this.state.isCheck === false ? true : false
  }
  onChecked = e => {
    e.target.checked === true ? this.setState({ isCheck: true }) : this.setState({ isCheck: false })
  }
  handleOnChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  render() {
    // console.log("Member SMS Messages >>> ", this.props.smsMessages)
    // if (Object.keys(this.props.smsMessages).length > 0) {
    //   console.log("Member SMS Messages >>> ", JSON.stringify(this.props.smsMessages[0].createdOn))
    // }
    return (
      <div className='container-fluid'>
        <div className='row'>
          <CommunityMembers
            updateSelectedMembers={this.updateSelectedMembers}
            selectedMembers={this.selectedMembers}
          />

          <div className='col-8 fix-height revenue-scrollbar'>
            <div className='tab-content' id='v-pills-tabContent'>
              <div
                className='tab-pane fade show active'
                id='v-pills-home'
                role='tabpanel'
                aria-labelledby='v-pills-home-tab'
              >
                {/* 1st Tab */}

                {this.props.smsMessages
                  ? this.props.smsMessages.map((m, index) => {
                      return (
                        <div className='row' key={index}>
                          <div className='col-lg-5'>
                            <div className='message-admin'>{m.message}</div>
                            <div className='time-stamp'>{m.createdOn}</div>
                          </div>
                          <div className='col-lg-7' />
                        </div>
                      )
                    })
                  : ''}
                {/*  */}

                {/* <div className="row my-3">
                  <div className="col-lg-7" />
                  <div className="col-lg-5">
                    <div className="message-user d-flex justify-content-end">
                      Farmers Message Here This is where you see messages from
                      the users
                    </div>
                    <div className="time-stamp">15/03 1:20 AM</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-7" />
                  <div className="col-lg-5">
                    <div className="message-user d-flex justify-content-end">
                      Farmers Message Here This is where you see messages from
                      the users
                    </div>
                    <div className="time-stamp">15/03 1:20 AM</div>
                  </div>
                </div> */}
                <form
                  className=' form-row form-inline fix-height revenue-scrollbar align-items-end'
                  onSubmit={e => this.sendMessage(e)}
                >
                  <textarea
                    rows='2'
                    name='message'
                    id='message'
                    className='form-control col-md-9 mb-0'
                    placeholder='Type a message'
                    onChange={e => this.handleOnChange(e)}
                  />
                  <div className='form-check col-md-2'>
                    {/* <label
                      className="form-check-label ml-0"
                      for="inlineFormCheck">
                      Send SMS
                    </label> */}
                    <select className='custom-select'>
                      <option selected>Choose Option</option>
                      <option value='1'>Email</option>
                      <option value='2'>SMS</option>
                    </select>
                    {/* <input
                      className="form-check-input"
                      type="checkbox"
                      id="inlineFormCheck"
                      onChange={e => this.onChecked(e)}
                    /> */}
                  </div>
                  <button
                    className='btn btn-revenue text-white px-4'
                    id='submitId'
                    disabled={this.state.message === '' ? true : false}
                  >
                    <i className='fas fa-paper-plane fa-sm' aria-hidden='true' />
                  </button>
                </form>
                {/* 1st Tab Closes */}
              </div>
              <div
                className='tab-pane fade'
                id='v-pills-profile'
                role='tabpanel'
                aria-labelledby='v-pills-profile-tab'
              >
                {/* 2nd Tab */}
                {/* <div className="row">
                  <div className="col-lg-5">
                    <div className="message-admin">
                      Admin Message Here This is where you see messages from the
                      admin
                    </div>
                    <div className="time-stamp">15/03 1:20 AM</div>
                  </div>
                  <div className="col-lg-7" />
                </div>
                <div className="row my-3">
                  <div className="col-lg-7" />
                  <div className="col-lg-5">
                    <div className="message-user d-flex justify-content-end">
                      Farmers Message Here This is where you see messages from
                      the users
                    </div>
                    <div className="time-stamp">15/03 1:20 AM</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-7" />
                  <div className="col-lg-5">
                    <div className="message-user d-flex justify-content-end">
                      Farmers Message Here This is where you see messages from
                      the users
                    </div>
                    <div className="time-stamp">15/03 1:20 AM</div>
                  </div>
                </div> */}

                {/* 2nd Tab Closes */}
              </div>
              <div
                className='tab-pane fade'
                id='v-pills-messages'
                role='tabpanel'
                aria-labelledby='v-pills-messages-tab'
              >
                {/* Home Tab */}
                {/* <div className="row">
                  <div className="col-lg-5">
                    <div className="message-admin">
                      Admin Message Here This is where you see messages from the
                      admin
                    </div>
                    <div className="time-stamp">15/03 1:20 AM</div>
                  </div>
                  <div className="col-lg-7" />
                </div>
                <div className="row my-3">
                  <div className="col-lg-7" />
                  <div className="col-lg-5">
                    <div className="message-user d-flex justify-content-end">
                      Farmers Message Here This is where you see messages from
                      the users
                    </div>
                    <div className="time-stamp">15/03 1:20 AM</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-7" />
                  <div className="col-lg-5">
                    <div className="message-user d-flex justify-content-end">
                      Farmers Message Here This is where you see messages from
                      the users
                    </div>
                    <div className="time-stamp">15/03 1:20 AM</div>
                  </div>
                </div> */}

                {/* 3rd Tab Closes */}
              </div>
              <div
                className='tab-pane fade'
                id='v-pills-settings'
                role='tabpanel'
                aria-labelledby='v-pills-settings-tab'
              >
                Topic Discussion 3
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    smsMessages: state.comReducer.smsMessages,
    communityId: state.comReducer.communityId
  }
}
const mapDispatchToProps = dispatch => {
  return {
    getMemberSmsMessages: communityId => dispatch(actions.getMemberSmsMessages(communityId)),
    resetSelectedMembers: reset => dispatch(actions.resetSelectedMembers(reset))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewCommunityChat)
