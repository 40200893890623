import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import messages from '../../assets/messages.svg';
import notifications from '../../assets/notifications.svg';
import placeholder from '../../assets/placeholder.jpg';
import { requestLogout } from '../app.actions';

class Navbar extends PureComponent {
  clearUser = () => {
    return this.props.requestLogout(this.props.history);
  };
  render() {
    const { navLinks, location, history } = this.props;

    return (
      <nav className='navbar navbar-expand-lg navbar-light bg-light'>
        <div className='container-fluid'>
          <div className='d-flex flex-column'>
            <div className='d-flex'>
              {location.pathname !== '/' && location.pathname !== '/ex-overview' && (
                <button className='btn btn-outline-light p-0 mr-1' onClick={history.goBack}>
                  <i className='fa fa-arrow-alt-circle-left fa-2x greener' />
                </button>
              )}
              {/*
               ** displays array of navlinks and adds the green class to signifiy the active page
               */}
              {navLinks.map(({ header = '', path = '/' }, i) =>
                navLinks[i].header === navLinks[navLinks.length - 1].header ? (
                  <NavLink key={`nav${i}`} to={path}>
                    <h6 className='green underline mt-2'>{header}</h6>
                  </NavLink>
                ) : (
                  <NavLink key={`nav${i}`} to={path}>
                    <h6 className='greener mt-2'>{header}</h6>
                  </NavLink>
                )
              )}
            </div>
            <small>{this.props.small}</small>
          </div>
          <button
            className='btn btn-dark d-inline-block d-lg-none ml-auto'
            type='button'
            data-toggle='collapse'
            data-target='#navbarSupportedContent'
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <i className='fas fa-align-justify' />
          </button>
          <div className='collapse navbar-collapse' id='navbarSupportedContent'>
            <ul className='nav flex-row align-items-baseline justify-content-end ml-auto'>
              {/* <li className='nav-item'>
                <button className='btn nav-link px-0'>
                  <img src={messages} alt='messages' className='d-inline' />
                </button>
              </li>
              <li className='nav-item'>
                <button className='btn nav-link px-0'>
                  <img src={notifications} alt='messages' className='d-inline mx-4' />
                </button>
              </li> */}
              <li className='nav-item dropdown'>
                <button
                  className=' btn nav-link px-0'
                  id='dropdownMenuLink'
                  data-toggle='dropdown'
                  aria-haspopup='true'
                  aria-expanded='false'
                >
                  <img
                    src={!this.props.assets ? this.props.assets.logo : placeholder}
                    alt={'profile'}
                    className='d-inline nav-round'
                    style={{ width: 45, height: 45 }}
                  />
                </button>
                <div className='dropdown-menu dropdown-menu-right'>
                  {/* <NavLink to='/wallet' className='dropdown-item'>
                    Wallet
                  </NavLink> */}
                  <button className='dropdown-item' onClick={this.clearUser}>
                    Log Out
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

export default connect(mapStateToProps, { requestLogout })(withRouter(Navbar));
