import React from 'react'

const { compose, withProps } = require('recompose')
const { withScriptjs, withGoogleMap, GoogleMap, Polygon } = require('react-google-maps')

const FarmMap = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyDuSYpAv3M_Z8rimKO7rV8-Go6-FKmWhJ4&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px`, margin: '1%' }} />,
    mapElement: <div style={{ height: `100%` }} />,
    center: { lat: 9.0765, lng: 7.3986 }
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap defaultZoom={3} defaultCenter={{ lat: 9.0765, lng: 7.3986 }}>
    <Polygon path={props.mapMarkers} />
  </GoogleMap>
))

export default FarmMap
