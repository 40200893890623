import { all, call, takeEvery, takeLatest } from 'redux-saga/effects';
import { actions } from '../../api/api.constant';
import { TOGGLE_SERVICE, UPDATE_SERVICE_PROVIDERS } from './serviceProvider.constants';

import {
  fetchAgentCapturedData,
  fetchAgents,
  fetchServiceProviders,
  fetchSharedAgencies,
  saveServiceProvider,
  toggleServiceStatus
} from './serviceProvider.saga';
// watchers
export function* watchFetchServiceProviders() {
  yield takeLatest(actions.REQUEST_SERVICE_PROVIDERS, fetchServiceProviders);
}
export function* watchFetchCurrentServiceProvider() {
  yield takeLatest(actions.REQUEST_SHARED_AGENCIES, fetchSharedAgencies);
}

export function* watchSaveServiceProvider() {
  yield takeEvery(UPDATE_SERVICE_PROVIDERS, saveServiceProvider);
}

export function* watchProviderToggleService() {
  yield takeLatest(TOGGLE_SERVICE, toggleServiceStatus);
}

export function* watchFetchAgents() {
  yield takeLatest(actions.REQUEST_AGENTS, fetchAgents);
}
export function* watchFetchAgentCapturedData() {
  yield takeLatest(actions.REQUEST_CAPTURED_DATA, fetchAgentCapturedData);
}

export function* serviceProvidersSaga() {
  return yield all([
    call(watchFetchServiceProviders),
    call(watchSaveServiceProvider),
    call(watchProviderToggleService),
    call(watchFetchCurrentServiceProvider),
    call(watchFetchAgents),
    call(watchFetchAgentCapturedData)
  ]);
}
