import React, { memo } from 'react'

const ActivationModal = ({
  activateAgent,
  agentData,
  clearActivationForm,
  handleActivationInput
}) => {
  const activate = _ => activateAgent(agentData)
  return (
    <div
      className='modal fade'
      id='activateModal'
      tabIndex='-1'
      role='dialog'
      aria-labelledby='activate agent'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-centered' role='document'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='h5 modal-title font-weight-bold'>Activate agent</h5>
            <button
              onClick={clearActivationForm}
              className='close'
              data-dismiss='modal'
              aria-label='Close'
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
          <div className='modal-body'>
            <div className='d-flex justify-content-around'>
              <span className='d-flex align-items-baseline'>
                <label className='font-18' htmlFor='extension'>
                  Extension
                </label>
                <input
                  onChange={handleActivationInput}
                  className='m-1'
                  type='checkbox'
                  name='activationType'
                  id='extension'
                  value='extension'
                />
              </span>
              <span className='d-flex align-items-baseline mr-2'>
                <label className='font-18' htmlFor='inputDistribution'>
                  Input <br /> Distribution
                </label>
                <input
                  onChange={handleActivationInput}
                  className='m-1'
                  type='checkbox'
                  name='activationType'
                  id='inputDistribution'
                  value='inputDistribution'
                />
              </span>
              <span className='d-flex align-items-baseline mr-2'>
                <label className='font-18' htmlFor='kyf'>
                  KYC
                </label>
                <input
                  onChange={handleActivationInput}
                  className='m-1'
                  type='checkbox'
                  name='activationType'
                  id='kyf'
                  value='kyf'
                />
              </span>
              <span className='d-flex align-items-baseline mr-2'>
                <label className='font-18' htmlFor='mapping'>
                  {' '}
                  Mapping
                </label>
                <input
                  onChange={handleActivationInput}
                  className='m-1'
                  type='checkbox'
                  name='activationType'
                  id='mapping'
                  value='mapping'
                />
              </span>
            </div>
          </div>
          <div className='modal-footer'>
            <button
              onClick={clearActivationForm}
              className='btn text-white btn-expend'
              data-dismiss='modal'
            >
              Close
            </button>
            <button onClick={activate} className='btn text-white btn-revenue'>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default memo(ActivationModal, (prevProps, nextProps) => prevProps === nextProps)
