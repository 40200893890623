import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import { api } from '../../../api/api.config';
import notify from '../../../helpers/notify';
import BaseUrl from '../../../../security/base-url';

const { error, success } = notify();

const RecoverPasswordModal = ({ clearRecoverModal, agentsId }) => {
  const [inputs, setInputs] = useState([]);

  const onChange = event => {
    event.persist();
    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value
    }));
  };

  //Pass in password recovery path here

  const recoverPassword = async e => {
    e.preventDefault();
    const data = {
      'newPassword': inputs.newPassword,
      'agentId': agentsId,
      'phone':JSON.parse(localStorage.getItem('selected_Agent')).phone
    }
    const options = {
      method: 'POST',
      headers:{
        'content-type': 'application/json'
      },
    
      body: JSON.stringify(data)

    }
    fetch(BaseUrl.url_dashbord + "/login/changePassword", options)
      .then(data => {
        if (!data.ok) {
          error('An error occurred while trying to update password.');
          throw Error(data.status);
        } else {
          return data.json();
        }
      }).then(update => {
        setInputs('');
        success('Password changed successfully');
      });
    // try {
    //   const recover = await api.post('', { agentsId, newPassword });
    //   const recoverRes = await recover.json();
    //   success(recoverRes.message);
    // } catch (_) {
    //   error('An error occurred while trying to update password.');
    // } finally {
    //   this.clearRecoverModal();
    // }
  };

  return (
    <div className='modal fade' id='recoverModal' tabIndex={-1} role='dialog' aria-hidden='true'>
      <div className='modal-dialog' role='document'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h4>Recover Password</h4>
            <button
              onClick={clearRecoverModal}
              className='close'
              data-dismiss='modal'
              aria-label='Close'
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
          <div className='modal-body p-4'>
            <form onSubmit={recoverPassword}>
              <label htmlFor='new-password'>New password</label>
              <input
                id='new-password'
                type='password'
                className='border p-2 w-100'
                name='newPassword'
                required
                value={inputs.Password}
                onChange={onChange}
              />
              <div className='text-right'>
                <button
                  onClick={clearRecoverModal}
                  className='btn btn-expend text-white mx-3'
                  data-dismiss='modal'
                >
                  Close
                </button>
                <button className='btn btn-revenue text-white'>Save changes</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

RecoverPasswordModal.propTypes = {
  clearRecoverModal: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default memo(RecoverPasswordModal);
