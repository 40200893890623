import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Form, InputGroup } from 'react-html5-form';
import Swal from 'sweetalert2';
import '../../pages/community/communityStyle.css';
import apiService from '../../api/apiService';
import { api } from '../../api/api.config';

const CommunitiesResourceForms = props => {

  const { communityResourceName,
    communityResourceDescription,
    communityResourceState,
    isMappingEnabled,
    states} = props;
    
  const handleCommunityResource = _ => {

    const basicInfo = {
      name: communityResourceName,
      description: communityResourceDescription,
      mappingEnabled: isMappingEnabled,
      state: communityResourceState
    };
    const newCommunityResourceInfo = props.isEditing
      ? {
          ...basicInfo,
          id: undefined,
          status: '',
          type: '',
          createdOn: ''
        }
      : basicInfo;
    submitNewFormData(newCommunityResourceInfo);
  };

  

  const submitNewFormData = async communityResource => {
    const { isEditing } = props;
   
   
    try {
      const response = await isEditing ? api.put('/community-resource/',communityResource ) : api
      .post('/community-resource/',communityResource )
      if (response.message === 'success') {
        Swal({
          title: 'Success!',
          text: isEditing
            ? 'Community resource successfully updated!'
            : 'New community resource added!',
          type: 'success',
          animation: true
        });
      }
    } catch (error) {
      Swal({
        title: 'Error!',
        text: isEditing
          ? 'Error occur while updating community resource.'
          : 'Error occur while adding new community resource.',
        type: 'error',
        animation: true
      });
    } finally {
      document.querySelector('#communityResourceClose').click();
    }
  };

  const { clearForm, handleEnableMapping, onInputChange, formState } = props;
  return (
    <>
      {/* start modal body for community resource  */}
      <div
        className='modal fade'
        id='communityResourceModal'
        data-backdrop='static'
        data-keyboard='false'
        tabIndex={-1}
        role='dialog'
        aria-labelledby='CommunityResourceModal'
        aria-hidden='true'
      >
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h4> {!props.isEditing ? 'Add Community Resource' : 'Edit Community Resource'}</h4>
              <button
                type='button'
                className='close'
                id='communityResourceClose'
                data-dismiss='modal'
                aria-label='Close'
                onClick={clearForm}
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              <Form id='resourceForm' name='resourceForm' onSubmit={handleCommunityResource}>
                {({ submitting, pristine }) => (
                  <div>
                    {/* start resource name input */}
                    <InputGroup tag='div' validate={['communityResourceName']}>
                      {({ error, valid }) => (
                        <div className='col-md-12 my-2'>
                          <input
                            type='text'
                            required
                            className={`form-control form-control-sm ${!valid && 'is-invalid'}`}
                            id='name'
                            name='communityResourceName'
                            onChange={onInputChange}
                            value={formState.communityResourceName}
                            placeholder='Enter Community Resource Name'
                          />
                          {error && <div className='invalid-feedback'>{error}</div>}
                        </div>
                      )}
                    </InputGroup>
                    {/* start resource name input */}
                    {/* start resource description input */}
                    <InputGroup tag='div' validate={['communityResourceDescription']}>
                      {({ error, valid }) => (
                        <div className='col-md-12'>
                          <textarea
                            className={`form-control form-control-sm ${!valid && 'is-invalid'}`}
                            required
                            id='description'
                            name='communityResourceDescription'
                            onChange={onInputChange}
                            value={formState.communityResourceDescription}
                            rows='3'
                            placeholder='Enter Community Resource Description'
                          />
                          {error && <div className='invalid-feedback'>{error}</div>}
                        </div>
                      )}
                    </InputGroup>
                    <div className='m-3 d-flex justify-content-between align-items-center px-1 small-font-fix'>
                      <div className='text-left text-black-50 mr-2'>
                        <span className='switch'>
                          <label htmlFor='mappingEnabled'>
                            Enable Mapping
                            <input
                              onClick={handleEnableMapping}
                              id='mappingEnabled'
                              name='mapping'
                              type='checkbox'
                              onChange={onInputChange}
                              value={formState.isMappingEnabled}

                            />
                            <span></span>
                          </label>
                        </span>
                      </div>
                      {formState.isMappingEnabled && (
                        <>
                          {/* <InputGroup validate={['communityResourceCountry']}>
                            {({ error, valid }) => (
                              <>
                                <input
                                  type='text'
                                  required
                                  className={`form-control form-control-sm mb-0 ${!valid &&
                                    'is-invalid'}`}
                                  id='communityCountry'
                                  name='communityResourceCountry'
                                  onChange={onInputChange}
                                  value={formState.communityResourceCountry}
                                  placeholder='Enter your country'
                                />
                                {error && <div className='invalid-feedback'>{error}</div>}
                              </>
                            )}
                          </InputGroup> */}
                          <InputGroup validate={['communityResourceState']}>
                            {({ error, valid }) => (
                              <>
                                <select
                                  required
                                  className={`form-control form-control-sm ${!valid &&
                                    'is-invalid'}`}
                                  id='communityState'
                                  name='communityResourceState'
                                  onChange={onInputChange}
                                  value={formState.communityResourceState}
                                >
                                  <option value='default'>Select state</option>
                                  {props.formState.states.map((state, idx) => (
                                    <option key={idx} value={state.name}>
                                      {state.name}
                                    </option>
                                  ))}
                                </select>
                                {error && <div className='invalid-feedback'>{error}</div>}
                              </>
                            )}
                          </InputGroup>
                        </>
                      )}
                    </div>
                    {/* start resource description input */}

                    <div className='modal-footer'>
                      <button
                        type='button'
                        className='btn btn-expend text-white'
                        data-dismiss='modal'
                        onClick={clearForm}
                      >
                        Close
                      </button>
                      <button
                        type='submit'
                        id='submitButton'
                        name='submitButton'
                        className='btn btn-revenue text-white float-right'
                        disabled={pristine || submitting}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                )}
              </Form>
            </div>
          </div>

          {/* end modal body for community resource  */}
        </div>
      </div>
    </>
  );
};
CommunitiesResourceForms.propTypes = {
  communityResourceName : PropTypes.string,
      communityResourceDescription: PropTypes.string, 
      communityResourceState: PropTypes.string,
      isMappingEnabled: PropTypes.bool,
      states: PropTypes.array
};
export default CommunitiesResourceForms;
