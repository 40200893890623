import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { NavLink, Switch } from 'react-router-dom';
import Footer from '../../components/Footer';
import ImportResourceModal from '../../components/modals/ImportResourceModal';
import Navbar from '../../components/Navbar';
import Spinner from '../../components/Spinner';
import WithSubRoutes from '../../HOC/WithSubRoutes';
import './styles/serviceProvider.css';
import { selectCurrentServiceProvider } from './serviceProvider.selectors';

class ActiveServices extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    routes: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedServiceProvider: PropTypes.object.isRequired
  };

  state = {
    activeServices: []
  };

  componentDidMount() {
    const activeServices = [];
    const { selectedServiceProvider } = this.props;

    // on mount create an activeServices array
    if (selectedServiceProvider.hasSharedAgency) {
      activeServices.push('Shared Agency');
    }
    if (selectedServiceProvider.hasLabour) {
      activeServices.push('Labour');
    }
    if (selectedServiceProvider.hasInputSupply) {
      activeServices.push('Input Supply');
    }
    if (selectedServiceProvider.hasExtensionService) {
      activeServices.push('Extension Services');
    }
    this.setState({ activeServices });
  }

  renderActiveServicesRoutes = () => {
    const activeServicesRoutes = this.props.routes.map(route => (
      <WithSubRoutes key={route.path} {...route} {...this.props} />
    ));
    return activeServicesRoutes;
  };

  // if used again move to utils
  renderStyles = (service = '', serviceURLs) => {
    const {
      location,
      match: { url }
    } = this.props;
    return location.pathname === url || location.pathname === `${url}/${serviceURLs[service]}`
      ? 'active-nav p-3'
      : 'p-3 nav-item';
  };

  renderActiveServicesNav = () => {
    const {
      match: { url }
    } = this.props;
    const { activeServices } = this.state;

    const activeServicesURL = {
      'Shared Agency': 'shared-agency',
      'Input Supply': 'input-supply',
      'Extension Services': 'extension-services',
      Labour: 'labour'
    };
    if (Object.entries(activeServices).length > 0) {
      const activeServiceTabs = activeServices.map(service => (
        <li className={this.renderStyles(service, activeServicesURL)} key={`active${service}`}>
          <NavLink to={`${url}/${activeServicesURL[service]}`}>{service}</NavLink>
        </li>
      ));
      return activeServiceTabs;
    }
  };

  render() {
    const {
      match: {
        params: { serviceProviderId }
      }
    } = this.props;
    const { activeServices } = this.state;
    return (
      <>
        <Navbar
          navLinks={[
            {
              path: '/resources/serviceprovider',
              header: 'ServiceProviders |'
            },
            {
              path: `/service-provider-resource/${serviceProviderId}`,
              header: '| Active Services'
            }
          ]}
          small='View and manage service provider'
        />
        <div className='pt-3 border border-light'>
          <ul className='navbar-nav mr-auto d-flex flex-wrap flex-row'>
            <Spinner data={activeServices}>{this.renderActiveServicesNav()}</Spinner>
          </ul>

          <Switch>{this.renderActiveServicesRoutes()}</Switch>
        </div>
        <ImportResourceModal />
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  selectedServiceProvider: selectCurrentServiceProvider(state, props)
});
export default connect(mapStateToProps)(ActiveServices);
