import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import BaseUrl from '../../../security/base-url'
import apiService from '../../api/apiService'
import Navbar from '../../components/Navbar'
import { handleEditAgentData } from './serviceProvider.actions'
import notify from '../../helpers/notify'

const {error, success} = notify()
class AgentInfoForm extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    editAgentData: PropTypes.object,
    match: PropTypes.object.isRequired,
    handleEditAgentData: PropTypes.func.isRequired
  }
  constructor(props) {
    super(props)
    this.state = {
      firstName: '',
      surname: '',
      othername: '',
      gender: '',
      phone: '',
      email: '',
      state: '',
      lga: '',
      town: '',
      address: '',
      states: [],
      lgs: [], //local governments
      wards: [],
      agentListActive: [],
      agentListInActive: [],
      sharedAgencyId: this.props.match.params.sharedAgencyId,
      isUpdate: false
    }
  }

  /**
   *
   * Fetch all the  from the api bysharedagency
   */

  componentDidMount() {
    this.getStates()
    const { editAgentData } = this.props
    if (editAgentData !== undefined || Object.keys(editAgentData).length > 0) {
      this.setState({
        isUpdate: true,
        firstName: editAgentData.firstName,
        surname: editAgentData.surname,
        othername: editAgentData.othername,
        gender: '',
        phone: editAgentData.phone,
        email: editAgentData.email,
        state: '',
        lga: '',
        town: '',
        address: editAgentData.address
      })
    }
  }

  componentWillUnmount() {
    this.props.handleEditAgentData({})
  }

  getStates = () => {
    fetch(BaseUrl.url_dashbord + '/wards/states', {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    })
      .then(response => response.json())
      .then(states => {
        this.setState({
          states: states
        })
      })
      .catch(err => {
        console.log(err)
      })
  }

  getLocalGovts = state => {
    fetch(BaseUrl.url_dashbord + '/wards/lgs/' + state, {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    })
      .then(response => response.json())
      .then(lgs => {
        this.setState({
          lgs: lgs
        })
      })
  }

  getWards = lg => {
    fetch(BaseUrl.url_dashbord + '/wards/wards/' + lg, {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    })
      .then(response => response.json())
      .then(wards => {
        this.setState({
          wards: wards
        })
      })
  }

  onChange = e => {
    e.target.name === 'state' && this.getLocalGovts(e.target.value)
    e.target.name === 'lga' && this.getWards(e.target.value)
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSubmitForm = event => {
    event.preventDefault()
    let agentData = {
      firstName: this.state.firstName,
      surname: this.state.surname,
      othername: this.state.othername,
      gender: this.state.gender,
      phone: this.state.phone,
      email: this.state.email,
      state: this.state.state,
      lga: this.state.lga,
      town: this.state.town,
      address: this.state.address,
      sharedAgencyId: this.props.match.params.sharedAgencyId
    }
    if (this.state.isUpdate === true) {
      agentData.id = this.props.editAgentData.id
    } else {
      agentData.id = 0
    }
    this.submitData(agentData)
  }

  submitData = async agentData => {
    try {
      const res = await apiService.post('/agents/saveagent/', agentData)
      const saveAgentRes = await res.json()
        this.props.handleEditAgentData(undefined)
     success(saveAgentRes.message)
    } catch (reason) {
      error('Unable to save data. Try again.')
    } finally {
      this.clearState()
      this.setState({ isUpdate: false })
    }
  }

  clearState = () =>
    this.setState({
      id: '',
      firstName: '',
      surname: '',
      othername: '',
      gender: '',
      phone: '',
      email: '',
      state: '',
      lga: '',
      town: '',
      address: '',
      sharedAgencyId: this.props.match.params.sharedAgencyId
    })

  render() {
    let regexPath = /edit-agent/gim
    const {
      match: {
        params: { sharedAgencyId, serviceProviderId }
      },
      location: { pathname }
    } = this.props
    return (
      <div>
        <Navbar
          navLinks={[
            {
              path: `/new-service-provider-resource/${serviceProviderId}`,
              header: 'Services |'
            },
            {
              path: `/${serviceProviderId}/new-shared-agency/${sharedAgencyId}`,
              header: `| Shared Agency`
            },
            {
              header: regexPath.test(this.props.location.pathname)
                ? '| Edit Agent'
                : '| Add New Agent',
              path: `${pathname}`
            }
          ]}
          small='Add new agent details here'
        />
        <div className='d-flex flex-column align-items-center my-3'>
          <h4>
            Boxes with ' <span className='red'>*</span> 'must be filled for form to submit
          </h4>
          <p>Progress is automatically saved</p>
        </div>
        <div className='d-flex justify-content-center bg-light'>
          <form className='w-40 my-4' onSubmit={this.handleSubmitForm}>
            <div className='form-group'>
              <label htmlFor='agentFirstName'>
                First Name <span className='red'>*</span>
              </label>
              <input
                type='text'
                className='form-control'
                id='agentFirstName'
                aria-describedby='emailHelp'
                placeholder='Teresa'
                required
                defaultValue={this.state.firstName}
                name='firstName'
                onChange={this.onChange}
              />
            </div>
            <div className='form-group'>
              <label htmlFor='agentLastName'>
                Last Name<span className='red'>*</span>
              </label>
              <input
                type='text'
                className='form-control'
                id='agentLastName'
                aria-describedby='emailHelp'
                placeholder='Thompson'
                name='surname'
                value={this.state.surname}
                onChange={this.onChange}
              />
            </div>
            <div className='form-group'>
              <label htmlFor='agentOtherName'>Other Name</label>

              <input
                type='text'
                className='form-control'
                id='agentLastName'
                aria-describedby='emailHelp'
                placeholder='James'
                name='othername'
                value={this.state.othername}
                onChange={this.onChange}
              />
            </div>
            <div className='form-group'>
              <div>
                <span>Phone</span>
                <input
                  type='text'
                  required
                  className='form-control'
                  placeholder='Phone'
                  name='phone'
                  value={this.state.phone}
                  onChange={this.onChange}
                />
              </div>
            </div>
            <div className='form-group'>
              <div>
                <span>Email</span>
                <input
                  required
                  className='form-control'
                  placeholder='Email'
                  name='email'
                  value={this.state.email}
                  onChange={this.onChange}
                />
              </div>
            </div>
            <div className='form-group'>
              <div>
                <span>Address</span>
                <input
                  type='text'
                  required
                  className='form-control'
                  placeholder='Address'
                  name='address'
                  value={this.state.address}
                  onChange={this.onChange}
                />
              </div>
            </div>
            <div className='form-group '>
              <span>Gender</span>
              <select
                className='form-control'
                required
                name='gender'
                value={this.state.gender}
                onChange={this.onChange}
              >
                <option>Choose...</option>
                <option value='Male'>Male</option>
                <option value='Female'>Female</option>
              </select>
            </div>

            <div className='form-group'>
              <span>State</span>
              <select
                className='form-control'
                required
                name='state'
                value={this.state.state}
                onChange={this.onChange}
              >
                <option>Choose...</option>
                {this.state.states.map(state => (
                  <option key={state.id} value={state.id}>
                    {state.name}
                  </option>
                ))}
                ;
              </select>
            </div>

            <div className='form-group'>
              <span>Local Government</span>
              <select
                className='form-control'
                required
                name='lga'
                value={this.state.lga}
                onBlur={this.onChange}
              >
                <option>Choose...</option>
                {this.state.lgs.map(lg => (
                  <option key={lg.id} value={lg.id}>
                    {lg.name}
                  </option>
                ))}
                ;
              </select>
            </div>
            <div className='text-center'>
              <button type='submit' className='btn btn-revenue text-white'>
                {this.state.isUpdate === false ? 'Save' : 'Save changes'}
              </button>
            </div>
          </form>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  editAgentData: state.serviceProvider.editAgentData
})
export default connect(mapStateToProps, { handleEditAgentData })(AgentInfoForm)
