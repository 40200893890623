import { CircularProgress } from '@material-ui/core';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { capturedDataColumns } from '../../../utils/tablecolumns';
import {
  selectAgentCapturedData,
  selectCapturedDataRequesting
} from '../../../pages/serviceProvider/serviceProvider.selectors';
import { fetchData } from '../../../api/api.actions';
import Toolbar from '../../Toolbar';
import Spinner from '../../Spinner';
import { connect } from 'react-redux';

class CapturedData extends PureComponent {
  componentDidMount() {
    const {
      fetchData,
      match: { params }
    } = this.props;
    fetchData('CAPTURED_DATA', { agentId: params.agentId });
  }
  render() {
    const { dataList, isLoading } = this.props;

    return (
      <div className='bg-white my-3'>
        <Toolbar />
        <Spinner isLoading={isLoading} data={dataList}>
          <MaterialTable
            columns={capturedDataColumns}
            data={dataList}
            style={{
              fontFamily: 'Nunito',
              width: '100%',
              boxShadow: 'none'
            }}
            options={{
              actionsColumnIndex: 4,
              sorting: true,
              showTitle: false,
              exportButton: true,
              loadingType: 'linear',
              headerStyle: {
                backgroundColor: '#4f4f4f',
                color: '#FFF'
              }
            }}
            components={{
              CircularProgress: props => (
                <div style={{ backgroundColor: '#4f4f4f' }}>
                  <CircularProgress {...props} />
                </div>
              )
            }}
          />
        </Spinner>
      </div>
    );
  }
}

CapturedData.propTypes = {
  match: PropTypes.shape({ params: PropTypes.objectOf({ id: PropTypes.string }) }),
  fetchData: PropTypes.func.isRequired,
  dataList: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default connect(
  state => ({
    dataList: selectAgentCapturedData(state),
    isLoading: selectCapturedDataRequesting(state)
  }),
  { fetchData }
)(CapturedData);
