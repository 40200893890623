import React from 'react'

export default function GrantAccessModal(props) {
  const {
    formState: { companyInfo },
    grantAccess
  } = props

  const renderResources = () =>
    companyInfo.resources &&
    companyInfo.resources.map(({ resource, reosurceList }) => {
      return (
        <div className='border rounded bg-light mb-3' key={resource}>
          <div className='border-bottom px-2 pt-2'>
            <h5 className='font-weight-bold'>{resource}</h5>
          </div>
          {reosurceList &&
            reosurceList.map(subResource => (
              <div
                className='p-2 d-flex align-items-baseline justify-content-between'
                key={subResource}
              >
                <h5 className='font-fix'> {subResource} </h5>
                <select
                  id={resource}
                  name={subResource}
                  className='subResource custom-select form-control-sm border-0 w-25 w-sm-50'
                  required
                >
                  <option value='select' name='select'>
                    Select..
                  </option>
                  <option value='viewOnly' name='viewOnly'>
                    {' '}
                    View only
                  </option>
                  <option value='Edit' name='Edit'>
                    View & Edit
                  </option>
                </select>
              </div>
            ))}
        </div>
      )
    })

  return (
    <div
      className='modal fade'
      id='grantAccess'
      tabIndex='-1'
      data-backdrop='static'
      data-keyboard='false'
      role='dialog'
      aria-labelledby='Grant Resource'
      aria-hidden='true'
    >
      <div className='modal-dialog-centered modal-dialog modal-md' role='document'>
        <div className='modal-content'>
          <div className='modal-header'>
            <div>
              <h5 className='modal-title'>
                <span className='greener mr-2 font-18'>{companyInfo.name} - </span>
                Grant Resource Access
              </h5>
              <small> Share access with other parties to help manage this resource</small>
            </div>
            <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
          <div className='modal-body'>{renderResources()}</div>
          <div className='modal-footer justify-content-center'>
            <button onClick={grantAccess} type='button' className='btn btn-revenue text-white'>
              Grant Access
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
