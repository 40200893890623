import React, { useEffect } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import NewSummary from './NewSummary';
import Navbar from './Navbar';

const Overview = props => {
  // useEffect(() => {
  //   console.log(6666666, props);
  // }, []);

  const renderOverview = () => {
    return (
      <div className='bg-overview overflow-hidden'>
        <div className='row align-items-center h-75'>
          <div className='col-6 text-center mx-auto'>
            <h2 className='strong text-dark-grey'>
              Welcome {props.userName}
              <br />
              You don’t have any active resources yet. Let’s get you started{' '}
            </h2>

            <Link to='/resources'>
              <button className='btn btn-lg btn-light text-revenue shadow px-4'>
                Add a Resource
              </button>
            </Link>
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Navbar
        navLinks={[
          {
            path: '/overview',
            header: 'Overview'
          }
        ]}
        small='Summary of Activities'
      />
      {/* {isPasswordReset === false ? (
        <Redirect to='/settings' />
      ) : !Array.isArray(userActiveResources) || !userActiveResources.length ? (
        <NewSummary username={userName} />
      ) : (
        { renderOverview }
      )} */}
      {props.isPasswordReset === false ? (
        <Redirect to='/settings' />
      ) : (
        <NewSummary username={props.userName} />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return state;
};

export default connect(mapStateToProps)(withRouter(Overview));

// {passwordReset == false ? (
//   <Redirect to='/settings' />
// ) : Array.isArray(userActiveResources) && userActiveResources.length ? (
//   <NewSummary username={userName} />
// ) : (
//   <div className='bg-overview overflow-hidden'>
//     <div className='row align-items-center h-75'>
//       <div className='col-6 text-center mx-auto'>
//         <h2 className='strong text-dark-grey'>
//           Welcome {userName}
//           <br />
//           You don’t have any active resources yet. Let’s get you started{' '}
//         </h2>

//         <Link to='/resources'>
//           <button className='btn btn-lg btn-light text-revenue shadow px-4'>
//             Add a Resource
//           </button>
//         </Link>
//       </div>
//     </div>
//   </div>
// )}
