import React, { Component } from 'react'
import { Route, NavLink, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import './FarmResourceItem.css'
import NewAssets from '../../components/farm/farmresource/assets/NewAssets'
import NewSoilMapping from '../../components/farm/farmresource/soil-mapping/NewSoilMapping'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import NewAreaMapping from '../../components/farm/farmresource/area-mapping/NewAreaMapping'
import FarmerLoggedIssues from './FarmerLoggedIssues'

//New UI Imports
// old name => NewFarmResource

class FarmDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedItem: this.props.farmItems[this.props.selectedFarmItemId],
      isAreaMappingEnable: ''
    }
  }

  componentDidMount() {
    console.log('SELECTED ITEM ID +> ' + JSON.stringify(this.state.selectedItem))
  }

  render() {
    return (
      <React.Fragment>
        {/* <Navbar
          path="/resources/farm/"
          subpath="/new-view-farms/"
          innersubpath="#"
          header="Farm Resources ||&nbsp;"
          header1="Farms ||&nbsp;"
          header2="Farm Features"
          small="Features under this Farm Resource"
        /> */}

        <Navbar
          navLinks={[
            {
              path: '/resources/farm/',
              header: ' Farm Resources |'
            },
            {
              path: '/new-view-farms/1',
              header: '| Farms |'
            },
            {
              path: '#',
              header: '| Farm Features'
            }
          ]}
          small='Features under this Farm Resource'
        />

        <div className='container-fluid'>
          <div className='row my-3'>
            <ul className='navbar-nav mr-auto d-flex flex-row'>
              {this.state.selectedItem.isAreaMappingEnable ? (
                <li className='nav-item'>
                  <NavLink
                    to={this.props.match.url + '/new-area-mapping/'}
                    className='p-3'
                    activeClassName='active-nav'
                  >
                    Area Mapping
                  </NavLink>
                </li>
              ) : (
                ''
              )}
              {this.state.selectedItem.isSoilMappingEnabled ? (
                <li className='nav-item'>
                  <NavLink
                    to={this.props.match.url + '/new-soil-mapping/'}
                    className='p-3'
                    activeClassName='active-nav'
                  >
                    Soil Mapping
                  </NavLink>
                </li>
              ) : (
                ''
              )}
              {this.state.selectedItem.isAssetEnabled ? (
                <li className='nav-item'>
                  <NavLink
                    to={this.props.match.url + '/new-assets/'}
                    className='p-3'
                    activeClassName='active-nav'
                  >
                    Assets
                  </NavLink>
                </li>
              ) : (
                ''
              )}
              <li className='nav-item'>
                <NavLink
                  to={this.props.match.url + '/logged-issues/'}
                  className='p-3'
                  activeClassName='active-nav'
                >
                  Logged Issues
                </NavLink>
              </li>
            </ul>

            <div className='d-flex filter-list'>
              {/* <Link to="/log-an-issue/" className="text-revenue mx-2 mt-2">
                Log Issue
              </Link> */}
              <form>
                <input type='text' className='form-control pl-2' placeholder='Search' />
              </form>{' '}
              {/* <List items={this.state.items}/> */}
            </div>
          </div>

          <div>
            <Route exact path='/new-farm-resource/new-area-mapping/' component={NewAreaMapping} />
            <Route path='/new-farm-resource/new-assets/' component={NewAssets} />
            <Route path='/new-farm-resource/new-soil-mapping/' component={NewSoilMapping} />
            <Route path='/new-farm-resource/logged-issues/' component={FarmerLoggedIssues} />
            {/* <Route
              exact
              path={this.props.match.path}
              render={() => (
                <div className="d-flex flex-column align-items-center mt-5">
                  <h5>Choose your Farm Subresource from the menu above</h5>
                  <p>
                    From this section you choose the subresource for your farm
                    and manage it from there.
                  </p>
                </div>
              )}
            /> */}
            <Footer />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    selectedResource: state.farmResource.selectedFarmResource,
    farmItems: state.farmResource.farmItems,
    selectedFarmItemId: state.farmResource.selectedFarmItemId
  }
}
export default withRouter(connect(mapStateToProps)(FarmDetails))
