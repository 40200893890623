import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Form, InputGroup } from 'react-html5-form'
import ReactTable from 'react-table-v6'
import swal from 'sweetalert2'
import BaseUrl from '../../../security/base-url'
class ViewFarms extends Component {
  state = {
    id: '',
    name: '',
    estimatedSize: '',
    state: '',
    localGovt: '',
    ward: '',
    farms: [],
    states: [],
    lgs: [],
    wards: [],
    markers: []
  }

  /**
   * Fetch data from the api
   */
  componentDidMount() {
    this.getFarms()
    this.getStates()
  }

  displayError = error => {
    return (
      error && (
        <div className='col-md-12'>
          <p style={this.errorstyles}>{error}</p>
        </div>
      )
    )
  }

  closePopUp() {
    document.getElementById('addFarm').reset()
    document.getElementById('addFarmModal').click()
  }

  gotoFarm() {
    this.props.history.push('/new-farm-resource/')
  }

  handleViewAsset = id => {
    console.log(id)
    this.props.history.push('/view-assets/' + id)
  }

  handleSelectedFarm = id => {
    this.props.history.push('/farm-details/' + id)
  }

  /**
   * Get the farm id
   */
  openAssetModal(id) {
    console.log(id)
    this.setState({
      id: id
    })
  }

  /**
   * Get the farm id
   */
  openViewAssetModal(id) {
    console.log(id)
    this.setState({
      id: id
    })
  }

  /**
   * Get the farm id
   */
  openDeleteFarmModal(id) {
    console.log(id)
    this.setState({
      id: id
    })
  }

  /**
   * Get the farm id
   */
  openEditFarmModal(id, name, type, size) {
    this.setState({
      id: id,
      farmName: name,
      farmType: type,
      estimatedSize: size
    })
  }

  /**
   *
   * Fetch all the farm types from the api
   */
  getStates() {
    fetch(BaseUrl.url_dashbord + '/wards/states', {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    })
      .then(response => response.json())
      .then(states => {
        this.setState({
          states: states
        })
        console.log(states)
      })
  }

  /**
   *
   * Fetch all the farm types from the api
   */
  getLocalGovts(state) {
    fetch(BaseUrl.url_dashbord + '/wards/lgs/' + state, {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    })
      .then(response => response.json())
      .then(lgs => {
        this.setState({
          lgs: lgs
        })
        console.log(lgs)
      })
  }

  /**
   *
   * Fetch all the farm types from the api
   */
  getWards(lg) {
    console.log('LG ' + lg)
    fetch(BaseUrl.url_dashbord + '/wards/wards/' + lg, {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    })
      .then(response => response.json())
      .then(wards => {
        this.setState({
          wards: wards
        })
        console.log(wards)
      })
  }

  /**
   * Delete a farm from the api
   *
   * @memberof deleteFarms
   */
  deleteFarm(id) {
    // id.preventDefault();
    fetch(BaseUrl.url_dashbord + '/farms/' + id, {
      method: 'DELETE',
      // mode: "CORS",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then(res => res.json())
      .then(res => {
        console.log(res)
        if (res.code === 0) {
          swal({
            title: 'Success!',
            text: 'Farm deleted successfully',
            type: 'success',
            animation: true
          })
          this.getFarms()
        } else {
          swal({
            title: 'Success!',
            text: res.message,
            type: 'success',
            animation: true
          })
        }
      })
      .catch(err => console.log(err))
  }

  // Update the value of the corresponding state field
  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  // Update the value of the corresponding state field
  onValueChange = (e, type) => {
    if (type === 'state') {
      this.getLocalGovts(e.target.value)
      this.setState({
        [e.target.name]: e.target.value
      })
    } else if (type === 'lg') {
      this.getWards(e.target.value)
      this.setState({
        [e.target.name]: e.target.value
      })
    }
  }

  /**
   * Update a farm resource
   */
  updateFarm = data => {
    let farm = {
      name: this.state.farmName,
      estimatedSize: this.state.estimatedSize,
      lg: this.state.localGovt,
      id: this.state.id
    }

    fetch(BaseUrl.url_dashbord + '/farms', {
      method: 'PUT',
      body: JSON.stringify(farm),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then(res => res.json())
      .then(res => {
        console.log(res)
        if (res.code === 0) {
          swal({
            title: 'Success!',
            text: 'Farm updated successfully',
            type: 'success',
            animation: true
          })
          this.getFarms()
          document.getElementById('editFarmModal').click()
        } else {
          swal({
            title: 'Error!',
            text: res.message,
            type: 'error',
            animation: true
          })
        }

        this.setState({
          farmId: '',
          farmName: '',
          farmType: '',
          state: '',
          localGovt: '',
          estimatedSize: ''
        })
      })
      .catch(err => console.log(err))
  }

  /**
   * Add a farm
   */
  createFarm = data => {
    let localGovernmentId = {
      id: this.state.localGovt
    }
    let farmResourceId = {
      id: this.props.match.params.farmResourceId
    }
    let farmData = {
      name: this.state.name,
      estimatedSize: this.state.estimatedSize,
      localGovernmentId: localGovernmentId,
      farmResourceId: farmResourceId
    }
    fetch(BaseUrl.url_dashbord + '/farms', {
      method: 'POST',
      body: JSON.stringify(farmData),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then(res => res.json())
      .then(res => {
        console.log(res)
        if (res.code === 0) {
          swal({
            title: 'Success!',
            text: 'Farm added successfully',
            type: 'success',
            animation: true
          })
          this.getFarms()
          this.closePopUp()
        } else {
          swal({
            title: 'Error!',
            text: res.message,
            type: 'error',
            animation: true
          })
        }

        this.setState({
          farmId: '',
          farmName: '',
          farmType: '',
          state: '',
          localGovt: '',
          estimatedSize: ''
        })
      })
      .catch(err => console.log(err))
  }

  /**
   * Fetch all the farms from the api
   */
  getFarms() {
    const {
      match: { params }
    } = this.props
    console.log(this.props.match.params.farmResourceId)

    fetch(BaseUrl.url_dashbord + '/farmersfarm/resource/' + params.farmResourceId, {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    })
      .then(response => response.json())
      .then(farm => {
        this.setState({
          farms: farm,
          markers: farm
        })
      })
  }

  render() {
    const columns = [
      {
        Header: 'Farm Name',
        accessor: 'name',
        headerClassName: 'table-header',
        filterable: true
      },
      {
        Header: 'Farm Size',
        accessor: 'estimatedSize',
        headerClassName: 'table-header',
        sortable: false
      },
      // {
      //   Header: "Description",
      //   accessor: "status",
      //   filterable: true
      // },
      {
        Header: 'Edit',
        filterable: false,
        sortable: false,
        headerClassName: 'table-header',
        Cell: props => {
          return (
            <span className='pointer'>
              <i
                class='fas fa-edit'
                styleClass='clickable-row'
                onClick={() =>
                  this.openEditFarmModal(
                    props.original.id,
                    props.original.name,
                    props.original.type,
                    props.original.estimatedSize
                  )
                }
                data-toggle='modal'
                data-target='#editFarmModal'
              />
            </span>
          )
        }
      },
      {
        Header: 'Delete',
        filterable: false,
        sortable: false,
        headerClassName: 'table-header',
        Cell: props => {
          return (
            <span className='pointer'>
              <i
                class='fas fa-trash'
                styleClass='clickable-row'
                onClick={() =>
                  swal({
                    title: 'Are you sure?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes'
                  }).then(result => {
                    if (result.value) {
                      this.deleteFarm(props.original.id)
                    }
                  })
                }
              />
            </span>
          )
        }
      }
      // {
      //   Header: "Details",
      //   filterable: false,
      //   sortable: false,
      //   Cell: props => {
      //     return (
      //       <span className="pointer">
      //         <i
      //           class="fas fa-eye"
      //           styleClass="clickable-row"
      //           onClick={() =>
      //             this.handleSelectedFarm(
      //               props.original.id
      //             )
      //           }
      //         />
      //       </span>
      //     )
      //   }
      // },
    ]

    return (
      <div className='container'>
        {/* <div>
          <Map markers={this.state.markers} />
        </div> */}
        <div className='col-md-12 order-md-1'>
          <div className='d-flex justify-content-end my-1'>
            <Link
              to='/resources/farm'
              className='d-inline btn btn-sm float-right btn-revenue text-white border px-3'
            >
              <i className='fas fa-lg fa-long-arrow-alt-left d-inline mr-2' />
              Back to Farm Resources
            </Link>
          </div>
          <button
            type='button'
            className='btn btn-revenue text-white btn-sm my-3'
            data-toggle='modal'
            data-target='#addFarmModal'
          >
            Add New Farm
          </button>

          <ReactTable
            columns={columns}
            className='-striped -highlight'
            data={this.state.farms}
            onclick={this.gotoFarm}
            filterable
            defaultPageSize={10}
            noDataText={'Loading Please wait.....'}
          />
        </div>

        {/* Add farm Modal */}
        <div className='col-md-12'>
          <Form id='addFarm' onSubmit={e => this.createFarm(e)}>
            {({ error }) => (
              <>
                <div
                  className='modal'
                  id='addFarmModal'
                  tabIndex={-1}
                  role='dialog'
                  aria-hidden='true'
                >
                  <div className='modal-dialog' role='document'>
                    <div className='modal-content'>
                      <div className='modal-header'>
                        <h5>Add Farm</h5>
                        <button
                          type='button'
                          className='close'
                          data-dismiss='modal'
                          aria-label='Close'
                        >
                          <span aria-hidden='true'>×</span>
                        </button>
                      </div>
                      <div className='modal-body'>
                        <div className='form-row'>
                          <div className='form-group col-6'>
                            <InputGroup validate={['name']}>
                              {({ error }) => (
                                <div>
                                  <span>Farm Name</span>
                                  <input
                                    required
                                    className='form-control'
                                    placeholder='Farm Name'
                                    name='name'
                                    value={this.state.name}
                                    onChange={e => this.onChange(e)}
                                  />
                                  {this.displayError(error)}
                                </div>
                              )}
                            </InputGroup>
                          </div>

                          <div className='form-group col-6'>
                            <InputGroup validate={['estimatedSize']}>
                              {({ error }) => (
                                <div>
                                  <span>Estimated Size</span>
                                  <input
                                    type='number'
                                    required
                                    className='form-control'
                                    placeholder='Estimated Size'
                                    name='estimatedSize'
                                    value={this.state.estimatedSize}
                                    onChange={e => this.onChange(e)}
                                  />
                                  {this.displayError(error)}
                                </div>
                              )}
                            </InputGroup>
                          </div>
                        </div>

                        <div className='form-row'>
                          <div className='form-group col-md-6'>
                            <span>State</span>
                            <select
                              className='form-control'
                              required
                              name='state'
                              value={this.state.state}
                              onChange={e => this.onValueChange(e, 'state')}
                            >
                              <option>Choose...</option>
                              {this.state.states.map(state => (
                                <option key={state.id} value={state.id}>
                                  {state.name}
                                </option>
                              ))}
                              ;
                            </select>
                          </div>

                          <div className='form-group col-md-6'>
                            <span>Local Government</span>
                            <select
                              className='form-control'
                              required
                              name='localGovt'
                              value={this.state.localGovt}
                              onChange={e => this.onValueChange(e, 'lg')}
                            >
                              <option>Choose...</option>
                              {this.state.lgs.map(lg => (
                                <option key={lg.id} value={lg.id}>
                                  {lg.name}
                                </option>
                              ))}
                              ;
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className='modal-footer'>
                        <button type='submit' className='btn btn-sm btn-revenue'>
                          Save
                        </button>

                        <button
                          type='button'
                          className='btn btn-sm btn-expend'
                          data-dismiss='modal'
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Form>
        </div>
        {/* Add farm modal ends here */}

        {/* Edit farm Modal */}
        <div className='col-md-12'>
          <Form id='updateFarm' onSubmit={e => this.updateFarm(e)}>
            {({ error }) => (
              <>
                <div
                  className='modal'
                  id='editFarmModal'
                  tabIndex={-1}
                  role='dialog'
                  aria-hidden='true'
                >
                  <div className='modal-dialog' role='document'>
                    <div className='modal-content bg-dark text-white'>
                      <div className='modal-header'>
                        <h5>Edit Farm</h5>
                        <button
                          type='button'
                          className='close'
                          data-dismiss='modal'
                          aria-label='Close'
                        >
                          <span aria-hidden='true'>×</span>
                        </button>
                      </div>
                      <div className='modal-body'>
                        <div className='form-row'>
                          <div className='form-group col-6'>
                            <InputGroup validate={['farmName']}>
                              {({ error }) => (
                                <div>
                                  <span>Farm Name</span>
                                  <input
                                    required
                                    className='form-control'
                                    placeholder='Name'
                                    name='farmName'
                                    value={this.state.farmName}
                                    onChange={e => this.onChange(e)}
                                  />
                                  {this.displayError(error)}
                                </div>
                              )}
                            </InputGroup>
                          </div>

                          <div className='form-group col-6'>
                            <InputGroup validate={['estimatedSize']}>
                              {({ error }) => (
                                <div>
                                  <span>Estimated Size</span>
                                  <input
                                    type='number'
                                    required
                                    className='form-control'
                                    placeholder='Estimated Size'
                                    name='estimatedSize'
                                    value={this.state.estimatedSize}
                                    onChange={e => this.onChange(e)}
                                  />
                                  {this.displayError(error)}
                                </div>
                              )}
                            </InputGroup>
                          </div>
                        </div>

                        <div className='form-row'>
                          <div className='form-group col-md-6'>
                            <span>State</span>
                            <select
                              className='form-control'
                              required
                              name='state'
                              value={this.state.state}
                              onChange={e => this.onValueChange(e, 'state')}
                            >
                              <option>Choose...</option>
                              {this.state.states.map(state => (
                                <option key={state.id} value={state.id}>
                                  {state.name}
                                </option>
                              ))}
                              ;
                            </select>
                          </div>

                          <div className='form-group col-md-6'>
                            <span>Local Government</span>
                            <select
                              className='form-control'
                              required
                              name='localGovt'
                              value={this.state.localGovt}
                              onChange={e => this.onValueChange(e, 'lg')}
                            >
                              <option>Choose...</option>
                              {this.state.lgs.map(lg => (
                                <option key={lg.id} value={lg.id}>
                                  {lg.name}
                                </option>
                              ))}
                              ;
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className='modal-footer'>
                        <button type='submit' className='btn btn-sm btn-revenue'>
                          Update
                        </button>

                        <button
                          type='button'
                          className='btn btn-sm btn-expend'
                          data-dismiss='modal'
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Form>
        </div>
        {/* Edit farm modal ends here */}
      </div>
    )
  }
}
export default withRouter(ViewFarms)
