import React, { Component } from 'react';
import apiService from '../../api/apiService';
import Spinner from '../Spinner';
import FarmersInCommunity from './FarmersInCommunity';
import FarmersInfo from './FarmersInfo';
import { SphericalUtil } from 'node-geometry-library';
import BaseUrl from '../../../security/base-url';
import { CSVLink } from 'react-csv';
import { Button } from 'bootstrap';
import shpwrite from 'shp-write';
import notify from '../../helpers/notify';
import { Loading, Notify } from 'notiflix';
const { info, success, error } = notify();


export default class Evaluation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      capturedDataDetails: [],
      farmerInfo: {},
      isLoading: false,
      pageNum: 1,
      pageLimit: 200,
      totalPages: 1,
      totalFarmers: 1,
      batchData: [],
      batchDataSelectedIndex: -1,
      selectedVertex: -1,
      editArea: 0,
      editedCoordinate: [],
      message: '',
      totalSizeMaped: 0,
      exportData: [],
      isExport: false,
      farmResourceId: -1,
      monitoringsCompany: ['Techni Disti', 'First Primeoak', 'Data Mining', 'EveryFarmer','WGS 84/UTM'],
      selectedMonitoringCompany: '',
      disabledExport: true,
      featureCollection: [],
      isRangeActive: false,
      startRangeIndex: 0,
      endRangeIndex: 0,
      isStart: false,
      isEnd: false,
      wgsutmDataForExport: [],
      isExportWGSUTMData:false,
      farmStatus:"ALL",
      agent:'ALL',
      agentUsername:'',
      dataWithSizeError:[]
    };
  }

  headers = [
    { label: 'ID', key: 'bvn' },
    { label: 'Coordinates', key: 'coords' },
    { label: 'LGA', key: 'name' },
    { label: 'Size', key: 'size' },
    { label: 'status', key: 'status' }
  ];

  wgsutmDataHeaders = [
    { label: "groupId", key: "farmId"},
    { label: "x", key: "x" },
    { label: "y", key: "y" },
    { label: "south", key: "south" },
    { label: "Zone", key: "zone" }
  ]

  reformatCoordinates = data => {
    var items = '';

    switch (this.state.selectedMonitoringCompany) {
      case this.state.monitoringsCompany[0]:
        data.map(i => {
          items += i.lat + ':' + i.lng + ';';
        });
        break;
      case this.state.monitoringsCompany[1]:
        var firstPoint = '[' + data[0].lat + ':' + data[0].lng + '];';
        data.map(i => {
          items += '[' + i.lat + ':' + i.lng + '];';
        });

        items += firstPoint;

        break;
      case this.state.monitoringsCompany[2]:
        var array = [];
        var firstPoint = [];
        firstPoint[0] = parseFloat(data[0].lng);
        firstPoint[1] = parseFloat(data[0].lat);

        data.map(i => {
          var item = [];
          item.push(parseFloat(i.lng));
          item.push(parseFloat(i.lat));
          array.push(item);
        });

        array.push(firstPoint);
        //Create Feature

        var feature = {};

        var coordibate = [];
        coordibate.push(array);

        // feature['type']= 'Feature';
        // feature['geometry'] =  {
        //       type: 'Polygon',
        //       coordinates: coordibate
        //   };
        //   feature['properties'] =  {
        //       name: 'Foo'
        //   };

        // console.log(feature)
        // var newData = this.state.shapeFileCoordinate;
        // newData.push(feature);
        // this.setState({shapeFileCoordinate:newData});

        return coordibate;

      // break;
      case this.state.monitoringsCompany[3]:
        data.map(i => {
          items += 'lat:' + i.lat + '-lng:' + i.lng + ';';
        });
        break; 
    }

    return items;
  };

  exportData = () => {
    if (this.state.selectedMonitoringCompany != this.state.monitoringsCompany[2]) {
      var data = [];
      this.state.batchData.map(i => {
        data.push({
          bvn: i.bvn,
          coords: this.reformatCoordinates(i.coords),
          name: i.name,
          size: SphericalUtil.computeArea(i.coords) * 0.0001,
          status: i.status
        });
      });

      this.setState({ exportData: data });
    } else {
      var features = [];

      
      this.state.batchData.map(i => {
        if (i.status == 'COMPLETED') {
          
          var poly = [this.reformatCoordinates(i.coords)];

          features.push({
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: poly
            },
            properties: {
              "name": i.name,
              "farmId": i.id
            }
          });
        } else {
          console.log('NOT COMPLETE');
        }
      });


      

      var data = {
        type: 'FeatureCollection',
        features: features
      };
    }
    this.setState({ isExport: true });
    this.setState({ featureCollection: data });

    if (this.state.selectedMonitoringCompany == this.state.monitoringsCompany[4])
    {
      this.exportWGSUTMDATA();
      }
  };

  handleSaveWork = () => {
    //remove sparse array holes
    var filtered = this.state.editedCoordinate.filter(function() {
      return true;
    });

    fetch(BaseUrl.url_dashbord + '/evaluation/edited_maps', {
      method: 'post',
      body: JSON.stringify(filtered),
      headers: {}
    })
      .then(response => response.json())
      .then(data => {
        this.setState({ message: 'Work Saved successfully' });
        success('Work Saved successfully');
      })
      .catch(e => {
        this.setState({ message: 'Error saving work' });
        error('Error saving work');
      });
  };
  handleFlagCompleted = farmerInfo => {
    this.setState({ message: '' });

    fetch(BaseUrl.url_dashbord + '/evaluation/mapping_completed/' + farmerInfo.farmId, {
      method: 'get',
      headers: {}
    })
      .then(response => response.json())
      .then(data => {
        this.setState({ message: 'Farm Status Completed' });
        success('Farm Status Completed');
      })
      .catch(e => {
        console.error(e);
        this.setState({ message: 'Error changing Farm Status' });
        error('Error changing Farm Status');
      });
  };

  handleFlagNotCompleted = farmerInfo => {
    this.setState({ message: '' });

    fetch(BaseUrl.url_dashbord + '/evaluation/mapping_not_completed/' + farmerInfo.farmId, {
      method: 'get',
      headers: {}
    })
      .then(response => response.json())
      .then(data => {
        this.setState({ message: 'Farm Status Not Completed' });
        info('Farm Status not Completed');
      })
      .catch(e => {
        console.error(e);
        this.setState({ message: 'Error changing Farm Status' });
        error('Error changing Farm Status');
      });
  };

  handleDeleteFarm = farmerInfo => {
    this.setState({ message: '' });

    fetch(BaseUrl.url_dashbord + '/evaluation/mapping_deleted/' + farmerInfo.farmId, {
      method: 'get',
      headers: {}
    })
      .then(response => response.json())
      .then(data => {
        this.setState({ message: 'Farm Deleted' });
        info('Farm Deleted');
      })
      .catch(e => {
        console.error(e);
        this.setState({ message: 'Error Deleting Farm' });
        error('Error Deleting Farm');
      });
  };

  handleResetAllMaps = () => {
    this.setState({ message: '' });

    fetch(BaseUrl.url_dashbord + '/evaluation/reset_all_map/' + this.state.farmResourceId, {
      method: 'get',
      headers: {}
    })
      .then(response => response.json())
      .then(data => {
        this.setState({ message: 'Farm Maps Reset Successfully' });
        success('Farm Maps Reset Successfully');
      })
      .catch(e => {
        console.error(e);
        this.setState({ message: 'Error Resetting Maps' });
        error('Error Resetting Maps');
      });
  };

  handleResetMap = farmerInfo => {
    this.setState({ message: '' });

    fetch(BaseUrl.url_dashbord + '/evaluation/reset_map/' + farmerInfo.farmId, {
      method: 'get',
      headers: {}
    })
      .then(response => response.json())
      .then(data => {
        this.setState({ message: 'Farm Maps Resets' });
        success('Farm Maps Reset Successfully');
      })
      .catch(e => {
        console.error(e);
        this.setState({ message: 'Error Resetting Maps' });
        error('Error Resetting Maps');
      });
  };
  convertMetersSquareToHectares = sizeInMeters => {
    return sizeInMeters * 0.0001;
  };

  handleActivateRange = () => {
    this.setState({ isRangeActive: true });
    this.setState({ isStart: true });
  };

  handleDeactivateRange = () => {
    this.setState({ isRangeActive: false });
    this.setState({ isStart: false, isDeleteReady: false });
  };
  handleDeleteVertex = () => {
    //This is for single Vertex Deleting
    if (
      this.state.selectedVertex != undefined &&
      this.state.selectedVertex > -1 &&
      !this.state.isRangeActive
    ) {
      var modifiedFarm = this.state.batchData[this.state.batchDataSelectedIndex];
      modifiedFarm.coords.splice(this.state.selectedVertex, 1);

      const data = this.state.editedCoordinate;
      data[this.state.batchDataSelectedIndex] = modifiedFarm;
      // this.setState({batchData:data})
      this.setState({ editedCoordinate: data });
      // this.setState({batchData:...this.state.batchData.remove()})
    } else if (
      this.state.isRangeActive &&
      this.state.startRangeIndex != undefined &&
      this.state.endRangeIndex != undefined
    ) {
      const modifiedFarm = this.state.batchData[this.state.batchDataSelectedIndex];
      //determine the start index
      var tempStart = 0;
      var tempEnd = 0;
      if (this.state.startRangeIndex > this.state.endRangeIndex) {
        tempStart = this.state.endRangeIndex;
        tempEnd = this.state.startRangeIndex;

        this.setState({ endRangeIndex: tempStart, startRangeIndex: tempEnd });
      } else {
        tempStart = this.state.startRangeIndex;
        tempEnd = this.state.endRangeIndex;
      }

      //Calculating the number of element to delete
      var diff = tempEnd - tempStart + 1;
      let array = modifiedFarm.coords;
      let removed = array.splice(tempStart, diff);
      modifiedFarm.coords = array;
      const data = this.state.editedCoordinate;

      data[this.state.batchDataSelectedIndex] = modifiedFarm;
      // this.setState({batchData:data})
      this.setState({
        editedCoordinate: data,
        message: 'Markers Deleted Successfully',
        isDeleteReady: false,
        isRangeActive: false
      });
      success('Markers Deleted Successfully');
    }
  };

  handleMouseUp = evt => {
    this.setState({ message: '' });
    if (evt.vertex != undefined) {
      if (this.state.isRangeActive && this.state.isEnd && !this.state.isStart) {
        this.setState({ endRangeIndex: evt.vertex, isEnd: false, isDeleteReady: true });
      }
      if (this.state.isRangeActive && this.state.isStart && !this.state.isEnd) {
        this.setState({ startRangeIndex: evt.vertex });

        this.setState({ isStart: false, isEnd: true, isDeleteReady: false });
      }

      this.setState({ selectedVertex: evt.vertex });
      var modifiedFarm = this.state.editedCoordinate[this.state.batchDataSelectedIndex]
        ? this.state.editedCoordinate[this.state.batchDataSelectedIndex]
        : this.state.batchData[this.state.batchDataSelectedIndex];

      modifiedFarm.coords.splice(this.state.selectedVertex, 1, {
        lat: evt.latLng.lat(),
        lng: evt.latLng.lng()
      });
      const data = this.state.editedCoordinate;
      data[this.state.batchDataSelectedIndex] = modifiedFarm;
      // this.setState({batchData:data})

      this.setState({ editedCoordinate: data });
      // this.setState({batchData:...this.state.batchData.remove()})
      this.setState({
        editArea: this.convertMetersSquareToHectares(
          SphericalUtil.computeArea(modifiedFarm['coords'])
        )
      });
      modifiedFarm.farmSize = this.state.editArea;
    } else if (evt.edge != undefined) {
      this.setState({ selectedVertex: -1 });
      var modifiedFarm = this.state.editedCoordinate[this.state.batchDataSelectedIndex]
        ? this.state.editedCoordinate[this.state.batchDataSelectedIndex]
        : this.state.batchData[this.state.batchDataSelectedIndex];
      const data = this.state.editedCoordinate;
      data[this.state.batchDataSelectedIndex] = modifiedFarm;
      // this.setState({batchData:data})

      modifiedFarm.coords.splice(evt.edge + 1, 0, { lat: evt.latLng.lat(), lng: evt.latLng.lng() });
      this.setState({ editedCoordinate: data });

      this.setState({
        editArea: this.convertMetersSquareToHectares(
          SphericalUtil.computeArea(modifiedFarm['coords'])
        )
      });
      modifiedFarm.farmSize = this.state.editArea;
    } else {
      this.setState({ selectedVertex: -1 });
    }

    // if(this.state.selectedVertex != undefined && this.state.selectedVertex > -1)
    // {

    //   var modifiedFarm = this.state.batchData[this.state.batchDataSelectedIndex];
    //    modifiedFarm.coords.splice(this.state.selectedVertex,1);
    //   // console.log(modifiedFarm["coords"])
    //   const data = this.state.editedCoordinate;
    //   data[this.state.batchDataSelectedIndex] = modifiedFarm;
    //   // this.setState({batchData:data})
    //   console.log( data[this.state.batchDataSelectedIndex]["coords"])
    //   this.setState({editedCoordinate:data})
    //   // this.setState({batchData:...this.state.batchData.remove()})
    //   console.log(this.state.editedCoordinate)
    // }
  };

  handleAgentChange  = e=>{
    switch(e.target.value)
    {
      case "ALL":
        localStorage.setItem("AGENT","ALL")
        this.setState({agent:"ALL"})
      break;
      case "SELECTED":
        localStorage.setItem("AGENT","SELECTED")
        this.setState({agent:"SELECTED"})
      break;
    }
  }
  handleFarmStatusChange = e=>{
    switch(e.target.value)
    {
      case "ALL":
        this.setState({farmStatus:'ALL'})
        localStorage.setItem("FARMSTATUS",'ALL')
      break;
      case "COMPLETED":
        this.setState({farmStatus:'COMPLETED'})
        localStorage.setItem("FARMSTATUS",'COMPLETED')

      break;
      case "ACTIVE":
        this.setState({farmStatus:'ACTIVE'})
        localStorage.setItem("FARMSTATUS",'ACTIVE')
      break;
    }
    

    this.updateMapView();
  }

  handleAgentUsernameChange = event=>{
    this.setState( {
        agentUsername :event.target.value
      });
  }
  handleMapIdChange = e => {
    this.setState({ selectedMapId: e.target.value });
  };
  render() {
    const { batchData, capturedDataDetails, pageNum, totalPages, farmerInfo } = this.state;
    return (
      <>
        <div className='py-1 mx-n2'>
          <div className='d-flex bg-white'>
            <Spinner isLoading={this.state.isLoading} data={capturedDataDetails}>
              <div className='col-md-3'>
                <FarmersInfo
                  mapIdType={this.state.selectedMapId}
                  farmerInfo={this.state.farmerInfo}
                  area={this.state.editArea}
                  onFlagCompleted={() => this.handleFlagCompleted(this.state.farmerInfo)}
                  onFlagNotCompleted={() => this.handleFlagNotCompleted(this.state.farmerInfo)}
                  onResetFarm={() => this.handleResetMap(this.state.farmerInfo)}
                  onDeleteFarm = {()=>this.handleDeleteFarm(this.state.farmerInfo)}
                />
              </div>
              <div className='col-md-6'>
                {
                  this.state.batchData.length>0 && <FarmersInCommunity
                  indexStart={0}
                  indexEnd={30}
                  onPolygonSelected={this.handlePolygonSelect}
                  farms={batchData}
                  farmerInfo={farmerInfo}
                  onMouseUp={this.handleMouseUp}
                  updateCoords={this.handleUpdateFarmCoords}
                  onFarmSelected={this.handleFarmSelected}
                />
                }
              </div>

              <div className='col-md-3'>
                <h4 className='font-weight-bold my-2'>Map Tools</h4>
                <div>
                  <label>Farm Status</label>
                <select
                
                      value={this.state.farmStatus}
                      onChange={this.handleFarmStatusChange}
                    >
                      <option value='ALL'>All</option>
                      <option value='COMPLETED'>
                        COMPLETED
                      </option>
                      <option value='ACTIVE'>
                      NOT_COMPLETED
                      </option>
                   
                    </select>
                    </div>
                    <div>
                      {/* <form> */}
                        <label>Agent</label>
                        <select
                      value={this.state.agent}
                      onChange={this.handleAgentChange}
                    >
                      <option value='ALL'>All</option>
                      <option value='SELECTED'>
                        SELECTED
                      </option> 
                   
                    </select>
                        <input placeholder="Agent Username" type="text" name="agentUsername" value={this.state.agentUsername} onChange={this.handleAgentUsernameChange}/>
                        <button  className="btn btn-info btn-xs" onClick={()=>{
                            this.updateMapView();
                        }}>find</button>
                        {/* </form> */}
                      </div>

                {/* <select
                    render={false}
                      onChange={this.handleMapIdChange}
                      >
                      <option value="satellite">Satelite</option>
                      <option value="hybrid">Hybrid</option>
                      <option value="roadmap">Road Map</option>
                      <option value="terrain">Terrain</option>
                      </select> */}
                <span>
                  <h6 className='font-weight-bold'>Total Farms in Area:</h6>
                  <p className='text-center'>{this.state.batchData.length}</p>
                </span>
                <span className='my-3'>
                  <h6 className='font-weight-bold'>Total Area Captured:</h6>{' '}
                  <p className='text-center'>{this.state.totalSizeMaped.toFixed(2)} Hectares</p>
                </span>
                {/* <h5>Message:{this.state.message}</h5> */}

                <div className='bg-secondary border'>
                  <div className='p-3 d-flex align-items-center justify-content-between'>
                    <span className='d-flex align-items-center mr-2'>
                      <button
                        className='btn btn-light text-black-50'
                        onClick={() => this.updateMapView(-1)}
                        disabled={pageNum === 1 ? true : false}
                      >
                        <i className='fas fa-caret-left'></i>
                      </button>
                      <span className='h6 mx-2'>{`${pageNum}/${totalPages}`}</span>
                      <button
                        onClick={() => this.updateMapView(1)}
                        disabled={pageNum === totalPages ? true : false}
                        className='btn btn-light text-black-50'
                      >
                        <i className='fas fa-caret-right'></i>
                      </button>
                    </span>
                    <button
                      disabled={this.state.editedCoordinate.length <= 0}
                      className='btn btn-outline-revenue btn-sm'
                      onClick={this.handleSaveWork}
                    >
                      Save
                    </button>
                  </div>
                  <div className='p-3 d-flex align-items-center justify-content-between'>
                    <select
                      value={this.state.selectedMonitoringCompany}
                      onChange={this.onMonitoringCompanyChange}
                    >
                      <option value='select...'>select...</option>
                      <option value={this.state.monitoringsCompany[0]}>
                        {this.state.monitoringsCompany[0]}
                      </option>
                      <option value={this.state.monitoringsCompany[1]}>
                        {this.state.monitoringsCompany[1]}
                      </option>
                      <option value={this.state.monitoringsCompany[2]}>
                        {this.state.monitoringsCompany[2]}
                      </option>
                      <option value={this.state.monitoringsCompany[3]}>
                        {this.state.monitoringsCompany[3]}
                      </option>
                      <option value={this.state.monitoringsCompany[4]}>
                        {this.state.monitoringsCompany[4]}
                      </option>
                    </select>
                    &nbsp;&nbsp;
                    <button
                      onClick={this.exportData}
                      disabled={this.state.disabledExport}
                      className='btn btn-outline-revenue btn-sm'
                    >
                      Export
                    </button>
                    {!this.state.isExportWGSUTMData && this.state.isExport && !this.isShapeFileDownLoad() && (
                      <CSVLink
                        headers={this.headers}
                        filename='Export.csv'
                        data={this.state.exportData}
                        className='btn btn-info btn-sm'
                      >
                        download cv file
                      </CSVLink>
                    )}

                    {(this.state.isExport && this.state.isExportWGSUTMData)  && (
                      <CSVLink
                        headers={this.wgsutmDataHeaders}
                        filename='Export.csv'
                        data={this.state.wgsutmDataForExport}
                        className='btn btn-info btn-sm'
                      >
                        Download WGS84/UTM file
                      </CSVLink>
                    )}

                    {this.state.isExport && this.isShapeFileDownLoad() && (
                      <button onClick={this.exportShapeFile} className='btn btn-revenue btn-sm'>
                        download shape file
                      </button>
                    )}
                  </div>
                </div>
                <div className='d-flex my-3'>
                  <button
                    onClick={this.handleActivateRange}
                    className='btn btn-revenue text-white btn-sm'
                  >
                    Select Range
                  </button>

                  <button
                    disabled={!this.state.isRangeActive}
                    onClick={this.handleDeactivateRange}
                    className='btn btn-secondary btn-sm mx-2'
                  >
                    Deactivate Range
                  </button>
                </div>
                <div className='d-flex'>
                  <button
                    disabled={!this.state.isDeleteReady}
                    onClick={this.handleDeleteVertex}
                    className='btn btn-danger btn-sm'
                  >
                    delete
                  </button>

                  <button className='btn btn-danger btn-sm mx-2' onClick={this.handleResetAllMaps}>
                    Reset All Maps
                  </button>
                </div>
               
              </div>
              
            </Spinner>
          </div>
        </div>
      </>
    );
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    // let fetchTracker = async () => {
    //   try {
    //     const lastSessionTracker = await apiService.get('/evaluation/lastSession');
    //     if (lastSessionTracker.code !== -1) {
    //       throw Error('no Tracker');
    //     }
    //   } catch (error) {
    //     return 0;
    //   }
    // };
    // fetchTracker().then(prevSession => this.renderLastVisited(prevSession));
    localStorage.setItem("FARMSTATUS","ALL")
    localStorage.setItem("AGENT","ALL")
    this.renderLastVisited(0)
  }

  // componentWillUnmount() {
  //   this.saveEvaluatonProgress();
  // }

  // saveEvaluatonProgress = async () => {
  //   try {
  //     //eslint-disable-next-line no-unused-vars
  //     const res = await apiService.post(`/evaluation/lastsession/${this.state.pageNum}`);
  //   } catch (error) {
  //     // eslint-disable-next-line no-console
  //     console.log(error);
  //   }
  // };

  getBatchData = async (start, stop) => {
    const { communityResourceId } = this.props;
    this.setState({ isLoading: true, totalSizeMaped: 0 });
    const farmStatus = localStorage.getItem("FARMSTATUS");
    const agent = localStorage.getItem("AGENT");
    try {
      const url = agent === 'ALL'?`/evaluation/maps/${communityResourceId}/${farmStatus}/${start}/${stop}`:
      `/evaluation/maps/${communityResourceId}/${farmStatus}/${start}/${stop}/${this.state.agentUsername}`
      const capturedData = await apiService.get(url);
      const farmers = JSON.parse(capturedData.farmers);
      
      var farmResourceId = -1;
      const ncapturedDataDetails = farmers.map(farm => {
        farm.coords = JSON.parse(farm.coords);
        farm.prints = JSON.parse(farm.prints);
        farm.nextOfKin = farm.nextOfKin ? JSON.parse(farm.nextOfKin) : {};
        farm.agents = !Array.isArray(farm.agents) ? JSON.parse(farm.agents) : farm.agents;
        farmResourceId = farm.farmResourceId;
        return farm;
      });

      
      
      this.setState(prevState => {
        // const newCapturedData = prevState.capturedDataDetails.concat(ncapturedDataDetails);
        return {
          capturedDataDetails: ncapturedDataDetails,
          batchData: ncapturedDataDetails,
          totalFarmers: capturedData.totalProcessed,
          totalPages: Math.ceil(capturedData.totalProcessed / this.state.pageLimit),
          farmResourceId: farmResourceId
        };
      });

      this.state.batchData.map(data => {

        this.setState({
          totalSizeMaped:
            this.state.totalSizeMaped + SphericalUtil.computeArea(data['coords']) * 0.0001
        });

        if(data.farmSize == 0)
        {
          data.farmSize = SphericalUtil.computeArea(data['coords']);
          this.setState({dataWithSizeError:[...this.state.dataWithSizeError,data]})
        }
      });
      Loading.remove();
      

    } catch (err) {
      // eslint-disable-next-line no-console
      console.info(`Network message: ${err}`);
    } finally {
      this.setState({ isLoading: false });
      Loading.remove();
    }

    if(this.state.dataWithSizeError.length > 0 )
    {
      
        Loading.hourglass("Fixing missing Size");
        

    fetch(BaseUrl.url_dashbord + '/evaluation/fixfarmSize', {
      method: 'post',
      body: JSON.stringify(this.state.dataWithSizeError),
      headers: {}
    })
      .then(response => response.json())
      .then(data => {
        
        success("Missing Farm fixed");

        Loading.remove();
      })
      .catch(e => {
        this.setState({ message: 'Error saving work' });
        error('Error saving work');

        Loading.remove();
      });
   

    }
  };

  renderLastVisited = pageNum => {
    if (pageNum > 1) {
      this.setState({ pageNum }, this.updateMapView());
    } else {
      this.getBatchData(pageNum, this.state.pageLimit - 1);
    }
  };

  handleFarmSelected = (farmer, index, split) => () => {
    const google = window.google;
    
    
    
    
    // 
      this.setState({ farmerInfo: {} });
      this.setState({ farmerInfo: farmer });
      this.setState({ batchDataSelectedIndex: index });

      this.setState({
        editArea: this.convertMetersSquareToHectares(SphericalUtil.computeArea(farmer['coords']))
      });

      // if(evt.vertex != undefined){
      //   this.setState({selectedVertex:evt.vertex})
      // }else{
      //   this.setState({selectedVertex:-1})
      // }
      //   setTimeout(() => {
      // }, 300);
    
    
  };

  handleUpdateFarmCoords = (evt, farm, farmIdx) => {
    const newFarmData =
      evt.vertex === undefined
        ? {
            ...farm,
            ...{
              coords: [...farm.coords, { lat: evt.latLng.lat(), lng: evt.latLng.lng() }]
            }
          }
        : farm;

    // this.updateCoords(newFarmData, farmIdx);
  };

  updateCoords = async (newFarmData, farmIdx) => {
    const { capturedDataDetails } = this.state;
    try {
      const newFarmDataRes = await apiService.update(`/evaluation/farmmap/${newFarmData.id}`, {
        coords: newFarmData.coords
      });
      if (newFarmDataRes.code !== 1) {
        capturedDataDetails.splice(farmIdx, 1, newFarmData);
        this.setState({
          capturedDataDetails: [...capturedDataDetails]
        });
      } else {
        throw Error();
      }
    } catch (reason) {
      console.error(reason);
    }
  };

  updateMapView = isNextPage => {

    Loading.dots();
    const { capturedDataDetails, totalFarmers, totalPages, pageLimit, pageNum } = this.state;
    
    const page =
      isNextPage === 1 && pageNum < totalPages
        ? pageNum + 1
        : isNextPage === -1 && pageNum > 1
        ? pageNum - 1
        : pageNum;

    const num = pageLimit * page;
    const start = num - pageLimit;
    const stop = num > totalFarmers ? totalFarmers : num;

    if (isNextPage === -1) {
      this.setState({
        batchData: capturedDataDetails.slice(start, stop),
        pageNum: page
      });
      return;
    }
    this.getBatchData(start, stop - 1);
    this.setState({ pageNum: page });
  };

  onMonitoringCompanyChange = event => {
    this.setState({ selectedMonitoringCompany: event.target.value });
    this.setState({ disabledExport: event.target.value == 'select...' });
    this.setState({ isExport: false });
          this.setState({isExportWGSUTMData:false})

    if (this.state.monitoringsCompany[4] === event.target.value)
    {
      this.setState({isExportWGSUTMData:true})
      }
  };

  exportShapeFile = () => {
    // (optional) set names for feature types and zipped folder
    var options = {
      folder: 'myshapes',
      types: {
        // point: 'mypoints',
        polygon: 'mypolygons'
        // line: 'mylines'
      }
    };
    // a GeoJSON bridge for features
    shpwrite.download(this.state.featureCollection, options);
  };

  isShapeFileDownLoad = () => {
    return this.state.selectedMonitoringCompany == this.state.monitoringsCompany[2];
  };

  exportWGSUTMDATA = () => {


    var UtmConverter = require('utm-converter');
    var converter = new UtmConverter();

     var farmNewCoords = [];
    this.state.batchData.map(i => {
      if (i.status == 'COMPLETED') {
        
       
        var poly = [this.reformatCoordinates(i.coords)];

       
        
        i.coords.map(crd => {
          
          var wgsCoord = [];
          wgsCoord.push(Number(crd.lng));
          wgsCoord.push(Number(crd.lat));
          var utmResult = converter.toUtm({ coord: wgsCoord });
      
          farmNewCoords.push({ "farmId": i.farmId, "x": utmResult.coord.x, "y": utmResult.coord.y, "south": utmResult.isSouthern?1:0, "zone": utmResult.zone });
        
        });

        

        this.setState({ isExportWGSUTMData: true });
      }
    });

this.setState({ wgsutmDataForExport: [...this.state.wgsutmDataForExport,...farmNewCoords ] });
        // console.log(farmNewCoords);
  }

  
}
