import { createSelector } from 'reselect'

export const getUserLogoSelector = createSelector(
  state => state.assets.logo,
  userLogo => userLogo
)

export const accountNameSelector = createSelector(
  state => state.assets.accountName,
  accountName => accountName
)
