import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  loginFailed,
  loginSuccessful,
  logoutSuccessful,
  renewToken,
  resetIsLoggingIn
} from './app.actions';
import { REQUEST_LOGOUT, REQUEST_USER } from './app.constant';
import { getIsAuthenticated, getValidityPeriod } from './app.selectors';
import authService from './services/auth.service';
import accessStorage from './utils/accessStorage';
import errorHandler from './utils/errorHandler';

const { login, logout } = authService();

function* scheduleRenewal() {
  const validityPeriod = yield select(getValidityPeriod);
  const timeout = validityPeriod - Date.now();

  if (timeout > 0) {
    yield call(setInterval, renewToken, timeout);
  }
}

function* logoutUser({ payload: history }) {
  try {
    yield call(logout, 'token', 'role', 'user');
    yield put(logoutSuccessful());
    //const accessId = yield call([accessStorage, 'getFromStore']);
    history.push(`/`);
  } catch (error) {
    errorHandler(error);
  }
}

function* authenticate(creds, history) {
  const [authResponse, authError] = yield call(login, creds);
  if (Object.values(authResponse).length > 0) {
    const { user, headers } = authResponse;
    const [authToken, validity] = headers;

    yield call(accessStorage.store, 'token', authToken);
    yield call(accessStorage.store, 'role', user.roles[0]);
    yield call(accessStorage.store, 'access_id', user.accessId);

    yield put(loginSuccessful(user, validity));

    const isAuthenticated = yield select(getIsAuthenticated);

    if (isAuthenticated) {
      history.push('/');
      yield call(scheduleRenewal);
    }
  } else {
    errorHandler(authError);
    throw Error('Unable to log in. Please try again.');
  }
}

function* fetchUser({ payload: { creds, history } }) {
  try {
    yield call(authenticate, creds, history);
  } catch (reason) {
    yield put(loginFailed('Unable to log in. Please try again.'));
  } finally {
    yield put(resetIsLoggingIn());
  }
}

// watchers
export function* watchFetchUser() {
  yield takeEvery(REQUEST_USER, fetchUser);
}

export function* watchClearUser() {
  yield takeLatest(REQUEST_LOGOUT, logoutUser);
}
