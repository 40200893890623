import React, { Component } from 'react'
import Swal from 'sweetalert2'
import BaseUrl from '../../../security/base-url'
import apiService from '../../api/apiService'
import NewDataModal from '../modals/NewDataModal'
import ResourceList from '../resources/resourcesList/ResourceList'
import Spinner from '../Spinner'
import Toolbar from '../Toolbar'

class ServiceProviderExtension extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      description: '',
      extensionResources: [],
      isUpdate: false,
      isLoading: false
    }
  }

  async componentDidMount() {
    const { serviceProviderId } = this.props
    this.setState({ isLoading: true })
    try {
      const extensionResources = await apiService.get(
        `/extension_service/service_provider/${serviceProviderId}`
      )
      if (extensionResources.code === -1) {
        throw Error(extensionResources.message)
      } else {
        this.setState({ extensionResources })
      }
    } catch (reason) {
      console.error(reason)
    } finally {
      this.setState({ isLoading: false })
    }
  }

  //handle creation of a resource
  handleSaveResource = async () => {
    const { serviceProviderId } = this.props
    const data = {
      name: document.getElementById('name').value,
      description: document.getElementById('description').value
    }
    fetch(BaseUrl.url_dashbord + '/extension_service/' + serviceProviderId, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    }).then(res => {
      if (res.status === 201) {
        Swal({
          title: 'Success!',
          text: 'Extension Resource created successfully',
          type: 'success',
          animation: true
        })
        document.getElementById('name').reset()
        document.getElementById('description').reset()
        this.getExtensionResources()
        this.resetUpdateState()
      } else {
        Swal({
          title: 'Error!',
          text: 'An error occured, try to creete the resource again',
          type: 'error',
          animation: true
        })
      }
    })
  }

  resetUpdateState = () => {
    document.getElementById('service-provider-extension').remove()
  }

  render() {
    const { extensionResources, isLoading } = this.state
    return (
      <>
        <div className='bg-white'>
          <Toolbar>
            <span className='badge badge-pill badge-warning'>
              {extensionResources.length} Services
            </span>
            <button
              className='btn btn-revenue text-white btn-sm px-3 ml-2'
              data-toggle='modal'
              data-target='#extensionResourceModal'
            >
              <i className='fas fa-plus fa-sm mr-2' />
              <span>Add Extension Service</span>
            </button>
          </Toolbar>

          <div className='fix-height revenue-scrollbar p-1'>
            <Spinner data={extensionResources} isLoading={isLoading}>
              <ResourceList resourceList={extensionResources} key={extensionResources.id} />
            </Spinner>
          </div>
        </div>

        <NewDataModal
          modalId='service-provider-extension'
          modalName='Extension'
          isUpdate={this.state.isUpdate}
          resetUpdateState={this.resetUpdateState}
          saveResource={this.handleSaveResource}
        />
      </>
    )
  }
}

export default ServiceProviderExtension
