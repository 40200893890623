import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../../components/Navbar'
import StaffList from '../../components/staff/StaffList'

export default class Staff extends Component {
  constructor(props) {
    super(props)
    this.state = {
      staff: [],
      isLoading: false
    }
  }

  componentDidMount() {}

  fetchStaff = () => {
    this.setState({ isLoading: true })
  }

  render() {
    return (
      <div
        className={
          this.state.staff.length === 0 ? 'bg-overview overflow-hidden' : 'overflow-hidden'
        }
      >
        <Navbar
          navLinks={[
            {
              path: '/staff',
              header: 'Staff'
            }
          ]}
          small='Add Staff Members and assign their access rights on the platform'
        />
        {this.state.staff.length === 0 ? (
          <>
            <div className='row align-items-center h-75'>
              <div className='col-6 text-center mx-auto'>
                <h2 className='strong text-dark-grey'>
                  You don’t have any staff members yet. Let's get you started.{' '}
                </h2>
                <Link to='/add-new-staff'>
                  <button className='btn btn-lg btn-light text-revenue shadow px-4'>
                    Add staff member
                  </button>
                </Link>
              </div>
            </div>
          </>
        ) : (
          <StaffList staffList={this.state.staff} isLoading={this.state.isLoading} />
        )}
      </div>
    )
  }
}
