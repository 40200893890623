import BaseUrl from '../../security/base-url'
import { METHOD_DELETE, METHOD_GET, METHOD_POST, METHOD_PUT } from '../utils/rest-config'

/**
 * @param {*} url The server endpoint
 * @param {*} config the headers configuration
 */
const fetchResources = async (url, config) => {
  try {
    const request = await fetch(`${BaseUrl.url_dashbord}${url}`, { ...config })
    if (request.ok && request.status === 200) {
      const response = await request.json()
      return response
    } else {
      throw Error(`Error has occurred. The server says "${request.statusText}" `)
    }
  } catch (reason) {
    return { code: -1, message: reason }
  }
}

/**
 * Makes `request` to server end and deletes data with the data's unique identifier attached to url
 * @params server `URL` endpoint to make request to.
 * #### Note - start url with `/`
 * @returns a resolved response
 */
const deleteData = async (url, config) => fetchResources(url, { ...METHOD_DELETE, ...config })

/**
 * Makes `request` to server end and gets data with the data's unique identifier attached to url
 * @params server `URL` endpoint to make request to.
 * #### Note: start url with `/`
 * @returns a resolved response
 */
const getData = async (url, config) => fetchResources(url, { ...METHOD_GET, ...config })

/**
 * Makes `request` to server end and posts data with the data's unique identifier attached to url
 * @params server `URL` endpoint to make request to.
 * #### Note:  start url with `/`
 * @params `body` the data that's sent.
 * @returns a resolved response
 */
const postData = async (url, body, config) => fetchResources(url, { ...METHOD_POST(body), config })

/**
 * Makes `request` to server end and updates data with the data's unique identifier attached to url
 * @params server `URL` endpoint to make request to.
 * #### Note - start url with `/`
 * @returns a resolved response
 * */
const updateData = async (url, body, config) =>
  fetchResources(url, { ...METHOD_PUT(body), ...config })

export default {
  delete: deleteData,
  get: getData,
  post: postData,
  update: updateData
}
