import React, { Component } from 'react'
import { Form, InputGroup } from 'react-html5-form'
import BaseUrl from '../../../../security/base-url'
import apiService from '../../../api/apiService' 
import Swal from 'sweetalert2'

export default class AssignedTaskForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      communityResources: [],
      selectedCommunityResourceId: 0,
      selectedTasks: []
    };
  }

  saveAssignedTask = async () => {
    console.log("SESLCE YEAK " )
    console.log()
    // this.state.selectedTasks
    var taskString = '';
    this.state.selectedTasks.map(tk=>taskString+=tk+" ")

    console.log(taskString)
    let newTaskForce = {
      communityResourceId: document.getElementById('community').value,
      costPerFarmer: parseFloat(document.getElementById('cost').value),
      groupName: document.getElementById('taskname').value,
      groupDescription: document.getElementById('description').value,
      // services: document.getElementById('service').value
      services: taskString
    }

    console.log(JSON.stringify(newTaskForce))
    try {
      console.log("Try")
      fetch(BaseUrl.url_dashbord+"/postinggroup",{
          method:"post",
          body: JSON.stringify(newTaskForce),
          headers:{
            "content-type":"application/json"
          }
      }).then(response=>response.json())
      .then(data=>{
          Swal({
            title: 'Success!',
            text: 'New season created successfully!',
            type: 'success',
            animation: true
          })
          this.props.onGetPosting();
      }).catch(error=>{
        console.log(error);
        Swal({
          title: 'Error!',
          text: 'Error while creating season!',
          type: 'error',
          animation: true
        })
      })
      // const createTaskRes = await apiService.post('/postinggroup/', newTaskForce)
      // console.log(createTaskRes)
      // if (createTaskRes.message === 'success') {
       
      // } else {
       
      // }
    } catch (error) {
      console.log(error)
      Swal({
        title: 'Error!',
        text: 'Error while creating season!',
        type: 'error',
        animation: true
      })
    } finally {
      document.getElementById('assignedTaskId').reset()
      document.getElementById('assignedTaskClose').click()
    }
  }

  componentDidMount() {
    this.getCommunityResource();
  }

  // saveAssignedTask = () => {};

  onValueChange = (e, type) => {
    
    switch (type) {

      case 'resource':
        //   this.getCommunities(community.id);
        this.setState({ selectedCommunityResource: e.target.value });
        break;
      case 'services':
        this.setState(prevState => {
          const selectedTasks = prevState.selectedTasks.some(task => task === e.target.name)
            ? prevState.selectedTasks.filter(task => task !== e.target.name)
            : [...prevState.selectedTasks, e.target.name];
          return { selectedTasks };
        });
        break;
      default:
        break;
    }
    e.persist()
  }

  getCommunityResource = () => {
    const headersConfig = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };
    fetch(BaseUrl.url_dashbord + '/community-resource/', headersConfig)
      .then(respons => respons.json())
      .then(data => {
        this.setState({ communityResources: data.reverse() });
      })
      .catch(err => {});
  };

  render() {
    const { hasUpdated, resetUpdateState } = this.props;
    return (
      <div
        className='modal fade'
        id='assignedTaskModal'
        data-backdrop='static'
        data-keyboard='false'
        tabIndex={-1}
        role='dialog'
        aria-hidden='true'
      >
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h4>{!hasUpdated ? 'Add New' : 'Edit Assigned'} Task </h4>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
                id='assignedTaskClose'
                onClick={resetUpdateState}
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              <Form
                id='assignedTaskId'
                name='assignedTaskId'
                
                // onSubmit={this.props.onCreate}
              >
                {({ submitting, pristine, form }) => (
                  <>
                    <InputGroup tag='div' validate={['services']}>
                      {({ error, valid }) => (
                        <div className='col-md-12 mb-3'>
                          <div className='font-weight-bold' htmlFor='services'>
                            {' '}
                            Service{' '}
                          </div>
                          <span style={{margin:"10px"}}>
                            <label htmlFor='mapping'>MAPPING</label>
                            <input
                              key='1'
                              id='mapping'
                              name='mapping'
                              value='mapping'
                              className={`form-control form-control-sm ${!valid && 'is-invalid'}`}
                              type='checkbox'
                              onChange={e => this.onValueChange(e, 'services')}
                            />
                          </span>
                          <span style={{margin:"10px"}}>
                            <label htmlFor='input-data'>KYF</label>
                            <input
                              key='2'
                              id='input-data'
                              name='KYF'
                              value="KYF"
                              className={`form-control form-control-sm ${!valid && 'is-invalid'}`}
                              type='checkbox'
                              onChange={e => this.onValueChange(e, 'services')}
                            />
                          </span>

                          <span style={{margin:"10px"}}>
                            <label htmlFor='input-distribution'>INPUT</label>
                            <input
                              key='3'
                              id='input-distribution'
                              name='Input-Distribution'
                              value='Input-Distribution'
                              type='checkbox'
                              className={`form-control form-control-sm ${!valid && 'is-invalid'}`}
                              onChange={e => this.onValueChange(e, 'services')}
                            />
                          </span>

                          {error && <div className='invalid-feedback'>{error}</div>}
                        </div>
                      )}
                    </InputGroup>
                    <InputGroup tag='div' validate={['community']}>
                      {({ error, valid }) => (
                        <div className='col-md-12 mb-3'>
                          <label className='font-weight-bold'> Community </label>
                          <select
                            required
                            className={`custom-select form-control-sm custom-select-sm ${!valid &&
                              'is-invalid'}`}
                            id='community'
                            name='community'
                            onChange={e => this.onValueChange(e, 'resource')}
                          >
                            <option value=''>Choose community</option>
                            {this.state.communityResources.map(resource => (
                              <option
                                id={resource.id}
                                title={resource.name}
                                className='resource dropdown-item d-flex justify-content-between align-items-center'
                                key={resource.id}
                                value={resource.id}
                              >
                                {resource.name}
                              </option>
                            ))}
                          </select>
                          {error && <div className='invalid-feedback'>{error}</div>}
                        </div>
                      )}
                    </InputGroup>
                    <InputGroup tag='div' validate={['cost']}>
                      {({ error, valid }) => (
                        <div className='col-md-12 mb-3'>
                          <label className='font-weight-bold'> Cost Per Unit </label>
                          <input
                            type='text'
                            required
                            className={`form-control form-control-sm ${!valid && 'is-invalid'}`}
                            id='cost'
                            name='cost'
                          />
                          {error && <div className='invalid-feedback'>{error}</div>}
                        </div>
                      )}
                    </InputGroup>
                    <InputGroup tag='div' validate={['taskname']}>
                      {({ error, valid }) => (
                        <div className='col-md-12 mb-3'>
                          <label className='font-weight-bold'> Task's Name </label>
                          <input
                            type='text'
                            required
                            className={`form-control form-control-sm ${!valid && 'is-invalid'}`}
                            id='taskname'
                            name='taskname'
                          />
                          {error && <div className='invalid-feedback'>{error}</div>}
                        </div>
                      )}
                    </InputGroup>
                    <InputGroup tag='div' validate={['description']}>
                      {({ error, valid }) => (
                        <div className='col-md-12 mb-3'>
                          <label className='font-weight-bold'> Task's Description </label>
                          <input
                            type='text'
                            required
                            className={`form-control form-control-sm ${!valid && 'is-invalid'}`}
                            id='description'
                            name='description'
                          />
                          {error && <div className='invalid-feedback'>{error}</div>}
                        </div>
                      )}
                    </InputGroup>

                    <div className='modal-footer border-top-0'>
                      <button
                     
                        type='button'
                        className='btn btn-expend text-white'
                        data-dismiss='modal'
                        onClick={resetUpdateState}
                      >
                        Close
                      </button>
                      <button
                       onClick={this.saveAssignedTask}
                        type='button'
                        id='submitButton'
                        name='submitButton'
                        className='btn btn-revenue text-white float-right'
                        disabled={pristine || submitting}>
                        Add
                      </button>
                    </div>
                  </>
                )}
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
