import PropTypes from 'prop-types';
import React, { lazy, Suspense, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchData } from '../../api/api.actions';
import { getErrorMsg, getSuccessMsg } from '../../api/api.selectors';
import Navbar from '../../components/Navbar';
import ServiceProviderTools from '../../components/serviceProvider/ServiceProviderTools';
import ResourceListSkeleton from '../../components/skeleton/ResourceListSkeleton';
import Spinner from '../../components/Spinner';
import Toolbar from '../../components/Toolbar';
import notify from '../../helpers/notify';
import useServiceProvider from '../../hooks/useServiceProvider';
import { toggleService, updateServiceProviders } from './serviceProvider.actions';
import {
  getResourcesLink,
  getServiceProviders,
  selectServiceProviders
} from './serviceProvider.selectors';

const SubResourcesList = lazy(() =>
  import('../../components/resources/resourceItemDetails/SubResourcesList')
);
const ServiceProviderForm = lazy(() =>
  import('../../components/serviceProvider/ServiceProviderForm')
);

const ServiceProviders = ({
  errorMsg,
  fetchData,
  successMsg,
  isLoading,
  serviceProviders,
  toggleService,
  updateServiceProviders
}) => {
  const [state, dispatch] = useServiceProvider();
  const { error, success } = notify();
  const saveProviderInfo = async _ => {
    const { description, hasUpdated, providerName, serviceProviderData } = state;
    let data = { name: providerName, description };
    if (hasUpdated) {
      data.id = serviceProviderData.id;
    }
    if (providerName.length > 0 && description.length > 0) {
      updateServiceProviders(data);

      document.querySelector('#ServiceProviderFormClose').click();
      onResetModal();
    } else error('Please fill all fields');
  };

  const onResetModal = _ => dispatch({ type: 'RESET' });

  const editServiceProvider = serviceProvider => {
    dispatch({ type: 'EDIT', payload: serviceProvider });
    document.getElementById('serviceProviderBtn').click();
  };
  const onChange = e => {
    dispatch({
      type: 'ON_CHANGE',
      payload: { [e.target.name]: e.target.value }
    });
  };

  const services = [
    { id: 'SharedAgency', name: 'Shared Agency' },
    { id: 'InputSupply', name: 'Input Supply' },
    { id: 'Labour', name: 'Labour' },
    { id: 'ExtensionService', name: 'Extension Service' }
  ];

  useEffect(() => {
    successMsg && success(successMsg);
    errorMsg && error(errorMsg);
    return;
  }, [successMsg, errorMsg, success, error]);

  useEffect(() => {
    const controller = new AbortController();
    fetchData('SERVICE_PROVIDERS', { signal: controller.signal });
    return () => controller.abort();
  }, [fetchData]);

  return (
    <div className='main-content container-fluid'>
      <Navbar
        navLinks={[
          {
            path: '/resources/serviceprovider',
            header: 'ServiceProviders'
          }
        ]}
        small='Add or Remove Service Provider Resources on the Platform'
      />
      <div className='bg-white py-1 px-2'>
        <Toolbar
          onSearch={onChange}
          tools={ServiceProviderTools}
          totalServiceProviders={serviceProviders?.length ?? 0}
        />
        <Spinner isLoading={isLoading} data={serviceProviders}>
          <Suspense fallback={<ResourceListSkeleton />}>
            <div className='py-2 fix-height revenue-scrollbar'>
              <SubResourcesList
                data={serviceProviders}
                filterTag='serviceProviderTag'
                manageUrl='service-provider-resource'
                searchInput={state.searchValue}
                services={services}
                toggleServiceStatus={toggleService}
                updateModal={editServiceProvider}
              />
            </div>
          </Suspense>
        </Spinner>
      </div>
      <Suspense fallback={<p />}>
        <ServiceProviderForm
          onChange={onChange}
          providerState={state}
          hasUpdated={state.hasUpdated}
          providerName={state.providerName}
          description={state.description}
          resetModal={onResetModal}
          saveAndUpdate={saveProviderInfo}
        />
      </Suspense>
    </div>
  );
};

ServiceProviders.propTypes = {
  errorMsg: PropTypes.string.isRequired,
  fetchData: PropTypes.func.isRequired,
  successMsg: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  serviceProviders: PropTypes.arrayOf(PropTypes.object).isRequired,
  toggleService: PropTypes.func.isRequired,
  updateServiceProviders: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  errorMsg: getErrorMsg(state),
  isLoading: getServiceProviders(state).isLoading,
  serviceProviders: selectServiceProviders(state),
  successMsg: getSuccessMsg(state),
  serverLink: getResourcesLink(state)
});

export default connect(mapStateToProps, {
  fetchData,
  toggleService,
  updateServiceProviders
})(ServiceProviders);
