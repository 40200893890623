import React, { memo } from 'react'

const PreviewCommunityResource = ({
  clearSelectedCommunityResource,
  selectedCommunityResource
}) => {
  return (
    <div
      className='modal fade'
      id='communityDetailsModal'
      data-backdrop='static'
      data-keyboard='false'
      tabIndex={-1}
      role='dialog'
      aria-labelledby='exampleModalLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog' role='document'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h3>
              {/* View Community Details Modal */}
              <span className='badge'>Community Resource Details</span>
            </h3>
            <button
              type='button'
              className='close'
              data-dismiss='modal'
              aria-label='Close'
              onClick={clearSelectedCommunityResource}
            >
              <span aria-hidden='true'>×</span>
            </button>
          </div>
          <div className='modal-body'>
            <p>Name:</p>
            <p>{selectedCommunityResource.name}</p>
            <p>Description:</p>
            <p>{selectedCommunityResource.description}</p>
            <p>Date Created:</p>
            <p>{selectedCommunityResource.createdOn}</p>
          </div>
          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-expend text-white'
              data-dismiss='modal'
              onClick={clearSelectedCommunityResource}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(PreviewCommunityResource)
