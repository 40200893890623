import PropTypes from 'prop-types';

export const activeResource = { rel: PropTypes.string };

export const userType = {
  userId: PropTypes.number.isRequired,
  userRole: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  userActiveResources: PropTypes.arrayOf(PropTypes.string)
};
