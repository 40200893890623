import PropTypes from 'prop-types';
import React, { memo, useEffect } from 'react';
import apiService from '../../api/apiService';
import Navbar from '../../components/Navbar';
import notify from '../../helpers/notify';
import usePostingForm from '../../hooks/usePostingForm';
import './styles/postAgentModal.css';

const { error } = notify();

const AgentPostingForm = props => {
  const {
    match: {
      params: { sharedAgencyId, agentId }
    },
    location: { pathname }
  } = props;

  const {
    state,
    dispatch,
    selectCommunityResource,
    selectCommunities,
    selectTaskGroup,
    selectFarmers,
    clearPostingForm,
    renderCommunities,
    // availableFarmers,
    // farmersLeft,
    postAgent
  } = usePostingForm();

  const handlePostAgent = () => {
    let data;

    const { selectedAgents, selectedCommunities, selectedTaskGroup } = state;
    const isValidForPosting =
      selectedCommunities.length > 0 && agentId && Object.entries(selectedTaskGroup).length > 0;

    if (isValidForPosting) {
      data = {
        agentId,
        selectedCommunities: selectedCommunities,
        postingGroupId: selectedTaskGroup.id
      };

      postAgent(data);
    } else {
      error(
        'Please select the community resource, community, task and the number of resources you want to assign.'
      );
    }
  };

  useEffect(() => {
    async function fetchCommunityResources() {
      try {
        const communityResources = await apiService.get('/community-resource');
        communityResources.code === undefined &&
          dispatch({
            type: 'GET_COMMUNITY_RESOURCES',
            payload: {
              communityResources: [...communityResources.reverse()]
            }
          });
      } catch (_) {
        error('Server is unable to process your request. Try again later.');
      }
    }
    fetchCommunityResources();
    return;
  }, [dispatch]);

  return (
    <>
      <Navbar
        navLinks={[
          {
            path: `/new-service-provider-resource`,
            header: 'Services |'
          },
          {
            path: `/new-shared-agency/${sharedAgencyId}`,
            header: `| Shared Agency |`
          },
          {
            path: `${pathname}`,
            header: '| Post Agent'
          }
        ]}
        small='Post your Agent here'
      />
      <div className='d-flex justify-content-center align-items-center p-3' role='document'>
        <div>
          <div className='modal-header border-bottom-0'>
            <h5 className='h5 modal-title font-weight-bold'> Choose Posting location</h5>
          </div>
          <div className='bg-light border'>
            <div className='modal-body border-top-0'>
              <div className='row justify-content-between'>
                <div className='col-lg-5 dropdown my-2'>
                  <span className='h6 px-1 font-weight-bold text-muted'>Community Resource</span>
                  <input
                    type='text'
                    list='data'
                    size='10'
                    placeholder='Choose Community Resource'
                    className='form-control'
                    onChange={selectCommunityResource}
                  />
                  <datalist id='data'>
                    {state.communityResources.length > 0 ? (
                      state.communityResources.map((resource, idx) => (
                        <option value={idx} key={resource.id}>
                          {resource.name}
                        </option>
                      ))
                    ) : (
                      <option key='no community' value='No community resource'>
                        No community resource
                      </option>
                    )}
                  </datalist>
                </div>

                <div className='col-lg-4 dropdown my-2'>
                  <label htmlFor='communitiesBtn' className='h6 px-1 font-weight-bold text-muted'>
                    Communities
                  </label>

                  <button
                    className='btn border dropdown-toggle custom-select'
                    id='communitiesBtn'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    Choose Communities
                  </button>
                  {state.communities && (
                    <>
                      <div
                        className='dropdown-menu fix-height fix-height50 revenue-scrollbar'
                        style={{
                          MaxHeight: '200px',
                          display: state.communities.length === 0 && 'none'
                        }}
                        aria-labelledby='communitiesBtn'
                      >
                        <button className='btn dropdown-item d-flex justify-content align-items-center'>
                          <input
                            type='checkbox'
                            id='select-all'
                            className='custom-checkbox community mx-2'
                            value='select-all'
                            onClick={selectCommunities}
                          />
                          <label className='cursor' htmlFor='select-all'>
                            Select all
                          </label>
                        </button>
                        {state.communities.map((community, idx) => (
                          <button
                            data-communityidx={idx}
                            className='btn dropdown-item d-flex flex-row-reverse justify-content-end align-items-baseline'
                            key={community.id}
                          >
                            <label className='cursor' htmlFor={community.name}>
                              {community.name}
                            </label>
                            <input
                              data-communityidx={idx}
                              type='checkbox'
                              className='community mx-2'
                              id={community.name}
                              name={community.name}
                              value={community}
                              onClick={selectCommunities}
                            />
                          </button>
                        ))}
                      </div>
                    </>
                  )}
                </div>

                <div className='col-lg-3 dropdown my-2'>
                  <label htmlFor='taskGroupBtn' className='h6 px-1 font-weight-bold text-muted'>
                    Task groups
                  </label>
                  <button
                    className='btn border dropdown-toggle custom-select'
                    id='taskGroupBtn'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    Choose task groups
                  </button>
                  {state.taskGroups && (
                    <div
                      className='dropdown-menu fix-height revenue-scrollbar'
                      style={{
                        MaxHeight: '200px',
                        display: state.taskGroups.length === 0 && 'none'
                      }}
                      aria-labelledby='dropdownMenuLink'
                    >
                      {state.taskGroups.map((taskGroup, idx) => (
                        <button
                          data-taskgroupid={idx}
                          className='btn postgroup dropdown-item d-flex justify-content-between align-items-center'
                          id={taskGroup.id}
                          title={taskGroup.groupName}
                          onClick={selectTaskGroup}
                          key={taskGroup.id}
                        >
                          {taskGroup.groupName}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              <div className='border-top pt-4 mt-4'>
                <div className='font-weight-bold h5'>Selected</div>
                <span className='row px-3 align-items-center'>
                  <h6 className='font-weight-bold font-italic py-1 my-2'>Community Resource: </h6>
                  <p className='m-2'>
                    {state.selectedCommunityResource && state.selectedCommunityResource.name}
                  </p>
                </span>
                <span className='row px-3 align-items-center'>
                  <h6 className='font-weight-bold font-italic py-1 my-2'>Communities: </h6>
                  <p className='m-2'>{renderCommunities()}</p>
                </span>
                <span className='row px-3 align-items-center'>
                  <h6 className='font-weight-bold font-italic py-1 my-2'>Task:</h6>
                  <p className='m-2'>{state.selectedTaskGroup && state.selectedTaskGroup.name}</p>
                </span>
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                onClick={clearPostingForm}
                className='btn btn-expend text-white btn-sm'
                data-dismiss='modal'
              >
                Cancel
              </button>
              <button
                type='button'
                className='btn bg-revenue text-white btn-sm '
                data-dismiss='modal'
                onClick={handlePostAgent}
              >
                Post
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

AgentPostingForm.propTypes = {
  // clearState: PropTypes.func.isRequired,
  // onSelectChange: PropTypes.func.isRequired,
  // handlePostAgent: PropTypes.func.isRequired,
  // agentId: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default memo(AgentPostingForm, (prevProps, nextProps) => prevProps === nextProps);
