import PropTypes from 'prop-types';
import React from 'react';
import { Route } from 'react-router-dom';

const WithSubRoutes = ({ component: Component, routes, path, exact, ...otherProps }) => {
  if (path === '/') {
    return <Component {...otherProps} path={path} routes={routes} />;
  }

  return (
    <Route
      exact={exact}
      path={path}
      render={props => <Component {...props} routes={routes} {...otherProps} />}
    />
  );
};

WithSubRoutes.propTypes = {
  exact: PropTypes.bool,
  component: PropTypes.elementType,
  routes: PropTypes.any,
  path: PropTypes.any
};

export default WithSubRoutes;
