import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form, InputGroup } from 'react-html5-form';
import Navbar from '../Navbar';
import notify from '../../helpers/notify';
import { requestLogout } from '../../app.actions';
const { success, error, info } = notify();

const Settings = props => {
  useEffect(() => {
    RenewPasswordPopup();
  }, []);

  const clearUser = () => {
    return props.requestLogout(props.history);
  };

  const RenewPasswordPopup = () => {
    if (props.auth.isPasswordReset === false) {
      info('Please update your password to keep your account secure');
    }
  };

  const handlePasswordChange = async form => {
    const formData = new FormData(form.getRef().current);
    let username = localStorage.getItem('user');
    const data = {
      username,
      password: formData.get('currentPassword'),
      newpassword: formData.get('newpassword')
    };
    // const response = await api.post('/login/changepassword', data);
    fetch('https://api.everyfarmer.live:8181/new-rest/login/changepassword', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
      method: 'POST'
    })
      .then(response => response.json())
      .then(data => {
        console.log('data', data);
        if (data.code == 200) {
          success(data.message);
          setTimeout(() => {
            clearUser();
          }, 2500);
        } else {
          error(data.message);
        }
      })
      .catch(error => {});

    // console.log(response);
    // if (response.code === 200) {
    //   success(response.message);
    // } else {
    //   error(response.message);
    // }
    // api.post('/login/changepassword', data).then(res => {
    //   console.log(1111111, res);
    // });
    // .then(res => {
    //   if (res.code === 200) {
    //     success(res.message);
    //   }
    // })
    // .catch(error => {
    //   console.log(11111111111, res.message);
    //   error(res.message);
    // });
  };

  const validatePassword = input => {
    let password = document.getElementById('newpassword').value;
    let confirmPassword = document.getElementById('confirmPassword').value;

    if (password !== confirmPassword) {
      input.setCustomValidity(`Passwords don't match`);
      return false;
    }
    return true;
  };

  return (
    <React.Fragment>
      <Navbar
        navLinks={[
          {
            path: '/settings',
            header: 'Settings'
          }
        ]}
        small='Update your Settings'
      />
      <div className='container-fluid p-3'>
        <div className='container'>
          <div className='row my-4'>
            <div className='col-md-6'>
              <Form onSubmit={handlePasswordChange} className='py-4'>
                {({ pristine, submitting }) => (
                  <>
                    <InputGroup validate={['currentPassword']} tag='div' className='form-row'>
                      {({ error, valid }) => {
                        return (
                          <div className='form-group  input-group-md col-md-12'>
                            <label htmlFor='currentPassword' className='text-black'>
                              Current Password
                            </label>
                            <input
                              data-testid='currentPassword'
                              id='currentPassword'
                              required
                              type='password'
                              // pattern='^([0-9]{9})|([A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3})$'
                              name='currentPassword'
                              placeholder='Enter your current password'
                              className={`form-control ${!valid && 'is-invalid'}`}
                            />

                            {error && <p className='text-danger'>{error}</p>}
                          </div>
                        );
                      }}
                    </InputGroup>

                    <InputGroup
                      validate={{
                        newpassword: validatePassword,
                        confirmPassword: validatePassword
                      }}
                      tag='div'
                      className='form-row'
                    >
                      {({ error }) => (
                        <>
                          <div className='form-group  input-group-md col-md-12'>
                            <label htmlFor='newpassword' className='text-black'>
                              New Password
                            </label>
                            <input
                              data-testid='newpassword'
                              id='newpassword'
                              required
                              name='newpassword'
                              type='password'
                              placeholder='Enter new Password'
                              className='form-control'
                            />
                            {error && <p className='text-danger'>{error}</p>}
                          </div>
                          <div className='form-group  input-group-md col-md-12'>
                            <label htmlFor='confirmPassword' className='text-black'>
                              Confirm New Password
                            </label>
                            <input
                              data-testid='confirmPassword'
                              id='confirmPassword'
                              required
                              name='confirmPassword'
                              type='password'
                              placeholder='Enter new Password'
                              className='form-control'
                            />
                            {error && <p className='text-danger'>{error}</p>}
                          </div>
                        </>
                      )}
                    </InputGroup>

                    <button
                      id='load'
                      type='submit'
                      disabled={pristine || submitting}
                      className='btn btn-sm btn-revenue text-white px-3'
                      data-loading-text="<i className='fa fa-spinner fa-spin '></i> Updating your Password"
                    >
                      Update Password
                    </button>
                  </>
                )}
              </Form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return state;
};

export default connect(mapStateToProps, { requestLogout })(withRouter(Settings));
