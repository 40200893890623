import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Swal from 'sweetalert2'
import moreIcon from '../../../../../assets/3Dots.svg'
import minus from '../../../../../assets/Minus-Remove.svg'
import BaseUrl from '../../../../../security/base-url'
import { selectFarmResource } from '../../../../pages/farm/farm.actions'

class FarmResourceItem extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  // componentDidMount() {
  //   console.log("hhhhhhhhhhh", this.props);
  // }

  manageStatus = event => {
    const status = event.target.name
    Swal.fire({
      title: 'Are you sure?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#96c63d',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then(result => {
      if (result.value) {
        const config = {
          method: 'PUT',
          headers: {
            'content-type': 'application/json',
            Accept: 'application/json'
          }
        }

        fetch(
          BaseUrl.url_dashbord + '/farmresources/managestatus/' + this.props.item.id + '/' + status,
          config
        )
          .then(response => {
            if (response.status === 200) {
              return response.json()
            } else {
              throw new Error()
            }
          })
          .then(data => {
            this.setState({ data })
          })
          .catch(e => {
            Swal({
              title: 'Error!',
              text: 'Sorry an Error Occured',
              type: 'error',
              animation: true
            })
          })
      }
    })
  }

  handleSelectFarmResource = () => {
    this.props.dispatch(selectFarmResource(this.props.item))
    this.props.history.push('/view-farms/')
  }

  render() {
    return (
      <>
        <div className='col-11 d-flex justify-content-between bg-list-inner rounded-pill'>
          <div className='d-flex flex-row align-items-center justify-content-center'>
            <input type='checkbox' name='' className='align-middle mx-2' />
            <button
              className='align-middle mb-0 cursor btn '
              onClick={this.handleSelectFarmResource}
            >
              {this.props.item.name}
            </button>
          </div>
          <div>
            {this.props.item.status === 'ACTIVE' ? (
              <span className='badge bg-revenue text-white m-2 p-1'>Active</span>
            ) : (
              <span className='badge bg-expend text-white m-2 p-1'>Inactive</span>
            )}
            <button
              className='btn'
              onClick={() =>
                Swal.fire({
                  title: 'Are you sure you want to delete?',
                  type: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#96c63d',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Yes'
                }).then(result => {
                  if (result.value) {
                    this.props.onDelete(this.props.item.id)
                  }
                })
              }
            >
              <img className='cursor' src={minus} alt='Remove Icon' />
            </button>
          </div>
        </div>
        {/* New Component Ends */}
        <div className='dropdown'>
          <div className='dropdown dropleft'>
            <div
              className='menuIconWrapper d-flex justify-content-center align-content-center mousePointer'
              id='dropdownMenuButton'
              data-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded='false'
            >
              <img src={moreIcon} className='cursor' alt='More Icon' />
            </div>
            <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
              {/* <a
                className="dropdown-item"
                href="#"
                data-toggle="modal"
                data-target="#grantResourceModal">
                Grant Resource Access
              </a>
              <a className="dropdown-item" href="#">
                Add a Program
              </a>
              <a
                className="dropdown-item border-top"
                href="#"
                data-toggle="modal"
                data-target="#editFarmResourceModal">
                Edit
              </a> */}
              <button
                className='dropdown-item'
                onClick={this.manageStatus}
                name={this.props.item.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'}
              >
                {this.props.item.status === 'ACTIVE' ? 'Suspend' : 'Activate'}
              </button>
            </div>
          </div>
        </div>

        {/* Grant Resource Modal */}
        <div
          className='modal'
          id='grantResourceModal'
          tabIndex={-1}
          role='dialog'
          aria-hidden='true'
        >
          <div className='modal-dialog modal-dialog-centered' role='document'>
            <div className='modal-content'>
              <div className='modal-header bg-light flex-column p-3'>
                <div className='d-flex'>
                  <h4 className='d-inline greener mb-0'>({this.props.item.name})</h4>
                  <span>
                    <p className='mb-0'>- Grant Resource Access</p>
                  </span>
                  <button
                    type='button'
                    className='close ml-5 p-2'
                    data-dismiss='modal'
                    aria-label='Close'
                  >
                    <span aria-hidden='true'>×</span>
                  </button>
                </div>
                <small className='my-1'>
                  Share Access to another person to help manage this resource
                </small>
              </div>

              <div className='modal-body'>
                <ul className='list-unstyled pl-0'>
                  <li className='my-2 d-flex justify-content-between'>
                    <p className='mb-0'>mymember@gmail.com</p>
                    <div className='d-flex'>
                      <small className='align-middle mx-3'>Owner</small>
                      <div className='dropdown'>
                        <div className='dropdown dropleft'>
                          <div
                            className='menuIconWrapper d-flex justify-content-center align-content-center mousePointer'
                            id='dropdownMenuButton'
                            data-toggle='dropdown'
                            aria-haspopup='true'
                            aria-expanded='false'
                          >
                            <img src={moreIcon} className='cursor mb-3' alt='More Icon' />
                          </div>
                          <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                            <button
                              className='dropdown-item'
                              data-toggle='modal'
                              data-target='#grantResourceModal'
                            >
                              Grant Resource Access
                            </button>
                            <button className='dropdown-item'>Add A Program</button>
                            <button
                              className='dropdown-item border-top'
                              data-toggle='modal'
                              data-target='#editFarmResourceModal'
                            >
                              Edit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className='my-2 d-flex justify-content-between'>
                    <p className='mb-0'>mystaff@gmail.com</p>
                    <div className='d-flex'>
                      <small className='align-middle mx-3'>Owner</small>
                      <div className='dropdown'>
                        <div className='dropdown dropleft'>
                          <div
                            className='menuIconWrapper d-flex justify-content-center align-content-center mousePointer'
                            id='dropdownMenuButton'
                            data-toggle='dropdown'
                            aria-haspopup='true'
                            aria-expanded='false'
                          >
                            <img src={moreIcon} className='cursor mb-3' alt='More Icon' />
                          </div>
                          <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                            <button
                              className='dropdown-item'
                              data-toggle='modal'
                              data-target='#grantResourceModal'
                            >
                              Grant Resource Access
                            </button>
                            <button className='dropdown-item'>Add A Program</button>
                            <button
                              className='dropdown-item border-top'
                              href='#'
                              data-toggle='modal'
                              data-target='#editFarmResourceModal'
                            >
                              Edit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <div className='modal-footer p-2'>
                <input type='text' name='members' className='col-9 form-control mb-0' />
                <div className='dropdown form-align'>
                  <div className='dropdown dropleft'>
                    <div
                      className='menuIconWrapper d-flex justify-content-center align-content-center mousePointer'
                      id='dropdownMenuButton'
                      data-toggle='dropdown'
                      aria-haspopup='true'
                      aria-expanded='false'
                    >
                      <img src={moreIcon} className='cursor' alt='More Icon' />
                    </div>
                    <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                      <button
                        className='dropdown-item'
                        data-toggle='modal'
                        data-target='#grantResourceModal'
                      >
                        Can View
                      </button>
                      <button className='dropdown-item'>Can Edit</button>
                    </div>
                  </div>
                </div>
                <button
                  type='button'
                  className='btn btn-revenue btn-sm text-white'
                  data-dismiss='modal'
                  onClick={this.deleteResource}
                >
                  Grant Access
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Grant Resource Modal Ends Here */}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    selectedFarmResource: state.farmResource.selectedFarmResource
  }
}

export default withRouter(connect(mapStateToProps)(FarmResourceItem))

// import React, { Component } from "react";
// import { NavLink } from "react-router-dom";
// import "./FarmResourceItem.css";
// import minus from "../../../../../assets/Minus-Remove.svg";
// import downIcon from "../../../../../assets/Down.svg";
// import moreIcon from "../../../../../assets/3Dots.svg";
// import AddPlus from "../../../../../assets/Add-Plus.svg";
// import RightChevron from "../../../../../assets/Chevron.svg";
// import Up from "../../../../../assets/Up.svg";
// import AreaMapping from "./area-mapping/AreaMapping";
// import SoilMapping from "./soil-mapping/SoilMapping";
// import Assets from "./assets/AssetItem";

// class FarmResourceItem extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       subResourcesVisible: false
//     };
//     //this.toggleResources = this.toggleResources.bind(this);
//   }

//   toggleResources = () => {
//     this.setState(prevState => ({
//       subResourcesVisible: !prevState.subResourcesVisible
//     }));
//   };

//   render() {
//     return (
//       <div className="p-1">
//         <div className="container item">
//           <div className="row w-100 border-bottom resource-head align-items-center">
//             <div className="col-4 d-flex">
//               <input type="checkbox" className="m-2" />
//               <p className="d-inline text-secondary font-weight-light mb-0">
//                 Cocoa Farm
//               </p>
//             </div>
//             <div className="col-1 d-flex justify-content-around">
//               <img src={minus} className="ml-1 mr-2" alt="Remove Icon" />
//               <div className="dropdown ml-auto">
//                 <div
//                   className="menuIconWrapper d-flex justify-content-center align-content-center mousePointer"
//                   id="dropdownMenuButton"
//                   data-toggle="dropdown"
//                   aria-haspopup="true"
//                   aria-expanded="false">
//                   <img src={moreIcon} className="" alt="More Icon" />
//                 </div>
//                 <div
//                   className="dropdown-menu"
//                   aria-labelledby="dropdownMenuButton">
//                   <a className="dropdown-item" href="#">
//                     Edit
//                   </a>

//                   <a className="dropdown-item" href="#">
//                     Add to Program
//                   </a>
//                   <NavLink to="/profile/" className="dropdown-item">
//                     View Farmers Profile
//                   </NavLink>
//                   <a className="dropdown-item border-top" href="#">
//                     Delete
//                   </a>
//                 </div>
//               </div>
//             </div>
//             <div
//               className="col-1 text-center py-2 ml-auto border-left resource-head__btn cursor"
//               onClick={this.toggleResources}>
//               {this.state.subResourcesVisible === false ? (
//                 <img src={downIcon} alt="Hide Icon" />
//               ) : (
//                 <img src={Up} alt="Hide Icon" />
//               )}
//             </div>
//           </div>
//         </div>

//         {this.state.subResourcesVisible === false ? (
//           " "
//         ) : (
//           <div className="container py-2 open-resource item">
//             <AreaMapping detailsOfArea={this.props.FarmResourceDetail} />
//             <SoilMapping detailsOfSoil={this.props.FarmResourceDetail} />
//             <Assets detailsOfAssets={this.props.FarmResourceDetail} />

//             <div className="col d-flex justify-content-end">
//               <NavLink
//                 to="/new-farm-resource/new-area-mapping/"
//                 type="button"
//                 className="btn text-secondary font-weight-light bg-white drop-shadow btn-sm mousePointer py-1 px-3 mb-1">
//                 View/Manage
//                 <img
//                   src={RightChevron}
//                   className="ml-3"
//                   alt="Right Arrow Icon"
//                 />
//               </NavLink>
//             </div>
//           </div>
//         )}
//       </div>
//     );
//   }
// }

// export default FarmResourceItem;
