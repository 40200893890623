import React from 'react';
import { Link } from 'react-router-dom';
import moreIcon from '../../../../assets/3Dots.svg';

const AgentsTools = ({ match: { params } }) => {
  return (
    <div className='row justify-content-end p-2'>
      <Link
        className='btn btn-outline-secondary btn-sm px-3'
        to={`/${params.sharedAgencyId}/assigned-tasks`}
      >
        Create/View assigned tasks
      </Link>
      {/* <Link
        className='btn btn-revenue text-white btn-sm px-2 mx-2'
        to={`/${params.sharedAgencyId}/add-agent`}
      >
        <i className='fas fa-plus fa-sm mr-2' />
        <span>Add Agent</span>
      </Link> */}
      <span className='dropdown dropleft py-3 mr-4'>
        <span
          className='d-flex justify-content-center mousePointer'
          id='dropdownMenuButton'
          data-toggle='dropdown'
          aria-haspopup='true'
          aria-expanded='false'
        >
          <img src={moreIcon} className='cursor px-2' alt='More Icon' />
        </span>

        <span className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
          <Link className='dropdown-item' to={`/job-postings`}>
            View Job Adverts
          </Link>
          <button className='dropdown-item' data-toggle='modal' data-target='#importModal'>
            Import Agents
          </button>
        </span>
      </span>
    </div>
  );
};

export default AgentsTools;
