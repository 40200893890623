import React from 'react';
import smalldots from '../../../assets/3Dots.svg';

const InputDashlet = props => {
  return (
    <>
      {props.eops
        ? props.eops.map((e, index) => {
            return (
              <div className='card border rounded-top m-2' key={index}>
                <div className='card-header rounded-top d-flex bg-dark text-white justify-content-between p-2'>
                  <span className='small-font-fix align-bottom mt-2'>{e.name}</span>
                  {/* Dropdown Menu */}
                  <div
                    className='menuIconWrapper mousePointer d-flex align-items-center justify-content-center'
                    id='dropdownMenuButton'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    <img
                      src={smalldots}
                      alt='More Icon'
                      className='dropdown-toggle mx-2'
                      type='Icon'
                      id='dropdownMenuButton'
                      data-toggle='dropdown'
                      aria-haspopup='true'
                      aria-expanded='false'
                    />
                    <div className='dropdown-menu pointer' aria-labelledby='dropdownMenuButton'>
                      <button
                        className='dropdown-item'
                        onClick={() => console.log('You just click me !!!!!')}
                      >
                        Delete
                      </button>
                    </div>
                  </div>

                  {/* Dropdown Menu Ends */}
                </div>
                <div className='card-body p-2'>
                  <div className='d-flex justify-content-between'>
                    <span className=' p-text'>Units</span>
                    <span className=' p-text font-weight-bold'>{e.unitOfMeasurement}</span>
                  </div>
                  <div className='d-flex justify-content-between my-2'>
                    <span className=' p-text'>Quantity per Hectare</span>
                    <span className=' p-text font-weight-bold'>{e.quantityPerHectare}</span>
                  </div>
                  <div className='d-flex justify-content-between'>
                    <span className=' p-text'>Cost</span>
                    <span className=' p-text font-weight-bold'>{e.unitCost}</span>
                  </div>
                </div>
              </div>
            );
          })
        : ''}
    </>
  );
};

export default InputDashlet;
