import React from 'react';

/**
 *
 * @param {*} icon Icon for info dashlet
 * @param {*} iconName (String) Description of icon
 * @param {*} title (String)
 * @param {*} progessRate (Numbee)
 * @param {*} value (Number)
 */
const InfoDashlet = ({ icon, iconName, title, progressionRate, value }) => {
  return (
    <div className='card drop-shadow border mr-3 mb-2 px-3' role='presentation'>
      <div className='card-body text-center px-3 py-2'>
        <img src={icon} alt={iconName} className='m-2' />
        <h4 className='text-dark px-3 py-2 mb-0'>{value}</h4>
        <h5 aria-labelledby='presentation-title'>{title}</h5>
        {/* <p
          data-progress-rate={progressionRate}
          className={`
            ${
              progressionRate > 1 ? 'greener' : 'text-danger'
            } my-2 font-weight-bold justify-content-center d-flex`}
        >
          <i className='fa-sort-up fas fa-1x mx-2 font-24' />
          <span>{progressionRate}% </span>
        </p> */}
      </div>
    </div>
  );
};

export default React.memo(InfoDashlet);
