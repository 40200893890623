import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { api } from '../../api/api.config';
import Swal from 'sweetalert2';
import BaseUrl from '../../../security/base-url';
import CommunitiyResourceItem from '../../components/community/CommunitiyResourceItem';
import CommunitiesResourceForms from '../../components/community/CommunityResourceForms';
import PreviewCommunityResource from '../../components/community/PreviewCommunityResource';
import Navbar from '../../components/Navbar';
import Spinner from '../../components/Spinner';
import Toolbar from '../../components/Toolbar';
import accessStorage from '../../utils/accessStorage';
import { getFilteredData } from '../../utils/resourceFilter';
import { fetchFarmItemsByCommunityResource } from '../farm/farm.actions';
import {
  getCommunityOverview,
  getCommunityResource,
  getSwitchFarmers,
  handleCommunityResourceId,
  handleSuspend
} from './communities.actions';
import './communityStyle.css';

class CommunityResource extends Component {
  state = {
    successMessage: '',
    selectedCommunityResource: {},
    searchValue: '',
    isEditing: false,
    communityResourceName: '',
    communityResourceDescription: '',
    isMappingEnabled: false,
    communityResourceCountry: '',
    communityResourceState: '',
    states: ''
  };

  

  componentDidMount() {
    this.props.getAllCommunityResource();
    this.getStates();
  }

  getStates = async () => {
    let response;
    try {
      response = await api.get('/states');
      this.setState({states:response.data.list});
    } catch (error) {
      console.log(error);
    }
  };

  clearForm = () => {
    this.setState({
      communityResourceName: '',
      communityResourceDescription: '',
      isMappingEnabled: false,
      communityResourceCountry: '',
      communityResourceState: ''
    });
    document.querySelector('#mappingEnabled').checked = false;
    this.state.isEditing && this.turnOffEditing();
  };

  handleEnableMapping = _ => {
    this.setState(prevState => ({
      isMappingEnabled: !prevState.isMappingEnabled
    }));
  };

  /**
   * this perform delete operation
   * @param {the full path of the community resource } path
   * @param {the headers request configuration} heardersConfig
   */
  sweetAlertDeleteMethod(path, heardersConfig) {
    const swalWithBootstrapButtons = Swal.mixin({
      confirmButtonClass: 'btn btn-revenue text-white mx-2',
      cancelButtonClass: 'btn btn-expend text-white',
      buttonsStyling: false
    });

    swalWithBootstrapButtons({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then(result => {
      if (result.value) {
        fetch(path, heardersConfig)
          .then(respons => respons.json())
          .then(data => {
            if (data !== null) {
              if (data.message === 'error') {
                swalWithBootstrapButtons(
                  'Error!',
                  'Error while trying to delete community resource.',
                  'error'
                );
              }
              this.props.getCommunityOverview();
              this.props.getAllCommunityResource();
              swalWithBootstrapButtons(
                'Deleted!',
                'Your community resource has been deleted.',
                'success'
              );
            }
          })
          .catch(err => {});
      } else if (
        // Read more about handling dismissals
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons('Cancelled', 'Your community resource is safe.', 'error');
      }
    });
  }

  handleDeleteCommunityResource = communityId => {
    const deletepath = BaseUrl.url_dashbord + '/community-resource/' + communityId;
    const headersConfig = {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };
    this.sweetAlertDeleteMethod(deletepath, headersConfig);
  };

  clearSelectedCommunityResource = () => this.setState({ selectedCommunityResource: {} });
  /**
   * This method edit community resource
   */
  handleSwitchFarmers = (commResourceId, commResourceName, isProcessed) => {
    this.props.getSwitchFarmers(commResourceId, commResourceName, isProcessed);
    this.props.history.push('/processed-farmers');
  };

  handleEditCommunityResource = selectedCommunityResource => {
    document.querySelector('#mappingEnabled').checked = selectedCommunityResource.mappingEnabled;
    this.setState(
      {
        communityResourceName: selectedCommunityResource.name,
        communityResourceDescription: selectedCommunityResource.description,
        isMappingEnabled: selectedCommunityResource.mappingEnabled,
        communityResourceCountry: selectedCommunityResource.country,
        communityResourceState: selectedCommunityResource.state,
        isEditing: true
      },
      document.getElementById(`${selectedCommunityResource.name}EditFormBtn`).click()
    );
  };

  // const onChange = event => {
  //   event.persist();
  //   setInputs(inputs => ({ ...inputs, [event.target.name]: event.target.value }));
  //   console.log('rrr', inputs)
  // };

  onInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  

  selectCommunityResource = selectedCommunityResource => {
    const { name } = selectedCommunityResource;
    this.setState(
      { selectedCommunityResource },
      document.getElementById(`${name}DetailsFormBtn`).click()
    );
  };

  turnOffEditing = () => this.setState({ isEditing: false, selectedCommunityResource: {} });

  viewCommunityResourceDetails = communityResource => {
    const { handleCommunityResourceView, fetchFarmItemsByCommunityResource } = this.props;
    handleCommunityResourceView(communityResource, false);
    // fetchFarmItemsByCommunityResource(communityResource.id);
  };

  renderCommunityResourceList = () => {
    const { communityResourceList } = this.props;
    const { searchValue } = this.state;
    const filteredCommunityResources = getFilteredData(
      communityResourceList,
      searchValue,
      'communityResourceTag'
    ).map((communityResource, index) => (
      <CommunitiyResourceItem
        key={index}
        viewCommunityResourceDetails={this.viewCommunityResourceDetails}
        communityResource={communityResource}
        deleteCommunityResource={this.handleDeleteCommunityResource}
        editCommunityResource={this.handleEditCommunityResource}
        previewCommunityResource={this.selectCommunityResource}
        viewProcessedFarmers={this.handleSwitchFarmers}
      />
    ));
    return <ul className='list-group w-100 px-2 pb-1'>{filteredCommunityResources}</ul>;
  };

  render() {
    const { isLoading, communityResourceList, getAllCommunityResource } = this.props;
    const { isEditing, selectedCommunityResource } = this.state;
    return (
      <div className='container-fluid main-content'>
        <Navbar
          navLinks={[
            {
              path: '/community',
              header: 'Community Resources'
            }
          ]}
          small='Create or Manage Community Clusters on the platform'
          className='raise-up'
        />

        <div className='container-fluid'>
          <div className='row bg-white'>
            <Toolbar onSearch={this.onInputChange}>
              <span className='badge badge-pill badge-warning'>
                {communityResourceList.length} Community Resources
              </span>
              {/* Button trigger modal to add community resource */}
              <button
                id='community_resource'
                type='button'
                className={
                  accessStorage.getFromStore('role') === 'admin'
                    ? 'btn btn-revenue text-white btn-sm px-3 ml-2'
                    : 'hidden'
                }
                data-toggle='modal'
                data-target='#communityResourceModal'
              >
                <i className='fas fa-plus fa-sm mr-3' />
                <span> Add Community Resource</span>
              </button>
            </Toolbar>
            <Spinner isLoading={isLoading} data={communityResourceList}>
              {this.renderCommunityResourceList()}
            </Spinner>
          </div>
        </div>

        <PreviewCommunityResource
          clearSelectedCommunityResource={this.clearSelectedCommunityResource}
          selectedCommunityResource={selectedCommunityResource}
        />
        <CommunitiesResourceForms
          clearForm={this.clearForm}
          onInputChange={this.onInputChange}
          getAllCommunityResource={getAllCommunityResource}
          //   getCommunityOverview={getCommunityOverview}
          handleEnableMapping={this.handleEnableMapping}
          isEditing={isEditing}
          turnOffEditing={this.turnOffEditing}
          formState={this.state}
          states={this.states}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    communityResourceList: state.comReducer.communityResourceList,
    communityOverview: state.comReducer.communityOverview,
    isLoading: state.comReducer.isLoading
  };
};

export default withRouter(
  connect(mapStateToProps, {
    fetchFarmItemsByCommunityResource,
    getAllCommunityResource: getCommunityResource,
    handleCommunityResourceView: handleCommunityResourceId,
    getCommunityOverview: getCommunityOverview,
    getSwitchFarmers: getSwitchFarmers,
    handleSuspend
  })(CommunityResource)
);
