import React, { useMemo } from 'react';
import { Redirect, Switch, withRouter } from 'react-router-dom';
import WithSubRoutes from '../HOC/WithSubRoutes';
import Sidebar from './Sidebar';
import PropTypes from 'prop-types';
import { userType } from '../types';

const AppLayout = ({
  routes: getRoutes,
  logo,
  userId,
  userName,
  userActiveResources,
  userRole,
  isPasswordReset
}) => {
  const routes = useMemo(() => getRoutes(userRole), [getRoutes, userRole]);

  return (
    <>
      {isPasswordReset === false ? (
        ''
      ) : (
        <Sidebar
          userName={userName}
          userActiveResources={userActiveResources}
          userId={userId}
          role={userRole}
          logo={logo}
        />
      )}
      <div id='content'>
        <Switch>
          {/* {userRole === 'cbn' ? (
            <Redirect exact strict from='/' to='/ex-overview' />
          ) : ( */}
          {isPasswordReset === false ? (
            <Redirect exact strict from='/' to='/settings' />
          ) : (
            <Redirect exact strict from='/' to='/overview' />
          )}

          {routes?.map((route, idx) => (
            <WithSubRoutes
              userRole={userRole}
              userName={userName}
              key={`${idx}${route.path}`}
              {...route}
            />
          )) ?? <Redirect to='/' />}
        </Switch>
      </div>{' '}
    </>
  );
};

AppLayout.propTypes = {
  ...userType,
  routes: PropTypes.func.isRequired,
  logo: PropTypes.string
};

export default withRouter(AppLayout);
