import React, { Component } from 'react'
import Swal from 'sweetalert2'
import Footer from '../../components/Footer'
import Navbar from '../../components/Navbar'
import BioInfo from '../../components/staff/BioInfo'
import PersonalInfo from '../../components/staff/PersonalInfo'
import WorkInfo from '../../components/staff/WorkInfo'
export default class AddNewStaff extends Component {
  constructor(props) {
    super(props)

    this.state = {
      accessGrants: [],
      pageNum: 1,
      staffData: {},
      //Dummy data for company;  should contain name and active resources
      companyInfo: {
        name: 'Egtai',
        resources: [
          {
            resource: 'Farm',
            resourceList: ['Area Mapping', 'Soil Mapping', 'Assets', 'Staff']
          },
          {
            resource: 'Service Provider',
            resourceList: ['Shared Agency', 'Input']
          },
          {
            resource: 'Agro-inventory'
          },
          {
            resource: 'Community'
          }
        ]
      }
    }
    this.formRef = React.createRef()
  }

  //check the active form
  checkIfActive = num => {
    if (this.state.pageNum === num) {
      return 'py-3 col-sm-4 bg-revenue border-bottom text-white font-weight-bold'
    } else {
      return 'py-3 col-sm-4 border-bottom'
    }
  }

  /**
   *submits staff data
   * */
  handleFormSubmit = _ => {
    // necessary info is available is in this.state
  }

  grantAccess = _ => {
    const resourceList = Array.from(document.querySelectorAll('select.subResource'))
    let accessGrants = []
    resourceList.forEach(subResource => {
      let idx = accessGrants.findIndex(grant => grant.resource === subResource.id)
      // If the resource does not exist
      if (idx === -1) {
        let accessRight
        subResource.value === 'viewOnly' || subResource.value === 'Edit'
          ? (accessRight = {
              [subResource.value]: [subResource.name]
            })
          : (accessRight = {
              viewOnly: [subResource.name]
            })
        accessGrants.push({ resource: subResource.id, ...accessRight })
      }
      // If the resource exist
      else {
        if (accessGrants[idx][subResource.value] === undefined) {
          ;(subResource.value === 'viewOnly' || subResource.value === 'Edit') &&
            (accessGrants[idx][subResource.value] = [subResource.name])
        } else {
          accessGrants[idx][subResource.value].push(subResource.name)
        }
      }
    })
    this.setState({ accessGrants })
    document.querySelector('button.close').click()
  }

  //view next form page
  nextPage = e => {
    e.preventDefault()
    //Makes use of formRef created by React.createRef()
    if (this.state.pageNum === 1) {
      const staffData = {
        firstName: this.formRef.current[0].value,
        lastName: this.formRef.current[1].value,
        gender: this.formRef.current[2].value,
        phoneNumber: this.formRef.current[3].value,
        email: this.formRef.current[4].value,
        address: this.formRef.current[5].value
      }

      this.setState(prevState => ({
        pageNum: prevState.pageNum + 1,
        staffData
      }))
    }

    if (this.state.pageNum === 2) {
      const workData = { designation: this.formRef.current[0].value }
      if (this.state.accessGrants.length !== 0)
        this.setState(prevState => ({
          pageNum: prevState.pageNum + 1,
          staffData: { ...prevState.staffData, ...workData }
        }))
      else
        Swal({
          title: 'Oops!',
          text: 'Please set the access level for this user to proceed.',
          type: 'info',
          animation: true
        })
    }

    if (this.state.pageNum === 3) {
      const bankData = {
        bvn: this.formRef.current[0].value,
        accountName: this.formRef.current[1].value,
        accountNumber: this.formRef.current[2].value
      }
      this.setState(prevState => ({
        staffData: { ...prevState.staffData, ...bankData }
      }))
      return this.handleFormSubmit()
    }
  }

  //view prev form page
  prevPage = _ => {
    if (this.state.pageNum > 1) {
      this.setState(prevState => ({ pageNum: prevState.pageNum - 1 }))
    }
  }

  renderFormPage = pageNum => {
    switch (pageNum) {
      case 1:
      default:
        return (
          <BioInfo
            formState={this.state}
            formRef={this.formRef}
            handleInputChange={this.handleInputChange}
            nextPage={this.nextPage}
          />
        )
      case 2:
        return (
          <WorkInfo
            formRef={this.formRef}
            formState={this.state}
            grantAccess={this.grantAccess}
            nextPage={this.nextPage}
            prevPage={this.prevPage}
          />
        )
      case 3:
        return (
          <PersonalInfo
            formRef={this.formRef}
            nextPage={this.nextPage}
            pageNum={this.state.pageNum}
            prevPage={this.prevPage}
          />
        )
    }
  }

  render() {
    const { pageNum } = this.state
    return (
      <>
        <Navbar
          navLinks={[
            {
              header: 'Staff |',
              path: '/staff'
            },
            {
              header: ' Add Staff ',
              path: '#'
            }
          ]}
        />
        <div className='bg-white'>
          <div className='d-flex flex-column align-items-center py-3 justify-content-center text-center'>
            <h4>
              Boxes with ' <span className='red'>*</span> ' must be filled for form to submit
            </h4>
            <p>Progress is automatically saved</p>
          </div>

          <div className='d-flex flex-column mx-auto pt-3 pb-5 my-2 col-lg-7 col-sm-10'>
            <div className='mx-0 row justify-content-center text-center flex-fill'>
              <div className={this.checkIfActive(1)}>Bio Info</div>
              <div className={this.checkIfActive(2)}>Work Info</div>
              <div className={this.checkIfActive(3)}>Personal Info</div>
            </div>
            <div className='bg-light py-4'>
              <div className='px-4 flex-fill'>{this.renderFormPage(pageNum)}</div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    )
  }
}
