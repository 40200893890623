import React, { useCallback, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import moreIcon from '../../../../assets/3Dots.svg'
import apiService from '../../../api/apiService'
import { handleEditAgentData } from '../../../pages/serviceProvider/serviceProvider.actions'
import { deleteAlert } from '../../../utils/deleteAlert'
import ActivationModal from './ActivationModal'

const TaskDropdown = props => {
  // eslint-disable-next-line no-unused-vars
  const [activationInput, setActivationInput] = useState([])
  const { row: agent, match, handleActivationInput } = props

  const clearActivationForm = () => setActivationInput([])

  const handleActivationStatus = useCallback(async ({ id, status }) => {
    const activationRes = await apiService.get(`/postinggroup/suspendposting/${id}/${status}`)
    if (activationRes.code !== -1) {
      Swal('success', activationRes.message, 'success')
      this.getGroupAgents()
    } else {
      Swal('Error', ' ' + activationRes.message, 'error')
    }
    clearActivationForm()
  }, [])

  const handleEditAgent = () => {
    props.handleEditAgentData(agent)
    props.history.push(`/${props.match.params.sharedAgencyId}/edit-agent`)
  }

  const handleDelete = () =>
    deleteAlert(`/postinggroup/deleteposting/${agent.id}`, this.getGroupAgents)

  const openActivateModal = useCallback(
    () =>
      agent.status === 'ACTIVE'
        ? handleActivationStatus(agent)
        : openModal(agent, 'activation', 'activateModal'),
    [agent, handleActivationStatus]
  )

  const openModal = (rowData, btnName, modalName) => {
    const elemBtn = document.getElementById(`${rowData.name}${btnName}`)
    elemBtn.setAttribute('data-target', `#${modalName}`)
    elemBtn.click()
    elemBtn.removeAttribute('data-target')
  }

  return (
    <div className='dropleft actions my-n1 position-static'>
      <div
        className='menuIconWrapper d-flex justify-content-center'
        id='dropdownMenuButton'
        data-toggle='dropdown'
        aria-haspopup='true'
        aria-expanded='false'
      >
        <img src={moreIcon} className='cursor px-2' alt='More Icon' />
      </div>
      <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
        <button className='dropdown-item' onClick={handleEditAgent}>
          {' '}
          Edit{' '}
        </button>

        <button
          id={`${agent.name}activation`}
          className='dropdown-item'
          data-toggle='modal'
          onClick={openActivateModal}
        >
          {agent.status === 'ACTIVE' ? 'Deactivate agent' : 'Activate agent'}
        </button>
        <Link
          className='dropdown-item'
          to={
            match.path === '/:sharedAgencyId/assigned-tasks/:assignedTaskId'
              ? `/${match.params.assignedTaskId}/agent-profile/${agent.id}/captured-data`
              : `/agent-profile/${agent.id}/captured-data`
          }
        >
          {' '}
          Captured data
        </Link>
        <button className='dropdown-item' onClick={handleDelete}>
          <span> Delete</span>
        </button>
      </div>
      <ActivationModal
        changeAgentStatus={handleActivationStatus}
        agentData={agent}
        clearActivationForm={clearActivationForm}
        handleActivationInput={handleActivationInput}
      />
    </div>
  )
}

export default connect(null, { handleEditAgentData })(TaskDropdown)
