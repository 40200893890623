import React from 'react'
import HoseIntakeAnalysis from './hose-intake-analysis'
import EmptyBagAnalysis from './empty-bag-analysis'

const InputSupplyAnalysis = ({ analytics }) => (
  <>
    <HoseIntakeAnalysis analytics={analytics} />
    <EmptyBagAnalysis analytics={analytics} />
  </>
)

export default InputSupplyAnalysis
