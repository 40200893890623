import React from 'react'
import Navbar from '../../components/Navbar'
import complainimage from '../../../assets/complain-image.svg'
import addimage from '../../../assets/add-image.svg'

const LogAnIssue = props => {
  const {
    match: {
      params: { sharedAgencyId, serviceProviderId }
    }
  } = props
  return (
    <div className='bg-white'>
      <Navbar
        navLinks={[
          {
            header: `| Farmer Issues |`,
            path: `/${serviceProviderId}/new-shared-agency/${sharedAgencyId}`
          },
          {
            header: `| Log an Issue`,
            path: `#`
          }
          //   {
          //     header: "| Create Job Advert",
          //     path: `${pathname}`
          //   }
        ]}
        small='Report issues and request visits'
      />
      <div className='d-flex flex-column align-items-center my-3'>
        <h4>
          Boxes with ' <span className='red'>*</span> ' must be filled for form to submit
        </h4>
        <p>Progress is automatically saved</p>
      </div>
      <div className='d-flex justify-content-center bg-light w-80 mx-auto col-sm-12'>
        <form className='my-4 w-60'>
          <div className='form-group'>
            <label htmlFor=''>
              Issue Title <span className='red'>*</span>
            </label>
            <input type='text' className='form-control' required />
          </div>
          <div className='form-group'>
            <label htmlFor='jobsSchedule'>
              Succession <span className='red'>*</span>
            </label>
            <div className='row'>
              <div className='col-sm-6'>
                <div className='d-flex align-items-center'>
                  <input
                    className='w-auto mr-2 custom-radio'
                    type='radio'
                    name='fulltime'
                    required
                  />
                  <label htmlFor='fullTime'>Continously </label>
                </div>
                <div className='d-flex align-items-center'>
                  <input className='w-auto mr-2  custom-radio' type='radio' name='parttime' />
                  <label htmlFor='parttime'>Intermitently</label>
                </div>
              </div>
            </div>
          </div>
          <div className='form-group'>
            <label htmlFor='hoursPerWeek'>
              When did this start?<span className='red'>*</span>
            </label>
            <input type='date' className='form-control w-50' required />
          </div>
          <div className='form-group'>
            <label htmlFor='duties'>Description</label>
            <textarea id='supplierdescription' className='form-control' rows='4' />
          </div>
          <div className='row py-3'>
            <div className='col-md-6'>
              <label htmlFor='joblocation'>Location</label>
              <select name='joblocation' className='form-control'>
                <option value='sokoto'>Sokoto</option>
              </select>
            </div>
            <div className='col-md-6'>
              <label htmlFor='noofemployee'>
                Number of Crops Affected
                <span className='red'>*</span>
              </label>
              <input id='noofcrops' pattern='[0-9.]+' type='text' className='form-control' />
            </div>
          </div>
          <div className='row py-3'>
            <div className='col-md-6'>
              <label htmlFor='incidenttime'>Period of incident before reporting</label>
              <select name='incidenttime' className='form-control'>
                <option value='12months'>1-2 months</option>
                <option value='24months'>2-4 months</option>
              </select>
            </div>
          </div>
          <div className='form-group'>
            <label htmlFor='duties'>Further Information</label>
            <textarea id='supplierdescription' className='form-control' rows='4' />
          </div>
          <div className='form-group'>
            <label htmlFor=''>
              Contact Info <span className='red'>*</span>
            </label>
            <input type='email' className='form-control' required />
          </div>
          <div className='form-group'>
            <label className=''>Upload Images/Video</label>
            <div className='bg-white p-2'>
              <img src={complainimage} alt='complaint' className='mx-2' />
              <img src={complainimage} alt='complaint' className='mx-2' />
              <img src={addimage} alt='addimage' className='mx-2' />
            </div>
          </div>
          <div className='row py-3'>
            <div className='col-sm-6 w-100'>
              <label className='w-sm-100' htmlFor='experience'>
                Request day of visit
              </label>
              <input type='date' className='form-control' required />
            </div>
            <div className='col-sm-6' />
          </div>

          <div className='text-center'>
            <button type='submit' className='btn btn-secondary text-white'>
              Cancel
            </button>
            <button type='submit' className='btn btn-revenue text-white mx-5'>
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default LogAnIssue
