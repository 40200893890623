import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { fetchData } from '../../api/api.actions';
import { api } from '../../api/api.config';
import Agents from '../../pages/serviceProvider/Agents';
import {
  selectCurrentServiceProviderLoading,
  selectSharedAgencies
} from '../../pages/serviceProvider/serviceProvider.selectors';
import sweetAlertDeleteMethod from '../../utils/deleteAlert';
import { extractResourceLink } from '../../utils/extractResourceLink';
import { getFilteredData } from '../../utils/resourceFilter';
import * as columnTitles from '../dataTable/columnTitles';
import DataTable from '../dataTable/DataTable';
import ImportResourceModal from '../modals/ImportResourceModal';
import Toolbar from '../Toolbar';
import SharedAgencyTools from './activeServices/SharedAgency-Tools';
import SharedAgencyForm from './activeServices/SharedAgencyForm';

class SharedAgency extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    fetchData: PropTypes.func.isRequired,
    sharedAgencies: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired
  };

  state = {
    sharedAgency: [],
    sharedAgencyData: {},
    isEditing: false,
    searchValue: ''
  };

  componentDidMount() {
    const {
      match: {
        params: { serviceProviderId }
      },
      fetchData
    } = this.props;
    fetchData('SHARED_AGENCIES', serviceProviderId);
  }

  saveSharedAgency = async sharedAgencyInfo => {
    const { links } = this.state.sharedAgencyData;
    const { href: endpoint, method } = extractResourceLink(links, 'update');
    const HttpPost = api[method];
    try {
      const { status } = await HttpPost(endpoint, sharedAgencyInfo);
      if (status === 200) {
        Swal({
          title: 'Success!',
          text: 'Successfully updated. ',
          type: 'success',
          animation: true
        });
      }
    } catch (reason) {
      Swal({
        title: 'Oops, Error!',
        text: 'Error occured while trying to save shared agency.',
        type: 'error',
        animation: true
      });
    } finally {
      document.querySelector('#resourceForm').reset();
      document.querySelector('#close').click();
    }
  };

  handleSaveSharedAgency = (name, id, isEditing) => {
    const {
      params: { serviceProviderId }
    } = this.props.match;
    let sharedAgencyInfo = { name, serviceProvider: serviceProviderId };
    !isEditing ? (sharedAgencyInfo['id'] = 0) : (sharedAgencyInfo['id'] = id);
    this.saveSharedAgency(sharedAgencyInfo);
  };

  deleteResource = ({ id }) =>
    sweetAlertDeleteMethod(`/shared-agency/deletesharedagency/${id}`, this.getSharedAgency);

  handleModalUpdate = row => {
    this.setState({
      sharedAgencyData: row,
      isEditing: true
    });
    const sharedAgencyBtn = document.getElementById(row.name);

    document.querySelector('#agencyName').value = row.name;
    sharedAgencyBtn.setAttribute('data-target', '#sharedAgencyModal');
    sharedAgencyBtn.click();
    sharedAgencyBtn.removeAttribute('data-target');
  };

  resetForm = () => {
    document.querySelector('#resourceForm').reset();
    this.setState({ isEditing: false });
  };

  onSearch = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  getFilteredSharedAgency = () =>
    getFilteredData(this.props.sharedAgencies, this.state.searchValue, 'sharedAgencyTag');

  render() {
    const { serviceProviderId } = this.props.match.params;
    const { isEditing, sharedAgencyData } = this.state;
    const { isLoading } = this.props;

    return (
      <div className='bg-white'>
        <Toolbar tools={SharedAgencyTools} onSearch={this.onSearch} />

        <DataTable
          {...this.props}
          columnTitle={columnTitles.sharedAgency}
          deleteData={this.deleteResource}
          handleModalUpdate={this.handleModalUpdate}
          isLoading={isLoading}
          rowUrl={`${serviceProviderId}/shared-agency`}
          saveDataModal='sharedAgency'
          data={this.getFilteredSharedAgency()}
        />

        {/* <Spinner isLoading={isLoading} data={this.getFilteredAgents()}>
            <MaterialTable
              columns={[
                ...agentColumns,

                {
                  title: '...',
                  field: 'actions',
                  render: data => (
                    <AgentTableActions
                      data={data}
                      history={history}
                      handleEditAgentData={handleEditAgentData}
                      changeStatus={this.handleChangeStatus}
                      sharedAgencyId={sharedAgencyId}
                      deleteData={this.deleteData}
                    />
                  )
                }
              ]}
              data={this.getFilteredAgents()}
              style={{
                fontFamily: 'Nunito',
                width: '100%',
                boxShadow: 'none'
              }}
              options={{
                actionsColumnIndex: 4,
                sorting: true,
                showTitle: false,
                exportButton: true,
                searchFieldAlignment: 'left',
                loadingType: 'linear',
                headerStyle: {
                  backgroundColor: '#4f4f4f',
                  color: '#FFF'
                }
              }}
              actions={[
                {
                  icon: 'account_circle',
                  tooltip: 'View User Profile',
                  onClick: (_, data) => history.push(`/agent-profile/${data.id}`)
                }
              ]}
              components={{
                CircularProgress: props => (
                  <div style={{ backgroundColor: '#4f4f4f' }}>
                    <CircularProgress {...props} />
                  </div>
                )
              }}
            />
          </Spinner> */}

        <SharedAgencyForm
          labels={{ header: "Agency's Profile" }}
          resetForm={this.resetForm}
          isEditing={isEditing}
          rowData={sharedAgencyData}
          saveSharedAgency={this.handleSaveSharedAgency}
        />
        <ImportResourceModal />
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    sharedAgencies: selectSharedAgencies(state, props),
    isLoading: selectCurrentServiceProviderLoading(state)
  };
}
const mapDispatchToProps = { fetchData };

export default connect(mapStateToProps, mapDispatchToProps)(SharedAgency);
