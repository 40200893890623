import React from 'react'
import { transactionHistory } from '../../dataTable/columnTitles'
import DataTable from '../../dataTable/DataTable'
import Toolbar from '../../Toolbar'

export default function TransactionHistory(props) {
  const { historyData, getDataInfo } = props
  return (
    <div className='bg-white my-3'>
      <Toolbar {...props} />

      <DataTable
        {...props}
        columnTitle={transactionHistory}
        data={historyData && historyData}
        getRowData={getDataInfo}
      />
    </div>
  )
}
