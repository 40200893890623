import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import ReactTable from 'react-table-v6';
import Swal from 'sweetalert2';
import Loader from '../../../assets/loader.gif';
import moreIcon from '../../../assets/3Dots.svg';
import assetmapview from '../../../assets/asset-map-view.svg';
import CommunitiesForms from '../../components/community/CommunitiesForm';
import BaseUrl from '../../../security/base-url';
import { getFilteredData } from '../../utils/resourceFilter';
import { getAllCommunities, handleCommunityIdAndName } from './communities.actions';

class Communities extends Component {
  state = {
    community: {},
    valueClick: 0,
    search: ''
  };
  componentDidMount() {
    this.props.getAllCommunities(this.props.communityResourceId);
  }

  clearCommunity = () => this.setState({ community: {} });

  /**
   * this perform delete operation
   * @param {the full path of the community resource } path
   * @param {the headers request configuration} heardersConfig
   */
  sweetAlertDeleteMethod(path, heardersConfig) {
    const swalWithBootstrapButtons = Swal.mixin({
      confirmButtonClass: 'btn btn-revenue text-white mx-3',
      cancelButtonClass: 'btn btn-expend text-white',
      buttonsStyling: false
    });

    swalWithBootstrapButtons({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then(result => {
      if (result.value) {
        fetch(path, heardersConfig)
          .then(respons => respons.json())
          .then(data => {
            if (data !== null) {
              if (data.message === 'error') {
                swalWithBootstrapButtons(
                  'Error!',
                  'Error while trying to delete community.',
                  'error'
                );
              }
              this.props.getAllCommunities(this.props.communityResourceId);
              swalWithBootstrapButtons('Deleted!', 'Your community has been deleted.', 'success');
            }
          })
          .catch(err => {});
      } else if (
        // Read more about handling dismissals
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons('Cancelled', 'Your community is safe :)', 'error');
      }
    });
  }
  goToCommunityMember = communityId => {
    this.props.history.push('/community-features/community-members/');
  };
  onSearch = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };
  /**
   * This method delete community resource
   */
  handleDeleteCommunity = async communityId => {
    const deletepath = BaseUrl.url_dashbord + '/community-resource/community/' + communityId;
    const headersConfig = {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };
    this.sweetAlertDeleteMethod(deletepath, headersConfig);
  };

  /**
   * This method edite community resource
   */
  handleEditeCommunityResource = community => {
    this.setState({
      community: community,
      valueClick: this.state.valueClick + 1
    });
  };

  gotoCommunityChat = community => {
    this.props.handleCommunityIdAndName(community.id, community.name, false);
    this.props.history.push('/community-features/community-chat/');
  };

  setCommunity = row => this.setState({ community: row.original });

  render() {
    const adminColumn = [
      {
        Header: 'S/N',
        id: 'row',
        maxWidth: 100,
        minWidth: 50,
        width: 50,
        filterable: false,
        headerClassName: 'table-header py-3',
        Cell: row => {
          return <div>{row.index + 1}</div>;
        }
      },
      {
        Header: 'Name',
        accessor: 'name',
        headerClassName: 'table-header py-3'
      },
      {
        Header: 'Short Description',
        accessor: 'shortDescription',
        headerClassName: 'table-header py-3'
      },
      {
        Header: 'Long Description',
        accessor: 'longDescription',
        headerClassName: 'table-header py-3'
      },
      {
        Header: 'Created On',
        accessor: 'createdOn',
        headerClassName: 'table-header py-3'
      },
      {
        Header: () => {
          return (
            <span className='text-center'>
              <i className='fas fa-ellipsis-h py-2' />
            </span>
          );
        },
        Cell: props => {
          return (
            <div className='dropdown pointer actions'>
              <div className='dropdown dropleft'>
                <div
                  className='menuIconWrapper d-flex justify-content-center align-content-center mousePointer'
                  id='dropdownMenuButton'
                  data-toggle='dropdown'
                  aria-haspopup='true'
                  aria-expanded='false'
                >
                  <img src={moreIcon} className='cursor' alt='More Icon' />
                </div>
                <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                  <button
                    className='dropdown-item'
                    onClick={() => this.gotoCommunityChat(props.original)}
                  >
                    Enter Community
                  </button>
                  <button
                    className='dropdown-item'
                    onClick={() => this.handleEditeCommunityResource(props.original)}
                  >
                    Edit
                  </button>
                  <button
                    className='dropdown-item'
                    data-toggle='modal'
                    data-target='#communityDetailsModal'
                    onClick={props => this.setCommunity(props)}
                  >
                    View Details
                  </button>
                  <button
                    className='dropdown-item'
                    onClick={() => this.handleDeleteCommunity(props.original.id)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          );
        },
        filterable: false,
        headerClassName: 'table-header',
        sortable: false,
        width: 60
      }
    ];

    const cbnColumn = [
      {
        Header: 'S/N',
        id: 'row',
        maxWidth: 100,
        minWidth: 50,
        width: 50,
        headerClassName: 'table-header py-3',
        filterable: false
      },
      {
        Header: 'Name',
        accessor: 'name',
        headerClassName: 'table-header py-3'
      },
      {
        Header: 'Short Description',
        accessor: 'shortDescription',
        headerClassName: 'table-header py-3'
      },
      {
        Header: 'Long Description',
        accessor: 'longDescription',
        headerClassName: 'table-header py-3'
      },
      {
        Header: 'Created On',
        accessor: 'createdOn',
        headerClassName: 'table-header py-3'
      },

      {
        Header: 'View',
        Cell: props => (
          <span className='pointer'>
            <Link
              to='/community-features/community-members/'
              onClick={() =>
                this.props.handleCommunityIdAndName(props.original.id, props.original.name, false)
              }
            >
              <i
                data-toggle='tooltip'
                title='View community!'
                className='fa fa-eye fa'
                name='view'
                aria-hidden='true'
              />
            </Link>
          </span>
        ),
        filterable: false,
        sortable: false,
        width: 50,
        maxWidth: 50,
        minWidth: 50,
        headerClassName: 'table-header py-3',
        style: {
          textAlign: 'center'
        }
      }
    ];

    return (
      <div className='container-fluid'>
        {/* <Navbar
            navLinks={[
              {
                path: "/community/",
                header: "Communty Resource |"
              },
              {
                path: "#",
                header: "| Communities"
              }
            ]}
            small="Communities under the Community Resource"
          /> */}

        <div className='container-fluid bg-white '>
          <div className='row bg-white'>
            <div className='col-md-12 text-right d-flex w-100 py-3 align-items-center'>
              {/* <input type='checkbox' name='' className='mx-2' />
              <select className='mr-3 custom-select form-control w-20'>
                <option value='Select actions...'>Select actions...</option>
                <option value='Nil'>Nil</option>
              </select>
              <i className='fas fa-filter text-black-50 input-prepend' /> */}
              <input
                className='mr-auto form-control w-20'
                type='search'
                name='search'
                placeholder='Search'
                onChange={event => this.onSearch(event)}
              />
              {/* <Link to='/community-resource/map-view' className='mx-2 text-revenue'>
                <img src={assetmapview} alt='Map View' className='mx-2' />
                View on Map
              </Link> */}
              <a
                  target='_blank'
                  href={
                    BaseUrl.url_dashbord+'/community-resource/export_extension/'+this.props.communityResourceId+'?reportType=Report'
                  }
                  className='btn btn-outline-revenue  btn-sm px-3 mr-2'
                >
                  <i className='fas fa-download fa-sm mx-2' />
                  Extension 
                </a>
                
              <a
                  target='_blank'
                  href={
                    BaseUrl.url_dashbord+'/community-resource/export_indirect_jobs/'+this.props.communityResourceId
                  }
                  className='btn btn-outline-revenue  btn-sm px-3 mr-2'
                >
                  <i className='fas fa-download fa-sm mx-2' />
                  Indirectr Jobs 
                </a>
              <a
                  target='_blank'
                  href={
                    BaseUrl.url_dashbord+'/community-resource/export_farmers/'+this.props.communityResourceId
                  }
                  className='btn btn-outline-revenue  btn-sm px-3 mr-2'
                >
                  <i className='fas fa-download fa-sm mx-2' />
                  Export Farmers
                </a>
              <CommunitiesForms
                communityResourceId={this.props.communityResourceId}
                getAllCommunities={this.props.getAllCommunities}
                community={this.state.community}
                valueClick={this.state.valueClick}
                isMappingEnabled={this.props.isMappingEnabled}
              />
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-12'>
            {this.props.allCommunities ? (
              <div>
                {/* <FarmersOnClusters
                    farms={this.props.farmItemsByCommunityResource}
                  /> */}
                <ReactTable
                  columns={localStorage.getItem('role') === 'ADMIN' ? adminColumn : cbnColumn}
                  className='-striped -highlight'
                  data={getFilteredData(
                    this.props.allCommunities,
                    this.state.search,
                    'communitiesTag'
                  )}
                  defaultPageSize={10}
                  noDataText={'No record(s) found'}
                />
              </div>
            ) : (
              <div className='d-flex w-25 mx-auto'>
                <img src={Loader} alt='loader' className='' />
              </div>
            )}
          </div>
        </div>
        {/* View Community Details Modal */}
        <div
          className='modal fade'
          id='communityDetailsModal'
          data-backdrop='static'
          data-keyboard='false'
          tabIndex={-1}
          role='dialog'
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
        >
          <div className='modal-dialog' role='document'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h3>
                  <span className='badge'>Community Resource Details</span>
                </h3>
                <button
                  type='button'
                  className='close'
                  data-dismiss='modal'
                  aria-label='Close'
                  onClick={this.clearCommunity}
                >
                  <span aria-hidden='true'>×</span>
                </button>
              </div>
              <div className='modal-body'>
                <p>Name:</p>
                <p>{this.state.community.name}</p>
                <p>Short Description:</p>
                <p>{this.state.community.shortDescription}</p>
                <p>Long Description:</p>
                <p>{this.state.community.longDescription}</p>
                <p>Date Created:</p>
                <p>{this.state.community.createdOn}</p>
              </div>
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-expend text-white'
                  data-dismiss='modal'
                  onClick={() => this.setState({ community: {} })}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* View Community Details modal ends here */}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    communityResourceId: state.comReducer.communityResourceId,
    communityResourceName: state.comReducer.communityResourceName,
    allCommunities: state.comReducer.allCommunities,
    farmItemsByCommunityResource: state.farmResource.farmItemsByCommunityResource
  };
};

export default withRouter(
  connect(mapStateToProps, {
    handleCommunityIdAndName,
    getAllCommunities
  })(Communities)
);
