import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { FixedSizeList as List } from 'react-window';
import { fetchData } from '../../api/api.actions';
import { getErrorMsg, getSuccessMsg } from '../../api/api.selectors';
import Navbar from '../../components/Navbar';
import Spinner from '../../components/Spinner';
import Toolbar from '../../components/Toolbar';
import TrashItem from '../../components/trash/TrashItem';
import { useDisplayResponse } from '../../hooks/useDisplayResponse';
import { restoreTrashItem } from './trash.actions';
import { getIsLoading, getTrashItems } from './trash.selectors';

const Trash = ({ isLoading, restoreTrashItem, fetchData, successMsg, errorMsg, trashItems }) => {
  const trashData = { trash: trashItems, restoreTrash: restoreTrashItem };
  useDisplayResponse(successMsg, errorMsg);

  useEffect(() => {
    const controller = new AbortController();
    fetchData('TRASH', controller.signal);
    return () => controller.abort();
  }, [fetchData]);

  return (
    <>
      <Navbar
        navLinks={[
          {
            path: '/resources/trashItems',
            header: 'Trash Recovery'
          }
        ]}
        small='Recover Deleted Resources on the Platform'
      />
      <Spinner
        message={'Your trash folder is empty.'}
        isLoading={isLoading}
        data={trashItems}
        size='medium'
      >
        <>
          <Toolbar />
          <div className='list-group-item d-flex p-2 px-3 row mx-0'>
            <span className='col-sm-6 col-md-7 font-weight-bold'>Description</span>
            <span className='col-2 font-weight-bold'>Type</span>
            <span className='col-2 font-weight-bold px-2'>Created On</span>
          </div>
          <div className=''>
            <List
              height={500}
              itemCount={trashItems.length}
              itemData={trashData}
              itemSize={90}
              width='100%'
              className='revenue-scrollbar py-1'
            >
              {TrashItem}
            </List>
          </div>
        </>
      </Spinner>
    </>
  );
};

const mapStateToProps = state => ({
  errorMsg: getErrorMsg(state),
  isLoading: getIsLoading(state),
  successMsg: getSuccessMsg(state),
  trashItems: getTrashItems(state)
});

Trash.propTypes = {
  isLoading: PropTypes.bool,
  restoreTrashItem: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
  successMsg: PropTypes.string.isRequired,
  errorMsg: PropTypes.string.isRequired,
  trashItems: PropTypes.arrayOf(PropTypes.object)
};

export default connect(mapStateToProps, { fetchData, restoreTrashItem })(Trash);
