import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import dots from '../../../assets/3Dots.svg'
import Navbar from '../../components/Navbar'

class LoggedIssues extends Component {
  constructor(props) {
    super(props)
    this.state = {
      agentInfo: this.props.agentInfo,
      remoteLink: ''
    }
  }
  componentDidMount() {}

  render() {
    return (
      <div className='container-fluid'>
        <Navbar
          navLinks={[
            {
              path: '/resources/extension',
              header: 'Extension |'
            },
            {
              path: '#',
              header: '| Logged Issues'
            }
          ]}
          small='Review of Logged Issues from the Farmer'
        />

        <div className='row flex-column text-center my-3 bg-white p-3'>
          <h4>Detailed Information of Issue posted by client</h4>
          <h6>Client summary on the left. Schedule form can be set on the right</h6>

          <div className='row'>
            <div className='col-lg-6 col-md-6 bg-light p-5'>
              {/* Profile Section */}
              <button id='cvbutton' hidden data-toggle='modal' data-target='#cvModal'>
                cv hidden button
              </button>
              <div className='row border-bottom'>
                <div className='row pb-3'>
                  <div className='col-sm-3'>
                    <img
                      // src={this.state.agentInfo.displayImage}
                      src=''
                      className='d-inline'
                      alt='profile'
                      width='100%'
                    />
                  </div>
                  <div className='col-sm-9'>
                    <div className='d-flex py-2 justify-content-around'>
                      <div className=' d-flex flex-column pr-1'>
                        <h4 className='font-weight-bold py-1 text-dark'>First Name Last Name</h4>
                        <h6 className='text-secondary py-1'>Extension Agent</h6>
                      </div>
                      <div className='border-left d-flex flex-column'>
                        <h6 className='text-secondary px-2 py-1 d-flex'>
                          <i className='fas fa-envelope mr-2' />
                          extension@email.com
                        </h6>
                        <h6 className='text-secondary px-2 py-1 d-flex'>
                          <i className='fas fa-phone mr-2' />
                          0803 333 6666{' '}
                        </h6>
                      </div>
                    </div>
                    <div className='d-flex mt-2'>
                      <span className='badge badge-light border p-2 mr-2'>
                        {}
                        <span className='font-weight-light text-dark-grey'>Farms</span>
                      </span>
                      <span className='badge badge-light border mr-2 p-2'>
                        {}
                        <span className='font-weight-light text-dark-grey'>Resources</span>
                      </span>
                      <span className='badge badge-light border mr-2 p-2'>
                        {}
                        <span className='font-weight-light text-dark-grey'>Communities</span>
                      </span>
                      <span>
                        {}

                        <button
                          className='btn btn-sm btn-revenue text-light'
                          onClick={e => this.previewCV()}
                        >
                          View CV
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Details Section with Recent Activity */}
              <div className='row flex-column border-bottom'>
                <h4 className='font-weight-bold my-3 text-dark h5'>
                  {/* Job Duties and Responsibilities */}
                  Address
                </h4>
                <p className='mb-2'>StoneHedge Hotel Central Business District</p>
                <div className='d-flex align-items-center mb-2'>
                  <button
                    className='btn btn-sm btn-revenue text-light'
                    data-toggle='modal'
                    data-target='#editProfileModal'
                  >
                    Edit Profile
                  </button>
                  <div
                    className='menuIconWrapper mousePointer ml-3 p-2 d-flex align-items-center justify-content-center'
                    id='dropdownMenuButton'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    <img src={dots} alt='More Icon' />
                    <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                      <button className='dropdown-item'>Activate/Suspend Agent</button>
                      <button className='dropdown-item'>Add to program</button>
                      <div className='border-top'>
                        <button className='dropdown-item'>Delete</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='d-flex align-items px-2 py-2 mb-2 align-content-center justify-content-between bg-light'>
                  <div className='d-flex flex-column justify-content-between'>
                    <h6>Current Posting:</h6>
                    {/* <p className="font-weight-bold d-flex">{posting.join(", ")}</p> */}
                  </div>
                  <div className='border-left px-3 my-auto text-revenue'>
                    <Link to='' className='border-bottom'>
                      Edit posting
                    </Link>
                  </div>
                </div>
              </div>
              <div className='row flex-column py-2 border-bottom'>
                <h4 className='font-weight-bold my-3 text-dark h5'>Gender</h4>
                <h6 className='mb-2'>####</h6>
              </div>
              <div className='row flex-column py-2 border-bottom'>
                <h4 className='font-weight-bold my-3 text-dark h5'>Login Details</h4>
                <h6 className='mb-2 mr-1'>
                  Username: <span>###</span>
                </h6>
                <span className='mb-2 mr-1' />
                <div>
                  <button className='btn btn-sm btn-revenue text-light'>Change</button>
                </div>
              </div>
              <div className='row flex-column py-2 border-bottom'>
                <h4 className='font-weight-bold my-3 text-dark h5'>BVN</h4>
                <h6 className='mb-2'>******</h6>
              </div>
              <div className='row flex-column py-2 border-bottom'>
                <h4 className='font-weight-bold my-3 text-dark h5'>Bank Information</h4>
                <h6 className='mb-2'>******</h6>
                <h6 className='mb-2'>******</h6>
                <h6 className='mb-2'>******</h6>
              </div>
              <div className='row flex-column py-2 border-bottom'>
                <h4 className='font-weight-bold my-3 text-dark h5'>Income Status</h4>
                <h6 className='mb-2'>{}</h6>
              </div>
            </div>
            <div className='col-lg-6 col-md-6 bg-light p-5'>Side Row Here</div>
          </div>
        </div>
        {/* Modal */}

        {/* preview  modal ends here */}

        <div
          className='modal fade'
          id='editProfileModal'
          tabIndex={-1}
          role='dialog'
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
        >
          <div className='modal-dialog' role='document'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLabel'>
                  Edit Profile
                </h5>
                <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-body'>
                {/* Edit Form Here */}
                <form>
                  <div className='form-row'>
                    <div className='col'>
                      <input type='text' className='form-control' placeholder='First name' />
                    </div>
                    <div className='col'>
                      <input type='text' className='form-control' placeholder='Last name' />
                    </div>
                  </div>
                  <div className='form-row'>
                    <div className='form-group col-md-6'>
                      <label htmlFor='inputEmail4'>Email</label>
                      <input
                        type='email'
                        className='form-control'
                        id='inputEmail4'
                        placeholder='Email'
                      />
                    </div>
                    <div className='form-group col-md-6'>
                      <label htmlFor='inputPhone4'>Phone Number</label>
                      <input
                        type='number'
                        className='form-control'
                        id='inputPhone4'
                        placeholder='Phone Numbber'
                      />
                    </div>
                  </div>
                  <div className='form-group'>
                    <label htmlFor='inputAddress'>Address</label>
                    <input
                      type='text'
                      className='form-control'
                      id='inputAddress'
                      placeholder='12 Ademola Street'
                    />
                  </div>
                  <div className='form-group my-3'>
                    <label htmlFor='inputGender'>Gender</label>
                    <div className='form-check form-check-inline'>
                      <input
                        className='form-check-input'
                        type='radio'
                        name='inlineRadioOptions'
                        id='inlineRadio1'
                        defaultValue='option1'
                      />
                      <label className='form-check-label' htmlFor='inlineRadio1'>
                        Male
                      </label>
                    </div>
                    <div className='form-check form-check-inline mx-4'>
                      <input
                        className='form-check-input'
                        type='radio'
                        name='inlineRadioOptions'
                        id='inlineRadio2'
                        defaultValue='option2'
                      />
                      <label className='form-check-label' htmlFor='inlineRadio2'>
                        Female
                      </label>
                    </div>
                  </div>
                </form>
              </div>
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-sm btn-expend text-white'
                  data-dismiss='modal'
                >
                  Close
                </button>
                <button type='button' className='btn btn-sm btn-revenue text-white'>
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default LoggedIssues
