import React from 'react';
import FarmResourceItem from './FarmResourceItem';
import '../../../../App.scss';

const FarmResourceItemList = props => {
  const { data, search } = props;

  let filteredData = !search
    ? data
    : data.filter(data => data.name.toLowerCase().includes(search.toLowerCase()));

  // REACT WINDOW IMPLEMENTATION

  // const filterData = (index, style) => {
  //   let filteredData = data.filter(resource => {
  //     if (resource.name.toLowerCase().indexOf(search.toLowerCase()) !== -1) {
  //       return true;
  //     }
  //     return false;
  //   });
  //   return filteredData.map(resource => (
  //     <li
  //       key={resource.id}
  //       className="list-group-item d-flex p-2 bg-list-outer"
  //       style={style}>
  //       <FarmResourceItem item={resource} data={resource[index]} {...props} />
  //       {/* Row [index] */}
  //     </li>
  //   ));
  // };

  // const renderList = (index, style) => {
  //   return (
  //     <li
  //       key={fixedData.id}
  //       className="list-group-item d-flex p-2 bg-list-outer"
  //       style={style}>
  //       <FarmResourceItem item={fixedData} data={fixedData[index]} {...props} />
  //       {/* Row [index] */}
  //     </li>
  //   );
  // };

  return (
    <div className='w-100 fix-height revenue-scrollbar p-1'>
      <ul className='list-group'>
        {filteredData.map(resource => {
           
          return (
            resource && <li key={resource.id} className='list-group-item d-flex p-2 bg-list-outer'>
              <FarmResourceItem item={resource} {...props} />
            </li>
          );
        })}
      </ul>
    </div>
    //  <div className="w-100 fix-height">
    //         <ul className="">
    // <AutoSizer>
    //   {({ height, width }) => (
    //     <List
    //       className="list-group revenue-scrollbar px-2 pb-1 bg-white"
    //       height={500}
    //       itemCount={data.length}
    //       itemSize={40}
    //       overscanCount={4}
    //       width={width}>
    //       {renderList}
    //     </List>
    //   )}
    // </AutoSizer>
    //      </ul>
    // </div>
  );
};

export default FarmResourceItemList;
