import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { findCenterCoordinateIndex } from '../../utils/map-utils';
import MarkerWithInfoBox from './MarkerWithInfoBox';

const { compose, withProps } = require('recompose');
const { withScriptjs, withGoogleMap, GoogleMap } = require('react-google-maps');
const { MarkerClusterer } = require('react-google-maps/lib/components/addons/MarkerClusterer');
// 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDuSYpAv3M_Z8rimKO7rV8-Go6-FKmWhJ4&v=3.exp&libraries=geometry,drawing,places',

// AIzaSyAzq9cefHYPN3XW3mlfzQdHHv-4SPpfYXM
const FarmersCluster = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyCpxNJ4OZjOzEBggaJf_ntGCBO9CO800ko&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px`, margin: '1%' }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => {

  return (
    <GoogleMap defaultZoom={3} defaultCenter={{ lat: 9.078013, lng: 7.403295 }}>
      <MarkerClusterer
        onClick={props.onMarkerClustererClick}
        averageCenter
        enableRetinaIcons
        gridSize={60}
      >
        {props.points.map(marker => (
          <MarkerWithInfoBox
            onClick={props.onToggleOpen}
            key={marker.id}
            marker={marker}
            farms={props.farms}
          />
        ))}
      </MarkerClusterer>
    </GoogleMap>
  );
});

class FarmersOnCluster extends Component {
  render() {
    var points = [];
    _.valuesIn(this.props.farms).forEach(farm => {
      
      if (farm.mapMarkers.length > 2) {
        var jsonObj = {};
        jsonObj['id'] = farm.id;
        jsonObj['point'] = JSON.parse(farm.mapMarkers)[
          findCenterCoordinateIndex(JSON.parse(farm.mapMarkers))
        ];
        points.push(jsonObj);
      }
    });

    return <FarmersCluster farms={this.props.farms} points={points} />;
  }
}

const mapStateToProps = state => {
  return {
    selectedFarmItemIndex: state.farmResource.selectedFarmItemId,
    farmItems: state.farmResource.farmItemsByCommunityResource
  };
};

export default withRouter(connect(mapStateToProps)(FarmersOnCluster));
